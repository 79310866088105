import React, { useState } from 'react'
import {
  Avatar,
  Button,
  logger,
  variantProvider,
  View,
  Text,
  Theme,
} from '@/app'
import { Carousel, ProfileAbout, Toast, Image, Link } from '@/shared'
import { useAppSelector } from '@/lib'
import { useListings } from '@/services/api'
import { onMount, onUpdate } from '@codeleap/common'
import { AppStatus } from '@/redux'

export default function Info(props) {
  const {
    setShowInfo,
    showInfo,
    tab,
    setTab,
    currOffer,
    setCurrOffer,
    Offers,
  } = props

  const { profile: myProfile } = useAppSelector((store) => store.Session)

  const isSmall = Theme.hooks.down('mid')

  logger.log({ currOffer })

  const handleCompleteOffer = () => {

    Offers.update
      .send({
        id: currOffer.id,
        target_listing: currOffer.target_listing.id,
        status: 'CP',
      })
      .then((res) => {
        if (res.status === 'CP') {
          Toast.success('Offer accepted')
          setCurrOffer(res)
          setShowInfo(false)
          AppStatus.toggleFeedbackModal()
        }
      })
  }

  const handleWithdrawOffer = () => {
    Offers.update
      .send({
        id: currOffer.id,
        target_listing: currOffer.target_listing.id,
        status: 'WD',
      })
      .then((res) => {
        if (res.status === 'WD') {
          Toast.success('Offer withdrawn')
          setCurrOffer(null)
          setShowInfo(false)
        }
      })
  }

  const handleDeleteOffer = () => {
    Offers.delete.send(currOffer.id).then(() => {
      setShowInfo(false)
      setCurrOffer(null)
    })
  }

  const myOffer = myProfile?.id === currOffer?.created_by?.id

  const isCompleted = currOffer?.status === 'CP'

  const mobileConditions = showInfo && isSmall && tab === 3

  return (
    <View
      css={[
        showInfo || mobileConditions ? styles.wrapper : styles.wrapperDisabled,
      ]}
      variants={['column']}
    >
      <View variants={['alignCenter', 'justifySpaceBetween', 'marginBottom:2']}>
        <Text variants={['h3', 'bold']} text='Information' />
        <Button
          variants={['icon']}
          icon={'close'}
          onPress={() => {
            setShowInfo(false)
            setTab(2)
          }}
        />
      </View>
      <View>
        <Carousel
          styleArrow={styles.slickArrow}
          stylePrevArrow={styles.slickArrowPrev}
          styleNextArrow={styles.slickArrowNext}
        >
          {currOffer?.target_listing?.images?.map((image, index) => (
            <Image key={index} css={styles.Carousel} source={image.url} />
          ))}
        </Carousel>
      </View>
      <View
        css={styles.middleWrapper}
        variants={['column', 'marginTop:2', 'gap:2']}
      >
        <View variants={['column', 'gap:2']}>
          <Text
            variants={['h3', 'bold']}
            text={currOffer?.target_listing?.title}
          />
          <View variants={['alignCenter', 'gap:1']}>
            <Text variants={['h4']} text={'Exchange for:' + ''} />
            <Text
              variants={['h4', 'bold']}
              text={currOffer?.target_listing?.exchange_for}
            />
          </View>
          <View variants={['column', 'gap:1']}>
            <Text variants={['bold']} text='Description' />
            <Text
              text={
                currOffer?.target_listing?.description
                  ? currOffer?.target_listing?.description
                  : 'No Description provided.'
              }
            />
          </View>
        </View>
        <View variants={['column', 'gap:2']}>
          <Text variants={['h3', 'bold']} text='Listed by' />
          <ProfileAbout profile={currOffer?.target_listing?.profile} small />
          <Link
            css={styles.button}
            variants={['alignCenter', 'flex', 'alignCenter']}
            to={`/profile/`}
          >
            <Text css={styles.buttonText} text='See Profile' />
            <Button variants={['icon']} icon={'arrowRight'} />
          </Link>
        </View>
        <View variants={['gap:2', 'fullWidth']}>
          {!myOffer && !isCompleted && (
            <Button text={'Complete Offer'} onPress={handleCompleteOffer} />
          )}
          {!isCompleted && (
            <Button
              variants={['transparent']}
              text={myOffer ? 'Withdraw Offer' : 'Delete Offer'}
              onPress={myOffer ? handleWithdrawOffer : handleDeleteOffer}
            />
          )}
        </View>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      width: '100%',
      height: '100vh',
      opacity: 1,
      visibility: 'visible',
      transition: '200ms',
    },
    wrapperDisabled: {
      opacity: 0,
      visibility: 'hidden',
      width: 0,
      height: 0,
    },
    middleWrapper: {
      gap: Theme.spacing.value(1.5),
    },
    button: {
      gap: Theme.spacing.value(1),
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    buttonText: {
      color: '#818181',
    },
    Carousel: {
      width: '100%',
      height: 300,
      borderRadius: '4px',
    },
    offersButton: {
      width: '50%',
    },
    slickArrow: {
      zIndex: Theme.values.zIndex.slickArrow,
      top: '45%',
      padding: 0,
      width: 48,
      borderRadius: '50%',
      backgroundColor: Theme.colors.light.white,
      boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 25px',
    },
    slickArrowPrev: {
      left: '5%',
      width: 38,
      height: 38,
    },
    slickArrowNext: {
      right: '5%',
      width: 38,
      height: 38,
      left: 'unset',
    },
  },
  true,
)
