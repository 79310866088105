import { createSlice } from '@codeleap/common'

export type TAppStatus = {
  status: 'loading' | 'done' | 'idle'
  feedbackModalOpen: boolean
  signinModalOpen: boolean
  signupModalOpen: boolean
  newLocationModalOpen: boolean
  myLocationsModalOpen: boolean
  filtersModalOpen: boolean
  myLocationModalOpen: boolean
  offerModalOpen: boolean
  categoriesModalOpen: boolean
  dateModalOpen: boolean
  timeModalOpen: boolean
  offerCurrencyModalOpen: boolean
  newSearchModalOpen: boolean
  savedSearchesModalOpen: boolean

}

const initialState: TAppStatus = {
  status: 'idle',
  feedbackModalOpen: false,
  signinModalOpen: false,
  signupModalOpen: false,
  newLocationModalOpen: false,
  myLocationsModalOpen: false,
  filtersModalOpen: false,
  myLocationModalOpen: false,
  offerModalOpen: false,
  categoriesModalOpen: false,
  dateModalOpen: false,
  timeModalOpen: false,
  offerCurrencyModalOpen: false,
  newSearchModalOpen: false,
  savedSearchesModalOpen: false,
}

export const appStatusSlice = createSlice({
  name: 'AppStatus',
  initialState,
  reducers: {
    set(state, status: TAppStatus['status']) {
      return {
        status,
      }
    },
    toggleSigninModal(state) {
      console.log({ state })
      return {
        signinModalOpen: !state.signinModalOpen,
      }
    },
    toggleSignupModal(state) {
      return {
        signupModalOpen: !state.signupModalOpen,
      }
    },
    toggleNewLocationModal(state) {
      return {
        newLocationModalOpen: !state.newLocationModalOpen,
      }
    },
    toggleMyLocationModal(state) {
      return {
        myLocationModalOpen: !state.myLocationModalOpen,
      }
    },
    toggleMyLocationsModal(state) {
      return {
        myLocationsModalOpen: !state.myLocationsModalOpen,
      }
    },
    toggleOfferModal(state) {
      return {
        offerModalOpen: !state.offerModalOpen,
      }
    },
    toggleCategoriesModal(state) {
      return {
        categoriesModalOpen: !state.categoriesModalOpen,
      }
    },
    toggleDateModal(state) {
      return {
        dateModalOpen: !state.dateModalOpen,
      }
    },
    toggleTimeModal(state) {
      return {
        timeModalOpen: !state.timeModalOpen,
      }
    },
    toggleOfferCurrencyModal(state) {
      return {
        offerCurrencyModalOpen: !state.offerCurrencyModalOpen,
      }
    },
    toggleNewSearchModal(state) {
      return {
        newSearchModalOpen: !state.newSearchModalOpen,
      }
    },
    toggleFiltersModal(state) {
      return {
        filtersModalOpen: !state.filtersModalOpen,
      }
    },
    toggleSavedSearchesModal(state) {
      return {
        savedSearchesModalOpen: !state.savedSearchesModalOpen,
      }
    },
    toggleFeedbackModal(state) {
      return {
        feedbackModalOpen: !state.feedbackModalOpen,
      }
    },
  },
  asyncReducers: {
  },
})
