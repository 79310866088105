/* eslint-disable max-len */
import React, { useRef, useState } from 'react'
import { Button, Icon, Touchable, Text, logger, Select, TextInput, variantProvider, View, Theme } from '@/app'
import { TimePicker } from './TimePicker'
import 'react-dates/initialize'
import { formatNums } from '@/lib/utils/helpers'
import { useClickOutside } from '@codeleap/web'
import { Calendar, utils } from 'norama-react-modern-calendar-datepicker'
import { v4 } from 'uuid'
import { format } from 'date-fns'

type DateInputProps = {
  label?: string
  textInputStyles?: any
  inputValue?: any
  fromToday?: boolean
  onlyFuture?: boolean
  showTime?: boolean
}

export const DateInput: React.FC<DateInputProps> = (props) => {
  const { label, textInputStyles, inputValue, onChange, fromToday, onlyFuture, showTime, ...rest } = props

  const [isFocused, setIsFocused] = useState(false)
  const ref = useClickOutside(() => setIsFocused(false))
  const [selectedDay, setSelectedDay] = useState(null)
  const [timer, setTimer] = useState({
    isFocused: false,
    zone: 'AM',
    hour: 0,
    min: 0,
  })

  const inputId = useRef(v4()).current

  const formatedDateVal = format(new Date(inputValue), 'MM/dd/yyyy')

  const handleSelect = (date) => {
    const formatedDate = `${date?.month}-${date?.day}-${date?.year}`
    setSelectedDay(date)
    setIsFocused(false)
    onChange(new Date(formatedDate))
  }

  const renderCalendar = () => {
    return (
      <>
        <TextInput label={label} value={`${formatedDateVal} - ${formatNums(timer?.hour)}:${formatNums(timer?.min)}${timer?.zone}`} styles={textInputStyles} onFocus={() => setIsFocused(true)} />
        <View css={[styles.calendarWrapper, isFocused ? styles.calendarActive : styles.calendarDisabled]} variants={['absolute']}>
          <Calendar
            value={selectedDay}
            onChange={handleSelect}
            shouldHighlightWeekends
            calendarClassName='modern-calendar'
            calendarRangeBetweenClassName={'modern-calendar-range-between'}
            calendarRangeStartClassName={'modern-calendar-range-edge'}
            calendarRangeEndClassName={'modern-calendar-range-edge'}
            {...(fromToday && { minimumDate: utils().getToday() })}
            renderFooter={() => <View css={styles.footerWrapper} variants={['fullWidth', 'justifySpaceBetween', 'relative']}>
              {showTime && (
                <View variants={['absolute']}>
                  <TimePicker state={timer} setState={setTimer} />
                </View>
              )}
            </View>}
          />
        </View>
      </>
    )
  }

  return (
    <View variants={['column', 'relative']} ref={ref} id={inputId}>
      {renderCalendar()}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    inputsWrapper: {
      width: '100%',

    },
    arrow: {
      position: 'absolute',
      top: 25,
    },
    arrowPrev: {
      left: 22,
    },
    arrowNext: {
      right: 22,
    },
    calendarWrapper: {
      top: '100%',
      transition: '300ms',
      zIndex: 99999999999999999,
      '.rdrCalendarWrapper': {
        fontSize: 14,
        boxShadow: '2px 4px 16px rgb(0 0 0 / 24%)',
        borderRadius: Theme.borderRadius.medium,
        fontFamily: 'Oxygen',
        fontWeight: 400,
      },
      '.rdrDay': {
        width: 40,
        height: 40,
      },
      '.rdrDayEndPreview': {
        borderRadius: Theme.borderRadius.small,
        color: `${Theme.colors.light.primary} !important`,
      },
      '.rdrDayHovered': {
        borderRadius: `${Theme.borderRadius.small}px !important`,
        border: 'none !important',
        color: 'transparent !important',
      },
      '.rdrSelected': {
        borderRadius: Theme.borderRadius.small,
        color: `${Theme.colors.light.primary} !important`,
      },
    },
    calendarActive: {
      opacity: 1,
      visibility: 'visible',
      zIndex: 99999999,
    },
    calendarDisabled: {
      opacity: 0,
      visibility: 'hidden',
    },
  },
  true,
)
