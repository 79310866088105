export const NoResults = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 124 124'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M56.8333 98.1667C79.6611 98.1667 98.1667 79.6611 98.1667 56.8333C98.1667 34.0056 79.6611 15.5 56.8333 15.5C34.0056 15.5 15.5 34.0056 15.5 56.8333C15.5 79.6611 34.0056 98.1667 56.8333 98.1667Z'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.7861 57.0183C28.7861 41.4261 41.4261 28.7861 57.0183 28.7861'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M108.5 108.5L86.0254 86.0254'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 107H25.0714'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
      />
      <path
        d='M98 19H109.071'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
      />
    </svg>
  );
};
