import ReactPlaceholder from 'react-placeholder';
import { View, Theme, variantProvider } from '@/app';
import { RectShape } from 'react-placeholder/lib/placeholders';

export const CardsSkeleton = (props) => {
  const { ready, children } = props;

  const renderSkeleton = (
    <View variants={['gap:2']} css={styles.cardsWrapper}>
      {Array(3)
        .fill(0)
        .map(() => (
          <RectShape
            color={Theme.colors.light.skeleton}
            style={{
              width: '100%',
              height: 450,
              borderRadius: Theme.borderRadius.medium,
            }}
          />
        ))}
    </View>
  );

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
      
    >
      {children}
    </ReactPlaceholder>
  );
};

const NUM_COL = 3;

const styles = variantProvider.createComponentStyle(
  {
    cardsWrapper: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: `repeat(${NUM_COL}, calc(${100 / NUM_COL}% - ${
        (Theme.spacing.value(2) * (NUM_COL - 1)) / NUM_COL
      }px)) `,
      gap: Theme.spacing.value(2),
      [Theme.media.down('mid')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
  },
  true
);
