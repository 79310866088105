import { RouterPageComposition, RouterPageStyles } from '@codeleap/web'
import { Theme, variantProvider } from '../theme'

const defaultStyles = RouterPageStyles

const createRouterPageStyle = variantProvider.createVariantFactory<RouterPageComposition>()

export const AppRouterPageStyles = {
  ...defaultStyles,
  default: createRouterPageStyle((theme) => ({
    ...defaultStyles.default(theme),

    content: {
      display: 'flex',
      width: '100%',
    },
    wrapper: {
      width: '100%',
    },
    // "menuItem:icon": {
    //   color: 'red',
    // },
    "menuItem:icon:selected": {
      color: 'black',
    },


    // menuItem:text": {
    
    //   textDecoration: 'none',
    // },
    router: {
      width: '100%',
    },
    topMenu: {
      width: '100%',
    },
    sideMenu: {
      width: '100%',
      flexDirection: 'column',
      gap: Theme.spacing.value(2),
    },
    "menuItem": {
      display: 'flex',
      textDecoration: 'none',
      alignItems: 'center',
      gap: Theme.spacing.value(1),
    },

    "menuItem:text": {
      textTransform: 'none',
      // color: 'black',
      // display: 'flex',
      // textDecoration: 'none',
    },
  })),
}