import { logger } from '@/app'
import { CategoryTypes } from '@/types'

export const OptionsToQueryString = (params: Record<string, CategoryTypes.Filter>) => {

  const options = Object.values(params)

  const searchParams = new URLSearchParams()

  options
    .forEach((optionOrCategory) => {
      let key = optionOrCategory.option

      logger.log({ opcaoOuCategory: optionOrCategory })

      if (optionOrCategory?.title === 'Category') {
        key = optionOrCategory.option.toLowerCase().replace(/\s/g, '_')
      }

      if (optionOrCategory.value?.trim?.()) {
        searchParams.append(key, optionOrCategory.value)
        return
      }

      if (optionOrCategory?.number) {
        searchParams.append(key, optionOrCategory.number)
      }

      if (optionOrCategory?.text) {
        searchParams.append(key, optionOrCategory.text)
      }

      if (optionOrCategory?.value) {
        searchParams.append(key, optionOrCategory.value[0])
      }

      if (optionOrCategory?.date) {
        searchParams.append(key, optionOrCategory.date)
      }

      if (optionOrCategory.selects.length === 0) {
        return ''
      }

      const selectedValues = optionOrCategory.selects.map((item) => {

        const select = optionOrCategory.selectValues.find((s) => s.id === item)

        return select?.title
      })

      const value = selectedValues.join(',')

      if (key && value) {
        searchParams.append(key, value)
      }
    })

  return searchParams.toString()
}

