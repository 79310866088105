import { Modal, Theme, variantProvider, View } from '@/app'
import { useAppSelector, newLocationForm } from '@/lib'
import { AppStatus } from '@/redux'
import { useForm, onUpdate } from '@codeleap/common'
import { NewLocationsForm } from '@/layout/Account'

type LocationModalProps = any

export const LocationsModal: React.FC<LocationModalProps> = (props) => {
  const { onCreateLocation } = props

  const form = useForm(newLocationForm, {
    output: 'json',
    validateOn: 'blur',
  })

  const { newLocationModalOpen, myLocationModalOpen } = useAppSelector(
    (store) => store.AppStatus,
  )

  onUpdate(() => {
    form.setFormValues({
      title: '',
    })
  }, [newLocationModalOpen])

  const handleToggleModal = () => {
    AppStatus.toggleNewLocationModal()
    form.setFormValues({
      title: '',
      address: null,
      coords: { lat: null, lng: null },
    })
  }

  return (
    <View>
      <Modal
        visible={newLocationModalOpen}
        toggle={handleToggleModal}
        title={'New Location'}
        styles={{

          box: styles.box,

        }}
      >
        <NewLocationsForm onCreateLocation={onCreateLocation} form={form} />
      </Modal>
    </View>
  )
}

const styles = variantProvider.createComponentStyle({
  box: {
    width: 550,
  },
}, true)
