import { api } from '@/app'
import { createSlice } from '@codeleap/common'

const MODULE_NAME = 'categories/'

const initialState = {
  categories: [],
  options: [],
  error: null,
}

export const categoriesSlice = createSlice({
  name: 'Categories',
  initialState,
  reducers: {

  },
  asyncReducers: {
    getData: async (state, setState) => {
      api.get(MODULE_NAME)
        .then(({ data }) => {
          setState({ categories: data.results.filter(c => c.title !== 'Contact') })

        }).catch(() => {

          setState({
            error: {
              message: 'Error fetching data',
            },
          })

        })
    },
    getOptions: async (state, setState) => {
      api.get(MODULE_NAME + 'options/?limit=999999999')
        .then(({ data }) => {
          setState({ options: data.results })

        }).catch(() => {

          setState({
            error: {
              message: 'Error fetching data',
            },
          })

        })
    },
  },
})
