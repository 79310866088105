export const CameraPlaceholder = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M31.1254 0.5C30.6859 0.5 30.33 0.856223 30.33 1.29539V4.07942H27.5459C27.1065 4.07942 26.7506 4.43565 26.7506 4.87481C26.7506 5.31426 27.1068 5.6702 27.5459 5.6702H30.33V8.45423C30.33 8.89369 30.6862 9.24962 31.1254 9.24962C31.5648 9.24962 31.9208 8.8934 31.9208 8.45423V5.6702H34.7048C35.1443 5.6702 35.5002 5.31397 35.5002 4.87481C35.5002 4.43535 35.144 4.07942 34.7048 4.07942H31.9208V1.29539C31.9208 0.855932 31.5645 0.5 31.1254 0.5ZM12.0344 9.25005C11.7239 9.25282 11.4812 9.41012 11.3508 9.6354L8.79031 14.0227H3.68191C1.93296 14.0227 0.5 15.4557 0.5 17.2046V32.3183C0.5 34.0673 1.93296 35.5002 3.68191 35.5002H28.3411C30.0901 35.5002 31.523 34.0673 31.523 32.3183V17.2046C31.523 15.4557 30.0901 14.0227 28.3411 14.0227H23.2327L20.6723 9.6354C20.5316 9.40097 20.2625 9.24921 19.9889 9.25005H12.0344ZM12.4819 10.8408H19.5414L22.0894 15.216C22.2273 15.4549 22.4972 15.6119 22.773 15.6138H28.341C29.2361 15.6138 29.9318 16.3096 29.9318 17.2046V32.3183C29.9318 33.2134 29.236 33.9091 28.341 33.9091H3.68182C2.78679 33.9091 2.09105 33.2133 2.09105 32.3183V17.2046C2.09105 16.3096 2.78685 15.6138 3.68182 15.6138H9.24989C9.52566 15.6119 9.7956 15.4551 9.93349 15.216L12.4814 10.8408H12.4819ZM16.0116 17.9999C12.5065 17.9999 9.64783 20.8583 9.64783 24.3638C9.64783 27.8689 12.5062 30.7276 16.0116 30.7276C19.5168 30.7276 22.3755 27.8689 22.3755 24.3638C22.3755 20.8586 19.5171 17.9999 16.0116 17.9999ZM16.0116 19.5907C18.657 19.5907 20.7843 21.7181 20.7843 24.3634C20.7843 27.0087 18.657 29.1361 16.0116 29.1361C13.3663 29.1361 11.239 27.0087 11.239 24.3634C11.239 21.7181 13.3663 19.5907 16.0116 19.5907Z'
        fill='black'
        fillOpacity='0.2'
      />
    </svg>
  );
}