import ReactPlaceholder from 'react-placeholder';
import { View, Theme, variantProvider } from '@/app';
import {
  TextBlock,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders';
import { ProductInfoSkeleton } from './ProductInfo';

export const InfoSkeleton = (props) => {
  const { ready, children } = props;

  const renderSkeleton = (
    <View
      css={styles.wrapper}
      variants={['gap:1', 'marginTop:2', 'column']}
    >
      <TextBlock color={Theme.colors.light.skeleton} rows={1} />
      <RectShape
        color={'rgb(234, 234, 234)'}
        style={{
          width: '100%',
          borderRadius: Theme.borderRadius.medium,
          height: 280,
        }}
      />
      <TextBlock color={Theme.colors.light.skeleton} rows={6} />
      <View>
        <RectShape
          color={Theme.colors.light.skeleton}
          style={{
            width: '170px',
            height: '110px',
            borderRadius: Theme.borderRadius.medium,
          }}
        />
        <TextBlock rows={4} color={Theme.colors.light.skeleton} />
      </View>
    </View>
  );

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  );
};

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      width: 280,
      // height: '100vh',
    },
    image: {
      width: '65%',
      height: 600,
    },
    details: {
      width: '35%',
    },
    teste: {
      div: {
        backgroundColor: Theme.colors.light.skeleton,
        borderRadius: Theme.borderRadius.small,
        height: '25px !important',
      },
    },
  },
  true
);
