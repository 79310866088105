import { Theme } from '@/app'

export const FooterFacebook = (props) => {
  return (
    <svg
      width='14'
      height='22'
      viewBox='0 0 14 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13 5.29985H10.6244H9.99355C9.45045 5.29985 9.01015 5.74015 9.01015 6.2833V9.7337H13L12.4156 13.5322H9.01015V20.5H4.62255V13.5322H1V9.7337H4.57575L4.62255 6.12815L4.6159 5.47355C4.59395 3.30109 6.33725 1.52217 8.5097 1.5002C8.52295 1.50007 8.5362 1.5 8.54945 1.5H13V5.29985Z'
        fill={Theme.colors.light.primary}
        fillOpacity='0.3'
        strokeWidth='1.9'
        strokeLinejoin='round'
      />
    </svg>
  );
};
