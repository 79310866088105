import { CenterWrapperComposition } from '@codeleap/common'
import { Theme, variantProvider } from '../theme'

const defaultStyle = variantProvider.getDefaultVariants('CenterWrapper')

const createCenterWrapperVariant = variantProvider.createVariantFactory<CenterWrapperComposition>()

export const AppCenterWrapperStyles = {
  ...defaultStyle,
  default: createCenterWrapperVariant((theme) => ({
    wrapper: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      width: '100%',
    },
    innerWrapper: {
      flex: 1,
      display: 'flex',
      width: '80%',
      maxWidth: 1280,
      [Theme.media.down('xxlarge')]: {
        paddingLeft: Theme.spacing.value(8),
        paddingRight: Theme.spacing.value(8),
      },
      // [Theme.media.down('large')]: {
      //   paddingLeft: Theme.spacing.value(12),
      //   paddingRight: Theme.spacing.value(12),
      // },
      // [Theme.media.down('largeish')]: {
      //   paddingLeft: Theme.spacing.value(8),
      //   paddingRight: Theme.spacing.value(8),
      // },
      [Theme.media.down('mid')]: {
        paddingLeft: Theme.spacing.value(4),
        paddingRight: Theme.spacing.value(4),
      },
      [Theme.media.down('small')]: {
        paddingLeft: Theme.spacing.value(2),
        paddingRight: Theme.spacing.value(2),
      },
    },
  }))
}