import {
  Avatar,
  Button,
  Image,
  logger,
  variantProvider,
  View,
  Text,
  Theme,
  TextInput,
  Touchable,
} from '@/app'

import { NoItems } from '@/shared'

import { MessagesSkeleton } from '@/skeletons'
import { useDebounce } from '@codeleap/common'

import Preview from './Preview'

export type AllMessagesProps = {

}

export default function AllMessages(props) {
  const {
    profile,
    offers,
    tab,
    setTab,
    setSearch,
    currOffer,
    setCurrOffer,
    Messages,
    loading,
  } = props

  const isSmall = Theme.hooks.down('mid')

  return (
    <View
      variants={['column', 'gap:2']}
      css={[
        offers.length > 0 && styles.whitePre,
        !isSmall || (isSmall && tab === 1)
          ? styles.wrapper
          : styles.wrapperDisabled,
      ]}
    >
      <View variants={['fullWidth', 'alignCenter', 'justifySpaceBetween']}>
        <Text text='Chat' variants={['h3', 'bold']} />
      </View>
      <TextInput
        onChange={(e) => {
          setSearch(e.target.value)
        }}
        variants={['hideErrors', 'withHover']}
        placeholder='Search'
        leftIcon={{
          name: 'search',
        }}
        styles={{
          icon: styles.icon,
        }}
      />
      <MessagesSkeleton ready={!loading}>
        <View css={styles.messagesWrapper} variants={['column', 'gap:2']}>
          {offers?.length > 0 ? (
            offers.map((offer, index) => (
              <Preview
                key={index}
                offer={offer}
                setTab={setTab}
                currOffer={currOffer}
                setCurrOffer={setCurrOffer}
                Messages={Messages}
              />
            ))
          ) : (
            <NoItems
              icon={'noChat'}
              title={"You've got nothing here"}
              subtitle={
                'Make or accept an offer to start a conversation with a seller or a buyer.'
              }
              wrapperStyle={styles.noItemsWrapper}
            />
          )}
        </View>
      </MessagesSkeleton>
    </View>
  )
}

const ALL_MESSAGES_WIDTH = 350

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      width: ALL_MESSAGES_WIDTH,
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    wrapperDisabled: {
      display: 'none',
    },
    messagesWrapper: {
      height: '60vh',
      overflowY: 'scroll',
    },
    icon: {
      color: 'rgba(0,0,0,0.4)',
      stroke: 'rgba(0,0,0,0.4)',
    },
    whitePre: {
      // whiteSpace: 'pre',
    },
    noItemsWrapper: {
      maxWidth: '100%',
    },
  },
  true,
)
