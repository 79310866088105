import { includes, find } from 'lodash'
import { logger } from '@/app'
import { getGeocode } from 'use-places-autocomplete'

const getAddressFromGeocode = (geocodeResults) => {
  const fallbackResponse = { formatted_address: (geocodeResults[0]?.types[0] != 'plus_code' && geocodeResults[0]?.formatted_address) || 'Your Location' }
  try {
    if (geocodeResults.length) {
      const cityFilter = o => includes(o.types, 'administrative_area_level_2') || includes(o.types, 'sublocality')
      const stateFilter = o => includes(o.types, 'administrative_area_level_1')
      const countryFilter = o => includes(o.types, 'country')
      const city = geocodeResults.find(g => g.address_components.find(cityFilter))?.address_components.find(cityFilter)
      const state = geocodeResults.find(g => g.address_components.find(stateFilter))?.address_components.find(stateFilter)
      const country = geocodeResults.find(g => g.address_components.find(countryFilter))?.address_components.find(countryFilter)
      if (city == undefined || state == undefined || country == undefined) return fallbackResponse
      return {
        city: city ? city?.long_name : '',
        state: state.short_name,
        country: country.long_name,
      }
    }
    return fallbackResponse
  } catch (err) {
    return fallbackResponse
  }
}

export const GetGeocodeName = (location, onSuccess = () => null, onFailure = () => null) => {

  getGeocode({ location }).then(result => {
    // logger.log({ location })
    const address = getAddressFromGeocode(result)
    const description = address.formatted_address || `${address.city} ${address.state}, ${address.country}`
    onSuccess(description)
  }).catch(err => {
    // logger.log({err})
  })
}

export const geocodeLatLng = (maps, center) => {

  const geocoder = maps.Geocoder()
  const map = maps.Map()

  const latLng = {
    lat: parseFloat(center.lat),
    lng: parseFloat(center.lng),
  }

  geocoder
    .geocode({ location: latLng })
    .then((response) => {
      if (response.results[0]) {
        map.setZoom(11)

        const marker = new google.maps.Marker({
          position: latLng,
          map: map,
        })

        return response.results[0].formatted_address

      } else {
        window.alert('No results found')
      }
    })
    .catch((e) => window.alert('Geocoder failed due to: ' + e))
}
