import { Image } from '@/shared';
import { toast,  } from 'react-toastify';

function info(message) {
  toast.info(message);
}

function success(message) {
  toast.success(message, {
    icon: ({ theme, type }) => (
      <Image
        source={'check.png'}
        styles={{
          backgroundColor: 'transparent',
        }}
      />
    ),
  });
}

function error(message) {
  toast.error(message);
}

export default {
  info,
  success,
  error,
};
