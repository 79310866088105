import React from 'react'
import {
  View,
  Theme,
  variantProvider,
  RouterPage,
  CenterWrapper,
  logger,
  Settings,
} from '@/app'
import { LoginSignupModal, OfferModal, MapStatus } from '@/shared'
import { useMapsApi } from '@/lib/hooks'
import { CenterWrapperProps, url } from '@codeleap/web'
import { useBooleanToggle, useComponentStyle } from '@codeleap/common'

import Header from './header'
import { Footer } from './Footer'
import { Helmet } from 'react-helmet'

type PageProps = CenterWrapperProps & {
  center?: boolean
  withRouter?: boolean
  basePath?: string
  title?: string
}

export const Page: React.FC<PageProps> = (props) => {
  const {
    children,
    center = true,
    basePath,
    title,
    withRouter,
    hideFooter,
    hideHeaderInputs,
    showCategories,
    filterProps,
    headerInputProps,
    fullWidth,
    ...routerProps
  } = props

  const styles = useComponentStyle(componentStyles)

  const status = useMapsApi()

  const content = withRouter ? (
    <RouterPage basePath={basePath} title={title} {...routerProps}>
      {children}
    </RouterPage>
  ) : (
    children
  )

  return (
    <>
      {status === 'ready' && (
        <>
          <View variants={['column']} css={[styles.wrapper]}>
            {!withRouter && <Helmet>{title && <title>{title}</title>}</Helmet>}

            <Header fullWidth={fullWidth} headerInputProps={headerInputProps} filterProps={filterProps} showCategories={showCategories} hideHeaderInputs={hideHeaderInputs} />
            {center ? <CenterWrapper>{content}</CenterWrapper> : content}
            {hideFooter ? null : <Footer />}
          </View>
          <LoginSignupModal />
        </>
      )}
    </>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    minHeight: '100vh',
    width: '100vw',
    maxWidth: '100%',
    backgroundColor: theme.colors.white,
  },
}))
