import { api, logger } from '@/app'
import { Toast } from '@/shared'
import { useAppSelector } from '@/lib'

import { logout, signupWithEmail, tryLogin, trySignup, loadOwnProfile, verificationEmail, TrySignupArgs, tryUpdateProfile } from '@/services/authentication'
import { createSlice } from '@codeleap/common'
import { Cookies } from '@codeleap/web'
import { AppStatus, Locations } from './index'

const DEB_CAT = 'Session'

type Profile = {
  id: string
  first_name: string
  last_name: string
  avatar: string
  email: string
  ratings_count: number
  average_rating: number
  username: string
  date_joined: Date
  data: {
    clothingSize: string
    currency: string
    distance_type: string
    distance_value: string
    footwearSize: string
    form: string
    region: string
    mainLocation: {
      name: string
      coords: {
        lat: number
        lng: number
      }
      address: string
    }
  }
}

export type TSession = {
  isLoggedIn: boolean
  profile: Profile
  profileId: Profile['id']
  appMounted: boolean
  loading: boolean
}

const initialState: TSession = {
  isLoggedIn: false,
  profile: null,
  appMounted: false,
  profileId: null,
  loading: false,
}

export const sessionSlice = createSlice({
  name: 'Session',
  initialState,
  reducers: {
    setMounted(state) {
      logger.log('setMounted', state, DEB_CAT)
      return {
        appMounted: true,
      }
    },
  },
  asyncReducers: {
    async setUser(state, setState, user) {
      return {
        profile: user,
      }
    },

    async login(state, setState, payload: Parameters<typeof tryLogin>[0]) {
      AppStatus.set('loading')
      const firebaseProfile = await tryLogin(payload, payload?.silent)

      if (!firebaseProfile) {
        AppStatus.set('idle')

        return 'error'
      }

      const profile = await loadOwnProfile()

      const newSession = {
        isLoggedIn: profile ? true : false,
        profile,
        loading: false,
      }

      setState(newSession)

      AppStatus.set('idle')

      // if (!profile && firebaseProfile) {
      //   setState({
      //     isLoggedIn: false,
      //     profile,
      //     loading
      //   })
      //   AppStatus.toggleSigninModal()
      //   AppStatus.toggleSignupModal()
      // }

      // if (profile && firebaseProfile) {

      //   const newSession = {
      //     isLoggedIn: true,
      //     profile,
      //     loading: false,
      //   }
      //   setState(newSession)
      // }
    },

    async updateProfile(state, setState, payload) {
      AppStatus.set('loading')

      const updatedProfile = await tryUpdateProfile(state.profile.id + '/', payload)

      if (!updatedProfile) {
        Toast.error('Error updating profile')
      }

      const profile = await loadOwnProfile()

      const newSession = {
        isLoggedIn: true,
        profile,
        loading: false,
      }

      setState(newSession)
      Toast.success('Profile updated')
      AppStatus.set('idle')
    },
    async createLocation(state, setState, payload) {

    },
    async autoLogin(state, setState) {
      try {
        const profile = await loadOwnProfile()

        if (profile) {
          setState({
            profile,
            isLoggedIn: true,
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async signup(
      state, setState, payload: TrySignupArgs) {
      AppStatus.set('loading')

      const profile = await trySignup(payload)

      if (!profile) {
        AppStatus.set('idle')
        return 'error'
      } else {
        setState({
          profile,
          isLoggedIn: true,
        })
        AppStatus.toggleSignupModal()
        AppStatus.set('idle')
      }

      // const isProfileReady = await loadOwnProfile()

      AppStatus.set('idle')

      return 'success'

    },
    async logout(state, setState) {
      AppStatus.set('loading')

      await logout()
      Cookies.remove('Session.isLoggedIn')

      setState({
        ...initialState,
        appMounted: true,
      })
      AppStatus.set('idle')
      Toast.success('Logged out')
    },
  },
})
