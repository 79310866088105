import { useState } from 'react'
import {
  Button,
  Text,
  Touchable,
  logger,
  Theme,
  View,
  variantProvider,
} from '@/app'
import { onMount, onUpdate } from '@codeleap/common'
import { Link, Avatar, RatingStars } from '@/shared'

type AboutProps = {
  bigRadius?: boolean
  small?: boolean
  profile: any
  isLink?: boolean
}

export const ProfileAbout: React.FC<AboutProps> = (props) => {
  const { small, profile, bigRadius, isLink } = props

  logger.log({ profile })

  const joynedDate = new Date(profile?.date_joined)

  const joynedYear = joynedDate.getFullYear()
  const joynedMonth = joynedDate.toLocaleString('en-US', { month: 'long' })

  const username = profile?.username

  const Wrapper = ({ children }) => {
    if (isLink) {
      return <Link to={`/profile/${profile?.id}`}>{children}</Link>
    } else {
      return <View>{children}</View>
    }
  }

  return (
    <Wrapper>
      <View variants={['alignCenter', 'gap:2']}>
        <Avatar
          profile={{
            avatar: profile?.avatar,
            username: username,
            first_name: username,
          }}
          styles={{ icon: styles.avatar }}
          variants={[
            `${bigRadius && 'bigRadius'}`,
            `${small ? 'medium' : 'large'}`,
          ]}
        />
        <View variants={['column', 'gap:1']}>
          <Text variants={[`${small ? 'h4' : 'h2'}`, 'bold']} text={username} />
          <Text variants={[`${small ? 'p3' : 'p2'}`]} text={`@${username}`} />
          <View variants={['row', 'alignCenter']}>
            <RatingStars profile={profile} />
          </View>
          <View variants={['alignCenter', 'gap:1']}>
            <View css={styles.gapOne}>
              <Text
                variants={['bold']}
                text={profile?.active_listings?.toString()}
              />
              <Text text='listings' />
            </View>
            <View css={styles.gapOne}>
              <Text
                variants={['bold']}
                text={profile?.sold_listings?.toString()}
              />
              <Text text='sold' />
            </View>
          </View>
          {!small && (
            <Text
              css={styles.joynedText}
              variants={['p2', 'marginTop:1']}
              text={`Joined in ${joynedMonth} ${joynedYear}`}
            />
          )}
        </View>
      </View>
    </Wrapper>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    avatar: {
      width: '100%',
      height: '100%',
      display: 'unset',
    },

    gapOne: {
      gap: Theme.spacing.value(0.5),
    },
    joynedText: {
      color: Theme.colors.light.quaternaryText,
    },
  },
  true,
)
