export const Monitor = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M26.9556 4.86121H5.62223C4.14947 4.86121 2.95557 6.05511 2.95557 7.52787V20.8612C2.95557 22.334 4.14947 23.5279 5.62223 23.5279H26.9556C28.4283 23.5279 29.6222 22.334 29.6222 20.8612V7.52787C29.6222 6.05511 28.4283 4.86121 26.9556 4.86121Z'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.9556 28.8612H21.6222'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.2891 23.5279V28.8612'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}