import {

  logger,
} from '@/app'
import { capitalize } from '@codeleap/common'

export type QueryToCategorySelectReturn = Record<string, CategoryTypes.Filter>
import { CategoryTypes } from '@/types'
export const QueryToCategorySelect = (qs: string, options: CategoryTypes.Option[], categories: CategoryTypes.Category[]): QueryToCategorySelectReturn => {

  const state = {} as QueryToCategorySelectReturn
  const params = new URLSearchParams(qs)

  logger.log({ paramsQueryToCategory: params })

  params.forEach((value, key) => {
    const serializedKey = key.split('_').map((str) => capitalize(str)).join(' ')

    const selectedValues = value.split(',')

    logger.log({ valTeste: value, keyTeste: key })

    if (serializedKey === 'Category') {
      const category = categories.find(c => c.title === selectedValues[0])

      if (category) {
        state[serializedKey] = {
          ...category,
          option: serializedKey,
          selectValues: categories,
          selects: [category.id],
        }
        return

      }
    }

    const opt = options.find(option => option.title === serializedKey)

    if (!opt) return

    if (!['SG', 'MT'].includes(opt.type)) {
      state[serializedKey] = {
        option: serializedKey,
        selectValues: [],
        selects: [],
        value: selectedValues.join(','),
      }
      return

    }

    const selects = opt?.selects?.filter(({ title }) => selectedValues.includes(title)).map(({ id }) => id)

    if (!selects.length) return

    state[serializedKey] = {
      ...opt,
      option: opt?.title,
      selectValues: opt?.selects,
      selects,
    }
  })
  return state
}

export const ListingToCategorySelect = (listing, categories, options) => {
  let state = {}

  const listingAttr = listing.attributes

  listingAttr?.forEach((value, key) => {

    const serializedKey = value.option.title

    const opt = options.find(option => option.title === serializedKey)

    const selects = value.selects.filter(item => item.id).map(({ id }) => id)

    state[serializedKey] = {
      ...opt,
      selectValues: opt.selects,
      option: serializedKey,
      ...(value.date && { date: new Date(value.date) }),
      ...(value.text && { text: value.text }),
      ...(value.number && { text: value.number }),
      selects,
    }
  })

  const Category = categories.find(c => c.title === listing.category)

  const categorySelected = categories.filter(c => c.title === listing.category).map(({ id }) => id)

  state = {
    ...state,
    Category: {
      ...Category,
      selectValues: categories,
      selects: categorySelected,
    },
  }

  return state
}
