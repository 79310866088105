/* eslint-disable max-lines */
import React, { useState } from 'react'
import { Theme, variantProvider } from '@/app/theme'
import _Select, { StylesConfig } from 'react-select'
import { useDefaultComponentStyle } from '@codeleap/common'
import { CustomSelectProps, TextInput, WebSelectComposition, StylesOf, Text, View } from '@codeleap/web'

export const customStyles: StylesConfig = {
  // container: (provided, state) => ({
  //   position: 'absolute',
  //   zIndex: 999999,
  // }),
  option: (provided, state) => ({
    padding: '20px',
    fontFamily: 'Oxygen',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Theme.colors.light.xWhite,
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: Theme.colors.light.white,
    border: 'none',
    boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.24)',
    borderRadius: Theme.borderRadius.small,
    width: 250,
    // overflow: 'visible',
  }),
  control: (provided, state) => ({
    backgroundColor: state.hasValue ? Theme.colors.light.primary : Theme.colors.light.xWhite,
    fontFamily: 'Oxygen',
    fontSize: 13,
    fontWeight: 700,
    color: state.hasValue ? Theme.colors.light.white : '#808080',
    display: 'flex',
    borderRadius: Theme.borderRadius.small,
    cursor: 'pointer',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#4A7D74',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: Theme.colors.light.white,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: Theme.colors.light.white,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.hasValue && Theme.colors.light.white,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
}

export const defaultStyles: StylesConfig = {
  option: (provided, state) => ({
    padding: '20px',
    fontFamily: 'Oxygen',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Theme.colors.light.xWhite,
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided, state) => ({
    ...provided,
    // ...variantStyles.buttonWrapper,
    height: Theme.values.buttonAndInputHeight,
    backgroundColor: Theme.colors.light.white,
    border: '1px solid rgb(222, 222, 222)',
    borderRadius: Theme.borderRadius.small,
    ...Theme.spacing.paddingHorizontal(1),
    fontFamily: 'Oxygen',
    padding: '10 5px',
    cursor: 'pointer',
    ':hover': {
      border: '1px solid rgb(222, 222, 222)',
      boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 25px',
    },
    // fontSize: 13,
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    border: 'none',
    boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.24)',
    borderRadius: Theme.borderRadius.small,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
}
export const filterStyles: StylesConfig = {
  option: (provided, state) => ({

    padding: '20px',
    fontFamily: 'Oxygen',
    fontWeight: 'bold',
    fontSize: 14,
    cursor: 'pointer',
    minwidth: 150,
    ':hover': {
      backgroundColor: Theme.colors.light.xWhite,
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided, state) => ({
    ...provided,
    // ...variantStyles.buttonWrapper,
    height: 38,
    minwidth: 150,
    backgroundColor: Theme.colors.light.white,
    border: '1px solid rgb(222, 222, 222)',
    borderRadius: Theme.borderRadius.small,
    ...Theme.spacing.paddingHorizontal(1),
    fontFamily: 'Oxygen',
    padding: '10 5px',
    cursor: 'pointer',
    ':hover': {
      border: '1px solid rgb(222, 222, 222)',
      boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 25px',
    },
    // fontSize: 13,
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    border: 'none',
    boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.24)',
    borderRadius: Theme.borderRadius.small,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    minwidth: 150,
    padding: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    minwidth: 150,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: 0,
    minwidth: 150,
    fontWeight: 'bold',
    fontSize: 14,
    color: Theme.colors.light.black,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    margin: 0,
    fontWeight: 'bold',
    fontSize: 14,
    color: Theme.colors.light.black,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  container: (provided, state) => ({
    ...provided,
    minWidth: 150,
  }),
}

export const headerStyles: StylesConfig = {
  option: (provided, state) => ({
    padding: '20px',
    fontFamily: 'Oxygen',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Theme.colors.light.xWhite,
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided, state) => ({
    ...provided,
    // ...variantStyles.buttonWrapper,
    height: Theme.values.buttonAndInputHeight,
    backgroundColor: Theme.colors.light.white,
    border: '1px solid rgb(222, 222, 222)',
    borderRadius: 0,
    ...Theme.spacing.paddingHorizontal(1),
    fontFamily: 'Oxygen',
    padding: '10 5px',
    cursor: 'pointer',
    ':hover': {
      border: '1px solid rgb(222, 222, 222)',
      boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 25px',
    },
    // fontSize: 13,
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    border: 'none',
    boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.24)',
    borderRadius: Theme.borderRadius.small,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
}

export const categoriesMobile: StylesConfig = {
  // container: (provided, state) => ({
  //   position: 'absolute',
  //   zIndex: 999999,
  // }),
  option: (provided, state) => ({
    padding: '20px',
    fontFamily: 'Oxygen',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Theme.colors.light.xWhite,
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  menuList: (provided, state) => ({
    backgroundColor: Theme.colors.light.white,
    border: 'none',
    boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.24)',
    borderRadius: Theme.borderRadius.small,
    width: '100%',
    // overflow: 'visible',
  }),
  control: (provided, state) => ({
    backgroundColor: state.hasValue ? Theme.colors.light.primary : Theme.colors.light.xWhite,
    fontFamily: 'Oxygen',
    fontSize: 13,
    fontWeight: 700,
    color: state.hasValue ? Theme.colors.light.white : '#808080',
    display: 'flex',
    borderRadius: Theme.borderRadius.small,
    cursor: 'pointer',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#4A7D74',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: Theme.colors.light.white,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: Theme.colors.light.white,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.hasValue && Theme.colors.light.white,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
}
