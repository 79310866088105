import React, { useState } from 'react'
import { Modal, Theme, variantProvider, View } from '@/app'
import { LoginComponent } from '../Login'
import { SignupComponent } from '../Signup'
import { useAppSelector, loginForm, signupForm } from '@/lib'
import { useForm } from '@codeleap/common'
import { AppStatus } from '@/redux'

type LoginSignupModalProps = any

export const LoginSignupModal: React.FC<LoginSignupModalProps> = (props) => {
  const [usingProvider, setUsingProvider] = useState('email')

  const { signinModalOpen, signupModalOpen } = useAppSelector(
    (store) => store.AppStatus,
  )

  const formSignup = useForm(signupForm, {
    output: 'json',
    validateOn: 'change',
  })

  const formSignin = useForm(loginForm, {
    output: 'json',
    validateOn: 'change',
  })

  return (
    <View>
      <Modal
        visible={signinModalOpen || signupModalOpen}
        toggle={
          signinModalOpen
            ? AppStatus.toggleSigninModal
            : AppStatus.toggleSignupModal
        }
        title={signinModalOpen ? 'Sign In' : 'Sign Up'}
        styles={{
          box: signinModalOpen ? styles.boxSignin : styles.boxSignup,
          body: signinModalOpen ? styles.bodySignin : styles.bodySignup,
        }}
      >
        {signinModalOpen && <LoginComponent usingProvider={usingProvider} setUsingProvider={setUsingProvider} form={formSignin} formSignup={formSignup} />}
        {signupModalOpen && <SignupComponent usingProvider={usingProvider} setUsingProvider={setUsingProvider} form={formSignup} />}
      </Modal>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    buttonsWrapper: {
      gap: Theme.spacing.value(1.5),
    },
    buttonLink: {
      width: '100%',
    },
    text: {
      color: Theme.colors.light.silver,
    },
    optionsWrapper: {
      gap: Theme.spacing.value(0.2),
    },
    boxSignin: {
      width: 450,
    },
    boxSignup: {
      width: 800,
      padding: 0,
      overflow: 'hidden',
    },
    bodySignup: {
      overflowY: 'scroll',
    },
    bodySignin: {
      overflowY: 'scroll',
    },
    box: {
      width: 550,
    },
  },
  true,
)
