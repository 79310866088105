import { api, } from '@/app';
import { createSlice } from '@codeleap/common';

const initialState = {
  items: [],
};

export const menuItemsSlice = createSlice({
  name: 'MenuItems',
  initialState,
  reducers: {},
  asyncReducers: {
    setItems: async (state, setState, items) => {
      setState({ items });
    },
  },
});
