import { Helmet } from 'react-helmet';

export const TitleComponent = (props) => {

  const { title } = props

  return (
    <Helmet>
      <title>{` ${title ? title + ' | Glocal' : 'Glocal'} `}</title>
    </Helmet>
  );
};
