import React, { useRef, useState } from 'react'
import { Button, Icon, Touchable, Text, logger, Select, TextInput, variantProvider, View, Theme } from '@/app'
import { Range, getTrackBackground } from 'react-range'
import { useClickOutside } from '@codeleap/web'
import 'rc-slider/assets/index.css'

export type SliderProps = {
  label?: string
  textInputStyles?: any
  inputValue?: any
  onChange?: (value: any) => void
}

export const SliderRange: React.FC<SliderProps> = (props) => {

  const { label, inputValue, textInputStyles, onChange } = props

  const [rangeValue, setRangeValue] = useState([50, 90])
  const [isFocused, setIsFocused] = useState(false)

  const STEP = 0.1
  const MIN = 0
  const MAX = 2000

  const ref = useClickOutside(() => setIsFocused(false))

  const inputVal = `Min: ${rangeValue[0].toFixed(1)}/ Max: ${rangeValue[1].toFixed(1)}`

  const handleChange = (values) => {
    setRangeValue(values)
    onChange(values)
  }

  const handleChangeInput = (val, index) => {
    logger.log({ val: val.target.value })
    const rangeVal = [...rangeValue]
    rangeVal[index] = parseInt(val.target.value)
    setRangeValue(rangeVal)
  }

  const renderSliderRange = () => {
    return (
      <View
        style={{
          display: 'flex',
          // justifyContent: 'center',
          flexWrap: 'wrap',
          width: 300,
        }}
      >
        <Range
          values={rangeValue}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={handleChange}

          renderTrack={({ props, children }) => (
            <View
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <View
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values: rangeValue,
                    colors: ['#E0E0E0', '#366E65', '#E0E0E0'],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: 'center',
                }}
              >

                {children}
              </View>
            </View>
          )}
          renderThumb={({ index, props, isDragged }) => (
            <View
              {...props}
              style={{
                ...props.style,
                height: 24,
                width: 24,
                borderRadius: '4px',
                backgroundColor: '#366E65',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <View
                variants={['absolute']}
                css={styles.label}

              >
                {rangeValue[index].toFixed(1)}
              </View> */}
            </View>
          )}

        />
        <View variants={['fullWidth', 'justifySpaceBetween']}>

          <View css={styles.inputWrapper} variants={['column', 'relative', 'paddingVertical:2']}>
            <Text css={styles.priceTitle} variants={['absolute']} text={'min price'} />
            <TextInput value={rangeValue[0].toFixed(1)} styles={{
              // textField: styles.input,
              innerWrapper: styles.inputInnerWrapper,
            }}
            onChange={(e) => { handleChangeInput(e, 0) }}
            />
          </View>
          <View css={styles.inputWrapper} variants={['column', 'relative', 'paddingVertical:2']}>
            <Text css={styles.priceTitle} variants={['absolute']} text={'max price'} />
            <TextInput type={'number'} max={'2000'} value={rangeValue[1]?.toFixed(1)} styles={{
              // textField: styles.input,
              innerWrapper: styles.inputInnerWrapper,
            }}
            onChange={(e) => { handleChangeInput(e, 1) }}
            />
          </View>
        </View>
      </View>
    )
  }

  return (
    <View variants={['relative']} ref={ref}>
      <TextInput label={label} value={inputVal} defaultValue={inputValue} styles={textInputStyles} onFocus={() => setIsFocused(true)} />
      <View css={[styles.sliderWrapper, isFocused ? styles.sliderActive : styles.sliderDisabled]} variants={['absolute']}>
        {renderSliderRange()}
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    inputsWrapper: {
      width: '100%',

    },
    arrow: {
      position: 'absolute',
      top: 25,
    },
    sliderWrapper: {
      top: '100%',
      transition: '300ms',
      backgroundColor: Theme.colors.light.white,
      width: 280,
      height: 140,
      alignItems: 'unset',
      ...Theme.spacing.padding(1.6),
      borderRadius: Theme.borderRadius.medium,
      boxShadow: '2px 4px 16px rgb(0 0 0 / 24%)',
    },
    sliderActive: {
      opacity: 1,
      visibility: 'visible',
    },
    sliderDisabled: {
      opacity: 0,
      visibility: 'hidden',
    },
    label: {
      color: Theme.colors.light.black,
      backgroundColor: Theme.colors.light.white,
      border: '1px solid rgba(0, 0, 0, 0.2)',
      top: '-40px',
      fontSize: '16px',
      fontFamily: 'Oxygen',
      fontWeight: 400,
      padding: '8px',
      borderRadius: '8px',
    },
    inputWrapper: {
      width: 80,
    },
    priceTitle: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.4)',
      left: '17%',
      paddingTop: Theme.spacing.value(0.8),
    },
    input: {
      marginTop: 30,
    },
    inputInnerWrapper: {
      paddingTop: Theme.spacing.value(4),
      paddingBottom: Theme.spacing.value(2),
    },
  },
  true,
)
