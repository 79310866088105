import { CenterWrapper, Theme, variantProvider, View } from '@/app'
import { CardComponent, Carousel, VerticalCard, HorizontalCard } from '@/shared'
import { CardsSkeleton } from '@/skeletons'

type CardListingProps = {
  children?: React.ReactNode
  listings: any
  ready: boolean
  cardsToShow: Number
}

export const CardListing: React.FC<CardListingProps> = (props) => {
  const { children, listings, ready, cardsToShow } = props

  const isSmall = Theme.hooks.down('mid')

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    draggable: true,
    slidesToShow: 1.2,
    slidesToScroll: 1,
  }

  const renderCards = (listingsArr) => {
    if (isSmall) {
      return (
        <Carousel {...settings}>
          {listingsArr.slice(0, cardsToShow).map((listing, index) => (
            <CardComponent key={index} listing={listing} />
          ))}
        </Carousel>
      )
    } else {
      return (
        <View variants={['fullWidth']} css={styles.cardsWrapper}>
          {listingsArr.slice(0, cardsToShow).map((listing, index) => (
            <CardComponent key={index} listing={listing} />
          ))}
        </View>
      )
    }
  }

  const CardsContainer = ({ children }) => {
    if (isSmall) {
      return (
        <View css={styles.sliderContainer} variants={['fullWidth']}>
          {children}
        </View>
      )
    } else {
      return (
        <CenterWrapper>
          <CardsSkeleton ready={ready}>
            {children}
          </CardsSkeleton>
        </CenterWrapper>
      )
    }
  }

  return <CardsContainer>{renderCards(listings)}</CardsContainer>
}

const NUM_COL = 3

const styles = variantProvider.createStylesheet({
  buttonsWrapper: {
    width: '12%',
  },
  noCardsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardsWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(${NUM_COL}, calc(${100 / NUM_COL}% - ${
      (Theme.spacing.value(2) * (NUM_COL - 1)) / NUM_COL
    }px)) `,
    gap: Theme.spacing.value(2),
    [Theme.media.down('mid')]: {
      display: 'flex',
      gap: Theme.spacing.value(2),
    },
  },
  noItemsWrapper: {
    maxWidth: '100%',
  },

  sliderContainer: {
    '.slick-track': {
      display: 'flex',
      gridGap: Theme.spacing.value(4),
      lineHeight: 'unset !important',
    },
    '.slick-slide img': {
      width: 'unset',
    },
    '.slick-list': {
      margin: 'unset !important',
      padding: 'unset !important',
      overflow: 'unset !important',
    },
    [Theme.media.down('small')]: {
      '.slick-track': {
        gridGap: Theme.spacing.value(2),
      },
    },
  },
})
