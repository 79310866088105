import { api, logger } from '@/app'
import { createSlice } from '@codeleap/common'

const MODULE_NAME = 'notifications/'

const initialState = {
  notifications: [],
  options: [],
  error: null,
}

export const notificationsSlice = createSlice({
  name: 'Notifications',
  initialState,
  reducers: {

  },
  asyncReducers: {
    getData: async (state, setState) => {
      api.get(MODULE_NAME + 'list?status=ST')
        .then(({ data }) => {
          setState({ notifications: data.results })

        }).catch(() => {

          setState({
            error: {
              message: 'Error fetching data',
            },
          })

        })
    },
    markRead: async (state, setState, data) => {
      logger.log({ state, data })
      try {
        logger.log({ state })

        const { data: notification } = await api.post(MODULE_NAME + 'mark_read/', data)

        setState({
          ...state,
          notifications: state.notifications.filter(notif => notif.id !== data.id),
        })

        return notification
      } catch (err) {

      }
    },
  },
})
