import ReactPlaceholder from 'react-placeholder';
import { View, Theme, variantProvider } from '@/app';
import {
  TextBlock,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders';
import { ProductInfoSkeleton } from './ProductInfo';

export const ProductDetailsSkeleton = (props) => {
  const { ready, children } = props;

  const renderSkeleton = (
    <View css={styles.wrapper} variants={['fullWidth', 'gap:1', 'marginTop:2']}>
      <View css={styles.image}>
        <RectShape
          color={'rgb(234, 234, 234)'}
          style={{
            width: '100%',
            borderRadius: Theme.borderRadius.medium,
          }}
        />
      </View>
      <ProductInfoSkeleton />
    </View>
  );

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  );
};

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      // height: '100vh',
    },
    image: {
      width: '65%',
      height: 600,
    },
    details: {
      width: '35%',
    },
  },
  true
);
