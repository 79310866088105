import { api, logger } from '@/app'
import { createSlice } from '@codeleap/common'
import { AppStatus, Session } from '@/redux'
import { Toast } from '@/shared'
import { getDifference, updateArrayComparing } from '@/lib/utils/helpers'

const MODULE_NAME = 'profiles/locations/'

export type Location = {
  address: string
  coords: {
    lat: number
    lng: number
  }
  id: number
  main_location: boolean
  my_location: boolean
  title: string
}

type PostState = {
  locations: Location[]
  loading: boolean
  error: {
    message: string
  } | null
}

const initialState: PostState = {
  locations: [],
  loading: false,
  error: null,
}

export const locationsSlice = createSlice({
  name: 'Locations',
  initialState,
  reducers: {

  },
  asyncReducers: {
    getData: async (state, setState) => {
      api.get(MODULE_NAME + '?limit=99999999')
        .then(({ data }) => {
          setState({ loading: false, locations: data.results })

        }).catch(() => {

          setState({
            error: {
              message: 'Error fetching data',
            },
          })

        })
    },
    delete: async (state, setState, id) => {
      api.delete(MODULE_NAME + id + '/')
        .then(({ data }) => {
          // setState({ ...state, locations: state.locations.filter(location => location.id !== id) })
          AppStatus.set('idle')

        }).catch(() => {
          AppStatus.set('idle')
        })
    },
    post: async (state, setState, data) => {
      try {
        const { data: location } = await api.post(MODULE_NAME, data)

        setState({ ...state, locations: [...state.locations, location] })

        return location

      } catch (err) {
        AppStatus.set('idle')
        logger.log({ err })
      }
    },
    update: async (state, setState, data) => {
      try {
        const { data: location } = await api.patch(MODULE_NAME + data.id + '/', data)

        // setState({ ...state, locations: state.locations.map(location => location.id === data.id ? data : location) })

        return location
      } catch (err) {
        logger.log({ err })
      }
    },

    updateProfileLocations: async (state, setState, { data }) => {
      try {
        const { locationsCreate, locationsUpdate, locationsDelete } = data

        let locations = [...state.locations]

        logger.log({ firstLocations: locations })

        locationsCreate?.map(create => api.post(MODULE_NAME, create).then((res) => {
          locations.push(res.data)
        }))

        locationsUpdate?.map(update => api.patch(MODULE_NAME + update.id + '/', update))

        locationsDelete?.map(loc => api.delete(MODULE_NAME + loc.id + '/'))

        locations = getDifference(locations, locationsDelete)

        logger.log({ locations })

        setState({ ...state, locations })

      } catch (err) {
        logger.log({ err })
      }
    },
  },
})
