/* eslint no-restricted-imports: 'off' */

import React, { useState, useRef } from 'react';
import { Button, View, Text, Theme, Touchable, FileInput, logger } from '@/app';
import { Image } from '@/shared';
import { FileInputRef, StylesOf } from '@codeleap/web';
import { variantProvider } from '@/app/theme';
import { AvatarStyles } from '@/app/stylesheets/Avatar';
import {
  AvatarComposition,
  ComponentVariants,
  matchInitialToColor,
  WebInputFile,
} from '@codeleap/common';
import { TSession } from '@/redux';

type AvatarProps = {
  styles?: StylesOf<AvatarComposition>;
  profile: TSession['profile'];
  onChange?: (file: WebInputFile) => void;
} & ComponentVariants<typeof AvatarStyles>;

export const Avatar: React.FC<AvatarProps> = (props) => {
  const { variants, responsiveVariants, styles } = props;

  const input = useRef<FileInputRef>(null);
  const [editImage, setEditImage] = useState(false);

  const variantStyles = variantProvider.getStyles(AvatarStyles, {
    variants,
    responsiveVariants,
    styles,
  });
  const profile = props.profile;

  const getLetterFromName = (pos) => {
    const name = profile?.firstName || '';
    const start = pos;
    const end = start + 1;
    if (name) {
      return name.substring(start, end);
    } else {
      return null;
    }
  };

  const renderImage = () => {
  
    if (!profile?.avatar && !profile?.username)
      return (
        // <Button
        //   variants={['mediumIcon']}
        //   css={[variantStyles.icon]}
        //   icon={'user'}
        //   color={'black'}
        //   styles={{
        //     icon: {
        //       color: 'black',
        //     }
        //   }}
        // />
        <Button
          variants={['mediumIcon']}
          css={[variantStyles.icon]}
          icon={'user'}
          color={'black'}
          styles={{
            icon: {
              color: 'black',
            }
          }}
        />
      );

    const hasAvatar = !!profile?.avatar;

    if (hasAvatar) {
      const image = profile?.avatar;
      return <Image css={[variantStyles.image]} source={image} />;
    } else {
      // const initial = profile?.first_name.substring(0, 1);

      return (
        <Touchable
          css={[pageStyles.avatarButton, variantStyles.wrapper]}
          variants={['justifyCenter', 'alignCenter', 'flex']}
          onPress={() => null}
        >
          <Text text={profile?.first_name ? profile?.first_name[0] : profile?.username[0]} variants={['textCenter', 'h2']} css={variantStyles.text} />
        </Touchable>
      );
    }
  };

  const image = renderImage();
  const canEdit = props.hasOwnProperty('onChange');
  const disabled = !canEdit;

  const onPress = () => {
    input.current.openFilePicker();
  };

  const onFileChange = ([data]) => {
    props.onChange(data);
  };

  const onHoverImage = () => {
    setEditImage(!editImage);
  };

  return (
    <Touchable
      css={[variantStyles.general, pageStyles.button, disabled && AvatarStyles.disabled]}
      disabled={disabled}
      onPress={onPress}
      // onHover={canEdit && onHoverImage}
    >
      {image}
      {editImage && (
        <>
          {/* <Touchable css={variantStyles.editImageBubble} onPress={onPress}>
          </Touchable> */}
          <View css={variantStyles.editing}>
            <Text text={'EDIT'} css={variantStyles.text} />
          </View>
        </>
      )}
      <View css={variantStyles.fileInput}>
        <FileInput
          css={variantStyles.fileInput}
          onFileSelect={onFileChange}
          ref={input}
        />
      </View>
    </Touchable>
  );
};

const pageStyles = variantProvider.createComponentStyle(
  {
    avatarButton: {
      borderRadius: '28px',
      backgroundColor: Theme.colors.light.lightGreen,
      // width: '48px !important',
      // height: 48,
      // '&:hover': {
      //   backgroundColor: '#a0ba9b',
      // },
    },
    
  },
  true
);
