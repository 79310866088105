import { Button, variantProvider, View, Settings, Theme } from '@/app'
import { useMapsApi } from '@/lib/hooks'
import GoogleMapReact from 'google-map-react'
import { MapStyles } from '@/data'

export type MapProps = {
  wrapperStyle?: any
  wrapperVariants?: any
}

export const MapComponent: React.FC<MapProps> = (props) => {
  const { children, wrapperStyle, wrapperVariants, ...mapProps } = props
  //Configure this apiKey in Settings.ts
  return (
    <View css={wrapperStyle} variants={wrapperVariants}>

      <GoogleMapReact
        bootstrapURLKeys={{ key: Settings.ApiKeys.GoogleMap }}
        yesIWantToUseGoogleMapApiInternals
        // resetBoundsOnResize={true}

        {...mapProps}
      >
        {children}
      </GoogleMapReact>

    </View>
  )
}
