/* eslint-disable max-len */
import React, { useState } from 'react'
import {
  Button,
  variantProvider,
  View,
  Text,
  TextInput,
  Theme,
  logger,
} from '@/app'
import { LocationInput } from '@/shared'
import { AppStatus } from '@/redux'

export type AccountLocationsProps = {
  locationsForm?: any
  profileMainLocationForm?: any
}

export default function AccountLocations(props) {
  const { locationsForm } = props

  const [showDropdown, setShowDropdown] = useState(false)

  const locationsFormValues = locationsForm.values.locations

  const otherLocations = locationsFormValues?.filter(loc => loc.main_location === false)

  const mainLocation = locationsFormValues?.find(loc => loc.main_location === true)

  const handleDeleteLocation = (location) => {
    const locationsCopy = [...locationsFormValues]
    const updatedLocations = locationsCopy.filter(loc => loc.row_number !== location.row_number)

    logger.log({ locationsCopy, location })

    locationsForm.setFieldValue('locations', updatedLocations)
  }

  const handleChangeLocationName = (value, location, isMainLoc) => {
    const locationsCopy = [...locationsFormValues]

    const isFirstMainLocation = isMainLoc && !mainLocation

    const currentLocation = !isFirstMainLocation ? locationsCopy.find(({ row_number }) => location.row_number === row_number) : { main_location: true, row_number: 0 }

    currentLocation.title = value

    isFirstMainLocation && locationsCopy.push(currentLocation)

    locationsForm.setFieldValue('locations', locationsCopy)
  }

  const handleChangeLocation = (address, lat, lng, location, isMainLoc) => {
    const locationsCopy = [...locationsFormValues]
    const isFirstMainLocation = isMainLoc && !mainLocation

    const currentLocation = !isFirstMainLocation ? locationsCopy.find(({ row_number }) => location.row_number === row_number) : { main_location: true, row_number: 0 }

    currentLocation.address = address
    currentLocation.coords = { lat, lng }

    isFirstMainLocation && locationsCopy.push(currentLocation)

    locationsForm.setFieldValue('locations', locationsCopy)
  }

  const renderLocations = () => {
    return (
      <>
        <Text
          css={styles.othersText}
          text='Others'
          variants={['marginTop:2', 'marginBottom:2']}
        />
        <View variants={['column', 'gap:2']}>
          {otherLocations?.map((location, index) => (
            <View key={index} variants={['gap:2', 'fullWidth', 'alignCenter']}>
              <TextInput
                variants={['withHover']}
                styles={{ wrapper: { width: '30%' }}}
                value={location.title}
                placeholder='Location Name'
                onChangeText={(val) => handleChangeLocationName(val, location, false)}
              />
              <LocationInput
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                showDefaultValue
                defaultValue={location?.address}
                inputWrapperStyles={styles.inputWrapper}
                placeholder='London, UK'
                onPress={(e, lat, lng) => { handleChangeLocation(e, lat, lng, location, true) }}
              />
              <Button
                variants={['icon', 'xSmallIcon']}
                icon='trash'
                onPress={() => handleDeleteLocation(location)}
              />
            </View>
          ))}
        </View>
      </>
    )
  }

  return (
    <View variants={['column']}>
      <View>
        <Text
          variants={['h3', 'veryBold', 'marginBottom:2', 'textCenter']}
          text='My locations'
        />
      </View>
      <View variants={['fullWidth', 'column']}>

        <Text
          css={styles.othersText}
          text='Main Location'
          variants={['marginTop:2', 'marginBottom:2']}
        />

        <View css={styles.locationsWrapper} variants={['fullWidth', 'gap:2']}>
          <TextInput
            variants={['withHover']}
            placeholder='Location Name'
            styles={{ wrapper: { width: '30%' }, label: { display: 'none' }}}
            onChangeText={(val) => handleChangeLocationName(val, mainLocation, true)}
            defaultValue={mainLocation?.title}
          />
          <LocationInput
            placeholder='London, UK'
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            defaultValue={mainLocation?.address}
            showDefaultValue
            inputWrapperStyles={styles.inputWrapper}
            onPress={(e, lat, lng) => { handleChangeLocation(e, lat, lng, mainLocation, true) }}
            inputStyles={{ label: { display: 'none' }}}
          />
          <Button
            variants={['icon', 'xSmallIcon']}
            icon='trash'
            onPress={() => null}
            css={styles.mainLocationButton}
          />
        </View>
        <View />
      </View>
      {renderLocations()}
      <Button
        text='New'
        onPress={() => {
          AppStatus.toggleNewLocationModal()
        }}
        variants={['transparent', 'marginTop:2']}
        css={styles.button}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    button: {
      width: 100,
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    othersText: {
      color: Theme.colors.light.primaryText,
    },
    locationsWrapper: {
      position: 'relative',
    },
    inputWrapper: {
      width: '100%',
    },
    mainLocationButton: {
      opacity: 0,
      visibility: 'hidden',
      pointerEvents: 'none',
    },
  },
  true,
)
