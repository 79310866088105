import React, { useState } from 'react'
import { useAppSelector } from '@/lib'
import { Animated } from '../Animated'
import { onMount, onUpdate } from '@codeleap/common'
import { ActivityIndicator, View, Theme, variantProvider } from '@/app'
import { AppStatus } from '@/redux'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { IoMdCheckmark } from 'react-icons/io'

const finalTransitionDelay = 500
const doneDelay = 2000

export default function AppStatusComponent() {
  const { status } = useAppSelector(store => store.AppStatus)
  const open = ['loading', 'done', 'error'].includes(status)
  const [show, setShow] = useState(open)
  const [target, setTarget] = useState(null)

  onMount(() => {
    setTarget(document.querySelector('#body'))
  })

  onUpdate(() => {
    if (open) {
      if (open != show) {
        setShow(open)
      }
      if (target) {
        disableBodyScroll(target)
      }
      if (status == 'done') {
        // log({ activity });
        const timer = setTimeout(() => {
          AppStatus.set('idle')
        }, doneDelay)
        return () => clearTimeout(timer)
      }
    } else if (open != show) {
      clearAllBodyScrollLocks()
      const timer = setTimeout(() => {
        setShow(open)
      }, finalTransitionDelay)
      return () => clearTimeout(timer)
    }
  }, [status, target])

  function renderContent() {
    switch (status) {
      case 'loading':
        return <ActivityIndicator />
      case 'done':
        return <IoMdCheckmark color={Theme.colors.light.primary} size={50} />
      default:
        break
    }
  }

  const content = renderContent()

  const animate = open && show ? 'show' : 'hide'

  const displayOverlay = open || show

  return displayOverlay ? (
    <View variants={['fixed']} style={styles.wrapper}>
      <Animated
        // style={[styles.wrapper, wrapperAnimations[animate]]}
        style={styles.background}
        onClick={(e) => e.stopPropagation()}
        animate={animate}
        variants={wrapperAnimations}
        transition={wrapperTransition}
        initial={'hide'}
      />
      <View style={styles.innerWrapper}>
        <Animated
          animate={animate}
          variants={modalAnimations}
          transition={modalTransition}
          initial={'hide'}
          style={styles.modal}
        >
          <View style={styles.content}>{content}</View>
        </Animated>
      </View>
    </View>
  ) : null
}

const modalSize = '100px'

const styles = variantProvider.createComponentStyle({
  wrapper: {
    ...Theme.presets.whole,
    ...Theme.presets.center,
    zIndex: 9999999999,
  },
  background: {
    ...Theme.presets.whole,
    ...Theme.presets.center,
    backgroundColor: `${Theme.colors.light.black}7`,
    position: 'fixed',
    zIndex: Theme.values.zIndex.appStatusBackground,
  },
  innerWrapper: {
    ...Theme.presets.center,
    ...Theme.presets.flex,
    ...Theme.presets.fullView,
  },
  modal: {
    ...Theme.presets.center,
    borderRadius: Theme.borderRadius.medium,
    backgroundColor: Theme.colors.light.white,
    width: modalSize,
    height: modalSize,
    zIndex: 99999999999999,
  },
  content: {
    ...Theme.presets.center,
    display: 'flex',
    height: '100%',
  },
}, true)

const wrapperAnimations = {
  show: {
    opacity: '100%',
  },
  hide: {
    opacity: '0%',
  },
}

const modalAnimations = {
  show: {
    opacity: '100%',
    scale: 1,
  },
  hide: {
    opacity: '0%',
    scale: 0.5,
  },
}

const durations = {
  modal: 200,
  wrapper: 300,
}

const wrapperTransition = {
  duration: durations.wrapper / 1000,
  ease: 'linear',
}
const modalTransition = {
  duration: durations.modal / 1000,
  ease: 'backInOut',
}
