import React from 'react'
import {
  Button,
  Checkbox,
  logger,
  variantProvider,
  View,
  Text,
  Theme,
} from '@/app'
import { Image, Link } from '@/shared'
import { useAppSelector } from '@/lib'

export const Display =
  (props) => {
    const {
      listing,
      Listings,
      createOffer,
      onChangeCheckbox,
      selectedListings,
      isSelected,
      setHoveredItem,
      bundles,
    } = props

    const { categories } = useAppSelector((store) => store.Categories)

    const handleDeleteListing = (e) => {
      // Listings.delete.send(listing.id);
      Listings.deleteByTypes.send({
        id: listing.id,
        isItems: !bundles,
      })
    }

    // const categoryByListing

    const categoryByListing = categories.find(
      (category) => category.title === listing.category,
    )

    return (
      <>
        <Link css={styles.linkWrapper} to={`/product/${listing?.id}/detail`}>
          <Image
            css={styles.image}
            source={listing?.images[0]?.url}
          />

          <View variants={['column', 'gap:2', 'justifyCenter']} responsiveVariants={{
            mid: ['gap:1'],
          }}>
            <Text
              css={styles.productLink}
              text={listing?.title}
              variants={['h3', 'bold']}
            />
            <View variants={['alignCenter', 'gap:1']}>
              <Image
                css={styles.categoryIcon}
                source={categoryByListing?.icon}
              />
              <Text
                css={styles.middleText}
                text={categoryByListing?.title}
                variants={['p2']}
              />
            </View>
            <Text
              text={`Exchange for ${listing?.exchange_for}`}
              variants={['p2', 'clamp']}
            />
          </View>
        </Link>
        <View variants={['gap:2']}>
          <Link to={`/product/${listing?.id}/edit`}>
            <Button icon='editSecondary' variants={['icon', 'xSmallIcon']} />
          </Link>
          <Button
            icon='trash'
            onPress={handleDeleteListing}
            variants={['icon', 'xSmallIcon']}
            onMouseEnter={() => setHoveredItem(true)}
            onMouseLeave={() => setHoveredItem(false)}
          />

        </View>
      </>
    )
  }

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      borderBottom: `1px solid ${Theme.colors.light.silver}`,
      ...Theme.spacing.paddingVertical(3),
    },
    linkWrapper: {
      display: 'flex',
      gap: Theme.spacing.value(2),
      flex: 1,
    },
    middleText: {
      color: Theme.colors.light.lightGrayText,
    },
    image: {
      borderRadius: Theme.borderRadius.medium,
      height: 140,
      width: 140,
      border: `1px solid ${Theme.colors.light.xWhite}`,
      objectFit: 'cover',
      [Theme.media.down('mid')]: {
        height: 110,
        width: 110,
      },
    },
    separator: {
      height: 1,
    },
    categoryIcon: {
      width: 25,
      height: 25,
      objectFit: 'contain',
    },
    productLink: {
      '-webkitLineClamp': '1',
      '-webkitBoxOrient': 'vertical',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  true,
)
