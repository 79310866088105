import { api, logger } from '@/app'
import { Toast } from '@/shared'
import { Post, AppStatus } from '@/redux'
import { useQuery, waitFor } from '@codeleap/common'

import { navigate } from 'gatsby'

const MODULE_NAME = 'listings/'

export interface Listing {
  id: number
  active: boolean
  profile: string
  created_datetime: Date
  title: string
  description?: any
  category: string
  exchange_for: string
  data: Data
  locations: Location[]
  image?: any
  listing_type: string
  bundle_items: any[]
  in_bundle: boolean
  offset: number
  expiry_datetime?: any
}
export interface Data {
  isbn: string
  genre: string
  author: string
  feed: Boolean
  language: string
  exchange_for_currency_name: string
  exchange_for_currency_amount: string
  region: string
  ticketCategory: string
  category: string
  limit: Number
}

export interface Coords {
  lat: number
  lng: number
}

export interface Location {
  id: number
  title: string
  address: string
  coords: Coords
  my_location: boolean
}

type Query = {
  count: number
  next: string
  previous: string
  results: Listing[]
}

type ListingsState = {
  listings: Query
  listing: Listing
  listingsDetail: Listing[]
  listingsByQuery: {
    profile: Query
    author: Query
    feed: Query
    category: Query
    ticketCategory: Query
    language: Query
    region: Query
    limit: Number
    lat: Number
    lng: Number
    in_bundle: Boolean
  }
}

const initialQueryState = {
  count: 0,
  next: '',
  previous: '',
  results: [],
}

export const useListings = () => useQuery({
  initialState: {
    listings: {
      ...initialQueryState,
    },
    listingsItems: {

    },
    listingsBundles: {

    },
    listingsSold: {

    },
    listingsByQuery: {
      profile: {
        ...initialQueryState,
      },
      author: {
        ...initialQueryState,
      },
      feed: {
        ...initialQueryState,
      },
      category: {
        ...initialQueryState,
      },
      ticketCategory: {
        ...initialQueryState,
      },
      language: {
        ...initialQueryState,
      },
      region: {
        ...initialQueryState,
      },
      limit: {
        ...initialQueryState,
      },
      lat: {
        ...initialQueryState,
      },
      lng: {
        ...initialQueryState,
      },
      in_bundle: {
        ...initialQueryState,
      },
      offset: {
        ...initialQueryState,
      },
    },

    listing: {},
    // listingsDetail: [],
  },
  routes: {
    getData: async ({ currentValue, setState, throwError }, state) => {
      try {
        const { data } = await api.get(MODULE_NAME)
        setState({ ...currentValue, listings: data, listing: state })
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    query: async ({ currentValue, setState, throwError }, query) => {
      try {
        const { data } = await api.get(MODULE_NAME, { params: query })
        setState({ ...currentValue, listings: data })
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    getBy: async (state, by) => {
      const newQueriesState = {
        ...state.currentValue.listingsByQuery,
      }

      for (const [key, id] of Object.entries(by)) {
        const { data } = await api.get(MODULE_NAME, {
          params: {
            [key]: id,
          },
        })
        newQueriesState[key] = data
      }
      console.log({ newQueriesState })
      state.setState({
        ...state.currentValue,
        listingsByQuery: newQueriesState,
      })
    },
    getByTypes: async ({ currentValue, setState, throwError }, query) => {
      try {
        const { data: items } = await api.get(MODULE_NAME + '?in_bundle=false', { params: query })
        const { data: bundles } = await api.get(MODULE_NAME + '?in_bundle=true', { params: query })
        const { data: sold } = await api.get(MODULE_NAME + '?sold=true', { params: query })
        setState({ ...currentValue, listingsItems: items, listingsBundles: bundles, listingsSold: sold })
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    getDetail: async ({ currentValue, setState, throwError }, id) => {

      try {
        const { data } = await api.get(MODULE_NAME + id + '/')
        // setState({...currentValue, listing: data})
        return data
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    create: async ({ currentValue, setState, throwError }, data) => {

      try {
        AppStatus.set('loading')
        const { data: listing } = await api.post(MODULE_NAME, data)

        setTimeout(() => {

          AppStatus.set('idle')

          Toast.success('Listing created successfully')
        }, 1500)

        return listing
      } catch (err) {
        Toast.error('Error creating listing')
      }
    },
    update: async ({ currentValue, setState, throwError }, { id, data }) => {

      logger.log({ id, data })

      try {
        AppStatus.set('loading')
        const { data: listing } = await api.patch(MODULE_NAME + id + '/', data)

        setTimeout(() => {

          AppStatus.set('idle')

          Toast.success('Listing updated successfully')
        }, 1500)

        return listing
      } catch (err) {
        Toast.error('Error updating listing')
      }
    },
    delete: async ({ currentValue, setState, throwError }, id) => {
      try {
        AppStatus.set('loading')
        const { data } = await api.delete(MODULE_NAME + id + '/')
        setState({
          ...currentValue,
          listingsByQuery: {
            ...currentValue.listingsByQuery,
            profile: {
              ...currentValue.listingsByQuery.profile,
              results: currentValue.listingsByQuery.profile.results.filter(listing => listing.id !== id),
            },
          },
        })
        AppStatus.set('idle')
        Toast.success('Listing deleted successfully')
        return data
      } catch (err) {
        AppStatus.set('idle')
        throwError('Error fetching data')
      }
    },
    deleteByTypes: async ({ currentValue, setState, throwError }, { id, isItems }) => {

      AppStatus.set('loading')
      try {
        const { data } = await api.delete(MODULE_NAME + id + '/')

        AppStatus.set('idle')
        Toast.success('Listing deleted successfully')

        logger.log({ currentValue })

        if (isItems) {

          setState({
            ...currentValue, listingsItems: {
              ...currentValue.listingsItems,
              results: currentValue.listingsItems.results.filter(listing => listing.id !== id),
            },
          })
        } else {
          setState({
            ...currentValue, listingsBundles: {
              ...currentValue.listingsBundles,
              results: currentValue.listingsBundles.results.filter(listing => listing.id !== id),
            },
          })
        }

        return data
      } catch (err) {
        logger.log({ err })
        AppStatus.set('idle')
        Toast.error('Error deleting Listing, please try again')
        throwError('Error fetching data')
      }
    },
    media: async ({ }, data, file) => {
      const { data: listing } = await api.post(MODULE_NAME + 'media/', data, file)
      return listing
    },
    mediaDelete: async ({ }, id) => {
      const { data } = await api.delete(MODULE_NAME + 'media/' + id + '/')

      return data
    },
  },
})
