import { Toast } from '@/shared';

export const LoginErros = (error) => {
  switch (error.code) {
    case 'auth/wrong-password':
      Toast.error('Wrong password');
      return 'Senha incorreta'
    case 'auth/user-not-found':
      Toast.error('User not found');
      return 'Usuário não encontrado'
    case 'auth/invalid-email':
      Toast.error('Invalid email');
      return 'Email inválido'
    case 'auth/email-already-in-use':
      Toast.error('Email already in use');
      return 'Email já em uso'
    default:
      return 'Erro desconhecido'
  }
}