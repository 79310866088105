import React, { useRef, useLayoutEffect } from 'react'
import {
  Button,
  logger,
  variantProvider,
  View,
  Text,
  Theme,
  TextInput,
} from '@/app'

import Message from './Message'
import Skeleton from 'react-loading-skeleton'

import { NoItems } from '@/shared'

import {
  onMount,
  onUpdate,
  useComponentStyle,
  useInterval,
  useForm,
} from '@codeleap/common'
import { useAppSelector, createMessageForm } from '@/lib'

import { useMessages, useProfiles } from '@/services/api'

import { ProductDetailsSkeleton, ChatSkeleton } from '@/skeletons'

export default function Chat(props) {
  const {
    currOffer,
    showInfo,
    setShowInfo,
    tab,
    setTab,
    setCurrOffer,
    messages,
    Messages,
    loading,
  } = props

  const { profile } = useAppSelector((store) => store.Session)

  const isSmall = Theme.hooks.down('mid')

  const form = useForm(createMessageForm, {
    output: 'json',
    validateOn: 'blur',
  })

  const isValidForm = form.isValid

  const finishedOffer = currOffer?.status !== 'AC'

  const styles = useComponentStyle(componentStyles)

  const divRef = useRef(null)

  onUpdate(() => {
    if (profile) {
      form.setFormValues({
        ...form.values,
        profile: profile?.id,
        offer: currOffer?.id,
      })
    }
  }, [profile])

  const handleSendMessage = (e) => {
    if (e) {
      e.preventDefault()
    }

    if (isValidForm) {
      Messages.create.send(form.values)
      form.setFieldValue('body', '')
    }
  }

  const messagesByDate = messages.sort((a, b) => {
    return  new Date(b.created_datetime) - new Date(a.created_datetime)
  })

  const handleBack = () => {
    setTab(1)
    setCurrOffer(null)
  }

  return (
    <View
      css={[
        !isSmall || (isSmall && tab === 2)
          ? styles.wrapper
          : styles.wrapperDisabled,
        showInfo && styles.wrapperInfo,
      ]}
      variants={['fullWidth', 'column']}
    >
      <View variants={['justifySpaceBetween', 'fullWidth', 'alignCenter']}>
        <Button down={'mid'} icon={'arrowLeft'} variants={['icon']} onPress={handleBack} />
        <Text
          variants={['h3', 'bold', 'paddingHorizontal:2']}
          text={
            currOffer?.target_listing?.profile?.username === profile?.username
              ? currOffer?.created_by?.username
              : currOffer?.target_listing?.profile?.username
          }
        />
        <View variants={['gap:2']}>
          <Button
            variants={['icon', 'mediumIcon']}
            icon='info'
            onPress={() => {
              setShowInfo(true)
            }}
          />
        </View>
      </View>
      <ChatSkeleton ready={true}>
        <View
          css={styles.messagesWrapper}
          variants={[`${messages?.length <= 0 && 'justifyCenter'}`]}
        >
          {finishedOffer && (
            <View variants={['alignCenter', 'gap:1']}>
              <View css={styles.separator} />
              <Text
                variants={['textCenter']}
                css={styles.finishedText}
                text={'Finished offer'}
              />
              <View css={styles.separator} />
            </View>
          )}
          {messagesByDate.length <= 0 &&
            !finishedOffer && (
            <NoItems
              icon={'noMessages'}
              title={"You've got no messages here"}
              subtitle={
                'Send a message typing something below in the text field.'
              }
              wrapperStyle={styles.noItemsWrapper}
            />
          )}
          {messagesByDate?.map((message, index) => (
            <Message
              key={index}
              myProfile={profile}
              message={message}
              currOffer={currOffer}
            />
          ))}
          
          
        </View>
        {currOffer?.status === 'AC' && (
          <form onSubmit={handleSendMessage}>
            <View
              variants={['fullWidth', 'alignEnd']}
              css={styles.sendMessageWrapper}
            >
              <TextInput
                placeholder='Type a message'
                variants={['fullWidth', 'searchInput']}
                {...form.register('body')}
                styles={{
                  error: styles.inputError,
                  innerWrapper: styles.textInputInnerWrapper,
                }}
              />
              <Button
                variants={['mediumIcon']}
                icon={'sendMessage'}
                color={Theme.colors.light.white}
                onPress={(e) => {
                  handleSendMessage(e)
                }}
                type={'button'}
                css={[
                  styles.sendButton,
                  isValidForm
                    ? styles.sendMessageButton
                    : styles.sendMessageButtonDisabled,
                ]}
                loading={loading}
              />
            </View>
          </form>
        )}
      </ChatSkeleton>
    </View>
  )
}

// const CHAT_WIDTH = 500

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    // width: CHAT_WIDTH,
    position: 'relative',
    opacity: 1,
    visibility: 'visible',
    [Theme.media.down('mid')]: {
      width: '100%',
    },
  },
  wrapperDisabled: {
    opacity: 0,
    visibility: 'hidden',
  },
  wrapperInfo: {
    width: '45%',
  },
  messagesWrapper: {
    overflowY: 'scroll',
    flexDirection: 'column-reverse',
    ...theme.spacing.paddingHorizontal(2),
    height: '60vh',
  },
  sendButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  sendMessageButton: {
    backgroundColor: theme.colors.primary,
    '&:hover': {
      backgroundColor: '#185047',
    },
  },
  sendMessageButtonDisabled: {
    backgroundColor: theme.colors.xLightGray,
    '&:hover': {
      backgroundColor: theme.colors.xLightGray,
    },
  },
  sendMessageWrapper: {
    bottom: '0',
    zIndex: 9,
    height: 80,
    backgroundColor: theme.colors.white,
  },
  separator: {
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    width: '100%',
  },
  finishedText: {
    color: 'rgba(0, 0, 0, 0.4)',
    whiteSpace: 'pre',
  },
  inputError: {
    display: 'none',
  },
  noItemsWrapper: {
    maxWidth: '85%',
  },
  textInputInnerWrapper: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}))
