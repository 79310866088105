import { api, logger } from '@/app'
import { useQuery, waitFor } from '@codeleap/common'
import { AppStatus, Session } from '@/redux'

const MODULE_NAME = 'messages/?limit=999999'

export const useMessages = () => useQuery({
  initialState: {
    messages: [

    ],
  },
  routes: {
    getData: async ({ currentValue, setState, throwError }) => {

      logger.log({ currentValue })

      try {
        const { data } = await api.get(MODULE_NAME)
        setState({ ...currentValue, messages: data?.results })
        return data
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    query: async ({ currentValue, setState, throwError }, query) => {
      try {
        const { data } = await api.get(MODULE_NAME, { params: query })
        setState({ ...currentValue, messages: data?.results })
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    create: async ({ currentValue, setState, throwError }, data) => {
      try {

        const { data: message } = await api.post(MODULE_NAME, data)
        logger.log({ message, currentValue })
        setState({ ...currentValue, messages: [...currentValue?.messages, message] })

        return message
      } catch (err) {
        logger.log({ err })
      }
    },
  },
})
