export const HomeArrowRight = (props) => {
  return (
    <svg
      width='0.8em'
      height='0.8em'
      viewBox='0 0 7 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.5 1L5.5 5L1.5 9'
        stroke='#818181'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
