import React from 'react'
import {
  Button,
  Text,
  variantProvider,
  Theme,
  Touchable,
  View,
  logger,
  Icon,
} from '@/app'
import { CardComponent, Image, MapCard } from '@/shared'
import { useComponentStyle, onUpdate } from '@codeleap/common'
import { useClickOutside } from '@codeleap/web'
import { useAppSelector } from '@/lib'
import { v4 } from 'uuid'

export type MarkerProps = {
  listing?: any
  card?: any
  setCard?: React.SetStateAction<any>
  hoveredItem?: any
  setHoveredItem?: React.SetStateAction<any>
}

export const Marker: React.FC<MarkerProps> = ({
  listing,
  card,
  setCard,
  hoveredItem,
  setHoveredItem,
}) => {
  const cardId = listing?.id?.toString()

  const { categories } = useAppSelector((store) => store.Categories)

  const isSmall = Theme.hooks.down('mid')

  const ref = useClickOutside(() => setCard(null))

  const handleShowCard = (item) => {
    setCard && setCard(item)
  }

  const handleHoverMarker = (hover) => {
    hover ? setHoveredItem(listing) : setHoveredItem(null)
  }

  const categoryByListing = categories.find(
    (category) => category.title === listing?.category,
  )

  const styles = useComponentStyle(componentStyles)

  return (
    <>
      <View css={styles.wrapper} >
        <Touchable
          key={listing.id}
          css={[
            styles.marker,
            hoveredItem?.id === listing.id && styles.markerHovered,
          ]}
          onHover={setHoveredItem && handleHoverMarker}
          onPress={() => {
            handleShowCard(listing)
          }}
        >
          {/* <Image css={styles.image} source={listing?.images[0]} /> */}
          <View
            css={styles.markerInnerWrapper}
            variants={['rpw', 'padding:1', 'gap:1', 'alignStart']}
          >
            <Image source={categoryByListing?.icon} css={styles.categoryIcon} />
            {/* <Text variants={['p3', 'thin']} text={'In exchange for:'} /> */}
            {listing.exchange_for && (
              <Icon name='swipe' style={styles.categoryIcon} />
            )}
            <Text
              variants={['p3', 'bold']}
              text={`${
                listing?.data?.exchange_for_currency_amount
                  ? listing?.data?.exchange_for_currency_amount
                  : listing?.exchange_for
              }`}
            />
          </View>
        </Touchable>
        {card && card.id === listing.id && !isSmall && (
          <View css={styles.cardWrapper} ref={ref}>
            <MapCard listing={card} />
          </View>
        )}
      </View>
      {card && card.id === listing.id && isSmall && (
        <View css={styles.cardWrapper} ref={ref}>
          <MapCard listing={card} />
        </View>
      )}
    </>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    position: 'relative',
    // width: 180,
    paddingTop: theme.spacing.value(1),
    paddingBottom: theme.spacing.value(1),
  },
  marker: {
    cursor: 'pointer',
    transition: '200ms',
    transform: 'scale(1)',
    backgroundColor: theme.colors.white,
    borderRadius: theme.borderRadius.medium,
    border: `1px solid ${theme.colors.xWhite}`,
    textAlign: 'center',
    fontWeight: 900,
    boxShadow: 'rgb(0 0 0 / 4%) 0px 0px 0px 1px, rgb(0 0 0 / 18%) 0px 2px 4px',

    '&:hover': {
      backgroundColor: theme.colors.xWhite,
      transform: 'scale(1.077)',
      zIndex: 9,
    },
  },
  markerHovered: {
    backgroundColor: theme.colors.xWhite,
    transform: 'scale(1.077)',
    zIndex: 9,
  },
  markerInnerWrapper: {},
  image: {
    width: '40%',
    borderTopLeftRadius: theme.borderRadius.medium,
    borderBottomLeftRadius: theme.borderRadius.medium,
    objectFit: 'cover',
  },
  cardWrapper: {
    position: 'absolute',
    zIndex: 999,
    [Theme.media.down('mid')]: {
      position: 'fixed',
      left: '-200px',
      top: 170,
    },
    [Theme.media.down('small')]: {
      // position: 'fixed',
      // left: '-160px',
      top: 110,
    },
    [Theme.media.down('smallish')]: {
      position: 'fixed',
      left: '-160px',
      top: 100,
    },
  },
  categoryIcon: {
    width: 15,
    height: 15,
    objectFit: 'contain',
  },
}))
