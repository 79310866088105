import React from 'react'
import {
  Button,
  View,
  Text,
  TextInput,
  logger,
  Theme,
  Touchable,
  variantProvider,
} from '@/app'
import { AppStatus, Session } from '@/redux'
import { useForm } from '@codeleap/common'
import { Social } from './Social'
import { loginForm } from '@/lib'
import { Toast } from '@/shared'

export type LoginProps = {
  form?: any
  formSignup?: any
}

export const LoginComponent: React.FC<LoginProps> = (loginProps) => {

  const { form, formSignup, usingProvider, setUsingProvider } = loginProps

  const handleSuccess = () => {
    Toast.success('Login successfully')
    AppStatus.toggleSigninModal()
  }

  const handleSubmit = async () => {
    const isValid = form.validateAll(true)
    if (isValid) {
      try {
        const res = await Session.login({
          withProvider: 'email',
          data: form.values,
        })

        if (res?.profile) {
          handleSuccess()
        }

      } catch (err) {
        logger.log({ err })

      }
    }
  }

  const handleSignupModal = () => {
    AppStatus.toggleSignupModal()
    AppStatus.toggleSigninModal()
  }

  return (
    <View variants={['column']} css={styles.wrapper}>
      <View variants={['column', 'gap:2']}>
        <TextInput {...form.register('email')} variants={['withHover']} />
        <TextInput
          {...form.register('password')}
          variants={['withHover']}
          visibilityToggle
        />
        <Button
          text={'Sign in'}
          variants={['marginVertical:1', 'marginHorizontal:auto', 'fullWidth']}
          disabled={!form.isValid}
          onPress={handleSubmit}
        />
      </View>
      <Social isLogin form={formSignup} usingProvider={usingProvider} setUsingProvider={setUsingProvider} />
      <View variants={['row', 'center', 'marginTop:4']}>
        <Touchable css={styles.touchableWrapper} onPress={handleSignupModal}>
          <Text
            text='Not a user? Sign up now.'
            variants={['p2', 'secondaryColor', 'bold']}
          />
        </Touchable>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    touchableWrapper: {
      cursor: 'pointer',
    },
    wrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
    },
  },
  true,
)
