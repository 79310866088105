import React from 'react';
import { Button, variantProvider, Theme } from '@/app';
import Slider, { Settings, CustomArrowProps } from 'react-slick';
import { FaBars, FaTimes } from 'react-icons/fa';

export type CarouselProps = {
  styleArrow?: any;
  stylePrevArrow?: any;
  styleNextArrow?: any;
  styleArrowSmall?: any;
};

const CarouselSlick: React.FC<CarouselProps> = (props, ref) => {
  const { children, styleArrow, stylePrevArrow, styleNextArrow, styleArrowSmall, ...sliderProps } = props;

  const isSmall = Theme.hooks.down('mid');

  const PrevArrow = ({
    onClick,
    currentSlide,
    slideCount,
  }: CustomArrowProps) => {
    return (
      <Button
        css={[
          styles.slickArrow,
          styles.slickPrevArrow,
          styleArrow,
          isSmall && styleArrowSmall,
          stylePrevArrow,
          // currentSlide === 0 && styles.slickArrowDisabled,
        ]}
        variants={['icon', 'mediumIcon', 'absolute']}
        icon={'arrowLeft'}
        onPress={onClick}
        styles={{ icon: styles.icon, text: { display: 'none' } }}
      />
    );
  };

  const NextArrow = ({
    onClick,
    currentSlide,
    slideCount,
  }: CustomArrowProps) => {
    return (
      <Button
        css={[
          styles.slickArrow,
          styles.slickNextArrow,
          styleArrow,
          styleNextArrow,
          isSmall && styleArrowSmall,
          // currentSlide === slideCount - 1 && styles.slickArrowDisabled,
        ]}
        variants={['icon', 'mediumIcon', 'absolute']}
        icon={'arrowRight'}
        onPress={onClick}
        styles={{ icon: styles.icon, text: { display: 'none' } }}
      />
    );
  };

  const settings: Settings = {
    arrows: true,
    centerMode: false,
    dots: false,
    infinite: true,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Slider ref={ref} {...settings} {...sliderProps}>
      {children}
    </Slider>
  );
};


const styles = variantProvider.createComponentStyle(
  {
    slickArrow: {
      zIndex: Theme.values.zIndex.slickArrow,
      top: '45%',
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      ...Theme.spacing.paddingHorizontal(1.6),
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.80)',
      },
      padding: 0,
      width: '10%',
      height: 48,
    },
    slickArrowDisabled: {
      backgroundColor: 'rgba(255, 255, 255, 0.55)',
      pointerEvents: 'none',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.55)',
      },
    },
    slickPrevArrow: {
      right: '80%',
    },
    slickNextArrow: {
      left: '80%',
    },
    icon: {
      width: '40%',
      height: '40%',
    },
  },
  true
);

export const Carousel = React.forwardRef(CarouselSlick);
