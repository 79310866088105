
import { WebRadioInputStyles } from '@codeleap/web'
import { RadioInputComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

const createRadioInputStyle = variantProvider.createVariantFactory<RadioInputComposition>()
const defaultStyles = WebRadioInputStyles

export const AppRadioInputStyles = {
  ...defaultStyles,
  default: createRadioInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      // flexDirection: 'row',
    },
    listWrapper: {
      ...defaultStyles.default(theme).listWrapper,
      gap: theme.spacing.value(1),
    },
    button: {
      ...defaultStyles.default(theme).button,
      border: '1px solid black',
    },
  })),
}
