import React from 'react';

import { Button, View, Text } from '@/app';
import { TitleComponent, Link, } from '@/shared';

import { variantProvider } from '@/app/theme';

export const NotFound: React.FC = () => {
  return (
    <>
      <TitleComponent />
      <View
        variants={[
          'column',
          'fullWidth',
          'fullHeight',
          'alignCenter',
          'justifyCenter',
          'gap:2',
        ]}
        css={styles.wrapper}
      >
        <Text variants={['h2']} text={'This Page is not available'} />
        <Text
          variants={['h4', 'textCenter']}
          text={
            'The link may not be working or the Page may have been removed. Check that the link you are trying to open is correct.'
          }
          css={styles.subtitle}
        />
        <Link css={styles.button} to={'/'}>
          <Button variants={['fullWidth']} text='Home' />
        </Link>
      </View>
    </>
  );
};

const styles = variantProvider.createStylesheet({
  wrapper: {
    minHeight: '100vh',
  },
  subtitle: {
    width: '60%',
  },
  button: {
    width: '25%',
  },
});
