import ReactPlaceholder from 'react-placeholder'
import { View, Theme, variantProvider } from '@/app'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'

export const ExploreSkeleton = (props) => {
  const { ready, children } = props

  const renderTextBlock = (width) => {
    return (
      <TextBlock
        style={{
          width: width,
        }}

        color={Theme.colors.light.skeleton}
      />
    )
  }

  const Card = () => {
    return (
      <View variants={['fullWidth', 'gap:1']}>
        <RectShape
          color={Theme.colors.light.skeleton}
          style={{
            width: '180px',
            height: '178px',
            borderRadius: Theme.borderRadius.medium,
          }}
        />
        <View css={styles.textsWrapper} variants={['column', 'gap:2', 'justifyCenter']}>
          {renderTextBlock('150px')}
          {renderTextBlock('180px')}
          {renderTextBlock('120px')}
          {renderTextBlock('190px')}
        </View>
      </View>
    )
  }

  const renderSkeleton = (
    <View css={styles.wrapper} variants={['fullWidth', 'gap:2', 'column', 'marginTop:1.5']}>
      <TextBlock style={{
        width: '50%',
        marginBottom: '16px',
      }} color={Theme.colors.light.skeleton} />
      <Card />
      <Card />
      <Card />
      <Card />
    </View>
  )

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  )
}

const styles = variantProvider.createStylesheet({
  wrapper: {
    // marginTop: Theme.spacing.value(16.7),
  },
  textsWrapper: {
    height: 178,
  },
})
