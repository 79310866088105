import React, { useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { View, Theme, variantProvider } from '@/app';
import {
  TextBlock,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders';

export const AvatarSkeleton = (props) => {
  const { ready, children } = props;

  const renderSkeleton = (
    <>
      <View
        variants={['column', 'justifySpaceBetween', 'gap:2']}
        css={styles.details}
      >
        <RectShape
          color={Theme.colors.light.xMediumGray}
          style={{
            width: 48,
            height: 48,
            borderRadius: '50%',
          }}
        />
      </View>
    </>
  );

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  );
};

const styles = variantProvider.createComponentStyle(
  {
    details: {
      width: '35%',
    },
  },
  true
);
