import { View, Button, variantProvider, Text, TextInput, Theme, logger } from '@/app'
import React, { useState } from 'react'
import { useClickOutside } from '@codeleap/web'
import { defaultFieldValues } from '@codeleap/common'

type TimePickerProps = {
  dateValue?: any
  setDateValue?: React.Dispatch<React.SetStateAction<any>>
  isFrom?: boolean
  defaultValue?: any
  onChange?: any
}

export const TimePicker: React.FC<TimePickerProps> = (props) => {

  const { defaultValue, state, setState, onChange } = props

  const isAM = state?.zone === 'AM'

  const handleIncrease = (isHour) => {

    const isMaxHour = state.hour === 12
    const isMaxMin = state.min === 60

    if (isHour) {
      setState(prev => {
        return {
          ...prev,
          hour: isMaxHour ? 0 : prev.hour + 1,
        }
      })
      // onChange()
    } else {
      setState(prev => {
        return {
          ...prev,
          min: isMaxMin ? 0 : prev.min + 1,
        }
      })
      // onChange()
    }
  }

  const handleDecrease = (isHour) => {
    if (isHour) {
      setState(prev => {
        return {
          ...prev,
          hour: prev.hour - 1,
        }
      })
      // onChange()
    } else {
      setState(prev => {
        return {
          ...prev,
          min: prev.min - 1,
        }
      })
      // onChange()
    }
  }

  const handleToggleTimeZone = (value) => {
    setState(prev => {
      return {
        ...prev,
        zone: value,
      }
    })
    // onChange()
  }

  function formatNums(num) {
    if (num < 10) {
      return '0' + num
    } else {
      return num
    }
  }

  return (
    <View css={styles.wrapper} variants={['gap:2', 'alignCenter']}>
      <View variants={['gap:2', 'alignCenter', 'justifyCenter']}>
        <View variants={['column']}>
          <Button variants={['icon']} icon={'arrowUp'} onPress={() => {
            handleIncrease(true)
          }} />
          <TextInput defaultValue={state?.hour} variants={['noBorderColor']} type={'number'} min={0} max={12} value={formatNums(state?.hour)} step={0} />
          <Button variants={['icon']} icon={'arrowDown'} onPress={() => {
            handleDecrease(true)
          }} />
        </View>
        <Text text={':'} />
        <View variants={['column']}>
          <Button variants={['icon']} icon={'arrowUp'} onPress={() => {
            handleIncrease(false)
          }}/>
          <TextInput variants={['noBorderColor']} defaultValue={state?.min} type={'number'} min={0} max={60} value={formatNums(state?.min)} />
          <Button variants={['icon']} icon={'arrowDown'} onPress={() => {
            handleDecrease(false)
          }} />
        </View>
      </View>
      <View variants={['column', 'gap:1']}>
        <Button variants={[`${!isAM && 'transparent'}`]} text={'AM'} css={styles.timeZoneBtn} onPress={() => { handleToggleTimeZone('AM') }} />
        <Button variants={[`${isAM && 'transparent'}`]} text={'PM'} css={styles.timeZoneBtn} onPress={() => { handleToggleTimeZone('PM') }} />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      backgroundColor: Theme.colors.light.white,
      // width: 250,
      borderRadius: Theme.borderRadius.medium,
      boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.24)',
      padding: `${Theme.spacing.value(2)}px  !important`,
    },
    calendarContainer: {
      height: '100%',
      width: '100%',
      transition: '300ms',
    },
    timeZoneBtn: {
      width: 56,
      height: 36,
    },
  },
  true,
)
