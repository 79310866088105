import { useState, useEffect } from 'react'

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
}

export const usePosition = (watch = false, userSettings = {}) => {
  const settings = {
    ...defaultSettings,
    ...userSettings,
  }

  const [position, setPosition] = useState({})
  const [error, setError] = useState(false)
  const [geoStatus, setGeoStatus] = useState('pending')

  const onChange = ({ coords, timestamp }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      speed: coords.speed,
      heading: coords.heading,
      timestamp,
    })
    setGeoStatus('success')
  }

  const onError = (error) => {
    setError(true)
    setGeoStatus('error')
  }

  useEffect(() => {
    if (!navigator || !navigator.geolocation) {
      setError(true)
      setGeoStatus('error')
      return
    }

    if (watch) {
      const watcher =
        navigator.geolocation.watchPosition(onChange, onError, settings)
      return () => navigator.geolocation.clearWatch(watcher)
    }

    navigator.geolocation.getCurrentPosition(onChange, onError, settings)
  }, [
    settings.enableHighAccuracy,
    settings.timeout,
    settings.maximumAge,
  ])

  return { ...position, error, geoStatus }
}
