/* eslint-disable max-lines */
import React from 'react'

import { Button, View, Text, CenterWrapper, Theme } from '@/app'

import { Link, Image } from '@/shared'

import { variantProvider } from '@/app/theme'

type FooterProps = any

export const Footer: React.FC<FooterProps> = (props) => {
  return (
    <View css={styles.wrapper} variants={['marginTop:auto']}>

      <CenterWrapper variants={['column']} >
        <View css={styles.separator} />
        <View variants={['fullWidth', 'justifySpaceBetween']} css={styles.footerInnerWrapper}>
          <View variants={['gap:2']}>
            <Image
              source='logo.png'
              alt='GlocalX'
              css={styles.logoImage}
            />
            <View variants={['column', 'alignSelfCenter', 'gap:1']}>
              <View variants={['gap:2']}>
                <Link css={styles.text} to={'/'} text='Privacy Policy' />
                <Link css={styles.text} to={'/'} text='Terms of Use' />
                <Link css={styles.text} to={'/'} text='Cookies' />
              </View>
              <Text
                css={styles.text}
                text='GlocalX © 2022 - All the rights reserved - 8502 Preston Rd. Inglewood, Maine 98380'
              />
            </View>
          </View>
          <View variants={['alignSelfCenter', 'gap:2']}>
            <Button icon={'footerFacebook'} variants={['icon']} />
            <Button icon={'footerInstagram'} variants={['icon']} />
            <Button icon={'footerLinkedin'} variants={['icon']} />
          </View>
        </View>
      </CenterWrapper>
    </View>

  )
}

const WRAPPER_MIN_HEIGHT = 480

const styles = variantProvider.createStylesheet({
  wrapper: {
    flexDirection: 'column',
    height: 200,
  },
  separator: {
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    marginBottom: Theme.spacing.value(2),
  },
  footerInnerWrapper: {
    alignContent: 'center',
    [Theme.media.down('mid')]: {
      gap: Theme.spacing.value(2),
      flexDirection: 'column',
    },
  },
  logoImage: {
    objectFit: 'cover',
    width: 'max(100px, min(calc(0px + 9vw), 20px))',
    [Theme.media.is('small')]: {
      marginLeft: Theme.spacing.value(2),
    },
  },
  text: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: 14,
  },
})
