import {
  View,
  Touchable,
  logger,
  Text,
  Icon,
  Theme,
  variantProvider,
} from '@/app'
import { CardBase } from './Base'
import { useComponentStyle } from '@codeleap/common'

export const VerticalCard: React.FC<any> = (props) => {

  const { listing } = props

  const styles = useComponentStyle(componentStyles)

  const verticalVariants = ['column', 'gap:1.5']

  return (
    <CardBase
      listing={listing}
      wrapperStyles={styles.wrapper}
      imageWrapperStyles={styles.imageWrapper}
      imageStyles={styles.image}
      textStyles={styles.texts}
      bottomTextsVariants={verticalVariants}
    />
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    width: '100%',
  },
  imageWrapper: {
    height: 250,
  },
  image: {
    maxWidth: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  texts: {
    height: 250,
    gap: Theme.spacing.value(2),
    justifyContent: 'center',
  },
}))
