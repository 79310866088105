// import { Avatar, Link } from '@/shared';

import { mapVariants } from '@codeleap/common'
import * as Components from '@codeleap/web'

import {} from '@codeleap/common/dist/utils'
import { AppButtonStyle } from './stylesheets/Button'
import { AppCheckboxStyles } from './stylesheets/Checkbox'
import { AppTextStyles } from './stylesheets/Text'
import { AppTextInputStyles } from './stylesheets/TextInput'
import { AppFileInputStyles } from './stylesheets/FileInput'
import { AppTooltipStyles } from './stylesheets/Tooltip'
import { AppCenterWrapperStyles } from './stylesheets/CenterWrapper'
import { AppSelectStyles } from './stylesheets/Select'
import { AppRouterPageStyles } from './stylesheets/RouterPage'
import { AppModalStyles } from './stylesheets/Modal'
import { AppMenuStyles } from './stylesheets/Menu'
import { AppRadioInputStyles } from './stylesheets/RadioInput'
import { AppViewStyles } from './stylesheets/View'
import { AppTouchableStyles } from './stylesheets/Touchable'
import { variantProvider } from './theme'

import { Select as _Select } from '../shared/Select'

const defaultStyles = variantProvider.getDefaultVariants()

const WebRadioInput = Components.WebRadioInputStyles

const WebModal = Components.WebModalStyles

const WebActivityIndicator = Components.WebActivityIndicatorStyles

export const variants = {
  ...defaultStyles,
  RadioInput: AppRadioInputStyles,
  Text: AppTextStyles,
  Button: AppButtonStyle,
  Checkbox: AppCheckboxStyles,
  ActivityIndicator: WebActivityIndicator,
  Modal: AppModalStyles,
  View: AppViewStyles,
  TextInput: AppTextInputStyles,
  FileInput: AppFileInputStyles,
  Tooltip: AppTooltipStyles,
  Select: AppSelectStyles,
  RouterPage: AppRouterPageStyles,
  CenterWrapper: AppCenterWrapperStyles,
  Touchable: AppTouchableStyles,
}

const components = variantProvider.typeComponents({
  Button: [Components.Button, AppButtonStyle],
  View: [Components.View, AppViewStyles],
  Checkbox: [Components.Checkbox, AppCheckboxStyles],
  Text: [Components.Text, AppTextStyles],
  TextInput: [Components.TextInput, AppTextInputStyles],
  Modal: [Components.Modal, WebModal],
  FileInput: [Components.FileInput, AppFileInputStyles],
  Overlay: [Components.Overlay, defaultStyles.Overlay],
  Drawer: [Components.Drawer, defaultStyles.Drawer],
  ContentView: [Components.ContentView, defaultStyles.ContentView],
  RadioInput: [Components.RadioGroup, AppRadioInputStyles],
  ActivityIndicator: [Components.ActivityIndicator, WebActivityIndicator],
  Icon: [Components.Icon, defaultStyles.Icon],
  FlatList: [Components.FlatList, defaultStyles.FlatList],
  Touchable: [Components.Touchable, AppTouchableStyles],
  Select: [_Select, defaultStyles.Select],
  Tooltip: [Components.Tooltip, AppTooltipStyles],
  RouterPage: [Components.RouterPage, defaultStyles.RouterPage],
  CenterWrapper: [Components.CenterWrapper, defaultStyles.CenterWrapper],
  HorizontalScroll: [Components.HorizontalScroll, defaultStyles.View],
  // Link: [Link, AppTextStyles],
  // Avatar: [Avatar, defaultStyles.Avatar],
  Menu: [Components.Menu, AppMenuStyles],
})

export default components
