/* eslint-disable max-lines */
/* eslint-disable max-len */
import { api } from '@/app';
import {
  logger,
  Settings,
} from '@/app';
import { Toast } from '@/shared';
import { Session, store, TSession } from '@/redux';
import { capitalize } from '@codeleap/common';
// import firebase from 'gatsby-plugin-firebase'
import { withFirebase } from './firebase';
import { LoginErros, SignupErrors } from '@/services/errors'
import fireabaseAuth from 'gatsby-plugin-firebase'
import { FileFromUrl } from '@/lib/utils/FileFromUrl';

type Credential = firebase.auth.UserCredential

type EmailAndPassword = Record<'email' | 'password', string>

type LoginData = {
  withProvider: keyof typeof AuthProviders
  data?: {
    email: string
    password: string
  }
}

const SCOPE = 'authentication'
const DEFAULT_ERROR_MESSAGE = 'Could not login. Please check your credentials.'

export const firebase = fireabaseAuth.auth
const auth = fireabaseAuth.auth

export async function logout() {
  await withFirebase((firebase) => firebase.auth().signOut())
}


export async function signupWithEmail(data: EmailAndPassword) {
  let result: Credential = null
  let uid = ''
  try {
    result = await withFirebase(firebase => firebase.auth().createUserWithEmailAndPassword(data.email, data.password))

    uid = await (await result.user.getIdTokenResult()).claims.user_id
    logger.log('User Created', result, SCOPE)
  } catch (err) {
    logger.warn('signupWithEmail', err, 'authentication')

    Toast.error(AuthProviders.email.getErrorMessage(err) || 'A signup error has occured. Please contact support.')
  }

  return uid
}

const socialProviders = {
  'google': () => withFirebase(firebase => firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())),
  // 'google': {
  //   provider: withFirebase(firebase => firebase.auth.GoogleAuthProvider,
  //   getErrorMessage(error){

  //   },
  // },
  // 'facebook': {
  //   provider: firebase.auth.FacebookAuthProvider,
  //   getErrorMessage(reason){
  //     if (reason?.code?.includes('auth/account-exists-with-different-credential')){
  //       return 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.'
  //     }

  //     return null
  //   },
  // },
  'facebook': () => withFirebase((firebase) => firebase.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider())),
}

export const AuthProviders = {
  ...socialProviders,
  email: {
    getErrorMessage(reason) {
      if (reason.code) {
        switch (reason.code) {
          case 'auth/user-not-found':
            return 'No user with this email found'
          case 'auth/weak-password':
            return 'Password must be at least 6 characters'
          default:
            break
        }
      }

      return null
    },
  },
}
export async function tryEmailLogin(data: EmailAndPassword) {
  let result: Credential = null

  try {
    result = await withFirebase(firebase => firebase.auth().signInWithEmailAndPassword(data.email, data.password))
    logger.info('Email Login', { result }, SCOPE)

  } catch (reason) {
    logger.warn('Email Login Failed', reason, SCOPE)
    LoginErros(reason)
    throw new Error(AuthProviders.email.getErrorMessage(reason) || DEFAULT_ERROR_MESSAGE)
  }

  return result
}

export async function trySocialLogin({ withProvider }) {
  const loginType = `${capitalize(withProvider)} Login`


  const chosenProvider = socialProviders[withProvider]

  try {


    const result = await chosenProvider()

    return result

  } catch (reason) {

    logger.warn(`${loginType} Failed`, reason, SCOPE)

    const message = chosenProvider?.getErrorMessage(reason) || DEFAULT_ERROR_MESSAGE

    throw new Error(message)
  }
}

export async function tryLogin(args, silent) {

  if (args) {

    try {

      let credential: Credential = null

      switch (args.withProvider) {
        case 'email':

          const { email, password } = args?.data || {}

          // credential = await tryEmailLogin(args?.data)
          credential = await withFirebase(firebase => firebase.auth().signInWithEmailAndPassword(
            email || 'a',
            password || 'a',
          ),
          )
          break
        default:
          credential = await trySocialLogin(args)
          break
      }

      return profileFromUser(credential.user)

    } catch (err) {
      LoginErros(err)
      console.log(err)
    }
  }
}

export type TrySignupArgs = {
  data: Omit<TSession['profile'], 'id'>
  provider?: Providers | 'email'
}

export async function trySignup({ data, provider }) {
  const currentUser = auth().currentUser

  let user = null

  if (currentUser && provider !== 'email') {
    const userHasChangedEmail = currentUser.email !== data.email

    if (userHasChangedEmail) {
      await currentUser.updateEmail(data.email)
    }

    currentUser.updatePassword(data.password)

    user = await profileFromUser(currentUser)

  } else {
    try {
      const newUser = await auth().createUserWithEmailAndPassword(
      data.email,
      data.password,
    )

    user = newUser.user
    } catch (err) {
      SignupErrors(err)
    }
  }

  try {
    
    const { data: profile } = await api.post(
      `profiles/create/`,
      {
        data: {
          data: {
            ...data,
            id: user.id ? user.id : user.uid,
          },
          files: data.avatar
        },
      },
      { multipart: true },
    )
    // await sendVerificationEmail()
    return profile
  } catch (e) {
    logger.log({e})
    SignupErrors(e)
    // logger.error('Signup error', e, SCOPE)
    return null
  }
}

export async function tryUpdateProfile(id, data) {
  // console.log({updateProfileData: data})
  try {

    const uploadData = {
      ...data,
      files: data.data.avatar,
    }

    const formData = new FormData();
    formData.append('data', JSON.stringify(data.data));
    formData.append('files', data.data.avatar);

    const { data: profile } = await api.patch(`profiles/${id}`, formData)

    return profile

  } catch (err) {
    console.log(err)
  }
}

export async function loadOwnProfile(): Promise<TSession['profile']> {
  try {
    const { data: profile } = await api.get('profiles/i/')
    return profile
  } catch (err) {
    return null
  }
}

export async function verificationEmail() {
  const user = await withFirebase(firebase => firebase.auth().currentUser)
  user.sendEmailVerification().then(() => {
    console.log('Verification email sent')
  }).catch((error) => {
    Toast.error(error.message)
  })
}

export async function profileFromUser(credential): TSession['profile'] {
  const currCredential = await credential
  if (!currCredential) return credential as null


  logger.log({ currCredential })

  return {
    id: currCredential.uid,
    avatar: null,
    email: currCredential.email,
    username: currCredential.displayName,
    last_name: '',
  }
}

export async function sendPasswordReset(email: string) {
  await auth().sendPasswordResetEmail(email)
}

export async function checkEmailAvailability(email: string) {
  const signinMethods = await withFirebase(firebase => firebase.auth().fetchSignInMethodsForEmail(email))
  const isAvailable = !signinMethods.includes('email')
  if (!isAvailable) {
    Toast.error('This email address is already taken.')
  }
  return isAvailable
}

export default {
  tryLogin,
  signupWithEmail,
  logout,
  checkEmailAvailability,
  sendPasswordReset,
}
