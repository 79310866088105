export const ArrowRight = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 13 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.5 2L11.5 12L1.5 22'
        stroke='#313131'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}