import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  Image,
  variantProvider,
  View,
  Text,
  Theme,
} from '@/app'
import { useAppSelector } from '@/lib'
import { Link } from '@/shared'
import { Display } from './Display'

export type InventoryProductProps = {
  listing?: any
  Listings?: any
  createOffer?: boolean
  onChangeCheckbox?: React.ChangeEvent<HTMLInputElement>
  selectedListings?: any[]
  isSelected?: boolean
  bundles?: any
}

export default function InventoryProduct(props: InventoryProductProps) {
  const {
    listing,
    Listings,
    createOffer,
    onChangeCheckbox,
    selectedListings,
    isSelected,
    bundles,
  } = props

  const [hoveredItem, setHoveredItem] = useState(null)

  const { categories } = useAppSelector((store) => store.Categories)

  const handleDeleteListing = () => {
    Listings.delete.send(listing.id)
  }

  // const categoryByListing

  const categoryByListing = categories.find(
    (category) => category.title === listing.category,
  )

  const Wrapper = ({ children }) => {
    if (hoveredItem) {
      return (
        <View>
          {children}
        </View>
      )
    } else {
      return (
        <Link to={`/product/${listing?.id}/detail`}>
          {children}
        </Link>
      )
    }
  }

  return (
    <>
      <View css={[styles.wrapper, createOffer && styles.wrapperCreteOffer]} variants={['fullWidth', 'gap:2', 'alignCenter']}>
        {createOffer && (
          <Checkbox
            onChange={onChangeCheckbox}
            value={listing.id}
            checked={selectedListings.includes(listing.id)}
          />
        )}

        <Display {...props} setHoveredItem={setHoveredItem} />

      </View>
    </>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      borderBottom: `1px solid #e8e8e8`,
      ...Theme.spacing.paddingVertical(3),
      // display: 'grid',
      // gridTemplateColumns: '15% 80%',
      [Theme.media.down('mid')]: {
        gridTemplateColumns: '30% 70%',
      },
    },
    wrapperCreteOffer: {
      gridTemplateColumns: '5% 20% 70%',
      [Theme.media.down('mid')]: {
        gridTemplateColumns: '5% 30% 55%',
      },
    },
    middleText: {
      color: Theme.colors.light.lightGrayText,
    },
    image: {
      borderRadius: Theme.borderRadius.xSmall,
      height: 140,
      width: 175,
      objectFit: 'cover',
      [Theme.media.down('mid')]: {
        height: 110,
      },
    },
    separator: {
      height: 1,
    },
    categoryIcon: {
      width: 25,
      height: 25,
      objectFit: 'contain',
    },
    productLink: {
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  true,
)
