import { WebCheckboxComposition, WebCheckboxStyles } from '@codeleap/web'
import { variantProvider } from '../theme'
import { mapVariants } from '@codeleap/common'

const createCheckboxStyle = variantProvider.createVariantFactory<WebCheckboxComposition>()

const defaultStyles = WebCheckboxStyles

export const AppCheckboxStyles = {
  ...defaultStyles,
  default: createCheckboxStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      'input': {
        ...defaultStyles.default(theme).wrapper.input,
        height: 24,
        width: 24,
      },
      'span': {
        border: `2px solid ${theme.colors.primary}`,
      },
      '.checkbox-label': {
        ...defaultStyles.default(theme).wrapper['.checkbox-label'],
        border: theme.border.white(1),
        // borderRadius: 0,
      },
    },
  })),
  selectOption: createCheckboxStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      'input': {
        ...defaultStyles.default(theme).wrapper.input,
        height: 24,
        width: 24,
        // borderRadius: '50%',
      },
      'span': {
        border: `2px solid #CCCCCC`,
        // borderRadius: '50%',
      },
      '.checkbox-label': {
        ...defaultStyles.default(theme).wrapper['.checkbox-label'],
        border: theme.border.white(1),
        borderRadius: 50,
      },
    },
  })),
  multiSelectOption: createCheckboxStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      'input': {
        ...defaultStyles.default(theme).wrapper.input,
        height: 24,
        width: 24,
        // borderRadius: '50%',
      },
      'span': {
        border: `2px solid #CCCCCC`,
        // borderRadius: '50%',
      },
      '.checkbox-label': {
        ...defaultStyles.default(theme).wrapper['.checkbox-label'],
        border: theme.border.white(1),
        borderRadius: 0,
      },
    },
  })),
}
