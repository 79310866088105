import { Settings } from '@/app'

import { createRedux } from '@codeleap/common'
import { appStatusSlice } from './AppStatus'
import { categoriesSlice } from './Categories'
import { postsSlice } from './Posts'
import { sessionSlice } from './Session'
import { listingsSlice } from './Listing'
import { locationsSlice } from './Locations'
import { locationSlice } from './Location'
import { ratingsSlice } from './Ratings'
import { menuItemsSlice } from './MenuItems'
import { messagesSlice } from './Messages'
import { offersSlice } from './Offers'
import { savedFiltersSlice } from './SavedFilters'
import { notificationsSlice } from './Notifications'

const ext = [

]
if (!(typeof window === 'undefined')) {

  if (Settings.Environment.IsDev && window.__REDUX_DEVTOOLS_EXTENSION__) {
    ext.push(window?.__REDUX_DEVTOOLS_EXTENSION__?.())
  }
}

export const {
  store,
  actions: {
    Posts,
    AppStatus,
    Session,
    Listings,
    Location,
    Categories,
    Locations,
    Offers,
    Ratings,
    MenuItems,
    Messages,
    Notifications,
    SavedFilters,
  },
} = createRedux({
  Posts: postsSlice,
  AppStatus: appStatusSlice,
  Session: sessionSlice,
  Listings: listingsSlice,
  Location: locationSlice,
  Categories: categoriesSlice,
  Locations: locationsSlice,
  Ratings: ratingsSlice,
  MenuItems: menuItemsSlice,
  Messages: messagesSlice,
  Notifications: notificationsSlice,
  Offers: offersSlice,
  SavedFilters: savedFiltersSlice,
}, [], ...ext)

export type RootState = ReturnType<typeof store.getState>
export * from './Posts'
export * from './AppStatus'
export * from './Session'
