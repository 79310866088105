import { Theme } from '@/app'

export const MapPin = (props) => {
  return (
    <svg
      width='58'
      height='58'
      viewBox='0 0 58 58'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g filter='url(#filter0_d)'>
        <rect x='8' y='7.99976' width='40' height='40' rx='20' fill='#366E65' />
      </g>
      <g clipPath='url(#clip0)'>
        <path
          d='M35.5 26.3331C35.5 32.1664 28 37.1664 28 37.1664C28 37.1664 20.5 32.1664 20.5 26.3331C20.5 24.3439 21.2902 22.4363 22.6967 21.0298C24.1032 19.6232 26.0109 18.8331 28 18.8331C29.9891 18.8331 31.8968 19.6232 33.3033 21.0298C34.7098 22.4363 35.5 24.3439 35.5 26.3331Z'
          stroke={Theme.colors.light.white}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28 28.8331C29.3807 28.8331 30.5 27.7138 30.5 26.3331C30.5 24.9524 29.3807 23.8331 28 23.8331C26.6193 23.8331 25.5 24.9524 25.5 26.3331C25.5 27.7138 26.6193 28.8331 28 28.8331Z'
          stroke={Theme.colors.light.white}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_d'
          x='0'
          y='-0.000244141'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feMorphology
            radius='1'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='4' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <clipPath id='clip0'>
          <rect
            width='20'
            height='20'
            fill={Theme.colors.light.white}
            transform='translate(18 17.9998)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
