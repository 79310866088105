import React from 'react'

import { Button, View, Text, Theme, variantProvider, logger } from '@/app'
import { ToggleComponent, Pagination, NoItems } from '@/shared'

import { OffersProduct, InventoryProduct } from '@/layout/Account'

export type AccountTabsProps = {
  isOffer?: boolean
  tab?: number
  createOffer?: boolean
  setParams?: React.Dispatch<any>
  params?: any
  onPressOffer?: () => void
  Items?: any
  itemsOne?: any
  itemsTwo?: any
  onChangeCheckbox?: any
  selectedListings?: any
  hasItems?: boolean
}

export const AccountTabs: React.FC<AccountTabsProps> = (props) => {
  const {
    createOffer,
    isOffer,
    params,
    tab,
    selectedListings,
    setParams,
    Items,
    hasItems,
    itemsOne,
    itemsTwo,
    onChangeCheckbox,
    onPressOffer,
  } = props

  const renderItems = (items, tabNumber) => {
    logger.log({ tab })

    return (
      <View
        css={tab != tabNumber && styles.disabled}
        variants={['column', 'gap:2', 'fullWidth', 'fullHeight']}
      >
        {items?.results?.length > 0 ? (
          <View
            css={styles.wrapper}
            variants={['column', 'fullWidth', 'fullHeight']}
          >
            {items?.results.map((item) => {
              if (isOffer) {
                return (
                  <OffersProduct
                    key={item?.id}
                    listing={item}
                    sent={tab == 2}
                    offer={item}
                    Offers={Items}
                  />
                )
              } else {
                return (
                  <InventoryProduct
                    key={item?.id}
                    createOffer={createOffer}
                    Listings={Items}
                    listing={item}
                    bundles={tab == 2}
                    onChangeCheckbox={onChangeCheckbox}
                    selectedListings={selectedListings}
                  />
                )
              }
            })}
            <Pagination params={params} setParams={setParams} items={items} />
            {createOffer && (
              <Button
                css={styles.offerButton}
                onPress={onPressOffer}
                text='Send offer'
                variants={['marginVertical:2']}
              />
            )}
          </View>
        ) : (
          <NoItems
            icon={'noChat'}
            title={"You've got nothing here"}
            subtitle={
              'Make or accept an offer to start a conversation with a seller or a buyer.'
            }
            wrapperStyle={styles.noItemsWrapper}
          />
        )}
      </View>
    )
  }

  return (
    <View
      variants={[
        'fullWidth',
        'justifyCenter',
        'fullHeight',
      ]}
    >
      {hasItems ? (
        <View variants={['column', 'fullWidth']}>
          <ToggleComponent
            firstTitle={`${isOffer ? 'Received' : 'Items'}`}
            secondTitle={`${isOffer ? 'Sent' : 'Bundles'}`}
            tab={tab}
            setTab={setParams}
          />
          {renderItems(itemsOne, 1)}
          {renderItems(itemsTwo, 2)}
        </View>
      ) : (
        <NoItems
          icon={'noInventory'}
          title={"You've got no items here"}
          subtitle={
            'Click the "Add" button at the top of the page to add an item or a bundle.'
          }
        />
      )}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      // minHeight: '80vh',
    },
    offerButton: {
      width: '30%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    disabled: {
      display: 'none',
    },
    noItemsWrapper: {
      // maxWidth: '100%',
      alignSelf: 'center',
      height: '100%',
      justifyContent: 'center',
      // minHeight: '80vh',
    },
  },
  true,
)
