/* eslint-disable max-lines */
import { useForm, createForm } from '@codeleap/common'
import * as yup from 'yup'
import { Currencies } from '@/data'
import { useAppSelector } from '@/lib'
import { Button, Icon, Touchable, Text, logger, Select, TextInput, variantProvider, View, Theme } from '@/app'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const currencyOptions = Currencies.map((currency) => { return { value: currency.id, label: `${currency.id}` } })

export const optionsForm = () => {
  const { options, categories } = useAppSelector((store) => store.Categories)

  const category = { title: 'Category', type: 'SG' }

  const requiredOptions = options.filter(el => el.required === true)

  requiredOptions.push(category)

  const optionTypeToValidate = (val) => {
    if (val === 'SG') {
      return {
        type: 'text', validate: yup.string()
          .ensure()
          .required('Select at least one value'),
      }
    } else if (val === 'NB') {
      return { type: 'text', validate: yup.string().required('Enter a valid number') }
    } else if (val === 'DT') {
      return { type: 'text', validate: yup.date().required('Enter the date') }
    } else if (val === 'MT') {
      return { type: 'select', validate: yup.bool().required('Select at least one Category').oneOf([true], 'true') }
    } else {
      return { type: 'text', validate: yup.string().required('Enter a valid text') }
    }
  }

  const teste = requiredOptions.map(opt => {
    return {
      key: opt.title,
      values: optionTypeToValidate(opt.type),
    }
  })

  const obj4 = teste.reduce((accumulator, value, index) => {
    return { ...accumulator, [value.key]: value.values }
  }, {})

  logger.log({ obj4 })

  const requiredOptionsForm = createForm('optionsForm', obj4)

  return requiredOptionsForm
}

export const mainLocationForm = createForm('mainLocation', {
  title: {
    type: 'text',
  },
  address: {
    type: 'text',
  },
  coords: {
    type: 'composite',
    fields: {
      lat: {
        type: 'text',
      },
      lng: {
        type: 'text',
      },
    },
  },
  main_location: {
    defaultValue: true,
  },
  my_location: {
    defaultValue: true,
  },
})

export const accountForm = createForm('login', {
  username: {
    type: 'text',
    label: 'Username',
    validate: yup.string().required('Username cannot be empty'),
  },
  email: {
    type: 'text',
    label: 'Email',
    validate: yup.string().required('Email cannot be empty').email('Invalid email'),
  },
  password: {
    type: 'text',
    password: true,
    label: 'Password',
  },
  main_location: {
    type: 'composite',
    fields: {
      name: {
        type: 'text',
      },
      address: {
        type: 'text',
      },
      coords: {
        type: 'composite',
        fields: {
          lat: {
            type: 'text',
          },
          lng: {
            type: 'text',
          },
        },
      },
    },
  },
  data: {
    type: 'composite',
    fields: {
      distance_type: {
        type: 'select',
        options: [
          { label: 'Km', value: 'Km' },
          { label: 'M', value: 'M' },
        ],
      },
      distance_value: {
        type: 'text',
        label: '‎',
      },
      currency: {
        type: 'select',
        // options: currencyOptions,
        // native: false,
      },

    },
  },

})

export const loginForm = createForm('login', {
  email: {
    type: 'text',
    label: 'Email',
    validate: yup.string().required('This cannot be empty').email('Invalid email'),
  },
  password: {
    type: 'text',
    password: true,
    label: 'Password',
    validate: yup.string().required('Password is required'),
  },
})

export const offerCurrencyForm = createForm('offerCurrency', {
  exchange_for_currency: {
    type: 'select',
    label: 'Currency',
  },
  exchange_for_value: {
    type: 'text',
    label: 'Value',
    validate: yup.string().required('Value is required'),
  },
  target_listing: {
    type: 'text',
  },
  created_by: {
    type: 'text',
  },
})

export const offerInventoryForm = createForm('offerInvenotry', {
  target_listing: {
    type: 'text',
  },
  created_by: {
    type: 'text',
  },
  // exchange_for_item: {
  //   type: 'composite',
  // }
})

export const signupForm = createForm('signup', {
  // first_name: {
  //   type: 'text',
  //   label: 'First Name',
  //   validate: yup
  //     .string()
  //     .required('This cannot be empty'),
  // },
  // last_name: {
  //   type: 'text',
  //   label: 'Last Name',
  //   validate: yup.string().required('This cannot be empty'),
  // },
  username: {
    type: 'text',
    label: 'Username',
    validate: yup.string().required('This cannot be empty'),
  },
  email: {
    type: 'text',
    label: 'Email Address',
    validate: yup.string().required('This cannot be empty').email('Invalid email'),
  },
  password: {
    type: 'text',
    password: true,
    label: 'Password',
    validate: yup.string().required('Password is required'),
  },
  repeatPassword: {
    type: 'text',
    label: 'Confirm Password',
    password: true,

    validate: (repeatPassword, { password }) => {
      const isValid = repeatPassword === password
      return {
        valid: isValid,
        message: isValid ? '' : "Passwords don't match",
      }
    },
  },
  data: {
    type: 'composite',
    fields: {
      region: {
        type: 'text',
        defaultValue: '',
      },
      currency: {
        type: 'text',
        defaultValue: '',
      },
      distance_type: {
        type: 'text',
        defaultValue: '',
      },
      distance_value: {
        type: 'text',
        defaultValue: '',
      },
      form: {
        type: 'text',
        defaultValue: '',
      },
      clothingSize: {
        type: 'text',
        defaultValue: '',
      },
      footwearSize: {
        type: 'text',
        defaultValue: '',
      },
    },
  },
})

export const newLocationForm = createForm('location', {

  title: {
    type: 'text',
    validate: yup.string().required('Enter your location title'),
  },
  address: {
    type: 'text',
    validate: yup.string().required('Select a valid address'),
  },
  coords: {
    type: 'composite',
    fields: {
      lat: {
        type: 'text',
        validate: yup.string().required('Select a valid address'),
      },
      lng: {
        type: 'text',
        validate: yup.string().required('Select a valid address'),
      },
    },
  },
})

export const locationsForm = createForm('locations', {
  ...newLocationForm,
})

export const newFilterForm = createForm('filter', {
  name: {
    type: 'text',
    validate: yup.string().required('Enter your filter name'),
  },
  location: {
    type: 'composite',
    fields: {
      title: {
        type: 'text',
      },
      address: {
        type: 'text',
      },
      coords: {
        type: 'composite',
        fields: {
          lat: {
            type: 'text',
          },
          lng: {
            type: 'text',
          },
        },
      },
    },
  },
})

export const mediaForm = createForm('media', {
  listing: {
    type: 'text',
  },
  file: {
    type: 'file',
  },
})

export const createProductForm = createForm('createProduct', {
  title: {
    type: 'text',
    validate: yup.string().required('Enter your Product title'),
  },
  description: {
    type: 'text',
    validate: yup.string().required('Enter your Product description'),
  },
  locations: {
    type: 'list',
    validate: yup.array().min(1, 'at least 1').required('Create at least one location'),
  },
  // Amount: {
  //   type: 'number',
  //   validate: yup.number().required('Enter your Product title'),
  // },
  // hasCategory: {
  //   validate: yup.bool().required('Select at least one Category').oneOf([true], 'true')
  // },
  // hasExchangeFor: {
  //   validate: yup.bool().required('Select at least one thing in exchange').oneOf([true], 'true')
  // },
})

export const profileLocationsForm = createForm('profileLocations', {
  locations: {
    // type: 'list',
    // validate: yup.array().min(1, 'at least 1').required('Create at least one location'),
  },
})

export const createMessageForm = createForm('createMessage', {
  profile: {
    type: 'text',
  },
  body: {
    type: 'text',
    label: '‎',
    validate: yup.string().required(),
  },
  offer: {
    type: 'text',
  },
})

export const feedbackForm = createForm('', {
  origin: {
    type: 'text',
  },
  target: {
    type: 'text',
  },
  value: {
    type: 'text',
  },
  target_offer: {
    type: 'text',
  },
})

