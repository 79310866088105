import { Theme } from '@/app';

export const SendMessage = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M22 2L11 13'
        stroke={Theme.colors.light.white}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22 2L15 22L11 13L2 9L22 2Z'
        stroke={Theme.colors.light.white}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
