/* eslint-disable max-lines */
/* eslint-disable max-len */
import { useState, useRef } from 'react'
import { variantProvider, logger, Touchable, Button, Text, Theme, View, Icon } from '@/app'
import { MapComponent, Marker, MapLoader } from '@/shared'
import { ExploreMapSkeleton } from '@/skeletons'
import { requestFullScreen, formatCenter, kmToMeters } from '@/lib/utils/helpers'
import { useAppSelector } from '@/lib'

import { Logger, useComponentStyle, onUpdate, onMount } from '@codeleap/common'
import { useClickOutside } from '@codeleap/web'
import { MapStyles } from '@/data'

export type ExploreMapProps = {
  listing?: any
  loading?: boolean
  hoveredItem?: any
  setHoveredItem?: React.Dispatch<React.SetStateAction<any>>
  mobileListing?: boolean
  params?: any
  locations?: any[]
}

export const ExploreMap: React.FC<ExploreMapProps> = (props) => {
  const {
    listings,
    loading,
    hoveredItem,
    setHoveredItem,
    setParams,
    mobileListing,
    params,
    mapBoundsRef,
    setCircles,
    mapState,
    setMapState,
    mapRef,
    mapsRef,
    ...mapProps
  } = props

  const isSmall = Theme.hooks.down('mid')

  const [card, setCard] = useState(null)
  // const mapRef = useRef(null)
  // const mapsRef = useRef(null)

  const { profile, appMounted } = useAppSelector((store) => store.Session)
  const { locations } = useAppSelector((store) => store.Locations)

  const ELEMENT_ID = 'google-maps'

  const profileDistanceType = profile?.data?.distance_type.toLowerCase()
  const profileDistanceValue = parseFloat(profile?.data?.distance_value)

  const profileDistanceInM = profileDistanceType === 'm' ? profileDistanceValue : kmToMeters(profileDistanceValue)

  const defaultProps = {
    center: {
      lat: parseFloat(params.lat),
      lng: parseFloat(params.lng),
    },
    zoom: 16,
  }

  const mapOptions = (maps) => {
    return {
      scrollwheel: true,
      styles: MapStyles,
      fullscreenControl: false,
      zoomControl: false,
    }
  }

  const handleZoomInOut = (level) => {
    setParams((prev) => {
      return {
        ...prev,
        zoom: level,
      }
    })
  }

  const renderFullscreenButton = () => {
    return (
      <View css={styles.fullScreenWrapper}>
        <Button
          variants={['alignCenter', 'justifyCenter', 'icon']}
          css={styles.mapButtons}
          styles={{
            icon: styles.mapButtonsIcon,
          }}
          icon={'fullscreen'}
          onPress={() => { requestFullScreen(ELEMENT_ID) }}
        />
      </View>
    )
  }

  const renderZoomButtons = () => {
    return (
      <View css={styles.zoomButtonsWrapper} >
        <View variants={['column']}>
          <Button onPress={() => handleZoomInOut(params.zoom + 1)} variants={['alignCenter', 'justifyCenter', 'icon']} css={[styles.mapButtons, styles.plusButton]} styles={{
            icon: styles.mapButtonsIcon,
          }} icon={'plus'} />
          <Button onPress={() => handleZoomInOut(params.zoom - 1)} variants={['alignCenter', 'justifyCenter', 'icon']} css={[styles.mapButtons, styles.minusButton]} styles={{
            icon: styles.mapButtonsIcon,
          }} icon={'minus'} />
        </View>
      </View>
    )
  }

  const renderZoomInfo = () => {
    return (
      <View variants={['alignCenter']} css={styles.zoomInfo}>
        <Text css={styles.textLocation} text={'Zoom out to show more listings'} />
      </View>
    )
  }

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
      })
    })
  }

  const renderCircles = () => {
    const updatedLocations = [...locations, profile?.data?.mainLocation]

    updatedLocations.forEach((item) => {
      const mapCircle = new mapsRef.current.Circle({
        strokeColor: '#E2F0DF',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#E2F0DF',
        fillOpacity: 0.35,
        radius: profileDistanceInM, //meters
        center: new google.maps.LatLng(item?.coords?.lat, item?.coords?.lng),
      })

      mapCircle.setMap(mapRef.current)

      setCircles((prev) => {
        return [
          ...prev,
          {
            location: item,
            circleBounds: mapCircle.getBounds(),
          },
        ]
      })
    })
  }

  onUpdate(() => {
    if (mapRef.current && mapsRef.current && locations.length > 0) {
      renderCircles()
    }
  }, [locations, mapRef.current, mapsRef.current])

  const handleApiLoaded = (map, maps) => {
    const mapBounds = map.getBounds()

    logger.log('api foi carregada')

    const se = { lat: mapBounds.getSouthWest().lng(), lng: mapBounds.getNorthEast().lat() }
    const nw = { lat: mapBounds.getNorthEast().lng(), lng: mapBounds.getSouthWest().lat() }

    mapBoundsRef.current = { nw, se }
    mapRef.current = map
    mapsRef.current = maps
    // setMapState(map)
    // setMapsState(maps)

    // bindResizeListener(map, maps, mapBounds)
  }

  const handleZoomEnd = (zoomLevel) => {
    setParams((prev) => {
      return {
        ...prev,
        zoom: zoomLevel,
      }
    })
  }

  const styles = useComponentStyle(componentStyles)

  return (
    <View
      responsiveVariants={{
        mid: ['relative'],
      }}
      variants={['fixed']}
      css={[
        styles.mapWrapper,
        isSmall && mobileListing && styles.mapWrapperDisabled,
      ]}
      id={ELEMENT_ID}
    >
      <ExploreMapSkeleton ready={props.params.lat}>
        <MapComponent
          center={defaultProps.center}
          wrapperStyle={styles.wrapper}
          zoom={parseInt(params.zoom)}
          options={mapOptions}
          onZoomAnimationEnd={handleZoomEnd}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          // yesIWantToUseGoogleMapApiInternals
          // resetBoundsOnResize={true}
          {...mapProps}
        >
          {listings?.results.map((listing) => (
            <Marker
              key={listing?.id}
              lat={listing.locations[0]?.coords.lat}
              lng={listing.locations[0]?.coords.lng}
              listing={listing}
              card={card}
              setCard={setCard}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
            />
          ))}
          {locations.map((location) => (
            <Icon name={'locationPin'} lat={location.coords?.lat} lng={location.coords.lng} style={styles.markerCenter} />
            // <Text text={'olá mundo'} lat={location.coords?.lat} lng={location.coords.lng} />
          ))}
        </MapComponent>
        {loading && <MapLoader wrapper={styles.loadingWrapper} />}
        <View css={[styles.mapItemsWrapper]} variants={['absolute', 'flex', 'justifySpaceBetween', 'fullWidth']}>
          <View variants={['gap:2']}>
            {renderZoomButtons()}
            {listings.results.length < 2 && renderZoomInfo()}
          </View>
          <View variants={['gap:2']}>
            {/* {renderLocationsButton()} */}
            {renderFullscreenButton()}
          </View>
        </View>
      </ExploreMapSkeleton>
    </View>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  mapItemsWrapper: {
    // left: 0,
    top: '7.5%',
    ...theme.spacing.paddingHorizontal(2),
    [Theme.media.down('mid')]: {
      top: 180,
    },
  },
  wrapper: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderTopLeftRadius: theme.spacing.value(1),
  },
  zoomButtonsWrapper: {
    left: 25,
    top: '7.5%',
  },
  fullScreenWrapper: {
    right: 15,
    top: '80.5%',
    // zIndex: 99999999999,
  },
  locationButtonWrapper: {
    top: '7.5%',
    right: 65,
    color: 'black',
  },
  mapButtons: {
    backgroundColor: theme.colors.white,
    width: 32,
    height: 32,
    borderRadius: theme.borderRadius.small,
    padding: 0,
    transition: '200ms',
    '&:hover': {
      background: '#EAEAEA',
    },
  },
  mapButtonsIcon: {
    width: 18,
    height: 18,
    color: '#999999',
    path: {
      stroke: 'yellow',
      color: 'yellow',
    },
  },
  locationsIcon: {
    width: 10,
    height: 10,
    marginLeft: theme.spacing.value(0.8),
  },
  textLocation: {
    color: '#808080',
    fontSize: 13,
    fontWeight: 'bold',
  },
  plusButton: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  minusButton: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  locationButton: {
    backgroundColor: theme.colors.white,
    height: 32,
    borderRadius: theme.borderRadius.xSmall,
    transition: '200ms',
    '&:hover': {
      background: '#EAEAEA',
    },
  },
  loadingWrapper: {
    width: 80,
    height: 32,
    zIndex: 9,
    backgroundColor: theme.colors.white,
    borderRadius: theme.borderRadius.medium,
    boxShadow: 'rgb(0 0 0 / 16%) 0px 0px 20px',
    display: 'inline-block',
    position: 'absolute',
    left: '48%',
    top: '7.5%',
    [theme.media.down('mid')]: {
      top: 170,
    },
  },
  mapWrapper: {
    width: `calc(100vw - 760px)`,
    height: '100%',
    // bottom: 0,
    top: 180,
    right: 0,
    [Theme.media.down('mid')]: {
      width: '100%',
      top: 'unset',
      left: 'unset',
      bottom: 'unset',
      height: '100vh',
    },
  },
  mapWrapperDisabled: {
    display: 'none',
  },
  locationsWrapper: {
    backgroundColor: theme.colors.white,
    boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.24)',
    borderRadius: theme.borderRadius.xSmall,
    overflow: 'hidden',
  },
  locationsActive: {
    opacity: 1,
    visibility: 'visible',
    transition: '300ms',
  },
  locationsDisabled: {
    opacity: 0,
    visibility: 'hidden',
    height: 0,
    transition: '300ms',
  },
  locationsTitleButton: {
    backgroundColor: theme.colors.white,
    borderRadius: 0,
    '&:hover': {
      background: '#EAEAEA',
    },
  },
  locationsTitleText: {
    color: theme.colors.black,
    fontWeight: 400,
  },
  zoomInfoWrapper: {
    left: '48%',
    top: '7.5%',
  },
  zoomInfo: {
    backgroundColor: theme.colors.white,
    height: 32,
    ...theme.spacing.paddingHorizontal(1),
    ...theme.spacing.paddingVertical(0.5),
    borderRadius: theme.borderRadius.xSmall,
  },
  markerCenter: {
    transform: 'translateZ(0) translate(-50%, -50%)',
    backfaceVisibility: 'hidden',
  },
}))
