import { ButtonComposition, shadeColor } from '@codeleap/common'
import { theme, variantProvider } from '../theme'

const defaultStyle = variantProvider.getDefaultVariants('Button')

const createButtonVariant = variantProvider.createVariantFactory<ButtonComposition>()

export const AppButtonStyle = {
  ...defaultStyle,
  default: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    ...defaultStyle.pill(theme),
    'wrapper': {
      ...defaultStyle.default(theme).wrapper,
      ...defaultStyle.pill(theme).wrapper,
      borderRadius: theme.borderRadius.small,
      height: theme.values.buttonAndInputHeight,
      // ':active': {
      //   transform: 'scale(0.9)',
      // },
      '&:hover': {
        backgroundColor: shadeColor(theme.colors.primary, -10),
      },
    },
    'wrapper:disabled': {
      ...defaultStyle.default(theme).wrapper,
      // backgroundColor: '#4b9488',
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.white,
      fontWeight: 900,
    },
    icon: {
      ...defaultStyle.default(theme).icon,
    },
  })),
  primary: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.default(theme).wrapper,
      background: theme.colors.primary,
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.white,
    },
    icon: {
      ...defaultStyle.default(theme).icon,
      color: theme.colors.white,
      width: 24,
      height: 24,
    },
  })),
  disabled: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    'wrapper': {
      ...defaultStyle.default(theme).wrapper,
      backgroundColor: theme.colors.lightGray,
      pointerEvents: 'none',
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: '#B6B6B6',
    },
  })),
  transparent: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    'wrapper': {
      ...defaultStyle.default(theme).wrapper,
      background: 'transparent',
      border: `1px solid ${theme.colors.primary}`,
      '&:hover': {
        background: 'rgba(54, 110, 101, 0.05)',
      },
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.primary,
    },
  })),
  transparentNoBorder: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    'wrapper': {
      ...defaultStyle.default(theme).wrapper,
      background: 'transparent',
      borderRadius: 0,
      '&:hover': {
        background: 'transparent',
      },
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.primary,
    },
  })),
  icon: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.icon(theme).wrapper,
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
      },
    },
    text: {
      display: 'none',
    },
  })),
  text: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.icon(theme).wrapper,
      background: 'transparent',
      width: 'unset',
      '&:hover': {
        background: 'transparent',
      },
    },
    icon: {
      display: 'none',
    },
  })),
  socialIcon: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    'wrapper': {
      ...defaultStyle.default(theme).wrapper,
      border: '1px solid',
      borderRadius: theme.borderRadius.medium,
      borderColor: '#00000033',
      height: theme.values.buttonAndInputHeight,
      backgroundColor: 'transparent',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
      ':active': {
        transform: 'scale(0.9)',
      },
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.black,
      flex: 'unset',
      textAlign: 'unset',
    },
  })),
  list: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    ...defaultStyle.icon(theme),
    wrapper: {
      ...defaultStyle.icon(theme).wrapper,
      ...defaultStyle.default(theme).wrapper,
      background: theme.colors.black,
      borderRadius: 0,
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.white,
    },
  })),
  'list:selected': createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.default(theme).wrapper,
      background: theme.colors.primary,
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.white,
    },
  })),
  gray: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.default(theme).wrapper,
      background: theme.colors.gray,
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.white,
    },
    icon: {
      ...defaultStyle.default(theme).icon,
      color: theme.colors.white,
      width: 24,
      height: 24,
    },
  })),
  smallIcon: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.default(theme).wrapper,
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
      },
    },
    icon: {
      ...defaultStyle.default(theme).icon,
      width: 10,
      height: 10,
    },
  })),
  xSmallIcon: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.default(theme).wrapper,
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
      },
    },
    icon: {
      ...defaultStyle.default(theme).icon,
      width: 20,
      height: 20,
    },
    text: {
      ...defaultStyle.default(theme).text,
      display: 'none',
    },
  })),
  mediumIcon: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    'wrapper': {
      ...defaultStyle.default(theme).wrapper,
      ...defaultStyle.pill(theme).wrapper,
      '&:hover': {
        backgroundColor: shadeColor(theme.colors.primary, -10),
      },
    },
    icon: {
      ...defaultStyle.default(theme).icon,
      width: 25,
      height: 25,
    },
    text: {
      display: 'none',
    },
  })),
  bigIcon: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.default(theme).wrapper,
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
      },
    },
    icon: {
      ...defaultStyle.default(theme).icon,
      width: 35,
      height: 35,
    },
    text: {
      display: 'none',
      // eslint-disable-next-line max-lines
    },
  })),
  bold: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    text: {
      ...defaultStyle.default(theme).text,
      fontWight: 900,
    },
  })),
  mapMobileLeft: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.default(theme).wrapper,
      width: '30%',
      borderRadius: theme.borderRadius.medium,
      boxShadow: '0px 3px 48px 0px #0000004D',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      '&:hover': {
        backgroundColor: shadeColor(theme.colors.primary, -10),
      },
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.white,
    },
  })),
  mapMobileRight: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.default(theme).wrapper,
      width: '30%',
      borderRadius: theme.borderRadius.medium,
      boxShadow: '0px 3px 48px 0px #0000004D',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      '&:hover': {
        backgroundColor: shadeColor(theme.colors.primary, -10),
      },
    },
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.white,
    },
  })),
  mapMobileDisabled: createButtonVariant((theme) => ({
    ...defaultStyle.default(theme),
    wrapper: {
      ...defaultStyle.default(theme).wrapper,
      width: '30%',
      backgroundColor: theme.colors.white,
      '&:hover': {
        backgroundColor: shadeColor(theme.colors.white, -10),
      },
    },
    
    text: {
      ...defaultStyle.default(theme).text,
      color: theme.colors.black,
    },
  })),
}
