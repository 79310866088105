/* eslint-disable max-len */
import { Theme } from '@/app'

export const LocationPin = (props) => {

  return (
    <svg width='28' height='35' viewBox='0 0 28 35' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M14.4049 33.9777L14.4035 33.9785C14.2793 34.0456 14.1403 34.0808 13.9992 34.0808C13.8595 34.0808 13.722 34.0464 13.5988 33.9806L13.5567 33.9566L13.4457 33.8932C13.4448 33.8926 13.4439 33.8921 13.443 33.8916C12.8011 33.5112 12.1748 33.1053 11.5655 32.6747L11.5654 32.6747C9.9847 31.5582 8.50783 30.3016 7.15267 28.9201C3.95274 25.6426 0.666667 20.7568 0.666667 14.5C0.666667 10.9638 2.07142 7.57239 4.57191 5.07191C7.07239 2.57142 10.4638 1.16667 14 1.16667C17.5362 1.16667 20.9276 2.57142 23.4281 5.07191C25.9286 7.57239 27.3333 10.9638 27.3333 14.5C27.3333 20.7584 24.049 25.6425 20.8476 28.9198C19.4919 30.3015 18.0144 31.5582 16.433 32.6746L16.4327 32.6748C15.8238 33.1055 15.1978 33.5116 14.5562 33.892C14.5555 33.8924 14.5548 33.8929 14.5542 33.8933L14.4458 33.9558L14.4049 33.9777ZM14 19.9167C15.4366 19.9167 16.8143 19.346 17.8302 18.3302C18.846 17.3143 19.4167 15.9366 19.4167 14.5C19.4167 13.0634 18.846 11.6857 17.8302 10.6698C16.8143 9.65402 15.4366 9.08333 14 9.08333C12.5634 9.08333 11.1857 9.65402 10.1698 10.6698C9.15401 11.6857 8.58333 13.0634 8.58333 14.5C8.58333 15.9366 9.15401 17.3143 10.1698 18.3302C11.1857 19.346 12.5634 19.9167 14 19.9167Z' fill={props.style.color ? props.style.color : '#4B9982'} stroke={props.style.stroke ? props.style.stroke : '#366E65'} stroke-width={props.style.strokeWidth ?? '0.833333'}/>
    </svg>
  )
}
