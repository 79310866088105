import { Theme } from '@/app';

export const MapButton = (props) => {
  return (
    <svg
      width='1.5em'
      height='1.5em'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.16675 4.00065V14.6673L5.83341 12.0007L11.1667 14.6673L15.8334 12.0007V1.33398L11.1667 4.00065L5.83341 1.33398L1.16675 4.00065Z'
        stroke={Theme.colors.light.white}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.8335 1.33398V12.0007'
        stroke={Theme.colors.light.white}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.1667 4V14.6667'
        stroke={Theme.colors.light.white}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
