import { useState } from 'react'
import {
  Button,
  logger,
  Modal,
  Text,
  TextInput,
  Touchable,
  Theme,
  variantProvider,
  View,
} from '@/app'
import { useAppSelector } from '@/lib'
import { AppStatus } from '@/redux'
import { Categories, Carousel } from '@/shared'

type FiltersModalProps = any

export const FiltersModal: React.FC<FiltersModalProps> = (props) => {
  const { generated, conditions, setConditions, setGenerated, setParams } = props

  const { filtersModalOpen } = useAppSelector((store) => store.AppStatus)

  const { categories, options } = useAppSelector((store) => store.Categories)

  const [currConditions, setCurrConditions] = useState(generated)

  return (
    <Modal
      // visible={filtersModalOpen}
      // toggle={AppStatus.toggleFiltersModal}
      // title={'Filters'}
      // styles={{
      //   box: styles.box,
      // }}
    >
      <View variants={['gap:1', 'column']} css={styles.modalWrapper}>
        {generated && (
          <View variants={['gap:2']} css={styles.categoriesWrapper}>
            <Categories
              index={0}
              initialState={conditions}
              setConditions={setCurrConditions}
            />
          </View>
        )}
        <Button text={'Apply Filters'} />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      columnGap: Theme.spacing.value(2),
      // ...Theme.spacing.paddingVertical(2),
      height: 150,
      borderRadius: Theme.borderRadius.medium,
      border: 'solid 1px #E4E4E4',
      cursor: 'pointer',
      '&:hover': {},
    },
    modalWrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
    },
    box: {
      width: 700,
      minHeight: '60vh',
    },
    categoriesWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      [Theme.media.down('mid')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
    },
  },
  true,
)
