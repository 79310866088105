/* eslint-disable max-lines */
import React from 'react'

import {
  Button,
  logger,
  variantProvider,
  View,
  Text,
  Theme,
  Icon,
} from '@/app'
import { Image, Toast, Link, Avatar, RatingStars } from '@/shared'

import { intlFormat } from 'date-fns'
import { navigate } from 'gatsby'

export default function OfferProduct(props) {
  const { offer, Offers, sent } = props

  const isSmall = Theme.hooks.down('mid')

  const formatedDate = intlFormat(new Date(offer?.created_datetime), {
    locale: 'en-US',
  })

  const handleDeleteOffer = () => {
    Offers.deleteByTypes.send({
      id: offer?.id,
      isSent: sent,
    })
  }

  const handleAcceptOffer = () => {
    Offers.updateByTypes
      .send({
        data: {
          ...offer,
          status: 'AC',
        },
        isSent: sent,
      })
      .then((res) => {
        if (res.status === 'AC') {
          Toast.success('Offer accepted')
          navigate('/account/messages')
        }
      })
  }

  const handleRejectOffer = () => {
    Offers.updateByTypes.send({
      data: {
        ...offer,
        status: 'RJ',
      },
      isSent: sent,
    })
  }

  const hasItems = offer?.exchange_for_item?.length > 0
  const pending = offer?.status === 'ST' && !sent

  const statsTxt = {
    AC: () => 'Accepted',
    RJ: () => 'Rejected',
    WD: () => 'Withdrawal',
  }

  const statusText = (status) => {
    switch (status) {
      case 'AC':
        return 'Accepted'
      case 'RJ':
        return 'Rejected'
      case 'WD':
        return 'Withdrawal'
      case 'CP':
        return 'Completed'
      default:
        return 'Pending'
    }
  }

  const statusButton = (status) => {
    switch (status) {
      case 'AC':
        return styles.acceptedTag
      case 'RJ':
        return styles.rejectTag
      case 'WD':
        return styles.withdrawnTag
      case 'CP':
        return styles.completedTag
      default:
        return styles.pendingTag
    }
  }

  const statusTextStyling = (status) => {
    switch (status) {
      case 'WD':
        return styles.withdrawnText
      case 'CP':
        return styles.completedText
      case 'AC':
        return styles.pendingText
      default:
        return styles.tagText
    }
  }

  logger.log({ offer })

  const renderFirstRow = () => {
    return (
      <View>
        <Image
          css={styles.image}
          variants={['marginRight:2']}
          source={offer?.target_listing?.images[0].url}
        />

        <View
          variants={['column', 'justifySpaceBetween']}
          responsiveVariants={{
            mid: ['gap:1'],
          }}
        >
          <View down={'mid'}>
            <Button
              css={[styles.pendingButton, statusButton(offer?.status)]}
              variants={['disabled', 'padding:0']}
              text={statusText(offer?.status)}
              styles={{
                text: statusTextStyling(offer?.status),
              }}
            />
          </View>
          <Link to={`/product/${offer?.target_listing?.id}/detail`}>
            <Text
              text={`Offer on ${offer?.target_listing?.title}`}
              variants={['h3', 'bold', 'clamp']}
              responsiveVariants={{
                mid: ['h4', 'bold'],
              }}
            />
          </Link>
          {!isSmall && (
            <Link to={sent ? `/profile/${offer?.target_listing?.profile?.id}` : `/profile/${offer?.created_by?.id}`}>
              <View variants={['alignCenter', 'gap:1']}>
                <Avatar
                  styles={{
                    icon: styles.avatar,
                    general: styles.avatarWrapper,
                    image: styles.avatarImage,
                    wrapper: styles.avatarImageWrapper,
                  }}
                  profile={{
                    avatar: sent
                      ? offer?.target_listing?.profile?.avatar
                      : offer?.created_by?.avatar,
                  }}
                  variants={['small', 'bigRadius']}
                />
                <View variants={['column', 'gap:0.5']}>
                  <Text
                    text={
                      sent
                        ? offer?.target_listing?.profile?.username
                        : offer?.created_by?.username
                    }
                    variants={['bold']}
                  />
                  <RatingStars offer={offer} sent={sent} />
                </View>
              </View>
            </Link>
          )}
          <View
            variants={['gap:1']}
            responsiveVariants={{
              mid: ['column'],
            }}
          >
            <Text
              text={`${
                sent ? 'You' : offer?.created_by?.username
              } offered in exchange:`}
              variants={['p2', 'clamp']}
            />
            {!hasItems && (
              <Text
                text={`${offer?.exchange_for_currency} ${offer?.exchange_for_value}`}
                variants={['bold']}
              />
            )}
            {hasItems &&
              offer.exchange_for_item.map((item) => (
                <Link
                  text={item.title}
                  to={`/product/${item.id}/detail`}
                  css={styles.productLink}
                />
              ))}
          </View>
        </View>
      </View>
    )
  }

  const renderSecondRow = () => {
    return (
      <View variants={['justifySpaceBetween', 'alignCenter']}>
        <View variants={['column', 'gap:2']}>
          <View variants={['row', 'alignCenter']}>
            {isSmall && (
              <Text
                down={'mid'}
                variants={['lightGrayText']}
                text={formatedDate}
              />
            )}
          </View>
        </View>
        <View up={'mid'} variants={['column', 'gap:2', 'alignEnd']}>
          <Text variants={['lightGrayText', 'p2']} text={formatedDate} />
          <Button
            css={[styles.pendingButton, statusButton(offer?.status)]}
            variants={['disabled', 'padding:0']}
            text={statusText(offer?.status)}
            styles={{
              text: statusTextStyling(offer?.status),
            }}
          />
          <View variants={['gap:1']}>
            {sent && offer?.status === 'ST' && (
              <Button text='Withdrawl Offer' onPress={handleDeleteOffer} />
            )}
            {pending && !sent && (
              <>
                <Button
                  css={styles.negotiateButton}
                  text='Negotiate'
                  onPress={handleAcceptOffer}
                />
                <Button
                  css={styles.rejectButton}
                  text='Reject'
                  variants={['transparent']}
                  onPress={handleRejectOffer}
                />
              </>
            )}
          </View>
        </View>
      </View>
    )
  }

  return (
    <View
      css={styles.wrapper}
      variants={['column', 'gap:2']}
      responsiveVariants={{
        mid: 'gap:1',
      }}
    >
      <View variants={['fullWidth', 'justifySpaceBetween']}>
        {renderFirstRow()}
        {renderSecondRow()}
      </View>
      <View down={'mid'} variants={['marginTop:2', 'gap:1']}>
        {sent ? (
          <Button
            text='Cancel Offer'
            onPress={handleDeleteOffer}
            responsiveVariants={{
              mid: ['fullWidth'],
            }}
          />
        ) : (
          <>
            <Button css={styles.negotiateButton} text='Negotiate' />
            <Button
              css={styles.rejectButton}
              text='Reject'
              variants={['transparent']}
            />
          </>
        )}
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      borderBottom: `1px solid #e8e8e8`,
      ...Theme.spacing.paddingVertical(3),
    },
    avatar: {
      borderRadius: '50%',
    },
    middleText: {
      color: Theme.colors.light.lightGrayText,
    },
    image: {
      borderRadius: Theme.borderRadius.medium,
      height: 140,
      width: 140,
      objectFit: 'cover',
      border: `1px solid ${Theme.colors.light.xWhite}`,
      [Theme.media.down('mid')]: {
        height: 110,
        width: 110,
      },
    },
    pendingButton: {
      width: 95,
      height: 38,
    },
    acceptedTag: {
      backgroundColor: '#E2F0DF',
    },
    rejectTag: {
      backgroundColor: '#FFEFEF',
    },
    pendingTag: {
      backgroundColor: '#FFF8DD',
    },
    completedTag: {
      backgroundColor: '#E2F0DF',
    },
    withdrawnTag: {
      backgroundColor: '#ff3665',
      fontSize: 14,
    },
    pendingText: {
      color: '#666666',
      fontSize: 14,
    },
    tagText: {
      fontSize: 14,
      color: Theme.colors.light.darkMediumGray,
    },
    withdrawnText: {
      fontSize: 14,
      color: '#f5f5f5',
    },
    completedText: {
      color: Theme.colors.light.darkMediumGray,
      fontSize: 14,
    },
    negotiateButton: {
      width: 130,
      [Theme.media.down('mid')]: {
        width: '50%',
      },
    },
    rejectButton: {
      width: 100,
      [Theme.media.down('mid')]: {
        width: '50%',
      },
    },
    avatarWrapper: {
      height: Theme.values.buttonAndInputHeight,
      width: '48px',
    },
    avatarImageWrapper: {
      width: '48px',
      // width: Theme.values.buttonAndInputHeight,
    },
    avatarImage: {
      borderRadius: '50%',
      width: '48px',
    },
    productLink: {
      textDecoration: 'underline',
      fontWeight: 'bold',
      [Theme.media.down('mid')]: {
        '-webkitLineClamp': '1',
        '-webkitBoxOrient': 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
      },
    },
    productOffer: {
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  true,
)
