import { Button, Modal, Text, Select, TextInput, Theme, logger, variantProvider, View, Touchable } from '@/app'
import { useState } from 'react'
import { useAppSelector } from '@/lib'
import { AppStatus } from '@/redux'
import { Calendar, utils } from 'norama-react-modern-calendar-datepicker'
import { formatFromDate, formatToDate } from '@/lib/utils/helpers'

export type DateModalProps = {

}

export const DateModal: React.FC<DateModalProps> = (props) => {

  const { fromToday } = props

  const { dateModalOpen } = useAppSelector((store) => store.AppStatus)

  const defaultFrom = {
    year: 2019,
    month: 3,
    day: 4,
  }

  const defaultTo = {
    year: 2019,
    month: 3,
    day: 7,
  }

  const defaultRange = {
    from: defaultFrom,
    to: defaultTo,
  }

  const [range, setRange] = useState(defaultRange)

  const handleChange = (item) => {
    setRange(item)

    // item.from && item.to && onChange({
    //   startDate: formatFromDate(item),
    //   endDate: formatToDate(item),
    // })
  }

  return (
    <Modal visible={dateModalOpen}
      toggle={AppStatus.toggleDateModal}
      title={'Date Listed'}>
      <View css={styles.modalWrapper} variants={['column', 'gap:4']}>
        <Touchable variants={['fullWidth', 'justifySpaceBetween']} onPress={() => AppStatus.toggleDateModal()}>
          <View css={styles.dateInfoWrapper} variants={['column']}>
            <Text css={styles.priceTitle} text={'start date'} />
            <Text text={`${range?.from?.month}/${range?.from?.day}/${range?.from?.year}`} />
          </View>
          <View css={styles.dateInfoWrapper} variants={['column']}>
            <Text css={styles.priceTitle} text={'final date'} />
            <Text text={range.to ? `${range?.to?.month}/${range?.to?.day}/${range?.to?.year}` : 'End Date'} />
          </View>
        </Touchable>
        <Calendar
          value={range}
          onChange={handleChange}
          {...(fromToday && { minimumDate: utils().getToday() })}
          shouldHighlightWeekends
          calendarClassName='modern-calendar'
          numberOfMonths={1}
          calendarRangeBetweenClassName={'modern-calendar-range-between'}
          calendarRangeStartClassName={'modern-calendar-range-edge'}
          calendarRangeEndClassName={'modern-calendar-range-edge'}
        />
        <Touchable variants={['fullWidth', 'justifySpaceBetween']} onPress={() => AppStatus.toggleTimeModal()}>
          <View css={styles.dateInfoWrapper} variants={['column']}>
            <Text css={styles.priceTitle} text={'start time'} />
            <Text text={`08:30 AM`} />
          </View>
          <View css={styles.dateInfoWrapper} variants={['column']}>
            <Text css={styles.priceTitle} text={'final time'} />
            <Text text={`02:00 PM`} />
          </View>
        </Touchable>
        <Button text={`Apply filters (3)`} variants={['marginTop:auto', 'fullWidth']} onPress={() => null} css={styles.filtersButton} />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    modalWrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
      height: '100%',
    },
    calendarContainer: {
      height: '100%',
      width: '100%',
      transition: '300ms',
    },
    calendarWrapper: {
      width: '100%',
      transition: '300ms',
      top: '100%',
    },
    calendarActive: {
      opacity: 1,
      visibility: 'visible',
      top: '100%',
    },
    calendarDisabled: {
      opacity: 0,
      visibility: 'hidden',
    },
    label: {
      fontSize: 12,
      marginBottom: 8,
      paddingLeft: 8,
    },
    priceTitle: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.4)',
      // left: '15%',
      paddingTop: Theme.spacing.value(0.5),
      // paddingLeft: Theme.spacing.value(1.8),
      // paddingTop: Theme.spacing.value(0.2),
    },
    dateInfoWrapper: {
      border: `1px solid #CCCCCC`,
      borderRadius: Theme.borderRadius.medium,
      width: 180,
      ...Theme.spacing.paddingHorizontal(1),
      ...Theme.spacing.paddingVertical(1),
    },
  },
  true,
)
