import React, { useState } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { View, Theme, variantProvider } from '@/app'
import {
  TextBlock,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders'

export const TabsSkeleton = (props) => {
  const { ready, children } = props

  const renderItem = () => {
    return (
      <View css={styles.wrapper} variants={['alignCenter', 'gap:1']}>
        <RectShape
          // color={Theme.colors.light.skeleton}
          css={styles.box}
          style={{
            width: 140,
            height: 140,
            borderRadius: Theme.borderRadius.medium,
          }}
        />
        <View variants={['justifySpaceBetween', 'flex:1']}>
          <View variants={['column', 'gap:2']}>
            <TextBlock
              style={{
                width: '180px',
              }}
              css={styles.teste}
            />
            <TextBlock
              style={{
                width: '130px',
              }}
              css={styles.teste}
            />
            <TextBlock
              style={{
                width: '150px',
              }}
              css={styles.teste}
            />
          </View>
          <View variants={['gap:1', 'alignCenter']}>
            <RectShape
              color={Theme.colors.light.skeleton}
              style={{
                width: 20,
                height: 20,
                borderRadius: Theme.borderRadius.medium,
              }}
            />
            <RectShape
              color={Theme.colors.light.skeleton}
              style={{
                width: 20,
                height: 20,
                borderRadius: Theme.borderRadius.medium,
              }}
            />
          </View>
        </View>
      </View>
    )
  }

  const renderSkeleton = (
    <>
      <View
        variants={[
          'column',
          'justifySpaceBetween',
          'flex:1',
          'gap:2',
          'fullWidth',
          'marginVertical:2',
        ]}
        css={styles.details}
      >
        <View variants={['column', 'gap:2']}>
          <View>
            <TextBlock
              style={{
                width: '15%',
              }}
              css={styles.teste}
            />
            <TextBlock
              style={{
                width: '15%',
              }}
              css={styles.teste}
            />
          </View>
          {renderItem()}
          {renderItem()}
          {renderItem()}
          {renderItem()}
        </View>
      </View>
    </>
  )

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation={true}
    >
      {children}
    </ReactPlaceholder>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      // minHeight: '80vh',
    },
    details: {
      width: '100%',
      // minHeight: '80vh',
    },
    textBlock: {
      width: '30%',
    },
    teste: {
      div: {
        backgroundColor: Theme.colors.light.skeleton,
        borderRadius: Theme.borderRadius.small,
        height: '15px !important',
      },
    },
    box: {
      backgroundColor: 'rgb(234, 234, 234)',
    },
  },
  true,
)
