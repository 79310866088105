import { View, Button, Touchable, logger, Theme, Text } from '@/app'
import { onMount, onUpdate, useComponentStyle, useForm } from '@codeleap/common'
import { Session, AppStatus } from '@/redux'
import { useAppSelector, signupForm } from '@/lib'
import { Toast } from '@/shared'
import { profileFromUser } from '@/services/authentication'
import { withFirebase } from '@/services'

import { variantProvider } from '@/app/theme'

type SocialProps = any

export const Social: React.FC<SocialProps> = (socialProps) => {
  const { isLogin, form, setUsingProvider } = socialProps

  const { profile } = useAppSelector((store) => store.Session)

  // const form = useForm(signupForm, {
  //   output: 'json',
  //   validateOn: 'submit',
  // });

  const handleSuccess = (isSignin) => {
    Toast.success('Login successfully')
    if (isSignin) {
      AppStatus.toggleSigninModal()

    } else {
      AppStatus.toggleSignupModal()
    }
  }

  const handleFillSignup = async (isSignin) => {

    logger.log({ form })

    if (isSignin) {
      AppStatus.toggleSignupModal()
      AppStatus.toggleSigninModal()
    }

    const currentUser = withFirebase((firebase) => firebase.auth().currentUser)

    const firebaseProfile = await profileFromUser(currentUser)

    if (firebaseProfile) {
      setUsingProvider('google')
      form.setFormValues(firebaseProfile)
    }
  }

  const handleSocialAuth = async (provider) => {
    try {
      const res = await Session.login({
        withProvider: provider,
      })

      if (res?.profile) {
        handleSuccess(isLogin)
      } else {
        handleFillSignup(isLogin)
      }

    } catch (err) {
      logger.log({ err })
    }
  }

  const styles = useComponentStyle(componentStyles)

  return (
    <View variants={['block', 'marginTop:4']}>
      <View variants={['row', 'flex', 'alignCenter']}>
        <View css={styles.separator} />
        <Text
          css={styles.socialText}
          text={'OR'}
          variants={['secondaryColor']}
        />
        <View css={styles.separator} />
      </View>
      <View variants={['gap:2']} css={styles.buttons}>
        <Button
          icon='google'
          variants={['socialIcon']}
          onPress={() => handleSocialAuth('google')
          }
          css={styles.socialButton}
          styles={{
            icon: styles.socialIcon,
          }}
        />
        <Button
          icon='facebook'
          variants={['socialIcon']}
          onPress={() => handleSocialAuth('facebook')
          }
          css={styles.socialButton}
          styles={{
            icon: styles.socialIcon,
          }}
        />
      </View>
    </View>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  socialIcon: {
    color: theme.colors.blue,
    height: '2em',
    width: '2em',
  },
  separator: {
    flex: 1,
    height: 1,
    ...Theme.spacing.marginHorizontal(4),
    backgroundColor: theme.colors.secondaryText,
  },
  socialButton: {
    color: theme.colors.black,
    width: 48,
    borderRadius: theme.borderRadius.xSmall,
    padding: Theme.spacing.value(1),
  },
  socialText: {
    color: theme.colors.gray,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',

    marginTop: Theme.spacing.value(4),
    [Theme.media.is('small')]: {
      gridTemplateColumns: 'auto',
      rowGap: Theme.spacing.value(2),
      marginTop: Theme.spacing.value(2),
      ...Theme.spacing.marginHorizontal(1),
    },
  },
}))
