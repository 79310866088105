import React, { useState } from 'react'
import { Button, Modal, Text, Select, TextInput, Theme, logger, variantProvider, View } from '@/app'
import { Categories, Carousel } from '@/shared'
import { AppStatus } from '@/redux'
import { useAppSelector } from '@/lib'
import { defaultStyles, customStyles, filterStyles, categoriesMobile } from '@/app/stylesheets/ReactSelect'

export type OfferModalProps = {
  generated?: any
  setConditions?: any
}

export const CategoriesModal: React.FC<OfferModalProps> = (props) => {

  const { generated, conditions, setConditions } = props

  const { categoriesModalOpen } = useAppSelector((store) => store.AppStatus)
  

  const handleAplyFilters = () => {
    logger.log('ola')
  }

  return (
    <Modal visible={categoriesModalOpen}
      toggle={AppStatus.toggleCategoriesModal}
      title={'Filters'}>
      <View css={styles.modalWrapper} variants={['column', 'gap:1', 'relative']}>
        {generated && (
          <Categories
            index={0}
            isExplore
            styles={categoriesMobile}
            initialState={conditions}
            setConditions={setConditions}
            textInputStyles={{
              wrapper: styles.selectWrapper,
              innerWrapper: styles.inputInnerWrapper,
              buttonWrapper: styles.buttonWrapper,
              label: styles.selectLabel,
              buttonText: styles.buttonText,
              textField: styles.text,
              error: { display: 'none ' },
            }}
          />
        )}
        <Button text={`Apply filters (3)`} variants={['marginTop:auto', 'absolute']} onPress={handleAplyFilters} css={styles.filtersButton} />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    filterWrapper: {
      borderBottom: 'solid 1px #E4E4E4',
    },
    modalWrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
      height: '100%',
    },
    inputInnerWrapper: {
      height: 38,
      backgroundColor: Theme.colors.light.xWhite,
      border: 'none',
    },
    inputWrapper: {
      height: 38,
    },
    selectWrapper: {
      borderRadius: Theme.borderRadius.small,
      minWidth: 150,
    },
    selectLabel: {
      fontSize: 12,
      marginBottom: 8,
      paddingLeft: 8,
    },
    buttonWrapper: {
      paddingLeft: Theme.spacing.value(1),
      paddingRight: Theme.spacing.value(1),
      backgroundColor: Theme.colors.light.xWhite,
      border: 'none',
      height: 38,
    },
    buttonText: {
      color: Theme.colors.light.darkMediumGray,
      fontSize: 14,
      fontWeight: 'bold',
      marginRight: Theme.spacing.value(1),
    },
    text: {
      color: Theme.colors.light.darkMediumGray,
    },
    filtersButton: {
      bottom: '5%',
      width: '90%',
      zIndex: 9999999999999999,
    },
  },
  true,
)
