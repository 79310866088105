export const NoChat = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 113 112'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M104.984 67.0062H75.7389L65.9905 81.6289H46.4937L36.7453 67.0062H7.5'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M57.7949 28.0126H33.0409C31.2272 28.0135 29.4499 28.5204 27.9085 29.4762C26.3672 30.432 25.1231 31.7988 24.316 33.4229L7.5 67.0062V96.2515C7.5 98.8369 8.52706 101.316 10.3552 103.145C12.1834 104.973 14.663 106 17.2484 106H95.2358C97.8212 106 100.301 104.973 102.129 103.145C103.957 101.316 104.984 98.8369 104.984 96.2515V67.0062L96.5762 50.2146'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M85.4892 42.8788C95.584 42.8788 103.768 34.6954 103.768 24.6006C103.768 14.5057 95.584 6.32227 85.4892 6.32227C75.3944 6.32227 67.2109 14.5057 67.2109 24.6006C67.2109 34.6954 75.3944 42.8788 85.4892 42.8788Z'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M90.9728 19.1171L80.0059 30.084'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M80.0059 19.1171L90.9728 30.084'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
