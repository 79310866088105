import { Theme } from '@/app';

export const Line = (props) => {
  return (
    <svg
      width='1'
      height='28'
      viewBox='0 0 1 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <line
        x1='0.25'
        y1='1.09279e-08'
        x2='0.249999'
        y2='28'
        stroke={Theme.colors.light.silver}
        strokeWidth='0.5'
      />
    </svg>
  );
};
