import React, { useState, useEffect } from 'react'
import {
  logger,
  View,
  Select,
  Button,
  Text,
  Theme,
  Touchable,
  variantProvider,
  Modal,
} from '@/app'
import { Categories, Carousel, MemorizedCategories } from '@/shared'
import { defaultStyles, customStyles, filterStyles, categoriesMobile } from '@/app/stylesheets/ReactSelect'
import { onMount, onUpdate, usePrevious } from '@codeleap/common'

import { useAppSelector } from '@/lib'
import { AppStatus } from '@/redux'

type FiltersRowProps = any

const CategoriesModalWrapper = ({ children }) => {
  const isSmall = Theme.hooks.down('mid')

  const { filtersModalOpen } = useAppSelector((store) => store.AppStatus)

  if (isSmall) {
    return (
      <Modal
        visible={filtersModalOpen}
        toggle={AppStatus.toggleFiltersModal}
        title={'Filters'}
      >
        <View css={styles.modalWrapper} variants={['column', 'gap:1', 'relative']}>
          {children}
          <Button text={`Apply filters (3)`} variants={['marginTop:auto', 'fullWidth']} onPress={() => null} css={styles.filtersButton} />
        </View>
      </Modal>
    )
  } else {
    return (
      <>
        {children}
      </>
    )
  }
}

export const FiltersRow: React.FC<FiltersRowProps> = ({
  setParams,
  setGenerated,
  generated,
  setConditions,
  mapRef,
  circles,
}) => {
  const { categories, options } = useAppSelector((store) => store.Categories)
  const { isLoggedIn } = useAppSelector((store) => store.Session)

  const { filtersModalOpen } = useAppSelector((store) => store.AppStatus)
  const { locations } = useAppSelector((store) => store.Locations)

  const isSmall = Theme.hooks.down('mid')

  const prevSmall = usePrevious(isSmall)

  const updatedSmall = isSmall === prevSmall

  const handleChangeLocation = (coords) => {
    const currentCircle = circles.filter(item => item?.location?.id === coords.id)

    logger.log({ currentCircle })
    mapRef.current.fitBounds(currentCircle[0].circleBounds)
  }

  const renderSavedSearchButton = () => {
    return (
      <View>
        <Button
          text='Saved Searches'
          styles={{ text: styles.textSavedButton }}
          css={[styles.savedButton, styles.savedSearchButton]}
          onPress={() => isLoggedIn ? AppStatus.toggleSavedSearchesModal() : AppStatus.toggleSigninModal()}
        />
        <Button
          icon={'plus'}
          styles={{ text: styles.textSavedButton }}
          css={[styles.savedButton, styles.savedPlusButton]}
          onPress={() => isLoggedIn ? AppStatus.toggleNewSearchModal() : AppStatus.toggleSigninModal()}
        />
      </View>
    )
  }

  return (
    <View
      variants={[
        'alignCenter',
        'justifySpaceBetween',
        'marginTop:2',
        'marginBottom:1',
      ]}
      responsiveVariants={{
        mid: ['margin:0'],
      }}
    >
      <View variants={['gap:2', 'alignCenter', 'fullWidth']} css={styles.categorieswrapper}>
        <CategoriesModalWrapper>
          {generated && (
            <MemorizedCategories
              index={0}
              initialState={generated}
              setConditions={setConditions}
              isExplore
              textInputStyles={{
                wrapper: styles.selectWrapper,
                innerWrapper: styles.inputInnerWrapper,
                buttonWrapper: styles.buttonWrapper,
                label: styles.selectLabel,
                buttonText: styles.buttonText,
                textField: styles.text,
                error: { display: 'none ' },
              }}
              styles={isSmall ? categoriesMobile : customStyles}
              shouldMount={false}
              showFree
            />
          )}
        </CategoriesModalWrapper>
        <View variants={['paddingTop:1.5']} up={'mid'}>
          <Button
            variants={['text', 'fullWidth']}
            text='Clear All'
            styles={{ text: styles.clearText }}
            css={styles.clearButton}
            onPress={() => {
              setParams((prev) => {
                return {
                  lat: prev.lat,
                  lng: prev.lng,
                  distance: prev.distance,
                }
              })

              setGenerated(null)
              setTimeout(() => {
                setGenerated({})
              }, 10)
            }}
          />
        </View>
        <View
          variants={['gap:2', 'paddingTop:1.5', 'marginLeft:auto']}
          up={'mid'}
          responsiveVariants={{
            mid: ['fullWidth', 'justifySpaceBetween', 'gap:0'],
          }}>
          <Touchable onPress={() => { isLoggedIn ? null : AppStatus.toggleSigninModal() }}>
            <Select
              placeholder={'My Locations'}
              options={locations.map((s) => ({
                label: s.title,
                value: s,
              }))}
              onValueChange={handleChangeLocation}
              customStyles={filterStyles}
              isDisabled={!isLoggedIn}
            />
          </Touchable>
          {renderSavedSearchButton()}
        </View>
      </View>
      <View variants={['alignCenter', 'fullWidth', 'justifySpaceBetween', 'marginTop:2']} down={'mid'}>
        {renderSavedSearchButton()}
        <View variants={['alignCenter', 'gap:2']}>
          <Button variants={['icon']} icon={'locationPin'} styles={{
            icon: styles.locationIcon,
          }} onPress={() => AppStatus.toggleMyLocationsModal()} />
          <Button variants={['icon']} icon={'filter'} onPress={() => AppStatus.toggleFiltersModal()} />
        </View>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    locationIcon: {
      color: Theme.colors.light.white,
      stroke: Theme.colors.light.black,
      strokeWidth: 3,
      width: 20,
      height: 20,
      svg: {
        width: 20,
        height: 20,
        color: Theme.colors.light.black,
        stroke: Theme.colors.light.black,
        strokeWidth: 3,
        path: {
          strokeWidth: '3px',
        },
      },
    },
    modalWrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
      height: '100%',
    },
    categorieswrapper: {
      [Theme.media.down('mid')]: {
        width: 'unset',
      },
    },
    inputInnerWrapper: {
      height: 38,
      backgroundColor: Theme.colors.light.xWhite,
      border: 'none',
    },
    inputWrapper: {
      height: 38,
    },
    selectWrapper: {
      borderRadius: Theme.borderRadius.small,
      minWidth: 150,
    },
    selectLabel: {
      fontSize: 12,
      marginBottom: 8,
      paddingLeft: 8,
    },
    buttonWrapper: {
      paddingLeft: Theme.spacing.value(1),
      paddingRight: Theme.spacing.value(1),
      backgroundColor: Theme.colors.light.xWhite,
      border: 'none',
      height: 38,
    },
    itemWrapper: {
      padding: Theme.spacing.value(1),
    },
    select: {
      fontWeight: 'bold',
      height: 0,
    },
    selectText: {
      fontSize: 13,
      color: Theme.colors.light.primaryText,
    },
    selectIcon: {
      width: 10,
      height: 10,
    },
    clearButton: {
      width: 'unset',
      height: 38,
    },
    clearText: {
      fontWeight: 'bold',
      color: Theme.colors.light.darkMediumGray,
    },
    savedButton: {
      backgroundColor: Theme.colors.light.white,
      border: '1px solid rgb(222, 222, 222)',
      height: 38,
      transition: '200ms',
      '&:hover': {
        background: 'rgba(54, 110, 101, 0.05)',
      },
    },
    savedSearchButton: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: 'none',
    },
    savedPlusButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    textSavedButton: {
      color: Theme.colors.light.black,
      fontSize: 14,
      fontWeight: 700,
      whiteSpace: 'pre',
    },
    buttonText: {
      color: Theme.colors.light.darkMediumGray,
      fontSize: 14,
      fontWeight: 'bold',
      marginRight: Theme.spacing.value(1),
    },
    text: {
      color: Theme.colors.light.darkMediumGray,
    },
    sliderContainer: {
      '.slick-track': {
        display: 'flex',
      },
      '.slick-list': {
        margin: 'unset !important',
        padding: 'unset !important',
      },
      [Theme.media.down('small')]: {
        '.slick-track': {
          gridGap: Theme.spacing.value(2),
        },
      },
    },
    filtersButton: {
      bottom: '5%',
      width: '90%',
      zIndex: 9999999999999999,
    },
  },
  true,
)
