import { api, logger } from '@/app'
import { Toast } from '@/shared'
import { useQuery, waitFor } from '@codeleap/common'
import { AppStatus } from '@/redux'
import { navigate } from 'gatsby'

const MODULE_NAME = 'offers/'

export const useOffers = () => useQuery({
  initialState: {
    offers: [],
    sentOffers: [],
    offersDetails: [

    ],
    offersSent: [],
    offersReceived: [],
  },
  routes: {
    getData: async ({ currentValue, setState, throwError }) => {

      try {
        const { data } = await api.get(MODULE_NAME)
        setState({ ...currentValue, offers: data })
        return data
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    query: async ({ currentValue, setState, throwError }, query) => {
      try {
        const { data } = await api.get(MODULE_NAME, { params: query })
        setState({ ...currentValue, offers: data.results })
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    getByTypes: async ({ currentValue, setState, throwError }, query) => {
      try {
        const { data: sent } = await api.get(MODULE_NAME + '?sent=true', { params: query })
        const { data: received } = await api.get(MODULE_NAME + '?sent=false', { params: query })
        setState({ ...currentValue, offersSent: sent, offersReceived: received })
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    post: async ({ currentValue, setState, throwError }, data) => {
      AppStatus.set('loading')
      try {
        const { data: offer } = await api.post(MODULE_NAME, data)
        AppStatus.set('idle')
        Toast.success('Offer created successfully')
        navigate('/account/offers')
        return offer
      } catch (err) {
        AppStatus.set('idle')
        Toast.error('Error creating offer, please try again')
        logger.log(err)
      }
    },
    getDetail: async ({ currentValue, setState, throwError }, id) => {
      try {
        const { data } = await api.get(MODULE_NAME + id + '/')
        setState({ ...currentValue, offersDetails: data })

        return data
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    delete: async ({ currentValue, setState, throwError }, id) => {
      AppStatus.set('loading')
      try {
        const { data } = await api.delete(MODULE_NAME + id + '/')

        AppStatus.set('idle')
        setState({
          ...currentValue,
          offers: currentValue.offers.filter(offer => offer.id !== id),
        })

        Toast.success('Offer deleted successfully')
        return data
      } catch (err) {
        AppStatus.set('idle')
        throwError('Error fetching data')
      }
    },
    deleteByTypes: async ({ currentValue, setState, throwError }, { id, isSent }) => {

      AppStatus.set('loading')
      try {
        const { data } = await api.delete(MODULE_NAME + id + '/')

        AppStatus.set('idle')
        Toast.success('Offer deleted successfully')

        if (isSent) {
          setState({
            ...currentValue, offersSent: {
              ...currentValue.offersSent,
              results: currentValue.offersSent.results.filter(offer => offer.id !== id),
            }
          })
          // setState({...currentValue, offersSent: currentValue.offersSent.results.filter(offer => offer.id !== id)})
        } else {
          setState({
            ...currentValue, offersReceived: {
              ...currentValue.offersReceived,
              results: currentValue.offersReceived.results.filter(offer => offer.id !== id),
            }
          })

        }

        return data
      } catch (err) {
        logger.log({ err })
        AppStatus.set('idle')
        Toast.error('Error deleting offer, please try again')
        throwError('Error fetching data')
      }
    },
    updateByTypes: async ({ currentValue, setState, throwError }, { data, isSent }) => {
      AppStatus.set('loading')

      const dataToSend = {
        id: data.id,
        status: data.status,
        target_listing: data.target_listing.id,
      }

      try {
        const { data: offer } = await api.patch(MODULE_NAME + data.id + '/', dataToSend)
        AppStatus.set('idle')

        if (isSent) {
          setState({
            ...currentValue, offersSent: {
              ...currentValue.offersSent,
              results: currentValue.offersSent.results.map(offer => offer.id === data.id ? data : offer),
            }
          })
        } else {
          setState({
            ...currentValue, offersReceived: {
              ...currentValue.offersReceived,
              results: currentValue.offersReceived.results.map(offer => offer.id === data.id ? data : offer),
            }
          })
        }
        return offer
      } catch (err) {
        AppStatus.set('idle')
        throwError('Error fetching data')
      }
    },
    update: async ({ currentValue, setState, throwError }, data) => {
      AppStatus.set('loading')

      const dataToSend = {
        id: data.id,
        status: data.status,
        target_listing: data.target_listing.id,
      }

      try {
        const { data: offer } = await api.patch(MODULE_NAME + data.id + '/', dataToSend)
        AppStatus.set('idle')
        logger.log({ currentValue })
        setState({ ...currentValue, offers: currentValue.offers.map(offer => offer.id === data.id ? data : offer) })
        return offer
      } catch (err) {
        AppStatus.set('idle')
        throwError('Error fetching data')
      }
    },

  },
})
