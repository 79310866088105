import React, { useState } from 'react'
import {
  Button,
  View,
  Theme,
  Settings,
  TextInput,
  variantProvider,
} from '@/app'
import { LocationInput } from '@/shared'

import { useMapsApi } from '@/lib/hooks'
import { useProfiles } from '@/services/api'
import { useAppSelector, newLocationForm } from '@/lib'

export type NewLocationsForm = {

}

export default function AccountLocations(props) {
  const { form, onCreateLocation } = props

  const [showDropdown, setShowDropdown] = useState(false)

  const handleCreateLocation = () => {
    const isValid = form.validateAll(true)
    if (isValid) {
      onCreateLocation(form.values)
    }
  }

  const handleSelect = (description, lat, lng) => {
    form.setFormValues({
      ...form.values,
      address: description,
      coords: { lat: lat, lng: lng },
    })
  }

  return (
    <View css={styles.wrapper} variants={['fullWidth', 'column', 'gap:3']}>
      <>
        <TextInput variants={['withHover']} {...form.register('title')} />
        <LocationInput
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          label='Location'
          onPress={handleSelect}
          showDefaultValue={false}
        />
        <Button text='Submit' onPress={handleCreateLocation} />
      </>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
    },
  },
  true,
)
