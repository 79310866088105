import 'firebase/auth'

import { RootComponent } from '@/RootComponent';

import 'react-toastify/dist/ReactToastify.css'
// import 'norama-react-modern-calendar-datepicker/lib/DatePicker.css'




export const wrapRootElement = ({ element }) => {
  
  return (
      <RootComponent>
        {element}
      </RootComponent>
  );
}
