import { Button, View,  Text, variantProvider, Theme } from '@/app';
import { useComponentStyle } from '@codeleap/common';
import {Link,} from '@/shared'
type ToggleComponentProps = any;

export const ToggleComponent: React.FC<ToggleComponentProps> = (props) => {
  const { firstTitle, secondTitle, thirdTitle, setTab, tab, hasThird } = props;

  const styles = useComponentStyle(componentStyles);


  return (
    <View variants={['fullWidth']}>
      <Button
        onPress={() => {
          setTab(prev => {
            return {
              ...prev,
              tab: 1,
            }
          });
        }}
        css={[
          styles.button,
          tab == 1 ? styles.buttonActive : styles.buttonInactive,
        ]}
        styles={{ text: tab == 1 ? styles.textActive : styles.textInactive }}
        text={firstTitle}
        variants={['transparentNoBorder']}
      />
      <Button
        onPress={() => {
          setTab((prev) => {
            return {
              ...prev,
              tab: 2,
            };
          });
        }}
        css={[
          styles.button,
          tab == 2 ? styles.buttonActive : styles.buttonInactive,
        ]}
        styles={{ text: tab == 2 ? styles.textActive : styles.textInactive }}
        text={secondTitle}
        variants={['transparentNoBorder']}
      />
      {hasThird && (
        <Button
          onPress={() => {
            setTab((prev) => {
              return {
                ...prev,
                tab: 3,
              };
            });
          }}
          css={[
            styles.button,
            tab == 3 ? styles.buttonActive : styles.buttonInactive,
          ]}
          styles={{ text: tab == 3 ? styles.textActive : styles.textInactive }}
          text={thirdTitle}
          variants={['transparentNoBorder']}
        />
      )}
    </View>
  );
};

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  button: {
    borderRadius: 0,
    ...Theme.spacing.paddingHorizontal(0),
    width: '15%',
    transition: '200ms',
    [Theme.media.down('mid')]: {
      width: '50%',
    },
  },
  buttonActive: {
    borderBottom: '2px solid black',
  },
  buttonInactive: {
    borderBottom: '2px solid #9C9C9C',
  },
  textActive: {
    color: theme.colors.black,
    fontWeight: 'bold',
  },
  textInactive: {
    color: theme.colors.mediumGray,
  },
}))

