import { motion } from 'framer-motion';

type AnimatedProps = any;

export const Animated: React.FC<AnimatedProps> = (rawProps) => {
  const { children, style, ...props } = rawProps;
  const AnimatedComponent = motion.div;

  return (
    <AnimatedComponent transition={transition} css={style} {...props}>
      {children}
    </AnimatedComponent>
  );
};

const transition = {
  duration: 0.25,
};
