import { variantProvider } from '@/app'
import { useComponentStyle } from '@codeleap/common'
import { CardBase } from './Base'

export const MapCard: React.FC<any> = (props) => {
  const { listing } = props

  const styles = useComponentStyle(componentStyles)

  const verticalVariants = ['column', 'gap:1.5']

  return (
    <CardBase
      wrapperStyles={styles.wrapper}
      imageWrapperStyles={styles.imageWrapper}
      imageStyles={styles.image}
      textStyles={styles.texts}
      bottomTextsStyles={styles.bottomTexts}
      bottomTextsVariants={verticalVariants}
      listing={listing}
      isMap={true}
    />

  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    width: 300,
    // flexDirection: 'row',
    boxShadow: '0 6px 20px rgb(0 0 0 / 20%)',
    border: 'none',
    [theme.media.down('mid')]: {
      width: 400,
      height: 130,
      display: 'flex',
    },
    [theme.media.down('smallish')]: {
      width: 315,
      height: 120,
    },
  },
  imageWrapper: {
    height: 150,
    [theme.media.down('mid')]: {
      width: '40%',
    },
  },
  image: {
    maxWidth: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  texts: {
    // height: 160,
    gap: theme.spacing.value(1),
    justifyContent: 'center',
  },
  bottomTexts: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.value(1),
    // backgroundColor: 'red',
  },
}))
