import React, { useState } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { View, Theme, variantProvider } from '@/app'
import {
  TextBlock,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders'

export const RowSkeleton = (props) => {
  const { ready, children } = props

  const renderSkeleton = (
    <>
      <View
        variants={['column', 'justifySpaceBetween', 'gap:2']}
        css={styles.details}
      >
        <TextBlock css={styles.teste} />
      </View>
    </>
  )

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    details: {
      width: '40%',
    },
    teste: {
      backgroundColor: Theme.colors.light.skeleton,
      borderRadius: Theme.borderRadius.medium,
      height: '20px !important',
    },
  },
  true,
)
