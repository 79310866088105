import React, { useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { View, Theme, variantProvider } from '@/app';
import {
  TextBlock,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders';

export const HeaderTextSkeleton = (props) => {
  const { ready, children } = props;

  const renderSkeleton = (
    <>
      <View
        variants={['marginTop:4', 'marginBottom:3']}
        css={styles.details}
      >
        <TextBlock css={styles.teste} />
      </View>
    </>
  );

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  );
};

const styles = variantProvider.createComponentStyle(
  {
    details: {
      width: '40%',
    },
    teste: {
      backgroundColor: Theme.colors.light.skeleton,
      height: '40px !important',
    },
  },
  true
);
