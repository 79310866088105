import { formatDistance } from 'date-fns'
import getSymbolFromCurrency from 'currency-symbol-map'

export * from './hooks/redux'
export * from './utils/Validate'
export * from './utils/GetCityName'
export * from './utils/ObjectToQueryString'
export * from './utils/OptionsToQueryString'
export * from './utils/QueryStringToOptions'
export * from './utils/GetGeocodeName'

export const dateToBackEnd = (date) => {
  return new Date(date).toISOString()
    .replace(/\.\d+/, '')
    .slice(0, -1)
}

export const numbersRangeToBackEnd = (numbers) => {
  return `${numbers[0]}||${numbers[1]}`
}

export const datesRangeToBackEnd = (dates) => {
  const startDate = dateToBackEnd(dates?.startDate)
  const endDate = dateToBackEnd(dates?.endDate)

  return `${startDate}||${endDate}`
}

export const openPicker = (input) => {
  input.current.openFilePicker()
}

export const formatedDistanceDate = (date) => {
  return formatDistance(
    new Date(date),
    new Date(),
    {
      includeSeconds: false,
    })
}

export const currencySymbol = (currency) => {
  return getSymbolFromCurrency(currency)
}

export const getUserLocation = () => {
  navigator.geolocation.getCurrentPosition(function (position) {
    return position?.coords
  })
}

export const addDays = (days) => {
  const today = new Date()

  today.setDate(today.getDate() + days)

  return today
}

export const formatFromDate = (item) => {
  const formatedFrom = `${item?.from?.month}-${item?.from?.day}-${item?.from?.year}`

  const fromToGo = new Date(formatedFrom)

  return fromToGo
}

export const formatToDate = (item) => {
  const formatedTo = `${item?.to?.month}-${item?.to?.day}-${item?.to?.year}`

  const toToGo = new Date(formatedTo)

  return toToGo
}
