import { logger } from '@/app';
import type Firebase from 'firebase'

type FirebaseInstance = {
  auth: typeof Firebase.auth
}
let initialized = false
export function withFirebase<T extends((f:FirebaseInstance) => any)>(cb:T):Promise<ReturnType<T>> {
  try {
    return import('firebase/app').then(({ default: _ }) => {
      initialized = _.apps.length === 1
      if (!initialized) {
        _.initializeApp({
          apiKey: "AIzaSyAg97w_kg5LSn-m0QccVtlwKq4dN4a78Rg",
          authDomain: "client-xc-dev-906fa.firebaseapp.com",
          databaseURL: "https://client-xc-dev-906fa.firebaseio.com",
           projectId: "client-xc-dev-906fa",
           storageBucket: "client-xc-dev-906fa.appspot.com",
           messagingSenderId: "1077367980816",
           appId: "1:1077367980816:web:935631b6d8ece9ab4d5b95"
       })
        initialized = true
      }

      return cb(_)
    })

  } catch (e) {
    logger.error(e)
  }
}