import { useState } from 'react'
import {
  Button,
  Modal,
  Text,
  logger,
  TextInput,
  Theme,
  variantProvider,
  View,
} from '@/app'
import { LocationInput } from '@/shared'
import { useAppSelector, newFilterForm } from '@/lib'
import { AppStatus } from '@/redux'
import { useForm, onMount, onUpdate } from '@codeleap/common'
import { useMapsApi } from '@/lib/hooks'

type OfferModalProps = any

export const NewSearchModal: React.FC<OfferModalProps> = (props) => {
  const { Profiles, params, query, queryObj } = props
  const { newSearchModalOpen } = useAppSelector((store) => store.AppStatus)

  const form = useForm(newFilterForm, {
    output: 'json',
    validateOn: 'blur',

    initialState: {
    },
  })

  const formatedQueryObj = Object.fromEntries(
    new URLSearchParams(query,
    ))

  const category = formatedQueryObj.category

  onUpdate(() => {
    form.setFieldValue('name', '')
  }, [newSearchModalOpen])

  const handleCreateFilter = () => {
    Profiles.createSavedFilter.send({
      name: form.values.name,
      location: {
        title: '',
        address: params.description,
        coords: { lat: params.lat, lng: params.lng },
      },
      data: query,
    })
  }

  const renderOptions = () => {

    const itemsArr = Object.keys(queryObj)

    const itemsArrWithoutCategory = itemsArr.filter(
      (item) => item !== 'category',
    )

    return (
      <View variants={['column', 'gap:2']}>
        {itemsArrWithoutCategory.map((key, index) => {
          const item = queryObj[key]

          let formatedKey = key.replace(/_/g, ' ')

          formatedKey = formatedKey
            .toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')

          return (
            <View key={index} css={styles.optionsWrapper}>
              <Text text={`${formatedKey}${':'}`} />
              <Text variants={['thin']} text={item} />
            </View>
          )
        })}
      </View>
    )
  }

  return (
    <Modal
      visible={newSearchModalOpen}
      toggle={AppStatus.toggleNewSearchModal}
      title={'New Search'}
      styles={{
        box: styles.box,
      }}
    >
      <View css={styles.wrapper} variants={['column', 'fullWidth', 'gap:4']}>
        <TextInput value={form.values.name} variants={['withHover']} {...form.register('name')} label={'Search Name'} />
        <View variants={['gap:2']}>
          <Text text={params?.description} />
          <Text text={category} />
        </View>
        {renderOptions()}

        <Button text='Save' onPress={handleCreateFilter} />
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
    },
    buttonsWrapper: {
      gap: Theme.spacing.value(1.5),
    },
    buttonLink: {
      width: '100%',
    },
    text: {
      color: Theme.colors.light.silver,
    },
    optionsWrapper: {
      gap: Theme.spacing.value(0.2),
    },
    box: {
      width: 550,
    },
  },
  true,
)
