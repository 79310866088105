/* eslint-disable max-len */
import React, { useState } from 'react'
import { logger } from '@/app'
import { Theme, variantProvider } from '@/app/theme'
import _Select, { StylesConfig } from 'react-select'
import { useDefaultComponentStyle, assignTextStyle, useValidate } from '@codeleap/common'
import { CustomSelectProps, Text, View } from '@codeleap/web'
import makeAnimated from 'react-select/animated'

const animatedComponents = makeAnimated()

export const Select = (props: CustomSelectProps) => {
  const selectedOption = props?.options?.find?.(o => {
    if (typeof props.value === 'object') {
      return o.value === props.value?.value
    }
    return o.value === props.value
  })

  const { showError, error } = useValidate(props.value, props?.validate)

  logger.log({ selectProps: props, showError, error })

  return (
    <View variants={['column']}>
      {props.label && <Text css={[styles.label, props?.styles?.label]} text={props.label} />}
      <_Select {...props} styles={props?.customStyles} onChange={(e) => { !props?.isMulti ? props.onValueChange(e?.value) : props.onValueChange(e) }} maxMenuHeight={300}
        menuPlacement='auto' value={selectedOption} />
      {showError && <Text text={error?.message} variants={['p2', 'marginTop:1']} css={styles.errorText} />}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    label: {
      marginBottom: 8,
      paddingLeft: 8,
      ...assignTextStyle('p1')(Theme).text,
    },
    errorText: {
      color: 'rgb(170, 17, 17)',
    },
  },
  true,
)
