import { Button, variantProvider, View, Settings, Theme } from '@/app'
import { useScript } from '@codeleap/common'
//Configure this apiKey in Settings.ts

const scriptURL = `https://maps.googleapis.com/maps/api/js?key=${Settings.ApiKeys.GoogleMap}&libraries=places`

export const useMapsApi = () => {
  const status = useScript(scriptURL)

  return status
}
