import { Theme } from '@/app'

export const FooterLinkedin = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3.5 5.00003C4.60457 5.00003 5.5 4.1046 5.5 3.00003C5.5 1.89546 4.60457 1.00003 3.5 1.00003C2.39543 1.00003 1.5 1.89546 1.5 3.00003C1.5 4.1046 2.39543 5.00003 3.5 5.00003Z'
        fill={Theme.colors.light.primary}
        fillOpacity='0.3'
        strokeWidth='2'
      />
      <path
        d='M5.5 8H1.5V20.5H5.5V8Z'
        fill={Theme.colors.light.primary}
        fillOpacity='0.3'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 12.75V20.5H13V13.5C13 12.25 13.75 11.25 15 11.25C16.25 11.25 17 12.5 17 13.5V20.5H20.5V12.75C20.5 11.25 18.75 8 15 8C11.25 8 9.5 11.25 9.5 12.75Z'
        fill={Theme.colors.light.primary}
        fillOpacity='0.3'
        strokeWidth='2'
        strokeLinejoin='round'
      />
    </svg>
  );
};
