import ReactPlaceholder from 'react-placeholder';
import { View, Theme, variantProvider } from '@/app';
import {
  TextBlock,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders';
import { ProductInfoPlaceholder } from './ProductInfo';

export const ChatSkeleton = (props) => {
  const { ready, children } = props;

  const renderTextBlock = (widthOne, widthTwo) => {
    return (
      <View variants={['gap:1', 'column']}>
        <TextBlock
          style={{
            width: widthOne,
          }}
          css={styles.teste}
        />
        <TextBlock
          css={styles.testeTwo}
          style={{
            width: widthTwo,
          }}
        />
      </View>
    );
  };

  const renderSkeleton = (
    <View variants={['fullWidth', 'gap:4', 'column', 'padding:2']}>
      {renderTextBlock('20%', '80%')}
      {renderTextBlock('60%', '40%')}
      {renderTextBlock('20%', '75%')}
      {renderTextBlock('20%', '90%')}
      {renderTextBlock('60%', '100%')}
      {renderTextBlock('60%', '100%')}
    </View>
  );

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  );
};

const styles = variantProvider.createComponentStyle(
  {
    teste: {
      div: {
        backgroundColor: Theme.colors.light.skeleton,
        borderRadius: Theme.borderRadius.small,
        height: '25px !important',
      },
    },
    testeTwo: {
      div: {
        backgroundColor: Theme.colors.light.skeleton,
        borderRadius: Theme.borderRadius.small,
        height: '25px !important',
        width: '80% !important',
      },
    },
  },
  true
);
