import { WebModalStyles } from '@codeleap/web'
import { ModalComposition, mapVariants } from '@codeleap/common'
import { Theme, variantProvider } from '../theme'

const defaultStyle = WebModalStyles

const createModalVariant = variantProvider.createVariantFactory<ModalComposition>() 

export const AppModalStyles = {
  ...defaultStyle,
  default: createModalVariant((theme) => ({
    ...defaultStyle.default(theme),
    header: {
      ...defaultStyle.default(theme).header,

      alignItems: 'center',
      justifyContent: 'space-between',
      // marginBottom: theme.spacing.value(3),
      // ...Theme.spacing.paddingHorizontal(4),
      // paddingTop: Theme.spacing.value(2),
      // ...Theme.spacing.paddingHorizontal(4),
      // ...Theme.spacing.paddingVertical(2),
      margin: 0,
      ...Theme.spacing.paddingHorizontal(4),
      ...Theme.spacing.paddingVertical(4),
    },
    title: {
      ...defaultStyle.default(theme).title,
      fontWeight: 'bold',
      fontSize: 32,
      margin: 0,
      padding: 0,
    },
    wrapper: {
      ...defaultStyle.default(theme).wrapper,
      zIndex: Theme.values.zIndex.modal,
      overflowY: 'unset',
      // ...Theme.spacing.paddingHorizontal(4),
      // ...Theme.spacing.paddingVertical(4),
    },
    box: {
      ...defaultStyle.default(theme).box,
      borderRadius: Theme.borderRadius.large,
      maxHeight: '70vh',
      width: 'unset',
      overflow: 'unset',
      padding: 0,
      // ...Theme.spacing.paddingHorizontal(4),
      // ...Theme.spacing.paddingVertical(4),
      // height: '90vh',
      [Theme.media.down('mid')]: {
        maxHeight: '100vh',
        width: '100vw',
        height: '100vh',
        borderRadius: 0,
      },
    },

    body: {
      ...defaultStyle.default(theme).body,
      overflowY: 'unset',
      padding: 0,
      // ...Theme.spacing.paddingHorizontal(4),
      // ...Theme.spacing.paddingVertical(4),
    },


  })),
}