export const Inventory = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          d='M21.5 8V21H3.5V8'
          stroke={props.style.color ? props.style.color : '#969696'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.5 3H1.5V8H23.5V3Z'
          stroke={props.style.color ? props.style.color : '#969696'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.5 12H14.5'
          stroke={props.style.color ? props.style.color : '#969696'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_452_3736'>
          <rect
            width='24'
            height='24'
            fill='black'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
