import { Theme } from '@/app'

export const ArrowUp = (props) => {
  const { strokeColor } = props
  return (
    <svg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M1 9L9 1L17 9' stroke='#366E65' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/>
    </svg>

  )
}
