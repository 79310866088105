export const EditSecondary = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.5 20.5H21.5'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17 3.99998C17.3978 3.60216 17.9374 3.37866 18.5 3.37866C18.7786 3.37866 19.0544 3.43353 19.3118 3.54014C19.5692 3.64674 19.803 3.803 20 3.99998C20.197 4.19697 20.3532 4.43082 20.4598 4.68819C20.5665 4.94556 20.6213 5.22141 20.6213 5.49998C20.6213 5.77856 20.5665 6.05441 20.4598 6.31178C20.3532 6.56915 20.197 6.803 20 6.99998L7.5 19.5L3.5 20.5L4.5 16.5L17 3.99998Z'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}