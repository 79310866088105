export const NoNotifications = (props) => {
  return (
    <svg width='142' height='142' viewBox='0 0 142 142' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M113 123H124.071' stroke='#D9D9D9' stroke-width='4' stroke-linecap='round'/>
      <path d='M97 53.6667C97 46.7711 94.2607 40.1579 89.3848 35.282C84.5088 30.406 77.8956 27.6667 71 27.6667C64.1044 27.6667 57.4912 30.406 52.6152 35.282C47.7393 40.1579 45 46.7711 45 53.6667C45 84.0001 32 92.6667 32 92.6667H110C110 92.6667 97 84.0001 97 53.6667Z' stroke='#D9D9D9' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/>
      <path d='M78.4953 110C77.7334 111.313 76.6399 112.403 75.3243 113.161C74.0086 113.919 72.5169 114.318 70.9986 114.318C69.4803 114.318 67.9886 113.919 66.673 113.161C65.3573 112.403 64.2638 111.313 63.502 110' stroke='#D9D9D9' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/>
      <path d='M21 22H32.0714' stroke='#D9D9D9' stroke-width='4' stroke-linecap='round'/>
    </svg>

  )
}
