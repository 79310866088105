/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  ComponentVariants,
  ImageComposition,
  ImageStyles,
  parseSourceUrl,
  StylesOf,
  useComponentStyle,
  useCodeleapContext,
  useDefaultComponentStyle,
} from '@codeleap/common';
import { CSSProperties } from 'react';

const dynamicAssetPrefixes = ['http', 'https', 'blob'];
type ImageProps = {
  source: string;
  css?: any;
  alt?: string;
  img?: string;
  objectFit?: Exclude<CSSProperties['objectFit'], CSSProperties['objectFit'][]>;
  styles?: StylesOf<ImageComposition>;
} & ComponentVariants<typeof ImageStyles>;

export const Image: React.FC<ImageProps> = (imgProps) => {
  const {
    variants = [],
    responsiveVariants = {},
    styles,
    objectFit,
    ...props
  } = imgProps;

  const { Settings, logger } = useCodeleapContext();
  const data = useStaticQuery(query);
  const source = parseSourceUrl(props, Settings);
  const fileNode = data.allFile.nodes.find((i) => source.includes(i.relativePath),
  );

  logger.log('render image', { fileNode, data, props, source }, 'Component');

  if (!props.alt) {
    logger.log('missing alt property in img', { source }, 'Component');
  }

  const alt = props.alt || 'image';

  const isStatic =
    !props.img &&
    dynamicAssetPrefixes.filter((i) => source?.startsWith(i)).length < 1;

  const variantStyles = useDefaultComponentStyle('Image', {
    responsiveVariants,
    variants,
    styles,
  });

  if (isStatic) {
    const image = getImage(fileNode);
    logger.log('isStatic', { props, source, image }, 'Component');
    return (
      <GatsbyImage
        {...props}
        objectFit={objectFit}
        image={image}
        css={variantStyles.wrapper}
        alt={alt}
      />
    );
  }

  logger.log('not static', { props, source }, 'Component');
  return (
    <img
      {...props}
      style={{ objectFit }}
      src={source}
      css={variantStyles.wrapper}
    />
  );
};

const query = graphql`
  query {
    allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
        relativePath
      }
    }
  }
`;
