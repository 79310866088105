export const ImagePlaceholder = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 27 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M23.6684 0H3.3322C2.65328 0.00094769 2.0024 0.270852 1.52227 0.75098C1.04236 1.23108 0.772261 1.88179 0.771484 2.56072V22.4393C0.772243 23.1183 1.04234 23.769 1.52227 24.2491C2.00237 24.7292 2.65328 24.9993 3.3322 25.0001H23.6684C24.3473 24.9991 24.998 24.7292 25.4781 24.2491C25.958 23.769 26.2279 23.1181 26.2285 22.4393V2.56072C26.228 1.88199 25.9581 1.23111 25.4781 0.75098C24.998 0.270876 24.3473 0.000970447 23.6684 0ZM3.3322 1.13212L23.6684 1.13231C24.0471 1.13269 24.4101 1.28338 24.6779 1.5512C24.9455 1.81902 25.096 2.18218 25.0964 2.5607V13.9426L19.8439 8.85648C19.6251 8.64457 19.2781 8.64381 19.0582 8.85458L11.6072 15.9995L9.1329 13.4464L9.13271 13.4462C8.92422 13.232 8.58494 13.2165 8.35766 13.4109L1.90322 18.9517V2.56087C1.9036 2.18217 2.05429 1.81901 2.32211 1.55117C2.58993 1.28354 2.95309 1.13285 3.33181 1.13248L3.3322 1.13212ZM1.9038 22.4392V20.4438L8.69159 14.6172L11.1831 17.1881L11.1843 17.19L11.1861 17.1913L17.6561 23.8675H3.33232C2.95362 23.8671 2.59047 23.7166 2.32262 23.4488C2.0548 23.181 1.90412 22.8178 1.90374 22.4391L1.9038 22.4392ZM23.6685 23.8678L19.2325 23.8676L12.3952 16.8129L19.4484 10.0494L25.0959 15.5189V22.4391C25.0955 22.8178 24.945 23.1808 24.6774 23.4486C24.4096 23.7164 24.0466 23.8671 23.6679 23.8675L23.6685 23.8678Z'
        fill='black'
        fillOpacity='0.3'
      />
      <path
        d='M6.96122 8.65194C7.6663 8.65194 8.34241 8.3718 8.84108 7.8733C9.33955 7.37464 9.61971 6.69853 9.61971 5.99345C9.61971 5.28837 9.33958 4.61207 8.84108 4.1136C8.34241 3.61493 7.6663 3.33496 6.96122 3.33496C6.25615 3.33496 5.57984 3.61491 5.08137 4.1136C4.58271 4.61207 4.30273 5.28837 4.30273 5.99345C4.30349 6.69814 4.58382 7.37406 5.0821 7.87238C5.58057 8.37085 6.25629 8.65121 6.96122 8.65194ZM6.96122 4.46708C7.36608 4.46708 7.75427 4.62781 8.04046 4.91402C8.32666 5.20041 8.48758 5.58858 8.48758 5.99345C8.48758 6.39832 8.32666 6.78649 8.04046 7.07268C7.75425 7.35888 7.36609 7.5198 6.96122 7.5198C6.55635 7.5198 6.16818 7.35888 5.88199 7.07268C5.5956 6.78648 5.43487 6.39832 5.43487 5.99345C5.43525 5.58878 5.59635 5.20079 5.88237 4.9146C6.16858 4.62859 6.55659 4.46748 6.96122 4.46709L6.96122 4.46708Z'
        fill='black'
        fillOpacity='0.3'
      />
    </svg>
  );
}