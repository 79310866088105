export const Apple = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.4247 8.78322C17.3324 8.78322 18.4702 8.15072 19.1477 7.3074C19.7614 6.54314 20.2088 5.47581 20.2088 4.40848C20.2088 4.26354 20.196 4.11859 20.1705 4C19.1605 4.03953 17.946 4.69838 17.2173 5.58123C16.642 6.25325 16.1179 7.3074 16.1179 8.38791C16.1179 8.54603 16.1435 8.70416 16.1562 8.75686C16.2202 8.77004 16.3224 8.78322 16.4247 8.78322ZM13.2287 24.7273C14.4688 24.7273 15.0185 23.8708 16.5653 23.8708C18.1378 23.8708 18.483 24.7009 19.8636 24.7009C21.2188 24.7009 22.1264 23.4096 22.983 22.1446C23.9418 20.6951 24.3381 19.272 24.3636 19.2061C24.2741 19.1798 21.679 18.0861 21.679 15.0159C21.679 12.3542 23.7244 11.1551 23.8395 11.0628C22.4844 9.05993 20.4261 9.00722 19.8636 9.00722C18.3423 9.00722 17.1023 9.95596 16.3224 9.95596C15.4787 9.95596 14.3665 9.05993 13.0497 9.05993C10.544 9.05993 8 11.1946 8 15.2267C8 17.7303 8.94602 20.3789 10.1094 22.0919C11.1065 23.5413 11.9759 24.7273 13.2287 24.7273Z'
        fill='black'
      />
    </svg>
  );
};
