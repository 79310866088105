import { logger, variantProvider, View, Text, Theme } from '@/app'
import { intlFormat, format } from 'date-fns'

export type MessageProps = {
  message?: any
  myProfile?: any
}

export default function Message(props: MessageProps) {
  const { message, myProfile } = props

  const today = new Date()

  const myMessage = message.profile.id === myProfile.id

  const sampleDate = new Date(message?.created_datetime)

  const formatedToday = intlFormat(new Date(today), {
    locale: 'en-US',
  })

  const formatedSampleDate = intlFormat(new Date(sampleDate), {
    locale: 'en-US',

  })

  const formatedHour = format(sampleDate, 'HH:mm')

  const beforeToday = formatedSampleDate < formatedToday

  return (
    <View css={styles.wrapper} variants={['alignCenter']}>
      <View variants={['column']}>
        <View variants={['alignCenter', 'gap:1']}>
          <Text
            css={styles.name}
            variants={['h4', 'bold']}
            text={myMessage ? myProfile.username : message.profile.username}
          />
          <View variants={['gap:1']}>
            {beforeToday && (<Text text={formatedSampleDate} variants={['p3']} />)}
            <Text variants={['p3']} text={formatedHour} />
          </View>
        </View>
        <Text css={styles.message} variants={['p2']} text={message?.body} />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      columnGap: Theme.spacing.value(2),
      ...Theme.spacing.paddingVertical(2),
    },
    name: {
      marginBottom: Theme.spacing.value(0.5),
    },
    message: {
      color: 'rgba(0, 0, 0, 0.7)',
    },
  },
  true,
)
