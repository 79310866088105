import ReactPlaceholder from 'react-placeholder';
import {
  CardComponent,
  View,
  Text,
  Theme,
  Touchable,
  Button,
  variantProvider,
} from '@/app';
import {
  TextBlock,
  MediaBlock,
  TextRow,
  RectShape,
  RoundShape,
} from 'react-placeholder/lib/placeholders';

export const PopularSkeleton = (props) => {
  const { ready, children } = props;

  const renderSkeleton = (
    <View variants={['gap:1', 'fullWidth']} css={styles.wrapper}>
      <RectShape
        color='gray'
        style={{
          gridColumn: '1/2',
          gridRow: '1/3',
          borderTopLeftRadius: Theme.borderRadius.xSmall,
          borderBottomLeftRadius: Theme.borderRadius.xSmall,
        }}
      />
      <RectShape
        color='gray'
        style={{
          gridColumn: '2/2',
        }}
      />
      <RectShape
        color='gray'
        style={{
          gridColumn: '3/3',
        }}
      />
      <RectShape
        color='gray'
        style={{
          gridColumn: '4/4',
          gridRow: '1/3',
          borderTopRightRadius: Theme.borderRadius.xSmall,
          borderBottomRightRadius: Theme.borderRadius.xSmall,
        }}
      />
      <RectShape
        color='gray'
        style={{
          gridColumn: '2/3',
        }}
      />
      <RectShape
        color='gray'
        style={{
          gridColumn: '3/4',
        }}
      />
    </View>
  );

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  );
};

const styles = variantProvider.createComponentStyle({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridAutoRows: 'minmax(150px, auto)',
    maxWidth: '100%',
  },
}, true);
