import { Modal, variantProvider, View, Select, Theme } from '@/app'
import { useAppSelector } from '@/lib'
import { AppStatus } from '@/redux'
import { defaultStyles, customStyles, filterStyles } from '@/app/stylesheets/ReactSelect'

export type MyLocationsModalProps = {
  setParams?: any
}

export const MyLocationsModal: React.FC<MyLocationsModalProps> = (props) => {

  const { setParams } = props

  const { myLocationsModalOpen } = useAppSelector(
    (store) => store.AppStatus,
  )

  const { locations } = useAppSelector((store) => store.Locations)

  const handleChangeLocation = (coords) => {
    setParams((prev) => {
      return {
        ...prev,
        lat: coords.lat,
        lng: coords.lng,
      }
    })
    AppStatus.toggleMyLocationsModal()
  }

  return (
    <View>
      <Modal
        visible={myLocationsModalOpen}
        toggle={AppStatus.toggleMyLocationsModal}
        title={'My Locations'}
        styles={{
          box: styles.box,

        }}
      >
        <View css={styles.modalWrapper} variants={['column']}>
          <Select
            placeholder={'My Locations'}
            options={locations.map((s) => ({
              label: s.title,
              value: s.coords,
            }))}
            onValueChange={handleChangeLocation}
            customStyles={filterStyles}
          />
        </View>
      </Modal>
    </View>
  )
}

const styles = variantProvider.createComponentStyle({
  box: {
    width: 550,
  },
  modalWrapper: {
    ...Theme.spacing.paddingHorizontal(4),
    paddingBottom: Theme.spacing.value(4),
  },
}, true)
