import { TextInputComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

const createTextInputStyle = variantProvider.createVariantFactory<TextInputComposition>()
const defaultStyles = variantProvider.getDefaultVariants('TextInput')

export const AppTextInputStyles = {
  ...defaultStyles,
  default: createTextInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    innerWrapper: {
      ...defaultStyles.default(theme).innerWrapper,
      ...theme.spacing.paddingHorizontal(1.6),
      borderRadius: theme.borderRadius.small,
      // backgroundColor: theme.colors.veryLightGray,
      height: theme.values.buttonAndInputHeight,
      border: '1px solid rgb(222, 222, 222)',
      alignItems: 'center',
      transition: '200ms',
    },
    textField: {
      ...defaultStyles.default(theme).textField,
      color: theme.colors.black,
      marginTop: 0,
    },
    label: {
      ...defaultStyles.default(theme).label,
      marginBottom: theme.spacing.value(1),
      ...theme.spacing.paddingHorizontal(1),
      color: theme.colors.primaryText,
    },
  })),
  hideLabel: createTextInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    label: {
      ...defaultStyles.default(theme).label,
      display: 'none',
    },
  })),
  withHover: createTextInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      ':focus': {
        backgroundColor: 'blue',
        border: '1px solid blue',
      },
    },
    innerWrapper: {
      ...defaultStyles.default(theme).innerWrapper,
      ':hover': {
        boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 25px',
      },
      ':focus-within': {
        border: `1px solid ${theme.colors.primary}`,
      },
    },
  })),
  withBlur: createTextInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    label: {
      ...defaultStyles.default(theme).label,
      display: 'none',
    },
  })),
  hideErrors: createTextInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    error: {
      ...defaultStyles.default(theme).error,
      display: 'none',
    },
  })),
  searchInput: createTextInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    error: {
      ...defaultStyles.default(theme).error,
      marginTop: 0,
    },
  })),
  searchInputRight: createTextInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    innerWrapper: {
      ...defaultStyles.default(theme).innerWrapper,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  })),
  noBorderRadius: createTextInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    innerWrapper: {
      ...defaultStyles.default(theme).innerWrapper,
      borderRadius: 0,
    },
  })),
  noBorderColor: createTextInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    innerWrapper: {
      ...defaultStyles.default(theme).innerWrapper,
      borderRadius: 0,
      border: 'none',
    },
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      borderColor: 'red',
    },
  })),
  smallIcon: createTextInputStyle((theme) => ({
    ...defaultStyles.default(theme),
    icon: {
      ...defaultStyles.default(theme).icon,
      width: 15,
      height: 15,
    },
  })),

  // Example to create a variant with a custom style

  // teste: createTextInputStyle((theme) => ({
  //   ...defaultStyles,
  //   innerWrapper: {
  //     border: 'none',
  //     backgroundColor: Theme.colors.inputBackground,
  //   },
  // })),
}
