import React from 'react'
import {

  Button,

  variantProvider,
  View,
  Text,
  Theme,
  Menu,
} from '@/app'
import ReactPlaceholder from 'react-placeholder'
import { Avatar, Link } from '@/shared'
export const AccountMenu = (props) => {
  const {
    styles,
    menuItems,
    profile,
    file,
    setFile,
    setPostFile,
    showMenu = true,
  } = props

  const joynedDate = new Date(profile?.date_joined)

  const joynedYear = joynedDate.getFullYear()
  const joynedMonth = joynedDate.toLocaleString('en-US', { month: 'long' })

  const isSmall = Theme.hooks.down('mid')

  const handleImageChange = (args) => {
    setFile(args.preview)
    setPostFile(args.file)
  }

  if (!showMenu) {
    return null
  }

  if (!isSmall) {
    return (
      <View variants={['column', 'padding:1']}>
        <Avatar
          profile={{ avatar: file ? file : profile?.avatar, firstName: profile?.first_name, username: profile?.username }}
          styles={{
            icon: pageStyles.avatar,

          }}
          variants={['large', 'bigRadius']}
          onChange={handleImageChange}
        />
        <View
          variants={[
            'column',
            'alignCenter',
            'fullWidth',
            'marginTop:2',
            'marginBottom:1',
            'gap:2',
          ]}
        >
          <ReactPlaceholder
            type='text'
            ready={profile !== null}
            showLoadingAnimation
          >
            <Text variants={['h3', 'veryBold', 'textCenter']} text={profile?.username} />
            <Text
              css={pageStyles.joynedText}
              variants={['p2', 'textCenter']}
              text={`Joined in ${joynedMonth} ${joynedYear}`}
            />
          </ReactPlaceholder>
          <Link css={pageStyles.profileLink} to={`/profile/${profile?.id}`}>
            <Button

              variants={['transparent', 'fullWidth']}
              text='View Profile'
            />
          </Link>
          <View variants={['fullWidth']} css={pageStyles.separator} />
        </View>
        <Menu styles={styles} items={menuItems} />
      </View>
    )
  } else {
    return (
      <View />
    )
  }
}

const pageStyles = variantProvider.createComponentStyle(
  {
    avatar: {
      width: '100%',
      height: '100%',
      display: 'unset',
    },
    joynedText: {
      color: Theme.colors.light.quaternaryText,
    },
    profileLink: {
      width: '90%',
    },
    separator: {
      height: 1,
      backgroundColor: Theme.colors.light.silver,
      marginBottom: Theme.spacing.value(2),
    },
    avatarImage: {
      borderRadius: '50%',
    },
  },
  true,
)
