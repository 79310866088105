import { Theme } from '@/app'

export const ArrowDown = (props) => {
  const { strokeColor } = props
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11 1.5L6 6.5L1 1.5'
        stroke={props.style.color ? props.style.color : '#313131'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
