import {
  Button,
  logger,
  variantProvider,
  View,
  Text,
  Theme,
  TextInput,
  Touchable,
} from '@/app'
import { Image, Carousel, ProfileAbout, Toast, Link } from '@/shared'
import { useAppSelector } from '@/lib'
import { useListings } from '@/services/api'
import { ProductInfoSkeleton, InfoSkeleton } from '@/skeletons'
import { AppStatus } from '@/redux'

export const ProductInfo = (props) => {
  const {
    setShowInfo,
    showInfo,
    profile,
    listing,
    setListing,
    messages,
    wrapperStyle,
    id,
    isDrawer,
    isMessage,
    tab,
    setTab,
    currOffer,
    setCurrOffer,
    Offers,
  } = props

  const {
    data: { listings },
    queries: Listings,
    loading,
  } = useListings()

  const { profile: myProfile } = useAppSelector((store) => store.Session)

  const handleCompleteOffer = () => {
    logger.log({ currOffer })

    // Listings.update.send({
    //   id: currOffer?.target_listing?.id,
    //   data: {
    //     ...currOffer?.target_listing,
    //     sold: true,
    //   },
    // })

    Offers.update
      .send({
        ...currOffer,
        status: 'CP',
      })
      .then((res) => {
        if (res.status === 'CP') {
          Toast.success('Offer accepted')
          setShowInfo(false)
          AppStatus.toggleFeedbackModal()
        }
      })
  }

  const handleWithdrawOffer = () => {
    Offers.update
      .send({
        ...currOffer,
        status: 'WD',
      })
      .then((res) => {
        if (res.status === 'WD') {
          Toast.success('Offer withdrawn')
          setCurrOffer(null)
          setShowInfo(false)
        }
      })
  }

  const handleDeleteOffer = () => {
    Offers.delete.send(currOffer.id).then(() => {
      Toast.success('Offer deleted successfully')
      setShowInfo(false)
      setCurrOffer(null)
    })
  }

  const myOffer = myProfile?.id === currOffer?.created_by?.id

  const isCompleted = currOffer?.status === 'CP'

  const currListing = isMessage ? currOffer?.target_listing : listing

  return (
    <InfoSkeleton ready={isMessage ? true : profile}>
      <View css={[styles.wrapper, wrapperStyle]} variants={['column']}>
        <View
          variants={['alignCenter', 'justifySpaceBetween', 'marginBottom:2']}
        >
          <Text variants={['h3', 'bold']} text={'Information'} />
          {isDrawer && (
            <Button
              variants={['icon']}
              icon={'close'}
              onPress={() => {
                setShowInfo(false)
              }}
            />
          )}
        </View>
        <View css={styles.imageWrapper}>
          <Carousel
            styleArrow={styles.slickArrow}
            stylePrevArrow={styles.slickArrowPrev}
            styleNextArrow={styles.slickArrowNext}
          >
            {currListing?.images?.map((image, index) => (
              <Image
                variants={['fullWidth']}
                key={index}
                css={styles.Carousel}
                source={image.url}
              />
            ))}
          </Carousel>
        </View>
        <View css={styles.middleWrapper} variants={['column', 'marginTop:2']}>
          <View variants={['column', 'gap:2']}>
            <Text variants={['h3', 'bold']} text={currListing?.title} />
            <View css={styles.exchangeText} variants={['alignCenter', 'gap:1']}>
              <Text text='Exchange for:' />
              <Text
                variants={['h4', 'bold']}
                text={`${currListing?.exchange_for}`}
              />
            </View>
            <View variants={['column', 'gap:2']}>
              <Text variants={['bold']} text='Description' />
              <Text text={currListing?.description} />
            </View>
          </View>
          <View variants={['column', 'gap:1']}>
            <Text variants={['h3', 'bold']} text='Listed by' />
            <ProfileAbout
              small
              profile={isMessage ? currOffer?.target_listing?.profile : profile}
              isLink
            />
            <Link
              css={styles.linkButton}
              variants={['alignCenter', 'flex', 'alignCenter']}
              to={`/profile/${
                !isMessage
                  ? currListing.profile?.id
                  : currOffer?.target_listing?.profile?.id
              }`}
            >
              <Text css={styles.buttonText} text='See Profile' />
              <Button variants={['icon']} icon={'arrowRight'} />
            </Link>
          </View>
          <View variants={['gap:2', 'fullWidth']}>
            {!myOffer && !isCompleted && isMessage && (
              <Button text={'Complete Offer'} onPress={handleCompleteOffer} />
            )}
            {!isCompleted && isMessage && (
              <Button
                variants={['transparent']}
                text={myOffer ? 'Withdraw Offer' : 'Delete Offer'}
                onPress={myOffer ? handleWithdrawOffer : handleDeleteOffer}
              />
            )}
          </View>
        </View>
      </View>
    </InfoSkeleton>
  )
}

const ARROW_SIZE = 38
const CAROUSEL_HEIGHT = 280

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      width: 300,
    },
    imageWrapper: {
      borderRadius: Theme.borderRadius.medium,
      overflow: 'hidden',
    },
    middleWrapper: {
      gap: Theme.spacing.value(2),
    },
    linkButton: {
      gap: Theme.spacing.value(1),
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      height: 0,
      padding: 0,
      marginTop: Theme.spacing.value(1),
    },
    buttonText: {
      color: Theme.colors.light.tertiaryText,
    },
    Carousel: {
      height: CAROUSEL_HEIGHT,
      border: `1px solid ${Theme.colors.light.xWhite}`,
      borderRadius: Theme.borderRadius.medium,
      objectFit: 'contain',
    },
    slickArrow: {
      zIndex: Theme.values.zIndex.slickArrow,
      top: '45%',
      padding: 0,
      width: 48,
      borderRadius: '50%',
      backgroundColor: Theme.colors.light.white,
      boxShadow: 'rgb(0 0 0 / 8%) 0px 0px 25px',
    },
    slickArrowPrev: {
      left: '5%',
      width: ARROW_SIZE,
      height: ARROW_SIZE,
    },
    slickArrowNext: {
      right: '5%',
      width: ARROW_SIZE,
      height: ARROW_SIZE,
      left: 'unset',
    },
    exchangeText: {
      gap: Theme.spacing.value(0.5),
    },
  },
  true,
)
