/* eslint-disable max-len */
import React, { useState } from 'react'
import { Button, Modal, Text, Select, TextInput, Theme, logger, variantProvider, View } from '@/app'
import { useForm, onUpdate } from '@codeleap/common'
import { useAppSelector, offerCurrencyForm, formatedDistanceDate } from '@/lib'
import { AppStatus } from '@/redux'
import { useListings, useOffers, useProfiles, useCurrencyConversion } from '@/services/api'
import { navigate } from 'gatsby'
import getSymbolFromCurrency from 'currency-symbol-map'
import { Link } from '@/shared'
import { defaultStyles } from '@/app/stylesheets/ReactSelect'

export type OfferModalProps = {
  id?: Number
  listing?: any
}

export type ConversionProps = {
  base?: string
  target?: string
  rate?: number
}

export const OfferModal: React.FC<OfferModalProps> = (props) => {
  const { id, listing: currentListing } = props

  const { offerModalOpen } = useAppSelector((store) => store.AppStatus)
  const { profile: myProfile } = useAppSelector((store) => store.Session)
  const { options } = useAppSelector((store) => store.Categories)

  const [conversion, setConversion] = useState<ConversionProps>({})

  const {
    queries: Offers,
  } = useOffers()

  const {
    data: { rates, timestamp },
    loading,
    queries: Conversion,
  } = useCurrencyConversion()

  const currenciesOptions = options?.find(opt => opt.title === 'Currency Name')?.selects

  const currencyOptions = currenciesOptions?.map((currency) => { return { value: currency.id, label: `${currency.title}` } })

  const timestampDate = new Date(timestamp * 1000)

  const formatedDate = formatedDistanceDate(timestampDate)

  const isListingCurrency = currentListing?.category === 'Currency'
  const isCurrencyExchange = currentListing?.exchange_for === 'Currency'

  const isCurrencyForCurrency = isListingCurrency && isCurrencyExchange

  const exchangeCurrency = currentListing?.currency_name?.split('-')[0]?.replace(/ /g, '')

  const form = useForm(offerCurrencyForm, {
    output: 'json',
    validateOn: 'change',
    initialState: {
      exchange_for_currency: myProfile?.data?.currency ?? 647,
      // data: {

      //   currency: profile?.data?.currency,
      //   distance_value: profile?.data?.distance_value,
      //   distance_type: profile?.data?.distance_type,
      // },
    },
  })

  const currencyName = currenciesOptions?.find(el => el.id === form.values.exchange_for_currency)

  const formatedCurrencyName = currencyName?.title?.split('-')[0]?.replace(/ /g, '')

  logger.log({ form, currencyName, formatedCurrencyName })

  const currencySymbol = getSymbolFromCurrency(formatedCurrencyName)

  const handleCloseModal = () => {
    AppStatus.toggleOfferModal()
  }

  onUpdate(() => {
    isCurrencyForCurrency && form.setFieldValue('exchange_for_currency', exchangeCurrency?.replace(/ /g, ''))
  }, [offerModalOpen])

  onUpdate(() => {
    if (isCurrencyForCurrency) {

      const listingCurrency = currentListing?.attributes[0]?.selects[0]?.title.split('-')[0]

      setConversion((prev) => {
        return {
          ...prev,
          base: listingCurrency,
          target: exchangeCurrency,
        }
      })

    }

  }, [currentListing])

  onUpdate(() => {
    if (conversion && isCurrencyForCurrency) {
      Conversion.query.send({
        listingCurrency: conversion?.base,
        offerCurrency: conversion?.target,
      })
    }
  }, [conversion?.base, conversion?.target])

  onUpdate(() => {
    const listingRate = rates[Object.keys(rates)[0]]
    const offerRate = rates[Object.keys(rates)[1]]

    const conversionRate = offerRate / listingRate

    setConversion((prev) => {
      return {
        ...prev,
        rate: conversionRate,
      }
    })

  }, [rates])

  onUpdate(() => {
    setConversion((prev) => {
      return {
        ...prev,
        target: form?.values?.exchange_for_currency,
      }
    })
  }, [form?.values?.exchange_for_currency])

  const handleCreateOffer = () => {

    const isValid = form.validateAll(true)
    if (isValid) {
      logger.log({ formValues: form.values, id, myProfile })
      Offers.post
        .send({
          ...form.values,
          target_listing: currentListing?.id,
          created_by: myProfile.id,
        })
        .then((res) => {})
      navigate(`/account/offers`)
    }
  }

  const handleChangeText = (val) => {
    const onlyValue = val.replace(/\D/g, '')
    form.setFieldValue('exchange_for_value', onlyValue)
  }

  return (
    <Modal
      visible={offerModalOpen}
      toggle={AppStatus.toggleOfferModal}
      title={'Make an offer'}
      styles={{

        body: styles.modalBody,
      }}
    >
      <View css={styles.wrapper} variants={['fullWidth', 'column']}>
        <View
          css={styles.buttonsWrapper}
          variants={['column', 'fullWidth', 'marginBottom:2']}
        >
          <Select
            label={'Currency'}
            placeholder='Select a Currency'
            {...form.register('exchange_for_currency')}
            arrowIconName='arrowDown'
            customStyles={defaultStyles}
            options={currencyOptions}
          />

          <TextInput
            placeholder={currencySymbol + '80.00'}
            variants={['withHover', 'relative']}
            {...form.register('exchange_for_value')}
            value={currencySymbol + ' ' + form.values.exchange_for_value}
            onChangeText={(val) => handleChangeText(val)}
          />

          <View variants={['gap:1', 'alignCenter']}>
            {isCurrencyForCurrency && (
              <>
                <View variants={['padding:1']} css={styles.currencyTag}>
                  <Text css={styles.currencyText} variants={['p2']} text={`${formatedDate}`} />
                </View>
                <Text css={styles.currencyText} variants={['bold']} text={`${form.values.exchange_for_value ? form.values.exchange_for_value : 1} ${conversion?.target}`} />
                <Text css={styles.currencyText} text={`= ${form?.values.exchange_for_value ? conversion?.rate * form?.values.exchange_for_value : conversion?.rate}${conversion?.base}`} />
              </>

            )}
          </View>
          <Button
            variants={['fullWidth']}
            text='Send Offer'
            onPress={handleCreateOffer}
          />
          <Link to={`/offer/${id}/inventory`}>
            <Button
              variants={['fullWidth']}
              text='Offer inventory item'
              onPress={handleCloseModal}
            />
          </Link>
          <Link to={`/offer/${id}/new-item`}>
            <Button
              variants={['fullWidth']}
              text='Offer new item'
              onPress={handleCloseModal}
            />
          </Link>
        </View>
        <View variants={['column', 'gap:2']}>
          <Text
            css={styles.text}
            text='Please maintain local social distancing protocols when meeting.'
          />
          <Text
            css={styles.text}
            text='Do not respond to offered items if you are ill, under quarantine or have Covid symptoms.'
          />
        </View>
      </View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
    },
    buttonsWrapper: {
      gap: Theme.spacing.value(1.5),
    },
    text: {
      color: Theme.colors.light.silver,
    },
    currencyText: {
      color: '#B3B3B3',
    },
    modalBody: {
      overflowY: 'scroll',
    },
  },
  true,
)
