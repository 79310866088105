import React from 'react';
import { variantProvider, Icon, View, Text, logger } from '@/app';

type RatingStarsProps = {
  sent?: boolean;
  offer?: any;
  profile?: any;
};

export const RatingStars: React.FC<RatingStarsProps> = (props) => {
  const { offer, sent, profile } = props;

  const offerText = sent
    ? offer?.target_listing?.profile?.ratings_count
    : offer?.created_by?.ratings_count;

  const text = profile ? profile?.ratings_count : offerText;

  logger.log({offerText, text})

  return (
    <View variants={['row', 'alignCenter', 'gap:0.5']}>
      {Array(5)
        .fill(0)
        .map((item, index) => {
          const offersLogic = sent
            ? offer?.target_listing?.profile?.ratings_count >= index + 1
            : offer?.created_by?.ratings_count >= index + 1;

          const isFilled = offer
            ? offersLogic
            : profile?.ratings_count >= index + 1;
          return (
            <Icon
              key={index}
              name={'star'}
              style={isFilled ? styles.starIcon : styles.starIconNotFilled}
            />
          );
        })}
      <Text variants={['p2']} text={`(${text})`} />
    </View>
  );
};

const STARS_SIZE = 13;

const styles = variantProvider.createComponentStyle(
  {
    starIcon: {
      width: STARS_SIZE,
      height: STARS_SIZE,
      color: '#FFB800',
      fill: '#FFB800',
      stroke: '#FFB800',
      svg: {
        path: {
          stroke: '#FFB800',
        },
      },
    },
    starIconNotFilled: {
      width: STARS_SIZE,
      height: STARS_SIZE,
      stroke: '#FFB800',
    },
  },
  true
);
