import { TooltipComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

const createTooltipStyle = variantProvider.createVariantFactory<TooltipComposition>()
const defaultStyles = variantProvider.getDefaultVariants('Tooltip')

export const AppTooltipStyles = {
  ...defaultStyles,
  default: createTooltipStyle((theme) => ({
    ...defaultStyles.default(theme),
    bubble: {
      ...defaultStyles.default(theme).bubble,
      background: theme.colors.gray,

      borderRadius: theme.borderRadius.small,
    },
    arrow: {
      ...defaultStyles.default(theme).arrow,
      background: theme.colors.gray,
      
    },
  })),
  highlight: createTooltipStyle((theme) => ({
    bubble: {
      border: theme.border.primary(1),
    
    },
    arrow: {
      border: theme.border.primary(1),
  
    },
  })),
}
