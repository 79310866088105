import React, { useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { View, Theme, variantProvider } from '@/app';
import {
  TextBlock,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders';

export const ProfileSkeleton = (props) => {
  const { ready, children } = props;

  const renderSkeleton = (
    <View variants={['alignCenter', 'gap:2']} css={styles.wrapper}>
      <RectShape
        color={Theme.colors.light.skeleton}
        style={{
          width: '200px',
          height: '200px',
          borderRadius: '50%',
        }}
      />
      <TextBlock
        rows={4}
        color={Theme.colors.light.skeleton}
        style={{
          width: '260px',
          height: '200px',
          display: 'flex',
          gap: Theme.spacing.value(1),
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      />
    </View>
  );

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  );
};

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      width: 500,
    },
    details: {
      width: '35%',
    },
    teste: {
      backgroundColor: Theme.colors.light.skeleton,
      borderRadius: Theme.borderRadius.medium,
      height: '20px !important',
    },
  },
  true
);
