import React from 'react'
import {
  Button,
  View,
  logger,
  variantProvider,
  Text,
  Theme,
  Touchable,
} from '@/app'
import { Image, Link } from '@/shared'
import { useComponentStyle } from '@codeleap/common'

export type DropDownProps = {
  isActive?: boolean
  isHeader?: boolean
  addresses?: any[]
  onPress?: (event: Event) => void
  onPressCategory?: (event: Event) => void
  categories?: any[]
}

export const DropdownComponent: React.FC<DropDownProps> = (props) => {
  const {
    isActive,
    isHeader,
    addresses,
    onPress,
    onPressCategory,
    categories,
  } = props

  const styles = useComponentStyle(componentStyles)

  return (
    <View
      variants={['absolute']}
      css={[
        styles.dropdownContainer,
        isActive ? styles.dropdownActive : styles.dropdownDisable,
      ]}
    >
      <View css={styles.itemsContainer} variants={['column', 'fullWidth']}>
        {addresses
          ? addresses?.map((item, index) => (
            <Touchable
              // key={index}
              variants={['padding:2']}
              css={styles.dropdownLink}
              onPress={() => onPress(item)}
            >
              <Text variants={['p2']} text={item?.description} />
            </Touchable>
          ))
          : categories?.map((item, index) => (
            <Touchable
              key={index}
              variants={['padding:2', 'flex', 'gap:1']}
              css={styles.dropdownLink}
              onPress={() => onPressCategory(item)}
            >
              <Image css={styles.icon} source={item.icon} />
              <Text variants={['p2']} text={item?.title} />
            </Touchable>
          ))}
      </View>
    </View>
  )
}

const DROPDOWN_CONTAINER_WIDTH = 300
const ICON_SIZE = 18

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  dropdownContainer: {
    width: DROPDOWN_CONTAINER_WIDTH,
    top: '100%',
    zIndex: 999,

    transition: '300ms',
    // overflow: 'hidden',
  },
  dropdownActive: {
    visibility: 'visible',
    opacity: 1,
  },
  dropdownDisable: {
    visibility: 'hidden',
    opacity: 0,
  },
  dropdownLink: {
    display: 'flex',
    color: theme.colors.black,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.colors.mediumGray,
    },
  },
  dropdownLinkContainer: {
    padding: Theme.spacing.value(2),
    '&:hover': {
      backgroundColor: theme.colors.mediumGray,
    },
  },
  itemsContainer: {
    cursor: 'pointer',
    backgroundColor: theme.colors.white,
    borderRadius: Theme.borderRadius.small,
    boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.24)',
    overflow: 'hidden',
  },
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
}))
