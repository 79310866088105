import { AppTheme, createTheme, VariantProvider } from '@codeleap/common'
import { useWindowSize } from '@codeleap/web'
import { CSSObject } from '@emotion/css'
import { textSizes } from './constants'
import {
  MdCheck,
  MdClose,
  MdSearch,
  MdArrowDropDown,
  MdExpandMore,
  MdRefresh,
  MdArrowForward,
} from 'react-icons/md'
import { FaFacebook } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import {
  Apple,
  AppStore,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Bars,
  Bell,
  CameraPlaceholder,
  ChevronLeft,
  ChevronRight,
  Cloth,
  Close,
  Dollar,
  EditPrimary,
  EditSecondary,
  Facebook,
  Files,
  Filter,
  FooterFacebook,
  FooterInstagram,
  FooterLinkedin,
  FullScreen,
  Google,
  GooglePlay,
  Home,
  HomeArrowRight,
  ImagePlaceholder,
  Info,
  Inventory,
  Line,
  LocationPin,
  MapButton,
  MapPin,
  Message,
  Minus,
  Monitor,
  More,
  NoResults,
  NoChat,
  NoNotifications,
  NoInventory,
  NoMessages,
  ParkArrowLeft,
  ParkArrowRight,
  Plus,
  Search,
  SendMessage,
  Star,
  Swipe,
  Trash,
  User,
} from './icons'
import { logger } from './logger'

const themeObj = {
  values: {
    maxHeaderMenuHeight: 60,
    buttonAndInputHeight: 48,
    height: 20,
    width: 20,
    zIndex: {
      appStatusModal: 90,
      appStatusBackground: 9999999,
      modal: 70,
      dropdown: 60,
      sideNav: 55,
      nav: 50,
      slickArrow: 30,
      selectList: 20,
    },
  },
  presets: {},
  colors: {
    light: {
      primary: '#366E65',
      primaryText: '#222222',
      lightGrayText: '#969696',
      secondaryText: '#878787',
      tertiaryText: '#818181',
      quaternaryText: '#8C8C8C',
      black: '#000',
      gray: '#333',
      mediumGray: '#9C9C9C',
      darkMediumGray: '#808080',
      xMediumGray: '#CDCDCD',
      lightGray: '#EFEFEF',
      xLightGray: '#EAEAEA',
      veryLightGray: '#FAFAFA',
      white: '#fff',
      xWhite: '#F2F2F2',
      mediumGreen: '#1F403B',
      lightGreen: '#C6E0C0',
      xLightGreen: '#B5D8A5',
      green: 'green',
      silver: '#B1B1B1',
      blue: 'blue',
      negative: '#a11',
      positive: '',
      background: '',
      border: '',
      backgroundSecondary: '',
      disabled: '',
      icon: '',
      neutral: '',
      placeholder: '',
      secondary: '',
      text: '',
      textH: '',
      textP: '',
      skeleton: 'rgb(234, 234, 234)',
    },
    dark: {
      primary: '#366E65',
      primaryText: '#222222',
      lightGrayText: '#969696',
      secondaryText: '#878787',
      tertiaryText: '#818181',
      quaternaryText: '#8C8C8C',
      black: '#000',
      gray: '#333',
      mediumGray: '#9C9C9C',
      darkMediumGray: '#808080',
      xMediumGray: '#CDCDCD',
      lightGray: '#EFEFEF',
      xLightGray: '#EAEAEA',
      veryLightGray: '#FAFAFA',
      white: '#fff',
      xWhite: '#F2F2F2',
      mediumGreen: '#1F403B',
      lightGreen: '#C6E0C0',
      xLightGreen: '#B5D8A5',
      green: 'green',
      silver: '#B1B1B1',
      blue: 'blue',
      negative: '#a11',
      positive: '',
      background: '',
      border: '',
      backgroundSecondary: '',
      disabled: '',
      icon: '',
      neutral: '',
      placeholder: '',
      secondary: '',
      text: '',
      textH: '',
      textP: '',
      skeleton: 'rgb(234, 234, 234)',
    },
  },
  initialTheme: 'light',
  breakpoints: {
    zero: 0,
    tinyest: 290,
    tiny: 350,
    smallish: 420,
    small: 600,
    mid: 900,
    largeish: 1050,
    large: 1200,
    xlarge: 1400,
    xxlarge: 1800,
    huge: 2559,
  },
  spacing: 10,
  borderRadius: {
    xLarge: 25,
    large: 15,
    medium: 12,
    xSmall: 4,
    small: 8,
  },
  typography: {
    baseFontSize: 16,
    fontFamily: 'Oxygen',
    styles: textSizes,
  },
  icons: {
    apple: Apple,
    appStore: AppStore,
    arrowDown: ArrowDown,
    arrowLeft: ArrowLeft,
    arrowRight: ArrowRight,
    arrowUp: ArrowUp,
    bars: Bars,
    bell: Bell,
    cameraPlaceholder: CameraPlaceholder,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    cloth: Cloth,
    close: Close,
    dollar: Dollar,
    home: Home,
    homeArrowRight: HomeArrowRight,
    editPrimary: EditPrimary,
    editSecondary: EditSecondary,
    facebook: Facebook,
    files: Files,
    filter: Filter,
    footerFacebook: FooterFacebook,
    footerInstagram: FooterInstagram,
    footerLinkedin: FooterLinkedin,
    fullscreen: FullScreen,
    google: Google,
    googlePlay: GooglePlay,
    imagePlaceholder: ImagePlaceholder,
    info: Info,
    inventory: Inventory,
    line: Line,
    locationPin: LocationPin,
    mapButton: MapButton,
    map: MapPin,
    message: Message,
    minus: Minus,
    monitor: Monitor,
    more: More,
    noResults: NoResults,
    noMessages: NoMessages,
    noInventory: NoInventory,
    noNotifications: NoNotifications,
    noChat: NoChat,
    parkArrowLeft: ParkArrowLeft,
    parkArrowRight: ParkArrowRight,
    plus: Plus,
    search: Search,
    sendMessage: SendMessage,
    star: Star,
    swipe: Swipe,
    trash: Trash,
    checkmark: MdCheck,
    refresh: MdRefresh,
    user: User,
    arrowDropdown: MdArrowDropDown,
    expandMore: MdExpandMore,
    arrowForward: MdArrowForward,
    'input-visiblity:visible': IoMdEye,
    'input-visiblity:hidden': IoMdEyeOff,
  },
} as const

const appTheme = createTheme(themeObj, {
  screenSize: useWindowSize,
})

const styleGetter = (style: CSSObject) => style
type StyleGetter = typeof styleGetter

export const variantProvider = new VariantProvider<
  StyleGetter,
  typeof themeObj
>(appTheme, styleGetter, logger)

export const Theme = variantProvider.theme
