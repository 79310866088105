import { logger, Settings } from '@/app'
import { useQuery, waitFor } from '@codeleap/common'
import { AppStatus, Session } from '@/redux'
import axios from 'axios'

const api = axios.create({
  baseURL: 'https://openexchangerates.org/api',
})

export const useCurrencyConversion = () => useQuery({
  initialState: {
    rates: {},
    timestamp: '',
  },
  routes: {
    query: async ({ currentValue, setState, throwError }, { listingCurrency, offerCurrency }) => {
      try {
        //Configure this apiKey in Settings.ts
        const { data } = await api.get(`/latest.json?app_id=${Settings.ApiKeys.Conversion}&symbols=${offerCurrency},${listingCurrency}`)

        currentValue.rates = {}

        logger.log({ data })

        setState({
          rates: data.rates,
          timestamp: data.timestamp,
        })
      } catch (err) {
        throwError('Error fetching data')
      }
    },
  },
})
