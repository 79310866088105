import {
  Button,
  logger,
  Modal,
  Text,
  TextInput,
  Touchable,
  LocationInput,
  Theme,
  variantProvider,
  View,
} from '@/app'
import { NoItems } from '@/shared'
import { useState, onUpdate } from '@codeleap/common'
import { useAppSelector, newLocationForm, QueryToCategorySelect } from '@/lib'
import { AppStatus } from '@/redux'

type OfferModalProps = any

export const SavedSearchesModal: React.FC<OfferModalProps> = (props) => {
  const { categories, options } = useAppSelector((store) => store.Categories)
  const { id, Profiles, savedFilters, setGenerated, setParams, params } = props
  const { offerModalOpen, savedSearchesModalOpen } = useAppSelector(
    (store) => store.AppStatus,
  )

  const [savedSearch, setSavedSearch] = useState(null)

  const handleDeleteSearch = (filter) => {
    Profiles.deleteSavedFilter.send(filter.id)
  }

  const handleSelectFilter = (filter) => {
    handleUpdateParams(filter)

    setSavedSearch(null)
    AppStatus.toggleSavedSearchesModal()
  }

  const handleUpdateParams = (filter) => {

    const categoriesToObj = Object.fromEntries(new URLSearchParams(filter.data))
    const updatedCategories = { ...categoriesToObj, lat: filter.location.coords.lat, lng: filter.location.coords.lng, description: filter.location.address }
    const filterQueryCategory = QueryToCategorySelect(
      filter.data,
      options,
      categories,
    )

    setGenerated(() => {
      return null
    })

    setTimeout(() => {
      setGenerated(() => {
        return filterQueryCategory
      })
    }, 300)

    setParams((prev) => {
      return {
        zoom: prev.zoom,
        ...updatedCategories,
      }
    })
  }

  const renderOptions = (queryObj, num) => {
    const formatedQueryObj = Object.fromEntries(new URLSearchParams(queryObj))
    const itemsArr = Object.keys(formatedQueryObj)

    const itemsArrWithoutCategory = itemsArr.filter(
      (item) => item !== 'category',
    )

    return (
      <View variants={['column', 'gap:2']}>
        {itemsArrWithoutCategory.slice(0, num).map((key, index) => {
          const item = formatedQueryObj[key]

          let formatedKey = key.replace(/_/g, ' ')

          formatedKey = formatedKey
            .toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')

          return (
            <View key={index} css={styles.optionsWrapper}>
              <Text text={`${formatedKey}${':'}`} />
              <Text variants={['thin']} text={item} />
            </View>
          )
        })}
      </View>
    )
  }

  const handleToggleModal = () => {
    AppStatus.toggleSavedSearchesModal()
    setSavedSearch(null)
  }

  const renderModalContent = () => {

    if (savedFilters.length <= 0) {
      return (
        <NoItems
          icon={'noInventory'}
          title={"You've got no items here"}
          subtitle={
            'Click the "Add" button at the top of the page to add an item or a bundle.'
          }
          wrapperStyle={styles.noItemsWrapper}
        />
      )
    }

    if (!savedSearch) {
      const returnedSavedFilters = savedFilters.map((filter, index) => {
        const formatedQueryObj = Object.fromEntries(
          new URLSearchParams(filter?.data),
        )

        const category = formatedQueryObj.category

        return (
          <View css={[styles.filterWrapper, savedFilters.length === index + 1 && styles.lastFilterWrapper]}>
            <Touchable
              key={index}
              onPress={() => {
                setSavedSearch(filter)
              // handleSelectFilter(filter);
              }}
              css={styles.wrapper}
              variants={['fullWidth']}
            >

              <View variants={['column', 'gap:2']}>
                <Text variants={['bold']} text={filter?.name} />
                <View variants={['gap:2']}>
                  <Text variants={['p2']} text={filter?.location?.address} />
                  <Text variants={['p2']} text={category} />
                </View>
                {renderOptions(filter?.data, 1)}
              </View>
            </Touchable>
            <Button
              variants={['icon', 'xSmallIcon', 'paddingVertical:2']}
              icon='trash'
              onPress={() => { handleDeleteSearch(filter) }}
              css={styles.deleteButton}
            />
          </View>
        )
      })

      return returnedSavedFilters
    } else {
      const formatedQueryObj = Object.fromEntries(
        new URLSearchParams(savedSearch?.data),
      )

      const category = formatedQueryObj.category

      return (
        <View variants={['column', 'gap:2']}>
          <Text variants={['h3', 'bold']} text={savedSearch?.name} />
          <View variants={['gap:2']}>
            <Text variants={['p2']} text={savedSearch?.location?.address} />
            <Text variants={['p2']} text={category} />
          </View>
          {renderOptions(savedSearch?.data, 9999999)}
          <Button
            text={'Search'}
            onPress={() => handleSelectFilter(savedSearch)}
          />
        </View>
      )
    }
  }

  return (
    <Modal
      visible={savedSearchesModalOpen}
      toggle={handleToggleModal}
      title={'Saved Searches'}
      
      styles={{
        box: styles.box,
        body: styles.body,
      }}
    >
      <View variants={['gap:1', 'column']} css={styles.modalWrapper}>{renderModalContent()}</View>
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    filterWrapper: {
      borderBottom: 'solid 1px #E4E4E4',
    },
    lastFilterWrapper: {
      borderBottom: 'none',
    },
    wrapper: {
      columnGap: Theme.spacing.value(2),
      padding: Theme.spacing.value(1.6),
      // height: 150,
      // borderRadius: Theme.borderRadius.medium,
      cursor: 'pointer',
      '&:hover': {},
    },
    buttonLink: {
      width: '100%',
    },
    text: {
      color: Theme.colors.light.silver,
    },
    filterName: {
      gap: Theme.spacing.value(0.2),
    },
    optionsWrapper: {
      gap: Theme.spacing.value(0.5),
    },
    box: {
      width: 500,
    },
    body: {
      overflowY: 'scroll',
    },
    noItemsWrapper: {
      maxWidth: '100%',
    },
    modalWrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
    },
    deleteButton: {
      height: 0,
      alignItems: 'unset',
    },
  },
  true,
)
