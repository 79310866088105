/* eslint-disable max-len */
import React, { useRef, useState } from 'react'
import { logger, Select, TextInput, variantProvider, View, Theme, Button, Text, Icon, Touchable, Modal } from '@/app'
import { TimePicker } from './TimePicker'
import 'react-dates/initialize'
import { formatNums } from '@/lib/utils/helpers'
import { useClickOutside } from '@codeleap/web'
import { v4 } from 'uuid'
import { intlFormat, previousDay } from 'date-fns'
import { AppStatus } from '@/redux'
import { Calendar, utils } from 'norama-react-modern-calendar-datepicker'
import { onUpdate } from '@codeleap/common'

type DateRangeInputProps = {
  label?: string
  textInputStyles?: any
  dateValue?: any
  setDateValue?: React.Dispatch<React.SetStateAction<any>>
  onChangeDate?: any
  onChangeTime?: any
  fromToday?: boolean
  onlyFuture?: boolean
  showTime?: boolean
}

export const DateRangeInput: React.FC<DateRangeInputProps> = (props) => {
  const { label, textInputStyles, dateValue, setDateValue, fromToday, onlyFuture, showTime, onChangeDate, onChangeTime, ...rest } = props

  const range = {
    date: {
      from: dateValue?.date?.from,
      to: dateValue?.date?.to,
    },
    time: {
      from: dateValue?.time?.from,
      to: dateValue?.time?.to,
    },
  }

  const fromHour = range?.time?.from?.hour
  const fromMinute = range?.time?.from?.minute
  const fromZone = range?.time?.from?.zone

  const toHour = range?.time?.to?.hour
  const toMinute = range?.time?.to?.minute
  const toZone = range?.time?.to?.zone

  logger.log({ fromHour, range })

  const [isFocused, setIsFocused] = useState(false)
  const [timer, setTimer] = useState({
    isFocused: false,
    range: '',
    zone: 'AM',
    hour: 0,
    min: 0,
  })

  const ref = useClickOutside(() => setIsFocused(false))
  const timerRef = useClickOutside(() => setTimer({ ...timer, isFocused: false }))

  const inputId = useRef(v4()).current

  const isSmall = Theme.hooks.down('mid')

  const isRangeFrom = timer.range === 'from' && timer.isFocused === true
  const isRangeTo = timer.range === 'to' && timer.isFocused === true

  const fromDate = new Date(`${range?.date?.from?.month}-${range?.date?.from?.day}-${range?.date?.from?.year}`)

  const toDate = new Date(`${range?.date?.to?.month}-${range?.date?.to?.day}-${range?.date?.to?.year}`)

  const timerFocused = timer.isFocused === true

  onUpdate(() => {
    if (timer.range === 'from') {
      setDateValue({ ...dateValue, time: {
        ...dateValue.time,
        from: {
          hour: timer.hour,
          minute: timer.min,
          zone: timer.zone,
        },
      }})
    } else {
      setDateValue({ ...dateValue, time: {
        ...dateValue.time,
        to: {
          hour: timer.hour,
          minute: timer.min,
          zone: timer.zone,
        },
      }})
    }
  }, [timer.hour, timer.min, timer.zone])

  // onUpdate(() => {
  //   // setTimer({...timer, f})
  //   setTimer({ ...timer, hour: 0, min: 0 })
  //   logger.log({ dateValueFocused: dateValue })
  // }, [timer.isFocused])

  const formatedFrom = range.date.from && intlFormat(fromDate, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }, {
    locale: 'en-US',
  })
  const formatedTo = range.date.to && intlFormat(toDate, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }, {
    locale: 'en-US',
  })

  const handleChange = (item) => {
    setDateValue({ ...dateValue, date: item })
    // onChangeDate(false, true)
  }

  const renderCalendar = () => {
    if (isSmall) {
      return (
        <Touchable variants={['fullWidth', 'justifySpaceBetween']} onPress={() => AppStatus.toggleDateModal()}>
          <View css={styles.dateInfoWrapper} variants={['column']}>
            <Text css={styles.priceTitle} text={'start date'} />
            <Text text={range?.date?.from ? `${range?.date?.from?.month}/${range?.date?.from?.day}/${range?.date?.from?.year}` : 'Start Date'} />
          </View>
          <View css={styles.dateInfoWrapper} variants={['column']}>
            <Text css={styles.priceTitle} text={'final date'} />
            <Text text={range?.date?.to ? `${range?.date?.to?.month}/${range?.date?.to?.day}/${range?.date?.to?.year}` : 'End Date'} />
          </View>
        </Touchable>
      )
    } else {
      return (
        <View css={[styles.calendarContainer]} variants={['column', 'relative']}>
          <TextInput value={`${formatedFrom ?? 'Start Date'} - ${formatedTo ?? 'End Date'}`} label={label} defaultValue={dateValue} styles={textInputStyles} onFocus={() => setIsFocused(true)} />
          <View css={[styles.calendarWrapper, isFocused ? styles.calendarActive : styles.calendarDisabled]} variants={['absolute']} >
            <Calendar
              value={range.date}
              onChange={handleChange}
              {...(fromToday && { minimumDate: utils().getToday() })}
              shouldHighlightWeekends
              calendarClassName='modern-calendar'
              numberOfMonths={2}
              calendarRangeBetweenClassName={'modern-calendar-range-between'}
              calendarRangeStartClassName={'modern-calendar-range-edge'}
              calendarRangeEndClassName={'modern-calendar-range-edge'}
              renderFooter={() => <View css={styles.footerWrapper} variants={['fullWidth', 'justifySpaceBetween', 'relative']}>
                <View css={styles.dateInfoWrapper} variants={['column']}>
                  <Text css={styles.priceTitle} text={'start date'} />
                  <Text text={range?.date?.from ? `${range?.date?.from?.month}/${range?.date?.from?.day}/${range?.date?.from?.year}` : 'Start Date'} />
                </View>
                <Touchable css={styles.dateInfoWrapper} variants={['column']} onPress={() => {
                  setTimer({ ...timer, range: 'from', isFocused: true })
                }}>
                  <Text css={styles.priceTitle} text={'start time'} />
                  <Text text={`${formatNums(fromHour) ?? formatNums(0)}:${formatNums(fromMinute) ?? formatNums(0)} ${fromZone ?? 'AM'}`} />
                </Touchable>
                <View css={styles.dateInfoWrapper} variants={['column']}>
                  <Text css={styles.priceTitle} text={'final date'} />
                  <Text text={range?.date?.to ? `${range?.date?.to?.month}/${range?.date?.to?.day}/${range?.date?.to?.year}` : 'End Date'} />
                </View>
                <Touchable css={styles.dateInfoWrapper} variants={['column']} onPress={() => {
                  setTimer({ ...timer, range: 'to', isFocused: true })
                }}>
                  <Text css={styles.priceTitle} text={'final time'} />
                  <Text text={`${formatNums(toHour) ?? formatNums(0)}:${formatNums(toMinute) ?? formatNums(0)} ${toZone ?? 'AM'}`} />
                </Touchable>
                {showTime && (
                  <View ref={timerRef} css={[styles.timePickerWrapper, timerFocused ? styles.timePickerOpen : styles.timePickerClosed, isRangeFrom && styles.timePickerFrom, isRangeTo && styles.timePickerTo]} variants={['absolute']}>
                    <TimePicker state={timer} setState={setTimer} />
                  </View>
                )}
              </View>}
            />
          </View>
        </View>
      )
    }
  }

  return (
    <>
      <View variants={['column', 'relative']} ref={ref} id={inputId}>
        {renderCalendar()}
      </View>
    </>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    footerWrapper: {
      padding: `${Theme.spacing.value(3)}px  !important`,
    },
    calendarContainer: {
      height: '100%',
      width: '100%',
      transition: '300ms',
    },
    calendarWrapper: {
      width: '100%',
      transition: '300ms',
      top: '100%',
    },
    calendarActive: {
      opacity: 1,
      visibility: 'visible',
      top: '100%',
    },
    calendarDisabled: {
      opacity: 0,
      visibility: 'hidden',
    },
    label: {
      fontSize: 12,
      marginBottom: 8,
      paddingLeft: 8,
    },
    priceTitle: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.4)',
      // left: '15%',
      paddingTop: Theme.spacing.value(0.5),
      // paddingLeft: Theme.spacing.value(1.8),
      // paddingTop: Theme.spacing.value(0.2),
    },
    dateInfoWrapper: {
      border: `1px solid #CCCCCC`,
      borderRadius: Theme.borderRadius.medium,
      width: 140,
      padding: `${Theme.spacing.value(1)}px  !important`,
      // `${...Theme.spacing.paddingHorizontal(1)}px !important`,
      // `...Theme.spacing.paddingVertical(1)`,
    },
    timePickerWrapper: {
      top: '100%',
      right: 0,
    },
    timePickerOpen: {
      opacity: 1,
      visibility: 'visible',
      transition: '300ms',
    },
    timePickerClosed: {
      opacity: 0,
      visibility: 'hidden',
      // height: 0,
      transition: '300ms',
    },
    timePickerTo: {

    },
    timePickerFrom: {
      left: 180,
    },
  },
  true,
)
