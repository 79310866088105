import 'react-dates/lib/css/_datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-placeholder/lib/reactPlaceholder.css'
import 'react-medium-image-zoom/dist/styles.css'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-slider/assets/index.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file


import { StyleProvider } from '@codeleap/common'
import { useWindowSize } from '@codeleap/web'
import { ToastContainer } from 'react-toastify'
import {
  variants,
  variantProvider,
  globalStyle,
  Settings,
  Theme,
  logger,
} from '@/app'
import { Global } from '@emotion/react'
import { onMount, onUpdate, useCodeleapContext } from '@codeleap/common'
import { Session, Categories, Location, Locations, store, Notifications } from '@/redux'
import { withFirebase } from '@/services'

<script src='https://unpkg.com/react-medium-image-zoom'></script>

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import Overlays from '@/shared/overlays'
import { Provider } from 'react-redux'

export const RootComponent = ({ children }) => {

  const windowSize = useWindowSize()

  variantProvider.theme.values.height = windowSize[1]
  variantProvider.theme.values.width = windowSize[0]

  onMount(() => {
    Categories.getData()
    Categories.getOptions()
    Location.getData()
    // Locations.getData()
    Session.setMounted()

    const unsubscribe = withFirebase((firebase) => firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        Locations.getData()
        Notifications.getData()
        await Session.autoLogin()
      }
    }),
    )
    return unsubscribe
  })

  return (
    <Provider store={store}>
      <StyleProvider
        variantProvider={variantProvider}
        // logger={logger}
        variants={variants}
      >
        <ToastContainer
          toastStyle={{
            background: Theme.colors.light.primary,
            color: Theme.colors.light.white,
          }}
        />
        <Global styles={globalStyle} />
        {children}
        <Overlays />
      </StyleProvider>
    </Provider>
  )
}
