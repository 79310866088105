import React, { useState } from 'react'
import {
  Button,
  View,
  Text,
  logger,
  Theme,
  Modal,
  TextInput,
  Select,
  variantProvider,
} from '@/app'
import { useForm, onUpdate, onMount } from '@codeleap/common'
import { useAppSelector, newLocationForm, formatedDistanceDate, offerCurrencyForm } from '@/lib'
import { useListings, useOffers, useProfiles, useCurrencyConversion } from '@/services/api'
import { navigate } from 'gatsby'
import { AppStatus } from '@/redux'
import { Link } from '@/shared'
import { defaultStyles } from '@/app/stylesheets/ReactSelect'
import getSymbolFromCurrency from 'currency-symbol-map'
type OfferModalProps = any

export const OfferCurrencyModal: React.FC<OfferModalProps> = (props) => {
  const { id } = props

  const { offerCurrencyModalOpen } = useAppSelector((store) => store.AppStatus)
  const { profile: myProfile } = useAppSelector((store) => store.Session)
  const { options } = useAppSelector((store) => store.Categories)

  const currenciesOptions = options?.find(opt => opt.title === 'Currency Name')?.selects

  const currencyOptions = currenciesOptions?.map((currency) => { return { value: currency.id, label: `${currency.title}` } })

  logger.log({ currencyOptions })

  const {
    queries: Offers,
    loading,
  } = useOffers()

  const {
    data: { listing },
    queries: Listings,
  } = useListings()

  const {
    data: { rates, timestamp },
    queries: Conversion,
  } = useCurrencyConversion()

  const [conversionRate, setConversionRate] = useState(0)

  const form = useForm(offerCurrencyForm, {
    output: 'json',
    validateOn: 'blur',
  })

  const handleCloseModal = () => {
    AppStatus.toggleOfferCurrencyModal()
  }

  onMount(() => {
    Listings.getDetail.send(id).then((item) => {
      Listings.getData.send(item)
    })
  })

  onUpdate(() => {
    if (myProfile?.data?.currency) {
      Conversion.query.send({
        profileCurrency,
        offerCurrency,
      })
    }
  }, [myProfile, form?.values?.exchange_for_currency])

  onUpdate(() => {
    if (rates) {
      const profileRate = rates[Object.keys(rates)[0]]
      const offerRate = rates[Object.keys(rates)[1]]

      setConversionRate(profileRate / offerRate)
    }
  }, [rates])

  const profileCurrency = myProfile?.data?.currency
  const offerCurrency = form?.values?.exchange_for_currency

  // const conversionRate = Object.values(conversion?.rates)[0] / Object.values(conversion?.rates)[1]

  const handleCreateOffer = () => {

    const isValid = form.validateAll(true)
    if (isValid) {
      Offers.post
        .send({
          ...form.values,
          target_listing: id,
          created_by: myProfile.id,
        })
        .then((res) => {})
    }
    navigate(`/account/offers`)
  }

  const currencySymbol = getSymbolFromCurrency(form.values.exchange_for_currency)

  const timestampDate = new Date(timestamp * 1000)

  const formatedDate = formatedDistanceDate(timestampDate)

  const handleChangeText = (val) => {
    const onlyValue = val.replace(/\D/g, '')
    form.setFieldValue('exchange_for_value', onlyValue)
  }

  return (
    <Modal
      visible={offerCurrencyModalOpen}
      toggle={AppStatus.toggleOfferCurrencyModal}
      title={'Offer Currency'}
      styles={{
        box: styles.box,
      }}
    >
      <View variants={['gap:2', 'marginBottom:4']} css={styles.wrapper}>
        <View css={styles.currencyWrapper} variants={['column', 'gap:4']}>
          <View css={styles.inputsWrapper} variants={['gap:2']}>

            <Select
              label={'Currency'}
              placeholder='Select a Currency'
              {...form.register('exchange_for_currency')}
              css={styles.select}
              customStyles={defaultStyles}
              options={currencyOptions}
            />

            <TextInput
              placeholder={currencySymbol + '80.00'}
              variants={['withHover', 'relative']}
              {...form.register('exchange_for_value')}
              value={currencySymbol + ' ' + form.values.exchange_for_value}
              onChangeText={(val) => handleChangeText(val)}
              // defaultValue={currencySymbol}
            />

          </View>
          <View variants={['gap:1', 'alignCenter']}>
            <View variants={['padding:1']} css={styles.currencyTag}>
              <Text css={styles.currencyText} variants={['p2']} text={`${formatedDate}`} />
            </View>
            <Text css={styles.currencyText} variants={['bold']} text={`1 ${profileCurrency}`} />
            <Text css={styles.currencyText} text={`= ${conversionRate}${Object.keys(rates)[1]}`} />
          </View>
          <Button
            css={styles.sendButton}
            text='Send offer'
            onPress={handleCreateOffer}
          />
        </View>
      </View>
    </Modal>
  )
}

const OFFER_CURRENCY_MODAL_WIDTH = 750

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
    },
    buttonsWrapper: {
      gap: Theme.spacing.value(1.5),
    },
    text: {
      color: Theme.colors.light.silver,
    },
    currencyWrapper: {
      width: '100%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    inputsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      [Theme.media.down('mid')]: {
        gridTemplateColumns: '1fr',
      },
    },
    select: {
      height: Theme.values.buttonAndInputHeight,
    },
    sendButton: {
      width: '30%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    currencyText: {
      color: '#B3B3B3',
    },
    currencyTag: {
      backgroundColor: Theme.colors.light.veryLightGray,
      borderRadius: Theme.borderRadius.small,
    },
    box: {
      width: OFFER_CURRENCY_MODAL_WIDTH,
    },
  },
  true,
)
