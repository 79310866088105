import { Theme } from "../theme";

export const GooglePlay = (props) => {
  return (
    <svg
      width='140'
      height='140'
      viewBox='0 0 89 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M84.0233 31.0325H5.09242C3.94196 31.0311 2.83906 30.5619 2.02604 29.7278C1.21302 28.8937 0.756347 27.7631 0.756348 26.5842V5.41412C0.756347 4.23526 1.21302 3.10462 2.02604 2.27055C2.83906 1.43649 3.94196 0.967212 5.09242 0.96582H84.0233C85.1742 0.96721 86.2777 1.43632 87.0915 2.27023C87.9053 3.10415 88.3631 4.23478 88.3645 5.41412V26.5842C88.3631 27.7636 87.9053 28.8942 87.0915 29.7281C86.2777 30.562 85.1742 31.0311 84.0233 31.0325ZM5.09242 2.01619C4.21382 2.01758 3.37167 2.37619 2.75088 3.01327C2.13009 3.65036 1.78142 4.51384 1.78142 5.41412V26.5842C1.78142 27.4845 2.13009 28.348 2.75088 28.9851C3.37167 29.6222 4.21382 29.9808 5.09242 29.9822H84.0233C84.4588 29.9822 84.89 29.8943 85.2923 29.7235C85.6947 29.5527 86.0602 29.3024 86.3681 28.9869C86.6761 28.6714 86.9203 28.2968 87.087 27.8846C87.2536 27.4723 87.3394 27.0304 87.3394 26.5842V5.41412C87.3394 4.96789 87.2536 4.52604 87.087 4.11379C86.9203 3.70153 86.6761 3.32694 86.3681 3.01142C86.0602 2.69589 85.6947 2.4456 85.2923 2.27484C84.89 2.10408 84.4588 2.01619 84.0233 2.01619H5.09242Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M27.0545 9.7783H26.3086C26.2636 9.47947 26.1907 9.18553 26.0908 8.90033H25.0082C24.9068 9.18508 24.8338 9.47917 24.7903 9.7783H24.084C24.084 9.71889 24.2688 9.21719 24.6319 8.27981C24.8503 7.77605 25.0334 7.2577 25.1798 6.72852H26.0644C26.1968 7.24898 26.3622 7.7605 26.5595 8.26001C26.7555 8.75546 26.9209 9.26251 27.0545 9.7783ZM25.972 8.45805C25.7079 7.64609 25.5759 7.21701 25.5759 7.1378H25.5363C25.5363 7.19941 25.3976 7.63949 25.1204 8.45805H25.972Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M30.0781 9.77881H29.4972C29.3131 9.41793 29.0918 9.07722 28.8371 8.76222C28.5203 8.36081 28.2624 7.91624 28.0713 7.44197H28.0317C28.069 7.74866 28.0888 8.05721 28.0911 8.36614C28.0911 8.75562 28.0911 9.22431 28.1241 9.77881H27.5498C27.5498 9.29692 27.5828 8.76222 27.5828 8.18131C27.5828 7.6004 27.5828 7.13171 27.5498 6.72903H28.2429C28.3931 7.08276 28.5883 7.41559 28.8238 7.71922C29.1097 8.10448 29.3469 8.52353 29.5302 8.96686H29.5632C29.5302 8.61699 29.5104 8.30673 29.5104 8.02948C29.5104 7.607 29.5104 7.17132 29.4774 6.70923H30.0649C30.0649 7.0987 30.0319 7.58059 30.0319 8.1615C30.0319 8.74242 30.0517 9.30352 30.0781 9.77881Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M33.471 8.14893C33.4763 8.57589 33.3234 8.98972 33.0419 9.31075C32.8929 9.47802 32.7081 9.60944 32.5012 9.69517C32.2943 9.78091 32.0707 9.81874 31.8471 9.80584C31.616 9.80584 31.2992 9.80584 30.8965 9.80584C30.8965 9.33715 30.8965 8.80245 30.8965 8.20834C30.8965 7.61423 30.8965 7.15874 30.8965 6.75606H31.8603C32.0832 6.73447 32.3083 6.76102 32.5201 6.83391C32.7319 6.90679 32.9256 7.02432 33.0881 7.17854C33.3337 7.44203 33.4705 7.78872 33.471 8.14893ZM32.8108 8.24795C32.8198 7.97294 32.7256 7.70451 32.5468 7.4954C32.4463 7.38599 32.322 7.30112 32.1835 7.24733C32.045 7.19354 31.8961 7.17227 31.7481 7.18515H31.4708C31.4708 7.4954 31.4708 7.84527 31.4708 8.16213C31.4708 8.47899 31.4708 8.90807 31.4708 9.27774C31.5718 9.28755 31.6735 9.28755 31.7744 9.27774C31.9144 9.28844 32.055 9.26591 32.1846 9.212C32.3142 9.15808 32.4293 9.0743 32.5204 8.96748C32.7017 8.76933 32.811 8.51585 32.8306 8.24795H32.8108Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M36.5993 9.77855H35.8864C35.7853 9.42341 35.6458 9.08032 35.4705 8.75535C35.4142 8.6869 35.3422 8.63312 35.2605 8.59863C35.1789 8.56414 35.0901 8.54996 35.0018 8.55732H34.7378C34.7378 8.95999 34.7378 9.36267 34.7378 9.77195H34.0776C34.0776 9.30326 34.1106 8.76856 34.1106 8.17444C34.1106 7.58033 34.1106 7.12484 34.0776 6.72217H35.2262C35.9458 6.72217 36.3023 6.96641 36.3023 7.45491C36.3054 7.6394 36.2467 7.81964 36.1354 7.96683C36.0241 8.11401 35.8667 8.21965 35.6883 8.26686V8.30647C35.7789 8.33706 35.8624 8.38555 35.9338 8.44906C36.0053 8.51256 36.0632 8.58979 36.1042 8.67614C36.2968 9.03061 36.4623 9.39913 36.5993 9.77855ZM35.7213 7.62654C35.7213 7.31628 35.5233 7.15785 35.1206 7.15785C34.9901 7.15757 34.8598 7.16862 34.7312 7.19086C34.7312 7.4351 34.7312 7.74536 34.7312 8.13484H34.949C35.4705 8.14144 35.7213 7.9698 35.7213 7.62654Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M39.9538 8.20743C39.969 8.64713 39.8127 9.07554 39.5181 9.40226C39.3808 9.55447 39.2127 9.67562 39.0248 9.75758C38.837 9.83955 38.6338 9.88045 38.4289 9.87755C38.2352 9.88216 38.0427 9.84573 37.864 9.77064C37.6854 9.69555 37.5247 9.5835 37.3925 9.44186C37.2541 9.29446 37.1461 9.12122 37.0748 8.93206C37.0034 8.7429 36.9701 8.54152 36.9766 8.33945C36.9614 7.89975 37.1176 7.47134 37.4123 7.14463C37.5495 6.99241 37.7177 6.87127 37.9055 6.7893C38.0934 6.70733 38.2966 6.66643 38.5015 6.66934C38.6952 6.66472 38.8877 6.70115 39.0663 6.77624C39.245 6.85133 39.4057 6.96338 39.5379 7.10502C39.6763 7.25242 39.7842 7.42566 39.8556 7.61482C39.9269 7.80399 39.9603 8.00536 39.9538 8.20743ZM39.2936 8.28004C39.3094 7.99531 39.2227 7.71433 39.0494 7.48789C38.9766 7.39212 38.8828 7.31434 38.7752 7.26055C38.6677 7.20676 38.5492 7.17839 38.4289 7.17763C38.3142 7.17883 38.2016 7.20766 38.1005 7.26167C37.9993 7.31568 37.9127 7.39328 37.848 7.48789C37.6834 7.71091 37.5996 7.98328 37.6103 8.26024C37.5934 8.54618 37.6828 8.82829 37.8612 9.05239C37.9322 9.1484 38.0247 9.22646 38.1313 9.28033C38.2379 9.3342 38.3557 9.36239 38.4751 9.36265C38.5897 9.36145 38.7024 9.33262 38.8035 9.27861C38.9046 9.2246 38.9912 9.147 39.056 9.05239C39.2282 8.82754 39.3168 8.54985 39.3068 8.26684L39.2936 8.28004Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M41.2802 9.7783H40.6201C40.6201 9.29641 40.6201 8.7617 40.6201 8.18079C40.6201 7.59988 40.6201 7.13119 40.6201 6.72852H41.2802C41.2802 7.12459 41.2472 7.60648 41.2472 8.18079C41.2472 8.7551 41.2604 9.30301 41.2802 9.7783Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M44.6736 8.14861C44.6807 8.57471 44.5303 8.98843 44.2511 9.31043C44.1016 9.47694 43.9166 9.60785 43.7099 9.69352C43.5031 9.7792 43.2798 9.81747 43.0563 9.80552C42.8252 9.80552 42.5018 9.80552 42.0991 9.80552C42.0991 9.33683 42.0991 8.80213 42.0991 8.20802C42.0991 7.61391 42.0991 7.15842 42.0991 6.75575H43.0695C43.2914 6.73469 43.5153 6.76151 43.726 6.83439C43.9367 6.90727 44.1293 7.02453 44.2907 7.17823C44.5364 7.44171 44.6731 7.7884 44.6736 8.14861ZM44.0135 8.24763C44.0218 7.97386 43.9303 7.70639 43.756 7.49508C43.6542 7.38555 43.5287 7.30067 43.3892 7.24691C43.2496 7.19315 43.0997 7.17191 42.9507 7.18483H42.6734C42.6734 7.49509 42.6734 7.84495 42.6734 8.16181C42.6734 8.47867 42.6734 8.90775 42.6734 9.27742C42.7766 9.28721 42.8805 9.28721 42.9837 9.27742C43.1236 9.28707 43.2638 9.26406 43.3932 9.21022C43.5227 9.15637 43.6379 9.07317 43.7296 8.96717C43.9065 8.76676 44.0131 8.51413 44.0333 8.24763H44.0135Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M49.1752 9.7783H48.4293C48.3843 9.47947 48.3113 9.18553 48.2114 8.90033H47.109C47.0035 9.18427 46.9283 9.47856 46.8846 9.7783H46.1782C46.1782 9.71889 46.3631 9.21719 46.7261 8.27981C46.9445 7.77605 47.1276 7.2577 47.274 6.72852H48.1586C48.2911 7.24898 48.4564 7.7605 48.6537 8.26001C48.8587 8.75485 49.0329 9.26192 49.1752 9.7783ZM48.0926 8.45805C47.8285 7.64609 47.6965 7.21701 47.6965 7.1378H47.6569C47.6569 7.19941 47.5183 7.63949 47.241 8.45805H48.0926Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M51.9084 7.54728C51.9196 7.7095 51.887 7.87176 51.8138 8.01698C51.7406 8.16219 51.6296 8.28499 51.4925 8.37244C51.1873 8.55993 50.8339 8.65401 50.4758 8.64309H50.3174C50.3174 8.93354 50.3174 9.30321 50.3174 9.80491H49.6572C49.6572 9.35602 49.6902 8.82132 49.6902 8.2074C49.6902 7.59349 49.6902 7.16441 49.6572 6.75513H50.8257C51.0905 6.74643 51.3521 6.81526 51.5783 6.95317C51.682 7.01296 51.7675 7.09983 51.8257 7.20448C51.8838 7.30913 51.9124 7.42763 51.9084 7.54728ZM51.3076 7.6793C51.3076 7.34264 51.0832 7.17761 50.6475 7.17761C50.53 7.17582 50.4127 7.18689 50.2976 7.21062C50.2976 7.50767 50.2976 7.83773 50.2976 8.214H50.4362C51.0172 8.2074 51.3076 8.01597 51.3076 7.6595V7.6793Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M54.72 7.54728C54.7313 7.7095 54.6986 7.87177 54.6254 8.01698C54.5522 8.1622 54.4413 8.28499 54.3042 8.37244C53.9965 8.55975 53.641 8.65377 53.281 8.64309H53.1225C53.1225 8.93354 53.1225 9.30321 53.1225 9.80491H52.4624C52.4624 9.35602 52.4624 8.82132 52.4624 8.2074C52.4624 7.59349 52.4624 7.16441 52.4624 6.75513H53.6242C53.889 6.74643 54.1506 6.81526 54.3768 6.95317C54.4806 7.01389 54.5669 7.1006 54.6271 7.20477C54.6873 7.30894 54.7193 7.42698 54.72 7.54728ZM54.1127 7.67931C54.1127 7.34264 53.8883 7.17761 53.4526 7.17761C53.3351 7.17608 53.2178 7.18714 53.1027 7.21062C53.1027 7.50767 53.1027 7.83773 53.1027 8.214H53.2414C53.8223 8.2096 54.1127 8.02477 54.1127 7.6595V7.67931Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M59.3083 8.20743C59.3245 8.6485 59.1655 9.07807 58.866 9.40226C58.7306 9.55499 58.5637 9.67656 58.3768 9.75861C58.1899 9.84066 57.9875 9.88124 57.7834 9.87755C57.5897 9.88216 57.3972 9.84573 57.2185 9.77064C57.0399 9.69555 56.8792 9.5835 56.747 9.44186C56.6086 9.29446 56.5006 9.12122 56.4293 8.93206C56.3579 8.7429 56.3246 8.54152 56.3311 8.33945C56.3159 7.89975 56.4721 7.47134 56.7668 7.14463C56.904 6.99241 57.0722 6.87127 57.26 6.7893C57.4479 6.70733 57.6511 6.66643 57.856 6.66934C58.0497 6.66472 58.2422 6.70115 58.4208 6.77624C58.5994 6.85133 58.7602 6.96338 58.8924 7.10502C59.0308 7.25242 59.1387 7.42566 59.2101 7.61482C59.2814 7.80399 59.3148 8.00536 59.3083 8.20743ZM58.6481 8.28004C58.665 7.9941 58.5757 7.71199 58.3973 7.48789C58.3262 7.39188 58.2337 7.31382 58.1271 7.25995C58.0205 7.20608 57.9028 7.17789 57.7834 7.17763C57.6687 7.17883 57.5561 7.20766 57.4549 7.26167C57.3538 7.31568 57.2672 7.39328 57.2025 7.48789C57.036 7.7103 56.9499 7.98257 56.9582 8.26024C56.9438 8.54695 57.0354 8.82893 57.2157 9.05239C57.2867 9.1484 57.3792 9.22646 57.4858 9.28033C57.5924 9.3342 57.7101 9.36239 57.8296 9.36265C57.9442 9.36145 58.0569 9.33262 58.158 9.27861C58.2591 9.2246 58.3457 9.147 58.4105 9.05239C58.5826 8.82754 58.6713 8.54985 58.6613 8.26684L58.6481 8.28004Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M62.4833 9.77881H61.9024C61.7203 9.41676 61.4989 9.07585 61.2423 8.76222C60.927 8.35863 60.6672 7.91459 60.4699 7.44197C60.5072 7.74866 60.527 8.05721 60.5293 8.36614C60.5293 8.75562 60.5294 9.22431 60.5624 9.77881H59.9814C59.9814 9.29692 60.0211 8.76222 60.0211 8.18131C60.0211 7.6004 60.0211 7.13171 59.9814 6.72903H60.6812C60.8273 7.08337 61.0205 7.41642 61.2555 7.71922C61.544 8.10274 61.7814 8.52213 61.9618 8.96686H62.0014C61.9641 8.65575 61.9443 8.3428 61.942 8.02948C61.942 7.607 61.942 7.17132 61.909 6.70923H62.4965C62.4965 7.0987 62.4635 7.58059 62.4635 8.1615C62.4635 8.74242 62.4635 9.30352 62.4833 9.77881Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M32.8307 18.8182C32.8591 19.3372 32.7819 19.8566 32.6036 20.3449C32.4254 20.8332 32.15 21.2802 31.7939 21.659C31.3931 22.0756 30.9089 22.4031 30.373 22.6202C29.8371 22.8372 29.2614 22.9389 28.6836 22.9186C28.0871 22.939 27.4925 22.8391 26.9354 22.6249C26.3783 22.4107 25.87 22.0865 25.4409 21.6717C25.0117 21.2569 24.6703 20.76 24.4372 20.2105C24.2041 19.6611 24.084 19.0703 24.084 18.4734C24.084 17.8766 24.2041 17.2858 24.4372 16.7363C24.6703 16.1869 25.0117 15.6899 25.4409 15.2751C25.87 14.8604 26.3783 14.5362 26.9354 14.322C27.4925 14.1078 28.0871 14.0079 28.6836 14.0282C29.2843 14.0261 29.879 14.1479 30.4306 14.3859C30.9429 14.5933 31.3993 14.9183 31.7628 15.3346L31.0112 16.0862C30.7212 15.7488 30.3566 15.4836 29.9463 15.3117C29.536 15.1399 29.0912 15.066 28.6473 15.0961C28.2069 15.0939 27.7705 15.1802 27.3641 15.3499C26.9577 15.5196 26.5895 15.7693 26.2815 16.0841C25.9735 16.3989 25.7318 16.7724 25.571 17.1824C25.4101 17.5924 25.3333 18.0306 25.3452 18.4708C25.3298 18.9133 25.404 19.3542 25.5632 19.7673C25.7225 20.1803 25.9635 20.5569 26.2719 20.8745C26.5803 21.1921 26.9497 21.4441 27.3578 21.6154C27.766 21.7868 28.2046 21.8739 28.6473 21.8715C29.0911 21.8866 29.5332 21.8101 29.9461 21.6469C30.359 21.4837 30.7339 21.2372 31.0475 20.9228C31.4826 20.4506 31.7345 19.8383 31.7577 19.1966H28.6473V18.1598H32.7944C32.8246 18.3779 32.8368 18.5981 32.8307 18.8182Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M34.1269 17.6007C34.6989 17.0328 35.4722 16.7141 36.2782 16.7141C37.0842 16.7141 37.8576 17.0328 38.4295 17.6007C38.9739 18.2106 39.2747 18.9994 39.2747 19.8169C39.2747 20.6343 38.9739 21.4231 38.4295 22.033C37.8576 22.6009 37.0842 22.9196 36.2782 22.9196C35.4722 22.9196 34.6989 22.6009 34.1269 22.033C33.5826 21.4231 33.2817 20.6343 33.2817 19.8169C33.2817 18.9994 33.5826 18.2106 34.1269 17.6007ZM34.9408 21.3435C35.1128 21.5255 35.3201 21.6705 35.5501 21.7696C35.78 21.8686 36.0278 21.9197 36.2782 21.9197C36.5286 21.9197 36.7764 21.8686 37.0064 21.7696C37.2363 21.6705 37.4437 21.5255 37.6157 21.3435C37.9806 20.9186 38.1813 20.377 38.1813 19.8169C38.1813 19.2567 37.9806 18.7151 37.6157 18.2902C37.4437 18.1082 37.2363 17.9632 37.0064 17.8642C36.7764 17.7651 36.5286 17.714 36.2782 17.714C36.0278 17.714 35.78 17.7651 35.5501 17.8642C35.3201 17.9632 35.1128 18.1082 34.9408 18.2902C34.5758 18.7151 34.3752 19.2567 34.3752 19.8169C34.3752 20.377 34.5758 20.9186 34.9408 21.3435Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M40.482 17.6007C41.054 17.0328 41.8273 16.7141 42.6333 16.7141C43.4393 16.7141 44.2127 17.0328 44.7846 17.6007C45.329 18.2106 45.6298 18.9994 45.6298 19.8169C45.6298 20.6343 45.329 21.4231 44.7846 22.033C44.2127 22.6009 43.4393 22.9196 42.6333 22.9196C41.8273 22.9196 41.054 22.6009 40.482 22.033C39.9408 21.4216 39.6421 20.6333 39.6421 19.8169C39.6421 19.0004 39.9408 18.2121 40.482 17.6007ZM41.2959 21.3435C41.4679 21.5255 41.6752 21.6705 41.9052 21.7696C42.1351 21.8686 42.3829 21.9197 42.6333 21.9197C42.8837 21.9197 43.1315 21.8686 43.3615 21.7696C43.5914 21.6705 43.7988 21.5255 43.9708 21.3435C44.3357 20.9186 44.5364 20.377 44.5364 19.8169C44.5364 19.2567 44.3357 18.7151 43.9708 18.2902C43.7988 18.1082 43.5914 17.9632 43.3615 17.8642C43.1315 17.7651 42.8837 17.714 42.6333 17.714C42.3829 17.714 42.1351 17.7651 41.9052 17.8642C41.6752 17.9632 41.4679 18.1082 41.2959 18.2902C40.9348 18.717 40.7368 19.2579 40.7368 19.8169C40.7368 20.3758 40.9348 20.9167 41.2959 21.3435Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M48.8649 25.4642C48.2587 25.4917 47.6616 25.3085 47.175 24.9458C46.7671 24.6377 46.4443 24.231 46.2367 23.7639L47.2372 23.3492C47.3504 23.6725 47.5649 23.9507 47.8489 24.1423C48.1487 24.3627 48.5138 24.4759 48.8857 24.4637C49.1454 24.4799 49.4056 24.4422 49.6501 24.353C49.8945 24.2638 50.1179 24.125 50.3061 23.9453C50.6627 23.5421 50.8486 23.016 50.8245 22.4783V21.9184H50.7467C50.5384 22.2183 50.2611 22.4637 49.938 22.6338C49.5758 22.8257 49.1712 22.9237 48.7613 22.9189C48.3916 22.9179 48.0264 22.8378 47.6902 22.684C47.354 22.5302 47.0546 22.3063 46.8121 22.0273C46.284 21.4117 45.9937 20.6274 45.9937 19.8163C45.9937 19.0053 46.284 18.221 46.8121 17.6054C47.0533 17.3248 47.3524 17.0998 47.6889 16.9458C48.0254 16.7919 48.3913 16.7127 48.7613 16.7138C49.1715 16.7064 49.5767 16.8046 49.938 16.9989C50.2611 17.169 50.5384 17.4144 50.7467 17.7143H50.7934V16.9056H51.8301V22.4316C51.8585 22.8509 51.7959 23.2714 51.6464 23.6643C51.497 24.0571 51.2643 24.4129 50.9644 24.7074C50.3847 25.2146 49.635 25.4849 48.8649 25.4642ZM48.9479 21.8977C49.1943 21.9002 49.4385 21.8507 49.6645 21.7525C49.8905 21.6542 50.0932 21.5094 50.2594 21.3274C50.6082 20.8931 50.7983 20.3527 50.7983 19.7956C50.7983 19.2385 50.6082 18.6981 50.2594 18.2638C50.094 18.0808 49.8915 17.9352 49.6653 17.8369C49.4391 17.7386 49.1945 17.6897 48.9479 17.6935C48.6995 17.6943 48.4541 17.7478 48.228 17.8506C48.0019 17.9533 47.8002 18.103 47.6364 18.2897C47.2848 18.7208 47.0928 19.26 47.0928 19.8163C47.0928 20.3726 47.2848 20.9119 47.6364 21.343C47.8021 21.5259 48.0046 21.6717 48.2307 21.7708C48.4567 21.87 48.7011 21.9203 48.9479 21.9184V21.8977Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M53.9703 22.7278H52.8765V14.2209H53.9703V22.7278Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M57.5838 22.9193C57.184 22.9326 56.786 22.861 56.4161 22.7091C56.0461 22.5572 55.7126 22.3284 55.4376 22.038C54.8799 21.4347 54.5836 20.6352 54.6134 19.8141C54.5972 19.0108 54.8819 18.2305 55.4117 17.6265C55.6657 17.3274 55.9843 17.0898 56.3435 16.9318C56.7027 16.7737 57.0931 16.6993 57.4853 16.7141C57.8714 16.6968 58.2568 16.7633 58.6147 16.9092C58.9727 17.0551 59.2948 17.2768 59.5588 17.5591C60.0991 18.1824 60.3777 18.9903 60.3364 19.8141V20.0163H55.7383C55.7422 20.5386 55.9528 21.0381 56.3241 21.4056C56.4912 21.5713 56.6895 21.7024 56.9074 21.7914C57.1254 21.8803 57.3587 21.9254 57.5941 21.924C57.9316 21.9541 58.2697 21.8731 58.5569 21.6935C58.8442 21.5138 59.065 21.2453 59.1856 20.9286L60.1602 21.3278C59.9638 21.789 59.6378 22.1833 59.2219 22.4631C58.7381 22.7852 58.1644 22.945 57.5838 22.9193ZM59.1389 19.1143C59.1004 18.754 58.9372 18.4184 58.6776 18.1656C58.5129 18.0066 58.3165 17.8842 58.1012 17.8064C57.8858 17.7287 57.6565 17.6974 57.4282 17.7146C57.048 17.7098 56.6792 17.8444 56.3915 18.093C56.0816 18.364 55.8671 18.7275 55.7798 19.1298L59.1389 19.1143Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M64.6182 22.728H63.5244V14.2212H66.4274C67.1199 14.211 67.7898 14.4671 68.2988 14.9366C68.5481 15.1685 68.747 15.4493 68.8829 15.7615C69.0189 16.0737 69.0891 16.4105 69.0891 16.751C69.0891 17.0915 69.0189 17.4283 68.8829 17.7405C68.747 18.0527 68.5481 18.3335 68.2988 18.5654C67.7917 19.0387 67.1209 19.297 66.4274 19.2859H64.6182V22.728ZM64.6182 18.2336H66.4481C66.6549 18.2435 66.8613 18.2072 67.0522 18.1274C67.2432 18.0476 67.4141 17.9263 67.5523 17.7722C67.8214 17.5004 67.9723 17.1334 67.9723 16.751C67.9723 16.3685 67.8214 16.0015 67.5523 15.7298C67.4143 15.5751 67.2437 15.453 67.0527 15.3723C66.8617 15.2916 66.6553 15.2544 66.4481 15.2632H64.6182V18.2336Z'
        fill={Theme.colors.light.white}
      />
      <path d='M70.896 22.7278H69.8022V14.2209H70.896V22.7278Z' fill={Theme.colors.light.white} />
      <path
        d='M73.7114 22.9189C73.1469 22.9433 72.5932 22.7587 72.1563 22.4005C71.9498 22.2152 71.7868 21.9866 71.6787 21.7311C71.5707 21.4756 71.5203 21.1994 71.5312 20.9222C71.5421 20.645 71.614 20.3736 71.7418 20.1273C71.8695 19.8811 72.05 19.666 72.2703 19.4975C72.8022 19.131 73.4395 18.949 74.0847 18.9791C74.6333 18.9578 75.1781 19.0793 75.6658 19.3316V19.1657C75.6751 18.9709 75.6403 18.7765 75.564 18.597C75.4877 18.4174 75.3719 18.2575 75.2252 18.1289C74.9276 17.8687 74.542 17.7315 74.1469 17.7453C73.8452 17.7402 73.5477 17.8173 73.2864 17.9682C73.0469 18.1071 72.8636 18.3253 72.768 18.5851L71.7727 18.16C71.9352 17.7614 72.2086 17.4178 72.5606 17.1699C73.0169 16.8565 73.5628 16.7 74.1158 16.7241C74.7946 16.7002 75.4574 16.9335 75.9716 17.3773C76.2211 17.6118 76.4162 17.8981 76.5434 18.2159C76.6705 18.5338 76.7266 18.8757 76.7077 19.2176V22.7374H75.671V21.9184H75.6295C75.427 22.2401 75.1428 22.5022 74.8058 22.678C74.4688 22.8538 74.0911 22.9369 73.7114 22.9189ZM73.8877 21.9184C74.3504 21.9112 74.7925 21.7255 75.1215 21.4C75.2951 21.2528 75.4344 21.0692 75.5293 20.8623C75.6242 20.6554 75.6726 20.4302 75.671 20.2025C75.2653 19.9201 74.7745 19.7865 74.2817 19.8241C73.8531 19.7934 73.4273 19.9145 73.079 20.1662C72.9549 20.2617 72.8541 20.3841 72.784 20.5242C72.714 20.6642 72.6766 20.8183 72.6747 20.9749C72.6721 21.1109 72.7052 21.2451 72.7707 21.3643C72.8362 21.4834 72.9318 21.5833 73.0479 21.654C73.2939 21.8266 73.5872 21.919 73.8877 21.9184Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M79.092 25.2941H77.9671L79.3201 22.3548L76.9199 16.9014H78.107L79.8437 21.0848L81.5336 16.9014H82.7207L79.092 25.2941Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M6.98316 16.9768C6.98316 14.5857 6.98316 12.1946 6.98316 9.80351C6.96473 9.51727 7.05982 9.23529 7.24782 9.01865C7.32996 8.93195 7.38928 8.90914 7.48967 9.01865C9.0959 10.6614 10.7067 12.3011 12.3221 13.9377C13.3685 15.0025 14.415 16.0672 15.4615 17.1319C15.5619 17.2369 15.571 17.2916 15.4615 17.3966C12.894 19.9489 10.331 22.5043 7.77259 25.0627C7.74834 25.0948 7.71697 25.1209 7.68094 25.1388C7.64492 25.1568 7.60522 25.1661 7.56496 25.1661C7.52471 25.1661 7.48501 25.1568 7.44898 25.1388C7.41296 25.1209 7.38158 25.0948 7.35734 25.0627C7.2317 24.9663 7.13152 24.8407 7.06551 24.6967C6.99951 24.5528 6.96967 24.3949 6.9786 24.2368V16.9768H6.98316Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M7.8999 8.74487C8.0606 8.74654 8.21802 8.79062 8.35622 8.87264L18.1898 14.3804C18.3404 14.4625 18.3358 14.5127 18.2217 14.6268C17.4871 15.3478 16.7615 16.0733 16.0314 16.7988C15.9539 16.881 15.9082 16.922 15.8033 16.7988C13.1992 14.137 10.5906 11.4812 7.97748 8.83157C7.96379 8.82701 7.94553 8.79963 7.8999 8.74487Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M8.63477 24.9804C11.0106 22.5771 13.3865 20.1708 15.7624 17.7615C15.913 17.6155 15.9814 17.6337 16.1138 17.7615C16.7161 18.3912 17.3276 19.0072 17.939 19.6232C18.0485 19.7328 18.0577 19.7784 17.9117 19.856L8.75797 24.8754C8.7137 24.9065 8.67245 24.9416 8.63477 24.9804Z'
        fill={Theme.colors.light.white}
      />
      <path
        d='M22.2192 17.0461C22.2164 17.1654 22.1852 17.2824 22.128 17.3871C22.0709 17.4919 21.9895 17.5815 21.8906 17.6484C21.7456 17.751 21.593 17.8425 21.4343 17.9222C20.5217 18.4333 19.5862 18.9398 18.6645 19.4554C18.6423 19.4732 18.6167 19.4862 18.5893 19.4938C18.5619 19.5014 18.5333 19.5033 18.5051 19.4994C18.4769 19.4956 18.4499 19.4861 18.4255 19.4715C18.4011 19.4568 18.38 19.4374 18.3633 19.4144C17.7062 18.736 17.0461 18.0637 16.3829 17.3975C16.2825 17.3016 16.2825 17.2469 16.3829 17.1465C17.1586 16.3844 17.9253 15.6178 18.6964 14.8649C18.7108 14.8444 18.7293 14.8271 18.7507 14.814C18.7721 14.801 18.796 14.7925 18.8208 14.7891C18.8456 14.7856 18.8709 14.7874 18.895 14.7941C18.9192 14.8009 18.9417 14.8126 18.9611 14.8284L21.699 16.3662C21.8384 16.4229 21.9602 16.5158 22.0517 16.6353C22.1431 16.7549 22.201 16.8967 22.2192 17.0461Z'
        fill={Theme.colors.light.white}
      />
    </svg>
  );
}