import { useState } from 'react'
import {
  Button,
  variantProvider,
  View,
  Text,
  CenterWrapper,
  TextInput,
  Theme,
  logger,
} from '@/app'

export type MapLoaderProps = {
  wrapper?: any
}

export const MapLoader: React.FC<MapLoaderProps> = (props) => {

  const { wrapper } = props

  return (
    <View variants={['absolute']} css={[styles.loadingWrapper, wrapper]}>
      <View variants={['relative']} css={styles.loadingInnerWrapper}>
        <View />
        <View />
        <View />
        <View />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    loadingWrapper: {
      // position: 'absolute',
      width: 80,
      height: 40,
      // zIndex: 1,
      // marginLeft: Theme.spacing.value(24),
      backgroundColor: Theme.colors.light.white,
      borderRadius: Theme.borderRadius.medium,
      boxShadow: 'rgb(0 0 0 / 16%) 0px 0px 20px',
      display: 'inline-block',
      left: '73%',
      top: '20%',
      [Theme.media.down('mid')]: {

        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    loadingInnerWrapper: {
      width: '100%',
      height: '100%',
      div: {
        position: 'absolute',
        top: '40%',
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: Theme.colors.light.primaryText,
        animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
      },

      'div:nth-child(1)': {
        left: '10px',
        animation: 'lds-ellipsis1 0.6s infinite',
      },
      'div:nth-child(2)': {
        left: '10px',
        animation: 'lds-ellipsis2 0.6s infinite',
      },
      'div:nth-child(3)': {
        left: '34px',
        animation: 'lds-ellipsis2 0.6s infinite',
      },
      'div:nth-child(4)': {
        left: '58px',
        animation: 'lds-ellipsis3 0.6s infinite',
      },
      '@keyframes lds-ellipsis1': {
        '0%': { transform: 'scale(0)' },
        '100%': { transform: 'scale(1)' },
      },
      '@keyframes lds-ellipsis3': {
        '0%': { transform: 'scale(1)' },
        '100%': { transform: 'scale(0)' },
      },
      '@keyframes lds-ellipsis2': {
        '0%': { transform: 'translate(0, 0)' },
        '100%': { transform: 'translate(24px, 0)' },
      },
    },
  },
  true,
)
