import { offerInventoryForm } from '@/lib';
import { TextComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

const createTextStyle = variantProvider.createVariantFactory<TextComposition>()
const defaultStyles = variantProvider.getDefaultVariants('Text')

export const AppTextStyles = {
  ...defaultStyles,
  default: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      ...defaultStyles.default(theme).text,
      color: theme.colors.black,
    },
  })),
  link: createTextStyle((theme) => ({
    ...defaultStyles.link,
    text: {
      textDecoration: 'none',
    },
  })),
  listItem: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      ...theme.spacing.padding(0.3),
      whiteSpace: 'nowrap',
    },
  })),
  lightGrayText: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      ...defaultStyles.default(theme).text,
      color: theme.colors.lightGrayText,
    }
  })),
  veryBold: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      fontWeight: '700'
    },
  })),
  bold: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      fontWeight: 'bold',
    },
  })),
  regular: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      fontWeight: '400',
    },
  })),
  thin: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      fontWeight: '300',
    },
  })),
  veryThin: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      fontWeight: '100',
    },
  })),
  primaryColor: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      color: theme.colors.primaryText,
    },
  })),
  secondaryColor: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      color: theme.colors.secondaryText,
    }
  })),
  black: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      color: theme.colors.black,
    },
  })),
  white: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      color: theme.colors.white,
    },
  })),
  gray: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      color: theme.colors.gray,
    },
  })),
  clamp: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      '-webkitLineClamp': '1',
      '-webkitBoxOrient': 'vertical',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
    },
  })),
}
