import { Theme } from '@/app'

export const FooterInstagram = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2092 2.79086 19 5 19H15C17.2092 19 19 17.2092 19 15V5C19 2.79086 17.2092 1 15 1Z'
        fill={Theme.colors.light.primary}
        fillOpacity='0.3'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M10 14C12.2092 14 14 12.2092 14 10C14 7.79085 12.2092 6 10 6C7.79085 6 6 7.79085 6 10C6 12.2092 7.79085 14 10 14Z'
        fill={Theme.colors.light.primary}
        stroke={Theme.colors.light.white}
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M15.5 5.5C16.0523 5.5 16.5 5.0523 16.5 4.5C16.5 3.9477 16.0523 3.5 15.5 3.5C14.9477 3.5 14.5 3.9477 14.5 4.5C14.5 5.0523 14.9477 5.5 15.5 5.5Z'
        fill={Theme.colors.light.white}
      />
    </svg>
  );
};
