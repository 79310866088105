import React, { useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { View, Theme, variantProvider } from '@/app';
import {
  TextBlock,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders';

export const ProductInfoSkeleton = (props) => {
  const { ready, children } = props;

  const renderSkeleton = (
    <>
      <View
        variants={['column', 'justifySpaceBetween', 'gap:2']}
        css={styles.details}
      >
        <View variants={['column', 'gap:2']}>
          <RectShape
            color={Theme.colors.light.skeleton}
            style={{
              width: '100%',
              height: 60,
              borderRadius: Theme.borderRadius.medium,
            }}
          />
          <TextBlock css={styles.teste} />
          <RectShape
            color={Theme.colors.light.skeleton}
            style={{
              width: '100%',
              height: 60,
              borderRadius: Theme.borderRadius.medium,
            }}
          />
        </View>
        <RectShape
          color={Theme.colors.light.skeleton}
          style={{
            width: '100%',
            height: 250,
            borderRadius: Theme.borderRadius.medium,
          }}
        />
        <TextBlock rows={5} color={Theme.colors.light.skeleton} />
        <View>
          <RectShape
            color={Theme.colors.light.skeleton}
            style={{
              width: '170px',
              height: '110px',
              borderRadius: Theme.borderRadius.medium,
            }}
          />
          <TextBlock rows={4} color={Theme.colors.light.skeleton} />
        </View>
      </View>
    </>
  );

  return (
    <ReactPlaceholder
      customPlaceholder={renderSkeleton}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  );
};

const styles = variantProvider.createComponentStyle(
  {
    details: {
      width: '35%',
    },
    teste: {
      backgroundColor: Theme.colors.light.skeleton,
      borderRadius: Theme.borderRadius.medium,
      height: '20px !important',
    },
  },
  true
);
