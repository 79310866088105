import { mapVariants } from '@codeleap/common'
import { variantProvider } from '../theme'
import { WebSelectComposition, WebSelectStyles } from '@codeleap/web'
const createSelectStyle = variantProvider.createVariantFactory<WebSelectComposition>()
const defaultStyles = WebSelectStyles

export const AppSelectStyles = {
  ...defaultStyles,
  default: createSelectStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
    },
    inputWrapper: {
      ...defaultStyles.default(theme).inputWrapper,
      height: theme.values.buttonAndInputHeight,
    },
    buttonWrapper: {
      ...defaultStyles.default(theme).buttonWrapper,
      height: theme.values.buttonAndInputHeight,
      backgroundColor: 'white',
      border: '1px solid rgb(222, 222, 222)',
      borderRadius: theme.borderRadius.small,
      ...theme.spacing.paddingHorizontal(1),
    },
    label: {
      ...defaultStyles.default(theme).label,
      marginBottom: theme.spacing.value(1),
      color: theme.colors.primaryText,
      ...theme.spacing.paddingHorizontal(1),
    },
    list: {
      ...defaultStyles.default(theme).list,
      backgroundColor: theme.colors.white,
      zIndex: theme.values.zIndex.selectList,
      border: 'none',
      boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.24)',
    },
    itemWrapper: {
      ...defaultStyles.default(theme).itemWrapper,
      // padding: theme.spacing.value(2),
      ...theme.spacing.paddingVertical(2),
      // height: 48,
      ...theme.spacing.paddingHorizontal(1),
    },
    buttonIcon: {
      ...defaultStyles.default(theme).buttonIcon,
      width: 13,
      height: 13,
      marginRight: theme.spacing.value(1),
      color: theme.colors.darkMediumGray,
    },
    buttonText: {
      ...defaultStyles.default(theme).buttonText,
      color: theme.colors.black,
    },
    'itemText:selected': {
      color: theme.colors.white,
    },
    // 'inputWrapper:open': {
    //   backgroundColor: 'yellow',
    // },

  })),
}
export {
  defaultStyles as webSelect,
}


