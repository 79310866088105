import { api } from '@/app'
import { createSlice } from '@codeleap/common'

const MODULE_NAME = 'Offers/'

export type Post = {
  id: number
  username: string
  created_datetime: string
  title: string
  content: string
}

type PostState = {
  offers: Post[]
  loading: boolean
  error: {
    message: string
  } | null
}

const initialState: PostState = {
  offers: [],
  loading: false,
  error: null,
}

export const offersSlice = createSlice({
  name: 'Offers',
  initialState,
  reducers: {

  },
  asyncReducers: {
    getData: async (state, setState) => {
      setState({ loading: true })
      api.get(MODULE_NAME)
        .then(({ data }) => {
          setState({ loading: false, offers: data.results })

        }).catch(() => {

          setState({
            error: {
              message: 'Error fetching data',
            },
          })

        })
    },
    postData: async (state, setState, values) => {
      setState({ loading: true })
      api.post('/post')
        .then(({ data }) => {
          setState({ loading: false, offers: data.results })

        }).catch(() => {
          setState({
            error: {
              message: 'Error fetching data',
            },
          })
        })
    },
  },
})
