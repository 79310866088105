import { ViewComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

const createViewStyle = variantProvider.createVariantFactory<ViewComposition>()
const defaultStyles = variantProvider.getDefaultVariants('View')

export const AppViewStyles = {
  ...defaultStyles,
  default: createViewStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      display: 'flex',
      flexDirection: 'row',
    },
  })),
  'flex:0': createViewStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      flex: 0,
    },
  })),
  'flex:1': createViewStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      flex: 1,
    },
  })),
  'gap:0': createViewStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      gap: theme.spacing.value(0),
    },
  })),
  'gap:0.5': createViewStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      gap: theme.spacing.value(0.5),
    },
  })),
  'gap:1': createViewStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      gap: theme.spacing.value(1),
    },
  })),
  'gap:1.5': createViewStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      gap: theme.spacing.value(1.5),
    },
  })),
  'gap:2': createViewStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      gap: theme.spacing.value(2),
    },
  })),
  'gap:3': createViewStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      gap: theme.spacing.value(3),
    },
  })),
  'gap:4': createViewStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      gap: theme.spacing.value(4),
    },
  })),
}
