import React, { useState } from 'react'
import { Button, View, Text, logger, TextInput, Theme, Touchable } from '@/app'
import { useForm, onMount, onUpdate } from '@codeleap/common'
import { Social } from './Social'
import { AppStatus } from '@/redux'
import { variantProvider } from '@/app/theme'
import { signupForm } from '@/lib'
import firebase from 'gatsby-plugin-firebase'
import { Session } from '@/redux'
import { Toast } from '@/shared'
import { profileFromUser } from '@/services/authentication'
import { withFirebase } from '@/services'

type SignupProps = any

export const SignupComponent: React.FC<SignupProps> = (signupProps) => {
  const { form, usingProvider, setUsingProvider } = signupProps

  // const [usingProvider, setUsingProvider] = useState('email')

  logger.log({ usingProvider })

  const handleSubmit = async () => {
    const isValid = form.validateAll(true)

    const currentUser = await withFirebase((firebase) => firebase.auth().currentUser)

    const firebaseProfile = await profileFromUser(currentUser)

    const handleSuccess = () => {
      Toast.success('Signup Successfully')
    }

    if (isValid) {
      try {
        const res = await Session.signup({
          data: form.values,
          provider: firebaseProfile ? 'google' : 'email',
        })

        if (res === 'success') {
          handleSuccess()
        }
      } catch (err) {
        logger.log({ err })
      }
    }
  }

  const handleSigninModal = () => {
    AppStatus.toggleSigninModal()
    AppStatus.toggleSignupModal()
  }

  const renderTextInput = (register, visibility) => {
    return (
      <TextInput
        {...form.register(register)}
        visibilityToggle={visibility}
        variants={['withHover']}
      />
    )
  }

  return (
    <View css={styles.wrapper} variants={['column', 'fullWidth']}>
      <View css={styles.inputs} variants={['gap:2']}>
        {renderTextInput('username', false)}
        {renderTextInput('email', false)}
        {usingProvider !== 'google' && renderTextInput('password', true)}
        {usingProvider !== 'google' && renderTextInput('repeatPassword', true)}
      </View>
      <Button
        text={'Create new account'}
        variants={['marginTop:2', 'fullWidth']}
        onPress={handleSubmit}
        // disabled={!form.isValid}
        css={styles.button}
      />
      <Social form={form} usingProvider={usingProvider} setUsingProvider={setUsingProvider} />
      <View variants={['column', 'center', 'marginTop:4']}>
        <Text
          text='By signing in you agree with the Terms and Conditions'
          variants={['secondaryColor', 'marginBottom:2']}
        />
        <Touchable css={styles.touchableWrapper} onPress={handleSigninModal}>
          <Text
            text='Already a user? Click here to login'
            variants={['p1', 'bold', 'secondaryColor']}
          />
        </Touchable>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    inputs: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      [Theme.media.down('mid')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
    },
    button: {
      height: Theme.values.buttonAndInputHeight,
    },
    touchableWrapper: {
      cursor: 'pointer',
    },
    wrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
    },
  },
  true,
)
