import { createSlice } from '@codeleap/common'
import axios from 'axios'

const api = axios.create({
  baseURL: 'https://ipapi.co/json',
})

const initialState: LocationState = {
  location: {},
  error: null,
}
export interface IPLoc {
  asn: string
  city: string
  continent_code: string
  country: string
  country_area: number
  country_calling_code: string
  country_capital: string
  country_code: string
  country_code_iso3: string
  country_name: string
  country_population: number
  country_tld: string
  currency: string
  currency_name: string
  in_eu: boolean
  ip: string
  languages: string
  latitude: number
  longitude: number
  network: string
  org: string
  postal: string
  region: string
  region_code: string
  timezone: string
  utc_offset: string
  version: string
}

type LocationState = {
  location: IPLoc
  error: {
    message: string
  } | null
}

export const locationSlice = createSlice({
  name: 'Location',
  initialState,
  reducers: {

  },
  asyncReducers: {
    getData: async (state, setState) => {
      try {
        const { data } = await api.get('')

        setState({
          location: data,
        })
      } catch (err) {
        setState({
          error: {
            message: 'Error fetching data',
          },
        })
      }
    },
  },
})
