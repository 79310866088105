import { Toast } from '@/shared';

export const SignupErrors = (error) => {
  switch (error.code) {
    case 'auth/weak-password':
      Toast.error('Weak password');
      return 'Senha fraca'
    case 'auth/email-already-in-use':
      Toast.error('Email already in use');
      return 'Email já em uso'
    case 'auth/invalid-email':
      Toast.error('Invalid email');
      return 'Email inválido'
    default:
      return 'Erro desconhecido'
  }
}