import { formatDistance } from 'date-fns'
import { logger } from '@/app'
import getSymbolFromCurrency from 'currency-symbol-map'

export const dateToBackEnd = (date) => {
  return new Date(date).toISOString()
    .replace(/\.\d+/, '')
    .slice(0, -1)
}

export const numbersRangeToBackEnd = (numbers) => {
  return `${numbers[0]}||${numbers[1]}`
}

export const datesRangeToBackEnd = (dates) => {
  const startDate = dates?.date?.from
  const endDate = dates?.date?.to

  const formatStartDate = formatDate(startDate)
  const formatEndDate = formatDate(endDate)

  const startTime = dates?.time?.from
  const endTime = dates?.time?.to

  const formatStartTime = formatTime(startTime)
  const formatEndTime = formatTime(endTime)
  // logger.log({ dates, startDate, endDate })

  logger.log({ formatStartDate, startDate })

  const toGo = `${formatStartDate}${formatStartTime}||${formatEndDate}${formatEndTime}`

  return toGo
}

export const openPicker = (input) => {
  input.current.openFilePicker()
}

export const formatedDistanceDate = (date) => {
  return formatDistance(
    new Date(date),
    new Date(),
    {
      includeSeconds: false,
    })
}

export const currencySymbol = (currency) => {
  return getSymbolFromCurrency(currency)
}

export const getUserLocation = () => {
  navigator.geolocation.getCurrentPosition(function (position) {
    return position?.coords
  })
}

export const addDays = (days) => {
  const today = new Date()

  today.setDate(today.getDate() + days)

  return today
}

export const requestFullScreen = (id) => {
  const element = document.getElementById(id).firstChild
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.msRequestFullScreen) {
    element.msRequestFullScreen()
  }
}

export const formatCenter = (params) => {
  return {
    lat: parseInt(params.lat),
    lng: parseInt(params.lng),
  }
}

export const kmToMeters = (km) => {
  return km * 1000
}

export const formatDate = (item) => {
  logger.log({ item })
  const formatedFrom = `${item?.month}-${item?.day}-${item?.year}`

  const fromToGo = new Date(formatedFrom)

  return formatedFrom
}

export const formatTime = (item) => {
  logger.log({ item })
  const formated = `T${formatNums(item?.hour)}:${formatNums(item?.minute)}:00`

  return formated
}

export const formatNums = (num) => {
  if (num < 10) {
    return '0' + num
  } else {
    return num
  }
}

export const formatToDate = (item) => {
  const formatedTo = `${item?.to?.month}-${item?.to?.day}-${item?.to?.year}`

  const toToGo = new Date(formatedTo)

  return toToGo
}

export const getDifference = (array1, array2) => {
  return array1?.filter(object1 => {
    return !array2?.some(object2 => {
      return object1?.id === object2?.id
    })
  })
}

export const updateArrayComparing = (array1, array2) => {
  return array1?.filter(object1 => {
    return !array2?.some(object2 => {
      return object1?.id === object2?.id ? object2 : object1
    })
  })
}

export const sortArrayById = (array) => {
  array.sort(function (a, b) {
    return a.id - b.id
  })
}

export const sortMainLocationArr = (array) => {
  const arr = array.sort(function (a, b) {
    return Number(b.main_location) - Number(a.main_location)
  })

  return arr
}

export const formatCategoryTitle = (title: string) => {
  return title.replace(/\s/g, '_').toLowerCase()
}

export const matchCategoryCondition = (key: string, value: string, condition: string) => {
  const matchKey = `${formatCategoryTitle(key)}=${value}`
  return condition?.includes(matchKey)
}

export const formatDateToYearMonthDay = (date: Date) => {
  // const today = date.get

  logger.log({ date })

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  }
}
