import React, { useState } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { View, Theme, variantProvider } from '@/app'
import {
  TextBlock,
  MediaBlock,
  RectShape,
} from 'react-placeholder/lib/placeholders'

export const ExploreMapSkeleton = (props) => {
  const { ready, children } = props

  const renderItem = () => {
    return (
      <RectShape
        style={{
          width: '100%',
          height: '100%',
        }}
        css={styles.box}
      />
    )
  }

  const renderPlaceholder = (
    <View variants={['fullWidth']}>
      {renderItem()}
    </View>
  )

  return (
    <ReactPlaceholder
      customPlaceholder={renderPlaceholder}
      ready={ready}
      showLoadingAnimation
    >
      {children}
    </ReactPlaceholder>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    box: {
      backgroundColor: 'rgb(234, 234, 234)',
    },
  },
  true,
)
