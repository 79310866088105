/* eslint-disable max-len */
import {
  View,
  Touchable,
  logger,
  Text,
  Icon,
  Theme,
  variantProvider,
} from '@/app'
import { Link, Image } from '@/shared'
import { useAppSelector } from '@/lib'
import { formatedDistanceDate } from '@/lib/utils/helpers'

type CardProps = {
  isMap?: boolean
  isHorizontal?: boolean
  isCardMobileListing?: boolean
  isHovered?: boolean
  listing: any
}

export const CardComponent: React.FC<CardProps> = (props) => {
  const {
    isHorizontal,
    isCardMobileListing,
    isHovered,
    isMap,
    listing,
    ...cardProps
  } = props

  const { categories } = useAppSelector((store) => store.Categories)

  const formatedDate = formatedDistanceDate(listing?.created_datetime)

  const categoryByListing = categories.find(
    (category) => category.title === listing?.category,
  )

  const exchange_forByListing = categories.find(
    (category) => category.title === listing?.exchange_for,
  )

  const isListingCurrency = listing?.category === 'Currency'
  const isCurrencyExchange = listing?.exchange_for === 'Currency'

  const listingCurrency = listing?.attributes[0]?.selects[0]?.title.split('-')[0]

  const exchangeCurrency = listing?.currency_name?.split('-')[0]
  const exchangeCurrencyToGo = exchangeCurrency?.replace(/ /g, '')

  const listingCurrencyToGo = listingCurrency?.replace(/ /g, '')

  const listingAmount = listing?.attributes[1]?.text

  const horizontalVariants = ['row', `justifySpaceBetween`, 'marginTop:2']
  const verticalVariants = ['column', 'gap:1.5']

  const hasMoreThanOneLocation = listing?.locations?.length > 1

  const locationText = (location) => {

    const locAddress = location.address
    const others = `${hasMoreThanOneLocation ? `  + ${listing?.locations?.length - 1} other locations` : ''}`
    return locAddress + others
  }

  return (
    <Link
      variants={[`${isHorizontal ? 'row' : 'column'}`]}
      css={[
        styles.wrapper,
        isHovered && styles.hovered,
        isHorizontal ? styles.horizontalWrapper : styles.verticalWrapper,
        isMap && styles.mapWrapper,
      ]}
      to={`/product/${listing?.id}/detail`}
      {...cardProps}
    >
      <View
        css={[
          styles.imageWrapper,
          isHorizontal
            ? styles.horizontalImageWrapper
            : styles.verticalImageWrapper,
          isMap && styles.mapImage,
          isCardMobileListing && styles.verticalMobileImageWrapper,
        ]}
      >
        <Image
          css={[isHorizontal ? styles.horizontalImage : styles.verticalImage]}
          source={listing?.images[0]?.url}
        />
      </View>
      <View
        variants={['column']}
        css={[
          styles.texts,
          isHorizontal ? styles.textsHorizontal : styles.textsVertical,
          isMap && styles.textsMap,
          isCardMobileListing && styles.textsMobileListing,
        ]}
      >
        <View variants={['column']} css={styles.textsPrimary}>
          <Text
            text={listing?.title ? listing?.title : 'No title provided.'}
            variants={[`${isMap ? 'h4' : 'h3'}`, 'bold', 'clamp']}
          />
          {!isMap && (
            <Text
              text={
                listing?.description
                  ? listing.description
                  : 'No description provided.'
              }
              variants={['thin', 'clamp']}
            />
          )}
          <View variants={['gap:1', 'alignCenter']}>
            {isListingCurrency ? <Text variants={['h4', 'bold']} text={`${listingCurrencyToGo + listingAmount}`} /> : <Image css={styles.categoryIcon} source={categoryByListing?.icon} />
            }
            <Icon name='swipe' style={styles.categoryIcon} />
            {isCurrencyExchange ? <Text variants={['h4', 'bold']} text={`${exchangeCurrencyToGo}`} /> : <Image
              css={styles.categoryIcon}
              source={exchange_forByListing?.icon}
            />}
          </View>
        </View>
        <View
          css={[
            isMap && styles.textsMapSecondary,
            isHorizontal && styles.bottomTextsHorizontal,
          ]}
          variants={isHorizontal ? horizontalVariants : verticalVariants}
        >
          {listing?.locations?.slice(0, 1).map((location, idx) => (
            <Text
              variants={[`${!isMap && 'p2'}`, `${!isMap && 'thin'}`, 'clamp']}
              text={locationText(location)}
              key={idx}

            />
          ))}
          {!isMap && (
            <Text
              variants={['p2', 'thin', 'clamp']}
              text={`${formatedDate} ago`}
            />
          )}
        </View>
      </View>
    </Link>
  )
}

const CARD_VERTICAL_WRAPPER_WIDTH = '100%'
const CARD_HORIZONTAL_IMAGE_WRAPPER_WIDTH = 180
const CARD_TEXTS_HORIZONTAL_WIDTH = 520

const styles = variantProvider.createStylesheet({
  wrapper: {
    border: `1px solid ${Theme.colors.light.xWhite}`,
    borderRadius: Theme.borderRadius.medium,
    overflow: 'hidden',
    transition: 'all 300ms ease 0s',
    '&:hover': {
      border: `1px solid ${Theme.colors.light.xWhite}`,
      transform: 'scale(1.02)',
    },
  },
  hovered: {
    transition: 'all 300ms ease 0s',
    transform: 'unset',
    // transform: 'scale(1.02)',
    boxShadow: 'rgb(0 0 0 / 16%) 0px 0px 20px',
  },
  verticalWrapper: {
    width: '100%',
  },
  horizontalWrapper: {
    width: '95%',
    height: 180,
    display: 'flex',
    transform: 'unset',
    '&:hover': {
      transform: 'unset',
    },
  },
  mapWrapper: {
    width: 300,
    boxShadow: '0 6px 20px rgb(0 0 0 / 20%)',
    [Theme.media.down('mid')]: {
      width: 400,
      height: 130,
    },
    [Theme.media.down('smallish')]: {
      width: 315,
      height: 120,
    },
  },
  imageWrapper: {
    backgroundColor: Theme.colors.light.white,
    // borderTopLeftRadius: Theme.borderRadius.medium,
  },
  horizontalImage: {
    maxWidth: '100%',
    objectFit: 'cover',
    width: 180,
    // borderTopLeftRadius: Theme.borderRadius.medium,
    // borderBottomLeftRadius: Theme.borderRadius.medium,
  },
  verticalImage: {
    maxWidth: '100%',
    width: '100%',
    objectFit: 'cover',
    // borderTopLeftRadius: Theme.borderRadius.medium,
    // borderTopRightRadius: Theme.borderRadius.medium,
  },
  mapImage: {
    height: 150,
    [Theme.media.down('mid')]: {
      width: 130,
    },
  },
  verticalImageWrapper: {
    height: 250,
    // borderTopRightRadius: Theme.borderRadius.medium,
  },
  verticalMobileImageWrapper: {
    width: '40%',
  },
  horizontalImageWrapper: {
    width: CARD_HORIZONTAL_IMAGE_WRAPPER_WIDTH,
    // borderBottomLeftRadius: Theme.borderRadius.medium,
  },
  texts: {
    backgroundColor: Theme.colors.light.white,
    ...Theme.spacing.paddingHorizontal(3),
    ...Theme.spacing.paddingVertical(2),
    justifyContent: 'space-between',

    // borderBottomRightRadius: Theme.borderRadius.medium,
  },
  textsVertical: {
    // borderBottomLeftRadius: Theme.borderRadius.medium,
    height: 250,
    gap: Theme.spacing.value(2),
    justifyContent: 'center',
  },
  textsMap: {
    height: 160,
    gap: Theme.spacing.value(1),
    justifyContent: 'center',
  },
  textsHorizontal: {
    width: CARD_TEXTS_HORIZONTAL_WIDTH,
    // borderTopRightRadius: Theme.borderRadius.medium,
    justifyContent: 'center',
  },
  textsPrimary: {
    gap: Theme.spacing.value(1.5),
  },
  textsMobileListing: {
    width: '60%',
  },
  textsMapSecondary: {
    display: 'flex',
    flexDirection: 'column',
    gap: Theme.spacing.value(1),
  },
  categoryIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
  },
  bottomTextsHorizontal: {
    color: Theme.colors.light.darkMediumGray,
  },
})
