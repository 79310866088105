import { api } from '@/app';
import { createSlice } from '@codeleap/common';

const MODULE_NAME = 'messages/'

export type Post = {
  id:number,
  username: string,
  created_datetime: string,
  title: string,
  content: string
}

type PostState = {
  messages: Post[]
  loading: boolean
  error: {
    message: string
  } | null
}

const initialState:PostState = {
  messages: [],
  loading: false,
  error: null,
}


export const messagesSlice = createSlice({
  name: 'Messages',
  initialState,
  reducers: {

  },
  asyncReducers: {
    getData: async ( state, setState ) => {
      setState({loading: true})
      api.get(MODULE_NAME)
        .then(({data}) => {
          setState({loading: false, messages: data.results})

        }).catch(() => {

          setState({
            error: {
              message: 'Error fetching data',
            },
          })

        })
    },
    postData: async ( state, setState, values ) => {
      setState({loading: true})
      api.post('/post')
        .then(({data}) => {
          setState({loading: false, messages: data.results})

        }).catch(() => {
          setState({
            error: {
              message: 'Error fetching data',
            },
          })
        })
    },
  },
})
