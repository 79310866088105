export const ArrowLeft = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 13 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.5 2L1.5 12L11.5 22'
        stroke={props.style.color ? props.style.color : '#313131'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}