import {
  Button,
  logger,
  variantProvider,
  View,
  Text,
  Theme,
  TextInput,
  Touchable,
} from '@/app';
import { Avatar, Image } from '@/shared';

import { useMessages, useProfiles } from '@/services/api';

export default function Preview(props) {
  const { offer, setTab, currOffer, setCurrOffer, Messages } = props;

  logger.log({offer})

  const handleGoToMessages = () => {
    Messages.query.send({ offer: offer?.id });
    setCurrOffer(offer);
    setTab(2);
  };

  return (
    <Touchable
      onPress={handleGoToMessages}
      css={[
        styles.wrapper,
        currOffer?.id === offer?.id && styles.wrapperHighlighted,
      ]}
      variants={['alignCenter']}
    >
      <Avatar
        profile={{
          avatar: offer?.target_listing?.images[0].url,
        }}
        styles={{
          icon: styles.avatar,
          image: styles.avatarImage,
          general: styles.avatarWrapper,
        }}
        variants={['small', 'bigRadius']}
      />
      <View variants={['column', 'gap:1']} css={styles.previewTexts}>
        <Text
          variants={['h4', 'bold', 'clamp']}
          text={offer?.target_listing?.title}
        />
        <View css={styles.exchangeFor}>
          <Text
            css={styles.textExchangeFor}
            variants={['p2', 'clamp']}
            text={`${'Clothing in exchange for:'}`}
          />
          {offer?.exchange_for_item?.length > 0 ? (
            offer?.exchange_for_item.map((item) => (
              <Text
                css={styles.textExchangeFor}
                variants={['p2', 'bold', 'clamp']}
                text={item.title}
              />
            ))
          ) : (
            <Text
              css={styles.textExchangeFor}
              variants={['p2', 'bold', 'clamp']}
              text={`${offer?.exchange_for_currency} ${offer?.exchange_for_value}`}
            />
          )}
        </View>
      </View>
    </Touchable>
  );
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      columnGap: Theme.spacing.value(2),
      ...Theme.spacing.paddingVertical(2),
      ...Theme.spacing.paddingHorizontal(1),
      backgroundColor: Theme.colors.light.veryLightGray,
      borderRadius: Theme.borderRadius.medium,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Theme.colors.light.xLightGray,
      },
    },
    wrapperHighlighted: {
      backgroundColor: Theme.colors.light.xLightGray,
    },
    avatar: {
      width: '100%',
      height: '100%',
      display: 'unset',
    },
    avatarWrapper: {
      width: 48,
    },
    avatarImage: {
      borderRadius: '50%',
      width: '100%',
    },
    textExchangeFor: {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    exchangeFor: {
      gap: Theme.spacing.value(0.2),
    },
    previewTexts: {
      width: 240,
    },
  },
  true
);
