import { makeFetcher, onUpdate } from '@codeleap/common'
import { Settings } from '.'
import { logger } from './logger'
import firebase from 'gatsby-plugin-firebase'

const client = makeFetcher(Settings, {
  logger,
})

client.axios.interceptors.request.use(async (req) => {
  let token = ''
  const user = await firebase.auth().currentUser
  if (user) {
    token = (await user.getIdTokenResult()).token
  }

  const res = {
    ...req,
    headers: {
      ...req.headers,
      'Authorization': token,
    },
  }

  return res
})
export const api = client

