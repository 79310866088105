import { AvatarComposition } from '@codeleap/common'
import { Theme, variantProvider } from '../theme'

// const defaultStyle = mapVariants(variantProvider.theme, WebModalStyles)

const createAvatarStyle = variantProvider.createVariantFactory<AvatarComposition | 'icon'>()

const defaults = variantProvider.getDefaultVariants('Avatar')

export const AvatarStyles = {
  ...defaults,
  default: createAvatarStyle((theme) => ({
    ...defaults.default(theme),
    wrapper: {
      ...defaults.default(theme).wrapper,
      borderRadius: '50%',
      backgroundColor: '#fa7373',
      width: '100%',
      height: '100%',
    },
    general: {
      ...defaults.default(theme).general,
      cursor: 'pointer',
    },
    image: {
      ...defaults.default(theme).image,
      width: '100%',
      height: '100%',
      borderRadius: Theme.borderRadius.medium,
    },
    icon: {
      backgroundColor: Theme.colors.lightGreen,
      // ...Theme.spacing.paddingHorizontal(0.5),
      '&:hover': {
        backgroundColor: Theme.colors.xLightGreen,
      },
    },
    fileInput: {
      display: 'none',
    },
    text: {
      ...defaults.default(theme).text,
      fontSize: 'unset !important',
      textTransform: 'uppercase',
      color: Theme.colors.light.black,
    },
  })),
  small: createAvatarStyle((Theme) => ({
    wrapper: {
      ...defaults.default(Theme).wrapper,
      borderRadius: Theme.borderRadius.medium,
      // backgroundColor: Theme.colors.light.lightGreen,
      width: '100%',
      height: '100%',
    },
    general: {
      ...defaults.default(Theme).general,
      width: 48,
      height: 48,
    },
    icon: {
      borderRadius: '50%',
      width: 48,
      height: 48,
      backgroundColor: '#ffb8b8',
      padding: 0,
      justifyContent: 'center',
      // ...Theme.spacing.paddingHorizontal(0.5),
      '&:hover': {
        backgroundColor: '#ffb8b8',
      },
    },
  })),
  bigRadius: createAvatarStyle((Theme) => ({
    wrapper: {
      ...defaults.default(Theme).wrapper,
      borderRadius: '50%',
    },
    icon: {
      ...defaults.default(Theme).icon,
      borderRadius: '50%',
      padding: 0,
      justifyContent: 'center',
      // backgroundColor: Theme.colors.lightGreen,
      // // ...Theme.spacing.paddingHorizontal(0.5),
      // '&:hover': {
      //   backgroundColor: Theme.colors.xLightGreen,
      // },
    },
    image: {
      ...defaults.default(Theme).image,
      borderRadius: '50%',
    },
  })),
  medium: createAvatarStyle((theme) => ({
    general: {
      ...defaults.default(theme).general,
      width: 110,
      height: 110,
    },
    text: {
      ...defaults.default(theme).text,
      fontSize: 'unset !important',
    },
  })),
  large: createAvatarStyle((theme) => ({
    ...defaults.default(theme),
    general: {
      ...defaults.default(theme).general,
      width: 200,
      height: 200,
    },
    wrapper: {
      ...defaults.default(theme).wrapper,
      width: '100%',
      height: '100%',
    },
    text: {
      ...defaults.default(theme).text,
      fontSize: 80,
      color: Theme.colors.light.black,
    },
  })),
}
