import { css } from '@emotion/react'
import { Theme } from '../theme'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'norama-react-modern-calendar-datepicker/lib/DatePicker.css'

export const globalStyle = css`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        scrollbar-color: ${Theme.colors.gray} transparent;
        scrollbar-width: 10px;
    }
    
    body {
      overflow-y: overlay !important;
    }
    @supports not (overflow-y: overlay !important) {
      body {
        overflow-y: scroll !important;
      }
   }

    #___gatsby, #gatsby-focus-wrapper {
        height: 100vh;
        min-height: 100vh;
        /* overflow-x: hidden; */
    }


    ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e4e4e4;
}

.Calendar, .Calendar * {
  /* padding: unset !important;
  margin: unset !important; */
}

input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

    // React inner image zoom
    .iiz {
      max-width: 100%;
      /* height: 100vh; */
      margin: 0;
      position: relative;
      overflow: hidden;
      display: inline-block;
      cursor: -webkit-zoom-in;
      cursor: zoom-in;
   }

   .iiz__img {
      max-width: 100%;
      height: 100vh;
      display: block;
      pointer-events: none;
      visibility: visible;
      opacity: 1;
    }

    // Calendar
    .modern-calendar {
      font-family: 'Oxygen';
    }
    .modern-calendar-range-between {
      background-color: #EBF1F0 !important;
      color: black !important;
    }

    .modern-calendar-range-edge {
      background-color: #366E65 !important;
      color: white !important;
      border-radius: 4px !important;
    }

    .Calendar__day.-selected, .Calendar__day.-selectedStart, .Calendar__day.-selectedEnd {
      background-color: #366E65 !important;
      border-radius: 4px !important;
    }

    .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
      background-color: #366E65 !important;
    }

    .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
      border-radius: 4px !important;
    }

    .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
      color: #444444 !important;
    }

    .react-daterange-picker__calendar {
      width: 420px !important;
    }
    .react-calendar {   
      border: none;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.24);
      border-radius: 8px;
      font-family: 'Oxygen';
      /* line-height: 1.125em; */
      width: 420px !important;
      height: 440px;
      margin-top: 5px;
      position: relative;
    }


    .react-calendar__month-view__weekdays,
    .react-calendar__navigation {
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 25px;
    }

    .react-calendar__navigation__label__labelText {
      font-size: 16px;
    }

    .react-daterange-picker__wrapper {
      display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    border-left-width: 1px;
    border-left-style: solid;
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-right-width: 1px;
    border-right-style: solid;
    align-items: center;
    border-radius: 8px;
    height: 38px;
    border: none;

    transition: 200ms;
    background-color: #F2F2F2;
    }

    .react-calendar__month-view__days {
	    /* display: flex; */
    /* flex-wrap: wrap; */
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    /* align-items: center; */
    /* justify-content: center; */
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    gap: 5px;
}

  .react-calendar__month-view__weekdays {
    border-bottom: 1px solid #E5E5E5;
  }

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    flex: unset !important;
    /* width: 40px; */
    /* height: 40px; */
    align-items: center;
}


    .react-calendar span, .react-calendar abbr {
      font-family: 'Oxygen';
      text-decoration: none;
      font-weight: normal;
    }

    .react-calendar__month-view__weekdays__weekday {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .react-calendar__month-view__weekdays__weekday abbr {
      color: #9C9C9C;
      padding-left: 0;
      padding-right: 0;
    }

    .tile-classname {
      font-size: 14px;
    }

  
    // Slick carousel configs
    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      overflow: hidden;
      user-select: none;
      touch-action: pan-y;

    }
    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
      height: 100%;
      }
    .slick-track {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
      line-height: 0;
    }
    .slick-slide img {
      width: 100%;
    }

    // Google map react
    .gm-style:first-of-type > div:nth-child(1) {
      cursor: default !important;
    }

    // Toast
    .toast-container {
      background-color: #366E65;
      border-radius: 14px;
    }
    .toast-body {
      color: #fff;
    }

    // LightBox Image
   .lightbox-image {
     max-height: unset !important;
   }


   //Stars
   .react-stars {
    span {

      text-shadow: #FFB800 -1px 0px, #FFB800 0px 1px, #FFB800 1px 0px, #FFB800 0px -1px;
    }
   }


   // React Placeholder
   .text-row {
    border-radius: 12px;
   }
`
