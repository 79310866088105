import { useRef, useState } from 'react'
import {
  View,
  Theme,
  logger,
  TextInput,
  variantProvider,
} from '@/app'
import { DropdownComponent } from '@/shared'
import { useClickOutside } from '@codeleap/web'
import { onUpdate } from '@codeleap/common'
import { useAppSelector, newLocationForm } from '@/lib'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'

import { v4 } from 'uuid'

export type LocationInputProps = {
  showDropdown?: boolean
  setShowDropdown?: React.SetStateAction<any>
  label?: string
  onPress?: (event: any) => void
  inputWrapperStyles?: any
  inputStyles?: any
  placeholder?: string
  defaultValue?: any
}

export const LocationInput: React.FC<LocationInputProps> = (props) => {
  const {
    showDropdown,
    setShowDropdown,
    label,
    onPress,
    inputWrapperStyles,
    inputStyles,
    placeholder,
    defaultValue,
    showDefaultValue,
    ...inputProps
  } = props

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  })

  const [locationValue, setLocationValue] = useState('')

  const inputId = useRef(v4()).current

  const ref = useClickOutside(() => clearSuggestions())

  const { newLocationModalOpen, myLocationModalOpen } = useAppSelector(
    (store) => store.AppStatus,
  )

  onUpdate(() => {
    if (showDefaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  onUpdate(() => {
    setLocationValue('')
  }, [newLocationModalOpen])

  const handleInput = (e) => {
    setShowDropdown(true)
    setLocationValue(e.target.value)
    setValue(e.target.value)
  }

  const handleSelect = ({ description }) => {

    setValue(description)
    setLocationValue(description)
    setShowDropdown(false)
    clearSuggestions()
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        onPress(description, lat, lng)
      })
      .catch((error) => {
        console.log('😱 Error: ', error)
      })
  }

  return (
    <View
      variants={['fullWidth', 'relative']}
      id={inputId}
      css={[inputWrapperStyles]}
      ref={ref}
    >
      <TextInput
        label={label}
        placeholder={placeholder}
        onChange={handleInput}
        onFocus={() => setShowDropdown(true)}
        defaultValue={defaultValue}
        value={showDefaultValue ? value : locationValue}
        variants={['fullWidth', 'withHover']}
        styles={inputStyles}
        {...inputProps}
      />
      <DropdownComponent
        isActive={showDropdown}
        addresses={data}
        onPress={handleSelect}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {

  },
  true,
)
