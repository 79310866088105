import { api } from '@/app'
import { Toast } from '@/shared'
import { useQuery, waitFor } from '@codeleap/common'
import { AppStatus, Session } from '@/redux'

const MODULE_NAME = 'profiles/'

const initialQueryState = {
  count: 0,
  next: '',
  previous: '',
  results: [],
}

export const useProfiles = () => useQuery({
  initialState: {
    profile: {},
    locations: [],
    savedFilters: [],
    rating: [],
  },
  routes: {
    getDetail: async ({ currentValue, setState, throwError }, id) => {

      try {
        const { data } = await api.get(MODULE_NAME + id + '/')
        setState({ ...currentValue, profile: data })
        return data
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    getLocations: async ({ currentValue, setState, throwError }) => {

      try {
        const { data } = await api.get(MODULE_NAME + 'locations/?limit=999/')
        setState({ ...currentValue, locations: data.results })
        return data
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    createLocation: async ({ currentValue, setState, throwError }, data) => {
      try {
        AppStatus.set('loading')
        const { data: location } = await api.post(MODULE_NAME + 'locations/', data)
        setState({ ...currentValue, locations: [...currentValue.locations, location] })

        AppStatus.set('idle')
        AppStatus.toggleNewLocationModal()

        return location

      } catch (err) {
        AppStatus.set('idle')
        AppStatus.toggleNewLocationModal()
        console.log(err)
      }
    },
    deleteLocation: async ({ currentValue, setState, throwError }, id) => {
      try {
        AppStatus.set('loading')
        await api.delete(MODULE_NAME + 'locations/' + id + '/')
        setState({ ...currentValue, locations: currentValue.locations.filter(location => location.id !== id) })
        AppStatus.set('idle')
      } catch (err) {
        AppStatus.set('idle')
        console.log(err)
      }
    },
    getSavedFilters: async ({ currentValue, setState, throwError }) => {
      try {
        const { data } = await api.get(MODULE_NAME + 'saved_filters/')
        setState({ ...currentValue, savedFilters: data.results })
        return data
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    createSavedFilter: async ({ currentValue, setState, throwError }, data) => {
      try {
        AppStatus.set('loading')
        const { data: savedFilter } = await api.post(MODULE_NAME + 'saved_filters/', data)
        setState({ ...currentValue, savedFilters: [...currentValue.savedFilters, savedFilter] })

        AppStatus.set('idle')
        Toast.success('Saved search created')
        AppStatus.toggleNewSearchModal()
        AppStatus.toggleSavedSearchesModal()
        return savedFilter

      } catch (err) {
        AppStatus.set('idle')
        Toast.error('Error creating saved search')
        console.log(err)
      }
    },
    deleteSavedFilter: async ({ currentValue, setState, throwError }, id) => {
      try {
        AppStatus.set('loading')
        await api.delete(MODULE_NAME + 'saved_filters/' + id + '/')
        setState({ ...currentValue, savedFilters: currentValue.savedFilters.filter(savedFilter => savedFilter.id !== id) })
        AppStatus.set('idle')
        Toast.success('Saved search deleted')
      } catch (err) {
        AppStatus.set('idle')
        Toast.error('Error deleting saved search')
        console.log(err)
      }
    },
    updateSavedFilter: async ({ currentValue, setState, throwError }, data, id) => {
      try {
        AppStatus.set('loading')
        const { data: savedFilter } = await api.patch(MODULE_NAME + 'saved_filters/' + id + '/', data)
        setState({ ...currentValue, savedFilters: currentValue.savedFilters.map(savedFilter => savedFilter.id === data.id ? savedFilter : savedFilter) })

        AppStatus.set('idle')

        return savedFilter

      } catch (err) {
        AppStatus.set('idle')

        console.log(err)
      }
    },
    getRating: async ({ currentValue, setState, throwError }, id) => {
      try {
        const { data } = await api.get(MODULE_NAME + 'ratings/' + id + '/')
        setState({ ...currentValue, rating: data })
        return data
      } catch (err) {
        throwError('Error fetching data')
      }
    },
    createRating: async ({ currentValue, setState, throwError }, data) => {
      try {
        AppStatus.set('loading')
        const { data: rating } = await api.post(MODULE_NAME + 'ratings/', data)
        setState({ ...currentValue, rating: rating })

        AppStatus.set('idle')

        return rating

      } catch (err) {
        AppStatus.set('idle')

        console.log(err)
      }
    },
    updateRating: async ({ currentValue, setState, throwError }, data, id) => {
      try {
        AppStatus.set('loading')
        const { data: rating } = await api.patch(MODULE_NAME + 'ratings/' + id + '/', data)
        setState({ ...currentValue, rating: rating })

        AppStatus.set('idle')

        return rating

      } catch (err) {
        AppStatus.set('idle')

        console.log(err)
      }
    },
    deleteRating: async ({ currentValue, setState, throwError }, id) => {
      try {
        AppStatus.set('loading')
        await api.delete(MODULE_NAME + 'ratings/' + id + '/')
        setState({ ...currentValue, rating: [] })
        AppStatus.set('idle')
      } catch (err) {
        AppStatus.set('idle')
        console.log(err)
      }
    },

  },
})

export const updateProfileLocation = async ({ data }, id: Number) => {

  try {
    const { data: profile } = await api.patch(MODULE_NAME + 'locations/' + id, data)

    return profile
  } catch (err) {
    console.log(err)
  }
}
