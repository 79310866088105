import { api } from '@/app';
import { createSlice } from '@codeleap/common';

const MODULE_NAME = 'listings/'

export type Post = {
  id:number,
  username: string,
  created_datetime: string,
  title: string,
  content: string
}

type PostState = {
  listings: Post[]
  listing: Post[]
  listingsByProfile: Post[]
  loading: boolean
  error: {
    message: string
  } | null
}

const initialState:PostState = {
  listings: [],
  listing: [],
  listingsByProfile: [],
  loading: false,
  error: null,
}


export const listingsSlice = createSlice({
  name: 'Listings',
  initialState,
  reducers: {

  },
  asyncReducers: {
    getData: async ( state, setState ) => {
      setState({loading: true})
      api.get(MODULE_NAME)
        .then(({data}) => {
          setState({loading: false, listings: data.results})

        }).catch(() => {

          setState({
            error: {
              message: 'Error fetching data',
            },
          })

        })
    },
    getByProfile: async ( state, setState, id ) => {
      setState({loading: true})
      api.get(MODULE_NAME + '?profile=' +id)
        .then(({data}) => {
          setState({loading: false, listingsByProfile: data.results})

        }).catch(() => {

          setState({
            error: {
              message: 'Error fetching data',
            },
          })

        })
    },
    getDetail: async ( state, setState, id ) => {
      api.get(MODULE_NAME + id + '/')
        .then(({data}) => {
          setState({loading: false, listing: data})

        }).catch(() => {

          setState({
            error: {
              message: 'Error fetching data',
            },
          })
        })
    },
    postData: async ( state, setState, values ) => {
      setState({loading: true})
      api.post('/post')
        .then(({data}) => {
          setState({loading: false, listings: data.results})

        }).catch(() => {
          setState({
            error: {
              message: 'Error fetching data',
            },
          })
        })
    },
  },
})
