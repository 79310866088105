export * from './Settings'
export * from './components'
export * from './logger'
export * from './theme'
export * from './api'
export { globalStyle } from './stylesheets/Global'
// import * as AppComponents from '@/shared'

import LibComponents from './components'

export const allComponents = {
  ...LibComponents,
  // ...AppComponents,
}

export const {
  Menu,
  Button,
  Text,
  View,
  Checkbox,
  Modal,
  FileInput,
  Overlay,
  Drawer,
  TextInput,
  ContentView,
  ActivityIndicator,
  RadioInput,
  Icon,
  FlatList,
  Touchable,
  Select,
  CenterWrapper,
  Tooltip,
  RouterPage,
} = allComponents
