import {
  Button,
  logger,
  Modal,
  Text,
  TextInput,
  Theme,
  variantProvider,
  View,
} from '@/app'
import { Link, Toast } from '@/shared'
import { useAppSelector, newLocationForm, feedbackForm } from '@/lib'
import { AppStatus } from '@/redux'
import ReactStars from 'react-rating-stars-component'
import { useForm, createForm, onUpdate } from '@codeleap/common'
import { useProfiles } from '@/services/api'

type FeedBackModalProps = any

export const FeedbackModal: React.FC<FeedBackModalProps> = (props) => {
  const {
    feedback,
    setFeedback,
    currOffer,
    setCurrOffer,
    origin,
  } = props
  const { feedbackModalOpen } = useAppSelector((store) => store.AppStatus)

  const {
    data: { locations },
    queries: Profiles,
  } = useProfiles()

  const form = useForm(feedbackForm, {
    output: 'json',
    validateOn: 'blur',
  })

  const handleChangeRating = (val) => {
    setFeedback(val)
  }

  const handlePostFeedback = () => {
    const isValid = form.validateAll(true)

    Profiles.createRating
      .send({
        value: feedback,
        origin: origin,
        target: currOffer.created_by.id,
        target_offer: currOffer.id,
      })
      .then(() => {
        setCurrOffer(null)
        Toast.success('Feedback sent successfully')
        AppStatus.toggleFeedbackModal()
      })
  }

  const handleToggleModal = () => {
    setCurrOffer(null)
    AppStatus.toggleFeedbackModal()
  }

  return (
    <Modal
      visible={feedbackModalOpen}
      toggle={handleToggleModal}
      title={'Share your feedback'}
      styles={{
        box: styles.box,
      }}
    >
      <View
        css={styles.wrapper}
        variants={['fullWidth', 'alignCenter', 'column', 'gap:2']}
      >
        <ReactStars
          count={5}
          onChange={handleChangeRating}
          size={46}
          activeColor={'#FFB800'}
          color={Theme.colors.light.white}
          className={'.react-stars'}
        />
        <View variants={['gap:1', 'column', 'fullWidth']}>
          <TextInput label={'Leave a comment (optional)'} />
          <Button onPress={handlePostFeedback} text={'Submit'} />
        </View>
      </View>
      ,
    </Modal>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      ...Theme.spacing.paddingHorizontal(4),
      paddingBottom: Theme.spacing.value(4),
    },
    box: {
      width: 550,
    },
  },
  true,
)
