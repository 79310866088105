/* eslint-disable max-len */
/* eslint-disable max-lines */
import React from 'react'
import { Text, logger, TextInput, View, variantProvider, Theme, Select, Checkbox } from '@/app'
import { useAppSelector, optionsForm, accountForm, createProductForm } from '@/lib'
import { numbersRangeToBackEnd, datesRangeToBackEnd, formatCategoryTitle, matchCategoryCondition } from '@/lib/utils/helpers'
import { DateInput, DateRangeInput, SliderRange, Image } from '@/shared'
import { useState } from 'react'

import { onMount, onUpdate, useForm, usePrevious } from '@codeleap/common'
import { components } from 'react-select'
import { useUnmount } from 'react-use'
import { CategoryTypes } from '@/types'
import { format } from 'date-fns/esm'

type CategoriesProps = {
  optionKey: string
  setConditions: React.Dispatch<
    React.SetStateAction<Record<string, CategoryTypes.Filter>>
  >
  optionOrCategory: CategoryTypes.Category | CategoryTypes.Option
  index: number
  initialState?: Record<string, CategoryTypes.Filter>
  hideOptions?: boolean
  hideTextInput?: boolean
  isExplore?: boolean
  isCurrencyExchange?: boolean
  isLanguageExchange?: boolean
  isTicketExchange?: boolean
  showFree?: boolean
}

export const Categories: React.FC<CategoriesProps> = (props) => {

  const { options, categories } = useAppSelector((store) => store.Categories)

  const { Option } = components

  const {
    optionOrCategory = {
      title: 'Category',
      selects: categories,
      condition: '',
      type: 'SG',
    },
    form,
    onDefaultExchangeFor = null,
    optionKey = 'category',
    index,
    setConditions,
    initialState,
    isExplore,
    isLanguageExchange = false,
    debugName,
    showFree,
    hideLabel,
    styles,
    textInputStyles,
    hideOptions,
    hideTextInput,

    // form,
  } = props

  const initialTitle = initialState?.[optionOrCategory.title]

  const initialSelected =
    optionOrCategory?.type === 'MT'
      ? initialTitle?.selects || []
      : initialTitle?.selects?.[0]
  const [selected, setSelected] = useState(() => initialSelected || null)
  const [textValue, setTextValue] = useState(
    () => initialTitle?.text || '',
  )
  const [dateValue, setDateValue] = useState(
    () => initialTitle?.date || new Date(),
  )

  const isEventTime = optionOrCategory.title === 'Event Time'

  const currOption = options.find(option => option.title === optionOrCategory.title)

  const isRequired = currOption?.required ?? optionOrCategory.title === 'Category'

  const haveForm = isRequired && !isExplore

  logger.log({ initialState, options, isRequired, optionOrCategory })

  onMount(() => {
    setConditions((prev) => {
      logger.log({ prevOnMount: prev })
      const newConditions: Record<string, CategoryTypes.Filter> = {
        ...prev,
        [optionOrCategory.title]: initialTitle || {
          ...optionOrCategory,
          option: optionOrCategory.title,
          selects: initialSelected ? [initialSelected] : [],
          selectValues: optionOrCategory.selects,

        },
      }

      if (!['SG', 'MT'].includes(optionOrCategory.type)) {
        newConditions[optionOrCategory.title].value =
            initialTitle?.value || ''
      }

      return newConditions
    })
    // }
  })

  onUpdate(() => {

    const haveDate = dateValue?.time && dateValue?.date

    if (isExplore && optionOrCategory.type === 'DT' && haveDate) {
      const toBackEnd = datesRangeToBackEnd(dateValue)

      setConditions((prev) => ({
        ...prev,
        [optionOrCategory.title]: {
          ...prev[optionOrCategory.title],
          date: toBackEnd,
        },
      }))
    }
  }, [dateValue])

  useUnmount(() => {
    setConditions((prev) => {
      const copy = { ...prev }
      delete copy[optionOrCategory.title]
      return copy
    })
  })

  const CustomSelectOption = props => {
    return (
      <Option {...props}>
        <View variants={['flex', 'gap:2']}>
          <Checkbox variants={['selectOption']} checked={props.isSelected} />
          {props?.data?.icon && <Image css={pageStyles.icon} source={props.data?.icon} />}
          {props.data.label}
        </View>
      </Option>
    )
  }

  const CustomMultiSelectOption = props => {
    return (
      <Option {...props}>
        <View variants={['flex', 'gap:2']}>
          <Checkbox variants={['multiSelectOption']} checked={props.isSelected} />
          {props?.data?.icon && <Image css={pageStyles.icon} source={props.data?.icon} />}
          {props.data.label}
        </View>
      </Option>
    )
  }

  const handleChange = (value) => {
    const isMulti = Array.isArray(value)

    const multiValues = isMulti && value?.map(a => a.value)

    const isCategory = categories.find(el => el.id === value)

    const categoryExchangeForID = isCategory && isCategory.default_exchange_for

    if (categoryExchangeForID) {
      onDefaultExchangeFor?.(categoryExchangeForID)
    }

    haveForm && form.setFieldValue(`${optionOrCategory?.title}`, value)

    setSelected(isMulti ? multiValues : value)
    setConditions((prev) => {
      return {
        ...prev,
        [optionOrCategory.title]: {
          ...prev[optionOrCategory.title],
          option: optionOrCategory.title,
          selects: isMulti ? multiValues : value !== undefined ? [value] : [],
        },
      }
    })
  }

  const currentSelect = optionOrCategory.selects.filter((s) => Array.isArray(selected) ? selected.includes(s.id) : s.id === selected,
  )

  const subOptions: CategoryTypes.Option[] = currentSelect
    ? options.filter((o) => {
      return currentSelect.some((s) => matchCategoryCondition(optionKey, s.title, o.condition),
      )
    })
    : []

  const initialSelects = initialState[optionOrCategory?.title]?.selects
  const initialSelectValues = initialState[optionOrCategory?.title]?.selectValues

  const defaultLabel = initialSelectValues?.filter(val => val?.id === initialSelects[0])

  const multiDefaultLabel = initialSelectValues?.filter(({ id: idOne }) => initialSelects.some((el) => idOne === el))

  const multiDefault = multiDefaultLabel?.map(label => {
    return {
      label: label.title,
      value: initialTitle?.id,
    }
  })

  const isCategory = optionOrCategory?.title === 'Category'

  const updatedCategories = isCategory && optionOrCategory?.selects.filter(el => el.title === 'Currency' || el.title === 'Language Exchange')

  const categoriesFree = showFree ? optionOrCategory.selects : optionOrCategory.selects.filter(el => el.title !== 'Free')

  const selectedCategory = optionOrCategory?.selects.find(el => el.id === selected)

  switch (optionOrCategory?.type || 'SG') {
    case 'SG':
      return (
        <>
          <Select
            {...(haveForm && { ...form.register(`${optionOrCategory.title}`) })}
            {...(selected && { value: { label: selectedCategory.title, value: selected }})}
            isSelected={selected}
            onValueChange={handleChange}
            // value={ null }
            options={isCategory && isLanguageExchange ? updatedCategories.map((s) => ({
              label: s.title,
              value: s?.id,
              icon: s?.icon,
            })) : categoriesFree.map((s) => ({
              label: s.title,
              value: s?.id,
              icon: s?.icon,
            }))}
            placeholder={`Select an option`}
            label={optionOrCategory.title}
            components={{ Option: CustomSelectOption }}
            isClearable
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            arrowIconName='arrowDown'
            customStyles={styles}
            styles={{
              label: isExplore && pageStyles.selectLabel,
            }}
          />
          {!hideOptions &&
            subOptions.sort((a, b) => a.index - b.index).map((o, idx) => {
              const key = index + idx + 1
              return (
                <Categories
                  index={key}
                  key={`${o.id}-${key}`}
                  initialState={initialState}
                  optionKey={o.title}
                  setConditions={setConditions}
                  optionOrCategory={o}
                  hideLabel={hideLabel}
                  styles={styles}
                  textInputStyles={textInputStyles}
                  hideTextInput={hideTextInput}
                  isExplore={isExplore}
                  form={form}
                />
              )
            })}
        </>
      )
    case 'MT':
      return (
        <>
          <Select
            // isOptionSelected={selected.forEach(i => i)}
            value={selected}
            {...(selected && defaultLabel && { defaultValue: multiDefault })}
            options={optionOrCategory.selects.map((s) => ({
              label: s.title,
              value: s.id,
            }))}
            isMulti

            placeholder={`Select an option`}
            label={optionOrCategory.title}
            onValueChange={handleChange}
            customStyles={styles}
            styles={{
              label: isExplore && pageStyles.selectLabel,
            }}
            components={{ Option: CustomMultiSelectOption }}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            hideSelectedOptions={false}
          />
          {!hideOptions &&
            subOptions.map((o, idx) => {
              const key = index + idx + 1
              return (
                <Categories
                  index={key}
                  key={`${o.id}-${key}`}
                  initialState={initialState}
                  optionKey={o.title}
                  setConditions={setConditions}
                  optionOrCategory={o}
                  hideLabel={hideLabel}
                  styles={styles}
                  textInputStyles={textInputStyles}
                  hideTextInput={hideTextInput}
                  form={form}
                />
              )
            })}
        </>
      )
    case 'DT':
      return (
        <>
          {!isExplore && (
            <DateInput
              label={optionOrCategory.title}
              textInputStyles={textInputStyles}
              inputValue={dateValue}
              fromToday={isEventTime}
              showTime={optionOrCategory.show_time}
              onlyFuture={optionOrCategory.only_future}
              onChange={(date) => {
                setDateValue(date)
                setConditions((prev) => ({
                  ...prev,
                  [optionOrCategory.title]: {
                    ...prev[optionOrCategory.title],
                    date,
                  },
                }))
              }}
            />
          )}
          {isExplore && (
            <DateRangeInput
              label={optionOrCategory.title}
              textInputStyles={textInputStyles}
              dateValue={dateValue}
              fromToday={isEventTime}
              showTime={optionOrCategory.show_time}
              onlyFuture={optionOrCategory.only_future}
              setDateValue={setDateValue}
            />
          )}
        </>
      )
    case 'NB':
      return (
        <>
          {!hideTextInput && !isExplore && (
            <TextInput
              {...(haveForm && { ...form.register(`${optionOrCategory.title}`) })}
              label={optionOrCategory.title}
              styles={textInputStyles}
              type={'number'}
              variants={['withHover']}
              // value={isExplore ? textValue : form.values.Amount}
              placeholder={optionOrCategory.title}
              onChangeText={(val) => {
                // form.setFieldValue('Amount', val)
                // setTextValue(val)
                setConditions((prev) => ({
                  ...prev,
                  [optionOrCategory.title]: {
                    ...prev[optionOrCategory.title],
                    text: val,
                  },
                }))
              }}
            />
          )}
          {isExplore && (
            <SliderRange label={optionOrCategory.title} textInputStyles={textInputStyles} onChange={(val) => {
              setConditions((prev) => ({
                ...prev,
                [optionOrCategory.title]: {
                  ...prev[optionOrCategory.title],
                  value: numbersRangeToBackEnd(val),
                },
              }))
            }} />
          )}
        </>
      )
    default:
      return (
        <>
          {!hideTextInput && (
            <TextInput
              label={optionOrCategory.title}
              styles={textInputStyles}
              value={textValue}
              variants={['withHover']}
              placeholder={optionOrCategory.title}
              onChangeText={(val) => {
                // form.setFieldValue('Amount', val)
                setTextValue(val)
                setConditions((prev) => ({
                  ...prev,
                  [optionOrCategory.title]: {
                    ...prev[optionOrCategory.title],
                    text: val,
                  },
                }))
              }}
              // form={form}
            />
          )}
        </>
      )
  }
}

export const MemorizedCategories = (React.memo(Categories, (prevProps, nextProps) => {
  return prevProps.index !== nextProps.index
}))

const pageStyles = variantProvider.createComponentStyle(
  {
    selectOpen: {
      backgroundColor: Theme.colors.light.primary,
    },
    selectOpenText: {
      color: Theme.colors.light.white,
    },
    selectLabel: {
      fontSize: 12,
    },
    icon: {
      width: 24,
      height: 24,
    },
  },
  true,
)
