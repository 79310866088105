import { useState } from 'react';
import { Button, logger, Theme, View, variantProvider } from '@/app';

export type PaginationProps = {
  items?: any;
  params?: any;
  setParams?: React.SetStateAction<any>;
}

export const Pagination: React.FC<PaginationProps> = (props) => {
  const { items, params, setParams } = props;

  const pageNumbers = [];

  const TOTAL_ITEMS = items?.count;

  for (let i = 1; i <= Math.ceil(TOTAL_ITEMS / 10); i++) {
    pageNumbers.push(i);
  }

  const handlePrevPage = () => {};

  const handleNextPage = () => {};

  const handlePressButton = (number) => {
    setParams((prev) => {
      return {
        ...prev,
        page: number,
      };
    });
  };

  return (
    <View variants={['marginTop:auto', 'paddingVertical:3']} css={styles.wrapper}>
      <Button
        css={[styles.button, styles.buttonLightGray]}
        variants={['icon', 'mediumIcon', 'marginRight:1']}
        icon={'parkArrowLeft'}
      />
      {pageNumbers.map((number, index) => (
        <Button
          key={index}
          css={[
            styles.button,
            params.page == number
              ? styles.buttonActive
              : styles.buttonInactive,
          ]}
          text={number}
          onPress={() => {
            handlePressButton(number);
          }}
        />
      ))}
      <Button
        css={[styles.button, styles.buttonLightGray]}
        variants={['icon', 'mediumIcon', 'marginLeft:1']}
        icon={'parkArrowRight'}
      />
    </View>
  );
};

const styles = variantProvider.createComponentStyle(
  {
    wrapper: {
      gap: Theme.spacing.value(0.5),
    },
    button: {
      width: 45,
      height: 45,
    },
    buttonActive: {
      backgroundColor: Theme.colors.light.primary,
    },
    buttonInactive: {
      backgroundColor: 'rgba(54, 110, 101, 0.05)',
    },
    buttonLightGray: {
      backgroundColor: 'rgba(54, 110, 101, 0.05)',
      color: 'black',
    },
  },
  true
);
