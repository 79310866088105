export const Swipe = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.0001 6.00019C16.0001 5.73497 15.8948 5.48062 15.7072 5.29308C15.5197 5.10554 15.2654 5.00019 15.0001 5.00019H3.41014L5.71014 2.71019C5.89844 2.52188 6.00423 2.26649 6.00423 2.00019C6.00423 1.73388 5.89844 1.47849 5.71014 1.29019C5.52184 1.10188 5.26644 0.996094 5.00014 0.996094C4.73384 0.996094 4.47844 1.10188 4.29014 1.29019L0.290139 5.29019C0.151382 5.43081 0.0573856 5.60938 0.0200115 5.80337C-0.0173626 5.99736 0.00356002 6.19807 0.0801395 6.38019C0.15516 6.5628 0.282557 6.71913 0.446278 6.82946C0.609998 6.9398 0.802714 6.9992 1.00014 7.00019H15.0001C15.2654 7.00019 15.5197 6.89483 15.7072 6.70729C15.8948 6.51976 16.0001 6.2654 16.0001 6.00019ZM19.9201 9.62019C19.8451 9.43757 19.7177 9.28124 19.554 9.17091C19.3903 9.06057 19.1976 9.00117 19.0001 9.00019H5.00014C4.73492 9.00019 4.48057 9.10554 4.29303 9.29308C4.1055 9.48062 4.00014 9.73497 4.00014 10.0002C4.00014 10.2654 4.1055 10.5198 4.29303 10.7073C4.48057 10.8948 4.73492 11.0002 5.00014 11.0002H16.5901L14.2901 13.2902C14.1964 13.3831 14.122 13.4937 14.0712 13.6156C14.0205 13.7375 13.9943 13.8682 13.9943 14.0002C13.9943 14.1322 14.0205 14.2629 14.0712 14.3848C14.122 14.5066 14.1964 14.6172 14.2901 14.7102C14.3831 14.8039 14.4937 14.8783 14.6156 14.9291C14.7374 14.9798 14.8681 15.006 15.0001 15.006C15.1322 15.006 15.2629 14.9798 15.3847 14.9291C15.5066 14.8783 15.6172 14.8039 15.7101 14.7102L19.7101 10.7102C19.8489 10.5696 19.9429 10.391 19.9803 10.197C20.0176 10.003 19.9967 9.8023 19.9201 9.62019Z'
        fill='black'
      />
    </svg>
  );
};
