/* eslint-disable max-len */
import {
  View,
  Text,
  Icon,
  Theme,
  variantProvider,
} from '@/app'
import { Link, Image } from '@/shared'
import { useAppSelector } from '@/lib'
import { useComponentStyle } from '@codeleap/common'
import { formatedDistanceDate } from '@/lib/utils/helpers'

export type CardBaseProps = {
  children?:any
  imageWrapperStyles?: any
  imageStyles?: any
  textStyles?: any
  bottomTextsStyles?: any
  isMap?: boolean
  listing?: any
  bottomTextsVariants?: any
  wrapperStyles?: any
}

export const CardBase: React.FC<CardBaseProps> = (props) => {
  const { children, imageWrapperStyles, imageStyles, bottomTextsStyles, listing, isMap, textStyles, bottomTextsVariants, wrapperStyles } = props

  const styles = useComponentStyle(componentStyles)

  const { categories } = useAppSelector((store) => store.Categories)

  const formatedDate = formatedDistanceDate(listing?.created_datetime)

  const categoryByListing = categories.find(
    (category) => category.title === listing?.category,
  )

  const exchange_forByListing = categories.find(
    (category) => category.title === listing?.exchange_for,
  )

  const isListingCurrency = listing?.category === 'Currency'
  const isCurrencyExchange = listing?.exchange_for === 'Currency'

  const listingCurrency = listing?.attributes[0]?.selects[0]?.title.split('-')[0]

  const exchangeCurrency = listing?.currency_name?.split('-')[0]
  const exchangeCurrencyToGo = exchangeCurrency?.replace(/ /g, '')

  const listingCurrencyToGo = listingCurrency?.replace(/ /g, '')

  const listingAmount = listing?.attributes[1]?.text

  const verticalVariants = ['column', 'gap:1.5']

  const locationText = (location) => {

    const locAddress = location.address
    const others = `${hasMoreThanOneLocation ? `  + ${listing?.locations?.length - 1} other locations` : ''}`
    return locAddress + others
  }

  const hasMoreThanOneLocation = listing?.locations?.length > 1

  return (
    <Link css={[styles.wrapper, wrapperStyles]} to={`/product/${listing?.id}/detail`} {...props}>
      <View css={[styles.imageWrapper, imageWrapperStyles]}>
        <Image source={listing?.images[0]?.url} css={[imageStyles]} />
      </View>
      <View
        variants={['column']}
        css={[styles.texts, textStyles]}
      >
        <View variants={['column', 'gap:1.5']}>
          <Text
            text={listing?.title ? listing?.title : 'No title provided.'}
            variants={[`${isMap ? 'h4' : 'h3'}`, 'bold', 'clamp']}
          />
          {!isMap && (
            <Text
              text={
                listing?.description
                  ? listing.description
                  : 'No description provided.'
              }
              variants={['thin', 'clamp']}
            />
          )}
          <View variants={['gap:1', 'alignCenter']}>
            {isListingCurrency ? <Text variants={['h4', 'bold']} text={`${listingCurrencyToGo + listingAmount}`} /> : <Image css={styles.categoryIcon} source={categoryByListing?.icon} />
            }
            <Icon name='swipe' style={styles.categoryIcon} />
            {isCurrencyExchange ? <Text variants={['h4', 'bold']} text={`${exchangeCurrencyToGo}`} /> : <Image
              css={styles.categoryIcon}
              source={exchange_forByListing?.icon}
            />}
          </View>
        </View>
        <View
          css={[bottomTextsStyles]}
          variants={verticalVariants}
        >
          {listing?.locations?.slice(0, 1).map((location, idx) => (
            <Text
              variants={[`${!isMap && 'p2'}`, `${!isMap && 'thin'}`, 'clamp']}
              text={locationText(location)}
              key={idx}

            />
          ))}
          {!isMap && (
            <Text
              variants={['p2', 'thin', 'clamp']}
              text={`${formatedDate} ago`}
            />
          )}
        </View>
      </View>
    </Link>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    border: `1px solid ${Theme.colors.light.xWhite}`,
    borderRadius: Theme.borderRadius.medium,
    overflow: 'hidden',
    transition: 'all 300ms ease 0s',
    '&:hover': {
      border: `1px solid ${Theme.colors.light.xWhite}`,
      transform: 'scale(1.02)',
    },
  },
  imageWrapper: {
    backgroundColor: theme.colors.white,
  },
  categoryIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
  },
  texts: {
    backgroundColor: Theme.colors.light.white,
    ...Theme.spacing.paddingHorizontal(3),
    ...Theme.spacing.paddingVertical(2),
    justifyContent: 'space-between',
  },
}))
