export const NoMessages = (props) => {

  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 121 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M111.5 59.5C111.517 66.0994 109.975 72.6094 107 78.5C103.472 85.5587 98.0488 91.4959 91.3372 95.6464C84.6257 99.797 76.8912 101.997 69 102C62.4007 102.017 55.8906 100.475 50 97.5L21.5 107L31 78.5C28.0247 72.6094 26.4828 66.0994 26.5 59.5C26.5031 51.6088 28.7031 43.8743 32.8536 37.1628C37.0041 30.4513 42.9413 25.0279 50 21.5C55.8906 18.5247 62.4007 16.9828 69 17H71.5C81.9217 17.575 91.7652 21.9738 99.1457 29.3543C106.526 36.7348 110.925 46.5783 111.5 57V59.5Z'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
