import { AppSettings } from '@codeleap/common'

const ENV = process.env.NODE_ENV as AppSettings['Environment']['Type']

export const Settings: AppSettings = {
  AppName: 'Project template web',
  Description: 'This is a template',
  Environment: {
    IsDev: ENV === 'development',
    Type: ENV,
  },
  ApiKeys: {
    //Enter your ApiKey here
    GoogleMap: '',
    //Enter your ApiKey here
    Conversion: '',
  },
  ApiCredentials: {
    GoogleMap: {
      ApiKey: '',
    },
    Conversion: {
      ApiKey: '',
    },
  },
  Application: {
    IsBrowser: typeof window !== 'undefined',
  },
  Fetch: {
    ApiURL: 'https://api-glocal.codeleap.co.uk/api/',
  },
  Social: {
    FaceURL: 'https://www.facebook.com/codeleapuk/',
    LinkedinURL: 'https://www.linkedin.com/company/codeleap-uk',
  },
  ContactINFO: {
    SupportEMAIL: 'support@codeleap.co.uk',
    ContactEMAIL: 'hello@codeleap.co.uk',
    ContactPHONE: '+44 (0) 333 050 9420',
  },
  Logger: {
    Level: 'debug',
    IgnoreWarnings: [
      'Do not call Hooks inside useEffect',
      'React does not recognize the',
    ],
  },
  Sentry: {
    enable: false,
  },
}
