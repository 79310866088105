export const Facebook = (props) => {
  return (
    <svg
      width='2em'
      height='2em'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13 0.199951C5.93109 0.199951 0.199951 5.96568 0.199951 13.0772C0.199951 19.5036 4.8801 24.8302 11.0004 25.8V16.8004H7.74887V13.0772H11.0004V10.2401C11.0004 7.01013 12.9116 5.22903 15.8342 5.22903C17.2346 5.22903 18.7016 5.48016 18.7016 5.48016V8.647H17.0836C15.4962 8.647 14.9995 9.64122 14.9995 10.6599V13.0746H18.5467L17.9796 16.7978H14.9995V25.7974C21.1198 24.8328 25.7999 19.5049 25.7999 13.0772C25.7999 5.96568 20.0688 0.199951 13 0.199951V0.199951Z'
        fill='#1877F2'
      />
    </svg>
  );
};
