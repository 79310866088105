import React, { useState, useRef } from 'react'
import {
  Button,
  logger,
  Select,
  TextInput,
  Touchable,
  Icon,
  Theme,
  Text,
  View,
  variantProvider,
} from '@/app'
import { DropdownComponent, Image } from '@/shared'
import { onMount, onUpdate } from '@codeleap/common'
import { useClickOutside } from '@codeleap/web'
import { useMapsApi } from '@/lib/hooks'
import { ObjectToQueryString } from '@/lib/utils/ObjectToQueryString'
import { useComponentStyle } from '@codeleap/common'
import { useAppSelector } from '@/lib'
import { defaultStyles, headerStyles } from '@/app/stylesheets/ReactSelect'
import { components } from 'react-select'

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'

import { v4 } from 'uuid'
import { navigate } from 'gatsby'
import { handleInputChange } from 'react-select/dist/declarations/src/utils'

export type HeaderInputProps = {
  params?: any
  setParams?: React.Dispatch<React.SetStateAction<any>>
  isExplore?: boolean
  loading?: boolean
}

export default function HeaderInputComponent(props: HeaderInputProps) {
  const { params, setParams, isExplore, loading } = props

  const [showDropdown, setShowDropdown] = useState(false)
  const [categoryName, setCategoryName] = useState(null)
  const [location, setLocation] = useState({})

  const { categories } = useAppSelector((store) => store.Categories)

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'initMap',
    debounce: 300,
  })

  const inputId = useRef(v4()).current

  const { Option } = components

  const styles = useComponentStyle(componentStyles)

  const ref = useClickOutside(() => clearSuggestions())

  const isSmall = Theme.hooks.down('mid')

  const handleInput = (e, isDescription) => {
    isDescription && setValue(e.target.value)
    setLocation((prev) => {
      return {
        ...prev,
        ...(!isDescription && { title: e.target.value }),
        ...(isDescription && { description: e.target.value }),
      }
    })
  }

  onUpdate(() => {
    if (params?.description) {
      setLocation((prev) => {
        return {
          ...prev,
          description: params?.description,
        }
      })
    }
  }, [params?.description])

  onUpdate(() => {
    if (params?.title) {
      setLocation((prev) => {
        return {
          ...prev,
          title: params?.title,
        }
      })
    }
  }, [params?.title])

  onUpdate(() => {
    if (params?.category) {
      setCategoryName(params.category)
    }
  }, [params?.category])

  const handleNavigateExplore = () => {
    if (isExplore) {
      setParams((prev) => {
        return {
          ...prev,
          ...(location.lat && { lat: location.lat }),
          ...(location.lng && { lng: location.lng }),
          ...(location.description && { description: location.description }),
          title: location.title,
          // ...(location.title ? { title: location.title } : null),
          category: categoryName ? categoryName : params.category,
        }
      })
    } else {
      const updatedLocation = { ...location }
      delete updatedLocation?.description
      navigate(`/?${ObjectToQueryString(updatedLocation)}`, {
        state: { location },
        replace: false,
      })
    }
  }

  const handleSelect = ({ description }) => {
    setValue(description)
    setShowDropdown(false)
    clearSuggestions()
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLocation((prev) => ({ ...prev, lat, lng, description }))
      })
      .catch((error) => {
        console.log('😱 Error: ', error)
      })
  }

  const handleSelectCategory = (e) => {
    setCategoryName(e)
    setLocation((prev) => ({ ...prev, category: e }))
  }

  const categoryOptions = categories.map((category) => {
    return {
      value: category.title,
      label: `${category.title}`,
      icon: category.icon,
    }
  })

  const CustomSelectOption = props => {
    return (
      <Option {...props}>
        <View variants={['flex', 'gap:2']}>
          {props?.data?.icon && <Image css={styles.icon} source={props.data?.icon} />}
          {props.data.label}
        </View>
      </Option>
    )
  }

  const isSmallExplore = isSmall && isExplore

  return (
    <View css={styles.headerInput} ref={ref}>
      <>
        <View css={[styles.inputsWrapper, isExplore && styles.exploreInputsWrapper]}>
          {!isSmallExplore && (
            <TextInput
              placeholder='Search'
              variants={[
                'searchInput',
                'searchInputRight',
                'fullWidth',
                'hideErrors',
              ]}
              responsiveVariants={{
                small: ['searchInput'],
              }}
              css={styles.textInput}
              onChange={(e) => handleInput(e, false)}
              value={location.title}
              deafultValue={params?.title}
            />
          )}
          <View id={inputId} css={styles.categoriesInput}>
            <TextInput
              placeholder='Location'
              variants={[
                'searchInput',
                'noBorderRadius',
                'fullWidth',
                'hideErrors',
              ]}
              responsiveVariants={{
                small: ['searchInput'],
              }}
              styles={{
                innerWrapper: styles.middleInnerWrapper,

              }}
              css={styles.textInput}
              onChange={(e) => { handleInput(e, true) }}
              value={location?.description}
              onFocus={() => setShowDropdown(true)}
            />
            <DropdownComponent
              isHeader
              isActive={showDropdown}
              addresses={data}
              onPress={handleSelect}
            />
          </View>
          {!isExplore && (
            <Select
              // styles={{
              //   buttonWrapper: styles.selectWrapper,
              //   buttonText:
              //     categoryName || params?.category
              //       ? styles.selectText
              //       : styles.emptySelectText,
              // }}
              customStyles={headerStyles}
              options={categoryOptions}
              placeholder='Categories'
              value={categoryName}
              components={{ Option: CustomSelectOption }}
              isClearable
              onValueChange={handleSelectCategory}
              arrowIconName='arrowDown'
              renderItem={({ styles: selectStyles, icon, label, onPress }) => (
                <Touchable
                  onPress={onPress}
                  variants={['gap:2', 'alignCenter']}
                  css={selectStyles.itemWrapper}
                >
                  <Image css={styles.icon} source={icon} />
                  <Text text={label} />
                </Touchable>
              )}
            />
          )}
        </View>
        <Button
          variants={['mediumIcon']}
          icon={'search'}
          color={Theme.colors.light.white}
          onPress={handleNavigateExplore}
          css={styles.button}
          loading={isExplore && loading}
        />
      </>
    </View>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  headerInput: {
    position: 'relative',
    flex: 1,
    justifyContent: 'center',
    [Theme.media.down('mid')]: {
      display: 'flex',
      // flexDirection: 'column',
      margin: 'unset',
    },
  },
  textInput: {
    // border: '1px solid rgb(222, 222, 222)',
    // backgroundColor: theme.colors.veryLightGray,
    color: theme.colors.black,
    width: '100%',
    '::placeholder': {
      color: theme.colors.silver,
    },
  },
  categoriesInput: {
    position: 'relative',
  },
  inputsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    width: '70%',
    [Theme.media.down('mid')]: {
      display: 'flex',
      width: '100%',
    },
  },
  exploreInputsWrapper: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  button: {
    // backgroundColor: theme.colors.primary,
    width: theme.values.buttonAndInputHeight,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  selectWrapper: {
    borderRadius: 0,
  },
  emptySelectText: {
    color: theme.colors.silver,
  },
  selectText: {
    color: theme.colors.black,
  },
  middleInnerWrapper: {
    borderLeft: 'none',
    borderRight: 'none',
    [Theme.media.down('mid')]: {
      borderLeft: '1px solid rgb(222, 222, 222)',
      borderTopLeftRadius: theme.borderRadius.medium,
      borderBottomLeftRadius: theme.borderRadius.medium,
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
}))
