/* eslint-disable max-lines */
import React, { useState } from 'react'
import { useWindowSize, useScrollEffect } from '@codeleap/web'
import {
  Drawer,
  View,
  variantProvider,
  Theme,
  Text,
  TextInput,
  CenterWrapper,
  Touchable,
  Button,
  Icon,
  logger,
} from '@/app'
import { Image, Avatar, Link } from '@/shared'
import { ObjectToQueryString } from '@/lib/utils/ObjectToQueryString'

import { useAppSelector } from '@/lib'
import {
  useBooleanToggle,
  onUpdate,
  useComponentStyle,
} from '@codeleap/common'
import { useLocation } from 'react-use'
import HeaderInput from './HeaderInput'
import { AppStatus } from '@/redux'
import { navigate } from 'gatsby'
import { FiltersRow } from '@/layout/Explore'
import { AvatarSkeleton } from '@/skeletons'

type HeaderProps = any
type LinkProps = any

const Header: HeaderProps = ({
  filterProps,
  headerInputProps,
  showCategories,
  fullWidth,
  hideHeaderInputs,
}) => {
  const [isMenuOpen, setMenu] = useState(false)
  const [below, setBelow] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const [locationParam, setLocationParam] = useState({})

  const styles = useComponentStyle(componentStyles)

  const { isLoggedIn, profile, loading, appMounted } = useAppSelector(
    (store) => store.Session,
  )

  const { notifications } = useAppSelector((store) => store.Notifications)

  useScrollEffect(setBelow, Theme.values.maxHeaderMenuHeight)
  const location = useLocation()

  const handleProfile = () => {
    isLoggedIn ? navigate('/account/edit') : AppStatus.toggleSigninModal()
  }

  onUpdate(() => {
    if (location.hash) {
      onAnchorClick(`${location.hash}`)
    }
  }, [location])

  function onAnchorClick(target: string) {
    const elementToScroll = document.getElementById(target)
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: 'smooth' })
      window.location.hash = target
    }

    if (isMenuOpen) {
      setMenu(false)
    }
  }

  const joynedDate = new Date(profile?.date_joined)

  const joynedYear = joynedDate.getFullYear()
  const joynedMonth = joynedDate.toLocaleString('en-US', { month: 'long' })

  const hasMoreThanEightNot = notifications.length > 8
  const hasNotifications = notifications.length > 0

  const renderMenuItem = (to, icon, text) => {
    return (
      <Link css={styles.menuLink} to={to}>
        <Icon name={icon} style={styles.menuIcon}/>
        <Text text={text} variants={['p1']} />
      </Link>
    )
  }

  const LogoWrapper = ({ children }) => {
    if (filterProps) {
      return (
        <Touchable onPress={() => { window.location.reload(true) }}>
          <Link
            css={searchOpen && styles.disabled}
            to={'.'}
          >
            {children}
          </Link>
        </Touchable>
      )
    } else {
      return (
        <Link css={searchOpen && styles.disabled} to={'/'} >{children}</Link>
      )
    }
  }

  const renderNotificationButton = () => {
    return (
      <Link to={'/account/notifications'}>
        <View variants={['relative']}>
          {hasNotifications && (
            <View variants={['absolute', 'flex', 'alignCenter', 'justifyCenter']} css={styles.badge}>
              <Text variants={['textCenter']} text={`${hasMoreThanEightNot ? '9+' : notifications.length}`} css={styles.badgeText} />
            </View>
          )}

          <Button variants={['icon']} icon={'bell'} css={styles.notificationsButton} styles={{
            icon: styles.bellIcon,
          }} />
        </View>
      </Link>
    )
  }

  const handleInput = (e) => {
    setLocationParam((prev) => {
      return {
        ...prev,
        title: e.target.value,
      }
    })
  }

  return (
    <CenterWrapper
      styles={{
        wrapper: filterProps
          ? styles.headerFilterWrapper
          : styles.headerWrapper,
        innerWrapper: fullWidth
          ? styles.headerFullWidth
          : styles.headerInnerWrapper,
      }}
    >
      <View css={styles.wrapper}>
        <View
          variants={['absolute', 'whole']}
          css={{
            opacity: below ? 1 : 0,
            transition: 'opacity 0.3s ease',
            zIndex: -1,
            boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
          }}
        />
        <LogoWrapper>
          <Image source='logo.png' alt='GlocalX' css={styles.logoImage} />
        </LogoWrapper>
        <View
          variants={['gap:2', 'alignCenter']}
          css={searchOpen && styles.disabled}
          down={'mid'}
        >

          <Button
            styles={{
              icon: styles.searchIcon,
            }}
            icon={'search'}
            variants={['icon']}
            onPress={() => {
              setSearchOpen(true)
            }}
          />
          {renderNotificationButton()}
          <Button
            variants={['xSmallIcon', 'alignCenter', 'justifyCenter']}
            icon={'bars'}
            onPress={() => {
              setDrawerOpen(true)
            }}
            css={styles.menuButton}
          />
        </View>
        <View
          variants={['fullWidth', 'alignCenter', 'justifySpaceBetween']}
          css={searchOpen ? styles.searchMobile : styles.disabled}
          down={'mid'}
        >
          <TextInput
            variants={['fullWidth']}
            placeholder={'Search in Explore...'}
            onChange={handleInput}
            styles={{
              wrapper: { marginRight: '10px' },
              error: { display: 'none' },
            }}
          />

          <View variants={['gap:2']}>
            <Button
              styles={{
                icon: styles.searchIcon,
              }}
              icon={'search'}
              variants={['icon']}
              onPress={() => {
                navigate(`/?${ObjectToQueryString(locationParam)}`, {
                  state: { locationParam },
                  replace: false,
                })
              }}
            />
            <Button
              variants={['xSmallIcon', 'alignCenter', 'justifyCenter']}
              icon={'close'}
              onPress={() => {
                setSearchOpen(false)
              }}
              css={styles.menuButton}
            />
          </View>
        </View>
        <View
          up={'mid'}
          variants={[
            'flex',
            `${hideHeaderInputs ? 'flex:0' : 'flex:1'}`,
            'fullWidth',
          ]}
        >
          {hideHeaderInputs ? null : (
            <HeaderInput
              {...headerInputProps}
              showCategories={showCategories}
            />
          )}
          <View>
            {isLoggedIn && (
              <View variants={['gap:2']}>
                <Link to='/product/create'>
                  <Button variants={['paddingHorizontal:4']} text='Sell' />
                </Link>
                {renderNotificationButton()}
              </View>
            )}
            <View variants={['marginLeft:2']}>
              <AvatarSkeleton ready={appMounted}>
                <Touchable onPress={handleProfile}>
                  <Avatar
                    profile={{
                      avatar: profile?.avatar,
                      username: profile?.username,
                      first_name: profile?.username,
                    }}
                    variants={['small', `${isLoggedIn && 'bigRadius'}`]}
                    styles={{
                      general: styles.avatarWrapper,
                      image: styles.avatarImage,
                      wrapper: styles.avatarImageWrapper,
                      icon: styles.avatarIcon,
                    }}
                  />
                </Touchable>
              </AvatarSkeleton>
            </View>
          </View>
        </View>
      </View>
      {filterProps && <FiltersRow {...filterProps} />}
      <Drawer
        open={drawerOpen}
        toggle={() => setDrawerOpen(!drawerOpen)}
        position={'left'}
        styles={{
          box: { backgroundColor: Theme.colors.light.white, width: '100%' },
        }}
      >
        <CenterWrapper
          styles={{
            wrapper: styles.drawerCenterWrapper,
            innerWrapper: styles.drawerInnerWrapper,
          }}
        >
          <Button
            icon={'close'}
            onPress={() => {
              setDrawerOpen(false)
            }}
            variants={[
              'xSmallIcon',
              'marginLeft:auto',
              'alignCenter',
              'justifyCenter',
            ]}
            css={styles.menuButton}
          />
          {isLoggedIn ? (
            <>
              <View variants={['gap:1']}>
                <AvatarSkeleton ready={appMounted}>
                  <Touchable onPress={handleProfile}>
                    <Avatar
                      profile={{
                        avatar: profile?.avatar,
                        username: profile?.username,
                        first_name: profile?.username,
                      }}
                      variants={['small', `${isLoggedIn && 'bigRadius'}`]}
                      styles={{
                        general: styles.avatarWrapper,
                        image: styles.avatarImage,
                        wrapper: styles.avatarImageWrapper,
                        icon: styles.avatarIcon,
                      }}
                    />
                  </Touchable>
                </AvatarSkeleton>
                <View variants={['column', 'gap:1']}>
                  <Text
                    variants={['h3', 'veryBold']}
                    text={profile?.username}
                  />
                  <Text
                    variants={['p2']}
                    text={`Joined in ${joynedMonth} ${joynedYear}`}
                  />
                </View>
              </View>
              <Link to='/product/create'>
                <Button
                  variants={['paddingHorizontal:4', 'fullWidth']}
                  text='Sell'
                />
              </Link>
            </>
          ) : (
            <>
              <Button
                text={'Login'} variants={['transparent', 'fullWidth']} onPress={handleProfile} />
            </>
          )}
          <View variants={['column']}>
            <View variants={['column', 'gap:3']}>
              {renderMenuItem('/', 'home', 'Home')}
              {isLoggedIn && (
                <>
                  {renderMenuItem(
                    '/account/inventory',
                    'inventory',
                    'Inventory',
                  )}
                  {renderMenuItem('/account/notifications', 'bell', 'Notifications')}
                  {renderMenuItem('/account/offers', 'dollar', 'Offers')}
                  {renderMenuItem('/account/messages', 'message', 'Messages')}
                  {renderMenuItem('/account/edit', 'user', 'Account')}
                  {renderMenuItem('/account/disclaimer', 'files', 'Disclaimer')}
                  {renderMenuItem('/account/about', 'info', 'About')}
                </>
              )}
            </View>
          </View>
        </CenterWrapper>
      </Drawer>
    </CenterWrapper>
  )
}

export default Header

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  headerWrapper: {
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: Theme.values.zIndex.nav,
    backgroundColor: Theme.colors.light.white,
    ...Theme.spacing.paddingVertical(2),
    // boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
    [Theme.media.down('mid')]: {
      height: 90,
    },
  },
  headerFilterWrapper: {
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: Theme.values.zIndex.nav,
    backgroundColor: Theme.colors.light.white,
    ...Theme.spacing.paddingVertical(2),
    // boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
    [Theme.media.down('mid')]: {
      height: 160,
    },
  },
  avatarWrapper: {
    height: Theme.values.buttonAndInputHeight,
    width: '48px',
  },
  avatarImageWrapper: {
    width: '48px',
    // width: Theme.values.buttonAndInputHeight,
  },
  bellIcon: {
    width: 24,
    height: 24,
    color: Theme.colors.light.black,
    svg: {
      path: {
        width: 90,
        height: 90,
        strokeWidth: '1.5px',
        stroke: '#222',
      },
    },
  },
  avatarIcon: {
    backgroundColor: 'white',
    border: '1px solid rgb(222, 222, 222)',
    '&:hover': {
      background: 'rgba(54, 110, 101, 0.05)',
    },
    svg: {
      path: {
        strokeWidth: '1.5px',
        stroke: '#222',
      },
    },
  },
  avatarImage: {
    borderRadius: '50%',
    width: '48px',
  },
  headerInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerFullWidth: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100',
    paddingRight: `${Theme.spacing.value(2)}px !important`,
    paddingLeft: `${Theme.spacing.value(2)}px !important`,
  },
  wrapper: {
    ...Theme.presets.row,
    ...Theme.presets.justifySpaceBetween,
    ...Theme.presets.alignCenter,
    flex: 1,
  },
  logo: {
    height: Theme.values.maxHeaderMenuHeight * 0.6,
    width: '50px',
    flexShrink: 0,
    marginLeft: Theme.spacing.value(1),
  },
  logoImage: {
    objectFit: 'cover',
    width: '50px',
    height: '50px',
    // width: 'max(100px, min(calc(0px + 9vw), 20px))',
    [Theme.media.is('small')]: {
      marginLeft: Theme.spacing.value(2),
    },
  },
  logoContainerMobile: {
    [Theme.media.is('small')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  nav: {
    width: '100%',
  },
  navLink: {
    ...Theme.spacing.marginHorizontal(2),
    [Theme.media.down('mid')]: {
      color: theme.colors.gray,
      ...Theme.spacing.marginVertical(3),
    },
  },
  menuButton: {
    opacity: 1,
    visibility: 'visible',
    borderRadius: '50%',
    padding: 0,
    width: 48,
    backgroundColor: theme.colors.white,
    border: `1px solid rgb(222, 222, 222)`,
    ':hover': {
      background: 'rgba(54, 110, 101, 0.05)',
    },
  },
  notificationsButton: {
    borderRadius: '50%',
    padding: 0,
    width: 48,
    backgroundColor: theme.colors.white,
    border: `1px solid rgb(222, 222, 222)`,
    ':hover': {
      background: 'rgba(54, 110, 101, 0.05)',
    },
  },
  drawerCenterWrapper: {
    alignItems: 'unset',
  },
  drawerInnerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: Theme.spacing.value(4),
  },
  menuLink: {
    display: 'flex',
    alignItems: 'center',
    gap: Theme.spacing.value(1),
  },
  profileOpen: {
    opacity: '1',
    visibility: 'visible',
  },
  profileClosed: {
    opacity: '0',
    visibility: 'hidden',
  },
  searchIcon: {
    color: 'black',

    width: 25,
    height: 25,
    stroke: 'black',
    // fill: 'black',

    path: {
      stroke: 'black',
    },
  },
  searchButton: {
    color: 'black',
    padding: 0,

    width: '48px !important',
    height: 48,
    stroke: 'black',
    // fill: 'black',

    path: {
      stroke: 'black',
    },
  },
  menuIcon: {
    color: 'rgb(150, 150, 150)',

    width: 25,
    height: 25,
    stroke: 'rgb(150, 150, 150)',
    // fill: 'black',

    path: {
      stroke: 'rgb(150, 150, 150)',
    },
  },
  disabled: {
    display: 'none',
  },
  searchMobile: {},
  badge: {
    backgroundColor: '#E25656',
    width: 20,
    height: 20,
    borderRadius: '50%',
  },
  badgeText: {
    color: Theme.colors.light.white,
    fontSize: 11,
  },
}))
