import {
  Button,
  CenterWrapper,
  View,
  Text,
  Icon,
  Touchable,
  Theme,
  variantProvider,
  logger,
} from '@/app'
import {
  onUpdate,
  onMount,
  useComponentStyle,
  useSearchParams,
  useForm,
} from '@codeleap/common'

export type NoItemsProps = {
  icon?: string
  title?: string
  subtitle?: string
  wrapperStyle?: any
}

export const NoItems: React.FC<NoItemsProps> = (props) => {
  const { icon, title, subtitle, wrapperStyle } = props

  const styles = useComponentStyle(componentStyles)

  return (
    <View css={[styles.wrapper, wrapperStyle]} variants={['column', 'gap:2', 'alignCenter', 'alignSelfCenter']}>
      <Icon name={icon} style={styles.resultsIcon} />
      <Text
        variants={['h3']}
        text={title}
        css={styles.resultsTitle}
      />
      <Text
        variants={['h4', 'textCenter']}
        text={subtitle}
        css={styles.resultsSubtitle}
      />
    </View>
  )
}

const componentStyles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    maxWidth: '40%',
  },
  resultsIcon: {
    width: 125,
    height: 125,
  },
  resultsTitle: {
    color: '#999999',
  },
  resultsSubtitle: {
    color: '#B3B3B3',
  },
}))
