export const Google = (props) => {
  return (
    <svg
      width='2em'
      height='2em'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.1688 11.0453C21.1688 10.2795 21.1001 9.54309 20.9724 8.83618H10.8008V13.0138H16.6131C16.3628 14.3638 15.6019 15.5076 14.4581 16.2735V18.9833H17.9484C19.9906 17.1031 21.1688 14.3344 21.1688 11.0453Z'
        fill='#4285F4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.7997 21.5998C13.7157 21.5998 16.1604 20.6327 17.9473 18.9833L14.457 16.2734C13.4899 16.9214 12.2528 17.3044 10.7997 17.3044C7.9868 17.3044 5.60589 15.4045 4.75662 12.8518H1.14844V15.65C2.92553 19.1796 6.57789 21.5998 10.7997 21.5998Z'
        fill='#34A853'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.75691 12.8521C4.54091 12.2041 4.41818 11.512 4.41818 10.8001C4.41818 10.0883 4.54091 9.39613 4.75691 8.74813V5.94995H1.14873C0.417273 7.40795 0 9.05741 0 10.8001C0 12.5429 0.417273 14.1923 1.14873 15.6503L4.75691 12.8521Z'
        fill='#FBBC05'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.7997 4.29545C12.3853 4.29545 13.809 4.84036 14.9283 5.91055L18.0259 2.81291C16.1555 1.07018 13.7108 0 10.7997 0C6.57789 0 2.92553 2.42018 1.14844 5.94982L4.75662 8.748C5.60589 6.19527 7.9868 4.29545 10.7997 4.29545Z'
        fill='#EA4335'
      />
    </svg>
  );
};
