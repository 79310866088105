export const NoInventory = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 143 142'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M97.978 18.0001L17.5 54.6107L25.8206 72.9012L106.299 36.2905L97.978 18.0001Z'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M99.3281 56.0116C103.616 56.2689 109.963 55.3403 115.403 51.7902C116.925 50.7973 118.375 49.5994 119.69 48.1651C120.059 47.763 120.417 47.3423 120.762 46.9023M126.12 29.2195C126.12 32.5575 125.735 35.5045 125.049 38.1018'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
      />
      <path
        d='M106.561 69.7554V122H34.2227V69.7554'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M62.3555 85.8306H78.4308'
        stroke='#D9D9D9'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
