import { useAppSelector } from '@/lib';
import { onUpdate } from '@codeleap/common';

export function Auth({ onUnauthorized = null, children }) {
  const { isLoggedIn, appMounted } = useAppSelector((store) => store.Session);
  onUpdate(() => {
    if (!isLoggedIn && appMounted) {
      onUnauthorized?.();
    }
  }, [isLoggedIn, appMounted, onUnauthorized]);

  return isLoggedIn ? children : null;
}
