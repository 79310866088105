import { Select, Text, TextInput, Theme, variantProvider, logger, View } from '@/app'
import { assignTextStyle } from '@codeleap/common'
import { useAppSelector } from '@/lib'
import { defaultStyles, customStyles } from '@/app/stylesheets/ReactSelect'

export type ProfileFormProps = {
  form: any
}

export default function AccountProfile(props) {
  const { form, currencyOptions } = props

  const { profile } = useAppSelector((store) => store.Session)
  // const { options } = useAppSelector((store) => store.Categories)

  // const currenciesOptions = options?.find(opt => opt.title === 'Currency Name')?.selects

  // const currencyOptions = currenciesOptions?.map((currency) => { return { value: currency.id, label: `${currency.title}` } })

  return (
    <View variants={['column']}>
      <Text variants={['h2', 'marginBottom:2']} text='Account' />
      <View variants={['gap:2']} css={styles.profileInputsWrapper}>
        <TextInput
          variants={['withHover']}
          placeholder='Alicia'
          {...form.register('username')}
        />
        <TextInput
          variants={['withHover']}
          placeholder='aliciak@email.co.uk'
          {...form.register('email')}
        />
        <Select
          label={'Default Currency'}
          placeholder={'Select a Currency'}
          {...form.register('data.currency')}
          arrowIconName='arrowDown'
          css={styles.select}
          customStyles={defaultStyles}
          options={currencyOptions}
        />
        <View variants={['column', 'fullWidth']}>
          <Text
            text='Distance Preferences'
            variants={['marginBottom:1']}
            css={styles.othersText}

          />
          <View variants={['gap:1']} css={styles.distanceWrapper}>
            <Select
              {...form.register('data.distance_type')}
              customStyles={defaultStyles}
              styles={{
                label: { display: 'none' },
              }}

            />
            <TextInput
              variants={['withHover']}
              styles={{
                label: { display: 'none' },
              }}
              type={'number'}
              placeholder='5'
              {...form.register('data.distance_value')}
            />
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  {
    profileInputsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      [Theme.media.down('mid')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
    },
    select: {
      height: Theme.values.buttonAndInputHeight,
    },
    distanceWrapper: {
      display: 'grid',
      gridTemplateColumns: '0.3fr 1fr',
      [Theme.media.down('mid')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
    },
    othersText: {
      color: Theme.colors.light.primaryText,
    },
    label: {
      ...assignTextStyle('p1')(Theme).text,
    },
  },
  true,
)
