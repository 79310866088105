import React from 'react';
import { View } from '@/app';
import { variantProvider } from '@/app/theme';
import AppStatusOverlay from './AppStatus';

function Overlays() {
  return (
    <View css={styles.overlay}>
      <AppStatusOverlay />
    </View>
  );
}

const styles = variantProvider.createComponentStyle({
  overlay: {
    zIndex: 2000000,
  },
}, true);

export default Overlays;
